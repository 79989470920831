import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { subMonths } from 'date-fns';

import PageHeader from 'components/ui-kit/PageHeader';
import PageContainer from 'components/ui-kit/PageContainer';
import FilterTool from 'components/ui-kit/FilterTool';
import Table from 'components/ui-kit/Table';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import {
  getInitialDates,
  getExportData,
  getBackendDateFormat,
} from 'utils/functions';
import Formatter from 'utils/formatter';
import { onlyNumbers } from 'utils/regex';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import {
  STANDARD_ERROR,
  NO_DATA_FOUND,
  GETTING_PDF,
  GETTING_XLS,
  PDF_ERROR,
  PDF_READY,
  XLS_ERROR,
  XLS_READY,
} from 'utils/messages';
import exportPDF from 'services/pdf';
import exportXLS from 'services/xls';

const defaultColumns = [
  { label: 'Associado', field: 'associateName' },
  {
    label: 'Placa',
    field: 'vehiclePlate',
  },
  { label: 'Qtd. de Acionamentos', field: 'numberActivations' },
  {
    label: 'Tipos de Orçamento',
    field: 'typesBudget',
  },
  {
    label: 'Valor Total Fechado',
    field: 'totalClosedValue',
    display: 'totalClosedValueBRL',
  },
  {
    label: 'Valor Total Pago Excedente',
    field: 'totalExcessPaidAmount',
    display: 'totalExcessPaidAmountBRL',
  },
  {
    label: 'Valor Total Pago Associação',
    field: 'totalAssociationPaidAmount',
    display: 'totalAssociationPaidAmountBRL',
  },
];

const UseOfFranchise = () => {
  const [franchiseList, setFranchiseList] = useState([]);

  const [associate, setAssociate] = useState();
  const [vehicle, setVehicle] = useState();
  const [numActivationsInput, setNumActivationsInput] = useState();
  const [searchHistory, setSearchHistory] = useState({});

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSeletRef = useRef(null);
  const tableRef = useRef(null);

  const { user } = useAuth();
  const { defaultDateFormat, exportDateFormat, formatToBRL } = new Formatter();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const inputs = [
    {
      type: 'select',
      maxWidth: '300px',
      endpoint: 'clients/all?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Associado',
      onOptionChange: option => {
        if (vehicle?.id) vehicleSeletRef.current?.clearValue();

        setAssociate(option);
      },
    },
    {
      ref: vehicleSeletRef,
      type: 'select',
      maxWidth: '220px',
      isDisabled: !associate?.id,
      endpoint: `vehicles/?client_id=${associate?.id}`,
      fieldToOptionLabel: 'plate',
      fieldToOptionValue: 'id',
      placeholder: 'Selecione uma Placa',
      onOptionChange: option => setVehicle(option),
    },
    {
      onChange: e => {
        if (!e.target.value.match(onlyNumbers)) return;

        setNumActivationsInput(e.target.value);
      },
      value: numActivationsInput,
      placeholder: 'Qtd. de Acionamentos',
      size: 'sm',
      height: '33px',
      maxWidth: '190px',
      $variant: 'registry',
    },
  ];

  const getFranchises = useCallback(async () => {
    setIsLoading(true);

    setFranchiseList([]);
    setSearchHistory({});

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get('reports/franchise', {
        onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          occurrency_created_on_from: dates?.startDate,
          occurrency_created_on_to: dates?.endDate,
          client_id: associate?.value,
          vehicle_id: vehicle?.value,
          amount_occurrences_to: formatParam(numActivationsInput),
        },
      });

      if (status !== 200) throw new Error();

      const franchiseListLog = response.data.map(item => ({
        associateName: item?.associate?.name,
        vehiclePlate: item?.vehicle?.name,
        numberActivations: item?.amount_occurrences || 0,
        typesBudget: item?.types_budget?.map(v => v?.name || '-').join(', '),
        totalClosedValue: item?.cost_total_final || 0,
        totalExcessPaidAmount: item?.cost_total_excess || 0,
        totalAssociationPaidAmount: item?.cost_total_covered || 0,
      }));

      if (franchiseListLog.length > 0) {
        setSearchHistory({
          startDate: dates?.startDate,
          endDate: dates?.endDate,
          associateName: associate?.label,
          vehiclePlate: vehicle?.label,
        });
      } else setSearchHistory({});

      setFranchiseList(franchiseListLog);

      setIsLoading(false);
      resetProgress();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
      setIsLoading(false);
      resetProgress();
    }
  }, [dates, associate, vehicle, numActivationsInput, searchHistory]);

  const dataTable = useMemo(() => {
    return franchiseList.map(item => ({
      ...item,
      associateName: item?.associateName || '-',
      vehiclePlate: item?.vehiclePlate || '-',
      numberActivations: item.numberActivations.toLocaleString('pt-BR'),
      typesBudget: item?.typesBudget || '-',
      totalClosedValue: item.totalClosedValue,
      totalClosedValueBRL: formatToBRL(item.totalClosedValue),
      totalExcessPaidAmount: item.totalExcessPaidAmount,
      totalExcessPaidAmountBRL: formatToBRL(item.totalExcessPaidAmount),
      totalAssociationPaidAmount: item.totalAssociationPaidAmount,
      totalAssociationPaidAmountBRL: formatToBRL(
        item.totalAssociationPaidAmount,
      ),
    }));
  }, [franchiseList]);

  const handleExportData = () => {
    const formatting = {
      totalClosedValue: formatToBRL,
      totalExcessPaidAmount: formatToBRL,
      totalAssociationPaidAmount: formatToBRL,
    };

    try {
      const { items, visibleCols } = getExportData(
        tableRef.current?.getData(),
        columns,
        {
          formatting,
          validateColumns: true,
        },
      );

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      (searchHistory.associateName
        ? 'Associado: ' + searchHistory.associateName + '\n'
        : '') +
      (searchHistory.vehiclePlate
        ? 'Placa: ' + searchHistory.vehiclePlate + '\n'
        : '') +
      'Período: ' +
      defaultDateFormat(searchHistory.startDate) +
      ' a ' +
      defaultDateFormat(searchHistory.endDate) +
      '\nProcessado em: ' +
      defaultDateFormat(new Date()) +
      '\nTotal de registros: ' +
      data.length;

    const exportData = {
      headers,
      title: 'Relatório de Utilização de Franquia',
      description,
      data,
    };

    exportPDF(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: PDF_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: PDF_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  const onExportXLS = async () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      headers,
      title: 'Relatório de Utilização de Franquia',
      data,
    };

    exportXLS(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: XLS_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: XLS_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    getFranchises();

    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Relatórios', 'Utilização de Franquia']}
        onExportPDF={franchiseList.length > 0 && onExportPDF}
        onExportXLS={franchiseList.length > 0 && onExportXLS}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter={true}
        disableFilter={franchiseList.length === 0}
        inputs={inputs}
        defaultColumns={columns}
        disableSearch={false}
        onSubmit={getFranchises}
        onFilterColumns={setColumns}
        initialDates={getInitialDates({
          months: 1,
        })}
      />

      {franchiseList.length > 0 && (
        <Table ref={tableRef} columns={columns} data={dataTable} />
      )}
    </PageContainer>
  );
};

export default UseOfFranchise;
