import { Navigate } from 'react-router-dom';

import AttendancesPerUser from 'pages/Main/pages/Reports/AttendancesPerUser';
import Financial from 'pages/Main/pages/Reports/Financial';
import Occurrences from 'pages/Main/pages/Reports/Occurrences';
import Budgets from 'pages/Main/pages/Reports/Budgets';
import UseOfFranchise from 'pages/Main/pages/Reports/UseOfFranchise';

import PrivateRoute from 'routes/PrivateRoute';

import { SUBROUTES, MODULES, VISUALIZE } from 'utils/constants';

const { path, subroutes } = SUBROUTES.reports;
const { main, sub } = MODULES.reports;

const ReportsRoutes = [
  {
    path: subroutes.attendancesPerUser.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.attendancesPerUser}
        permission={VISUALIZE}>
        <AttendancesPerUser />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.financial.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.financial}
        permission={VISUALIZE}>
        <Financial />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.occurrences.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.occurrences}
        permission={VISUALIZE}>
        <Occurrences />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.budgets.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.budgets}
        permission={VISUALIZE}>
        <Budgets />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.useOfFranchise.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.useOfFranchise}
        permission={VISUALIZE}>
        <UseOfFranchise />
      </PrivateRoute>
    ),
  },

  {
    path,
    element: <Navigate to={subroutes.occurrences.path} replace />,
  },
];

export default ReportsRoutes;
