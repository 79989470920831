import { memo } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import useAuth from 'hooks/useAuth';

const { LOGIN, HOME } = ROUTES;
import { DEV_ID } from 'utils/constants';

const PrivateRoute = ({
  children,
  module = '',
  submodule = '',
  permission = '',
  invertValidation = false,
}) => {
  const { user, userLoading } = useAuth();

  const isLoggedIn = !!Object.keys(user).length;

  if (invertValidation) {
    if (!isLoggedIn) return children;
    return <Navigate to={HOME} replace />;
  } else {
    if (userLoading) return;

    if (!isLoggedIn) return <Navigate to={LOGIN} replace />;

    const filteredModules = user?.role?.modules
      .map(mod => {
        if (user.role.id === DEV_ID) {
          return mod;
        } else {
          return {
            ...mod,
            submodules: mod.submodules.filter(sub => sub.isActive),
          };
        }
      })
      .filter(mod => user?.role?.id === DEV_ID || mod.isActive);

    const hasPermission = filteredModules.some(mod => {
      if (mod.identifier === module) {
        if (submodule) {
          const submod = mod.submodules.find(
            sub => sub.identifier === submodule,
          );
          if (submod) {
            return submod.permissions[permission];
          }
          return false;
        }
        return mod.permissions[permission];
      }
      return false;
    });

    if (!hasPermission && module !== 'main') {
      return <Navigate to={HOME} replace />;
    }

    return children;
  }
};

export default memo(PrivateRoute);
