import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import Select from '../Select';
import Input from '../Input';
import Buttons from './Buttons';
import Checkbox from '../Checkbox';
import DateInput from '../DateInput';
import DatePicker from '../DatePicker';
import FilterModal from './FilterModal';

import { getInitialDates } from 'utils/functions';
import { areEqual } from 'react-window';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit rounded-2xl bg-white box-border p-4 shadow-xl gap-5`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full h-fit items-start gap-4`}
`;

const CheckboxContainer = styled.div`
  ${tw`flex items-center w-fit h-fit relative`}
`;

const Text = styled.p`
  ${tw`text-themes-dark-gray p-0 m-0 text-sm w-fit h-fit mr-2`}
`;

const FilterTool = ({
  children,
  disableSearch = false, // desabilitar a busca
  disableInputs = false, // desabilitar inputs
  disableFilter = false, // desabilitar o filtro
  useColumnFilter = false, // usar o filtro de colunas
  disableDatePicker = false, // desabilitar o date picker
  useDatePicker = true, // usar ou nao o calendario
  useSearch = true,
  minCalendarDate = undefined, // data minima do calendario
  maxRange = 'none', // range maxima do calendario
  customPreSelection = [], // customizacao para a pre selecao do calendario
  initialDates = getInitialDates({
    hours: 3,
  }), // datas inicias do calendario
  defaultColumns = [], // colunas
  inputs = [
    {
      id: '',
      name: '',
      type: 'select',
      value: 'value',
      placeholder: 'placeholder',
      disabled: false,
      options: [{}],
      optionValue: 'test',
      optionLabel: 'test',
      onChange: () => {},
      endpoint: '',
      hide: false,
    },
  ], // inputs
  useClear = true,
  getDates = () => {},
  onChangeDates = () => {},
  onFilterColumns = () => {},
  getLocaleDates = () => {},
  onSubmit = async () => {},
  onClearFilter = () => {},
}) => {
  const [clear, setClear] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const renderInputs = () => {
    if (inputs.length === 0) return;

    return inputs.map((input, index) => {
      if (input.hide) return;

      const key = `filter-tool-input${index}`;

      const getInputType = () => {
        switch (input?.type) {
          case 'select':
            return (
              <Select
                {...input}
                size={input.padding || 'nano'}
                clear={clear}
                key={key}
              />
            );
          case 'date':
            return <DateInput {...input} clear={clear} key={key} />;
          case 'checkbox':
            return (
              <CheckboxContainer key={key}>
                <Text>{input.text}</Text>

                <Checkbox {...input} />
              </CheckboxContainer>
            );
          default:
            return <Input {...input} clear={clear} key={key} />;
        }
      };

      return getInputType();
    });
  };

  const onPressEnter = event => {
    const validKeys = ['NumpadEnter', 'Enter'];

    if (disableSearch || !validKeys.includes(event.code)) return;

    onSubmit();
  };

  useEffect(() => {
    window.addEventListener('keypress', onPressEnter);

    return () => {
      window.removeEventListener('keypress', onPressEnter);
    };
  }, [onSubmit]);

  useEffect(() => {
    if (clear) setClear(false);
  }, [clear]);

  return (
    <Container>
      <FilterModal
        isOpen={isOpen}
        onSetColumns={onFilterColumns}
        onClose={() => setIsOpen(false)}
        columns={defaultColumns.map(column => ({
          ...column,
          hide: column.hide || false,
        }))}
      />

      <Wrapper>
        {useDatePicker && (
          <DatePicker
            maxRange={maxRange}
            minDate={minCalendarDate}
            initialValues={initialDates}
            disabled={disableDatePicker}
            disableInputs={disableInputs}
            customPreSelection={customPreSelection}
            getDates={getDates}
            onChange={onChangeDates}
            getLocaleDates={getLocaleDates}
          />
        )}

        {renderInputs()}
      </Wrapper>
      <Buttons
        disableSearch={disableSearch}
        disableFilter={disableFilter}
        useSearch={useSearch}
        useClear={useClear}
        useColumnFilter={useColumnFilter}
        onOpenFilterModal={() => setIsOpen(true)}
        onClearFilter={() => {
          setClear(true);

          onClearFilter();
        }}
        onSearch={async () => {
          await onSubmit();
        }}>
        {children}
      </Buttons>
    </Container>
  );
};

export default memo(FilterTool, areEqual);
