import { memo, useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { motion, AnimatePresence } from 'framer-motion';

import Checkbox from 'components/ui-kit/Checkbox';
import Input from 'components/ui-kit/Input';

const Container = styled(motion.div)`
  ${tw`h-fit w-[230px] absolute top-full right-1/2 translate-x-1/2 select-none`}
`;

const Box = styled(motion.div)`
  ${tw`flex flex-col w-full h-full bg-white gap-1 pt-3 p-2
  rounded-lg border-solid border-2 border-[var(--gray-dark)]`}
`;

const List = styled.ul`
  ${tw`flex flex-col w-full h-full gap-2`}
`;

const Title = styled.h3`
  ${tw`text-[var(--royal-blue-theme)] text-base font-bold py-1`};
`;

const boxVariants = {
  open: {
    clipPath: 'inset(0% 0% 0% 0%)',
    transition: {
      type: 'spring',
      duration: 0.4,
    },
  },
  closed: {
    clipPath: 'inset(10% 50% 90% 50%)',
    transition: {
      type: 'spring',
      duration: 0.2,
    },
  },
};

const Filter = ({
  isOpen = false,
  options = [],
  searchInput,
  onSearchChange = () => {},
  onOptionChange = () => {},
}) => {
  const handleOptionChange = useCallback(
    event => {
      onOptionChange(event.target.id);
    },
    [onOptionChange],
  );

  return (
    <AnimatePresence>
      <Container
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, display: 'flex' },
          closed: {
            opacity: 0,
            transitionEnd: {
              display: 'none',
            },
          },
        }}>
        <Box variants={boxVariants}>
          <Input
            id="search-input"
            maxWidth="100%"
            maxLength={40}
            placeholder=""
            label={{ text: 'Buscar por' }}
            value={searchInput}
            onChange={e => onSearchChange(e.target.value)}
          />
          <Title>Ocorrências visíveis:</Title>
          <List>
            {options.map(option => (
              <Checkbox
                key={option.id}
                id={option.id}
                isChecked={option.selected}
                onChange={handleOptionChange}
                label={{ text: option.label }}
              />
            ))}
          </List>
        </Box>
      </Container>
    </AnimatePresence>
  );
};

export default memo(Filter);
