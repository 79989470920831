import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { Radio } from 'react-loader-spinner';

import { clipPath } from 'helpers/animations';

const statusStyles = {
  CONNECTING: {
    title: 'Conectando...',
    color: 'var(--warning-dark)',
    cursor: 'wait',
  },
  CONNECTED: {
    title: 'Conectado',
    color: 'var(--green-theme)',
    cursor: 'default',
  },
  DISCONNECTED: {
    title: 'Desconetado',
    color: 'var(--red-theme)',
    cursor: 'not-allowed',
  },
};

const Border = styled.div`
  ${tw`flex bg-transparent items-center w-fit justify-center relative select-none`}

  color: ${props => statusStyles[props.$status].color};
  cursor: ${props => statusStyles[props.$status].cursor};

  &::before {
    content: '';
    ${tw`w-full h-full rounded-[10px] absolute transition-all`}
    background: ${props => statusStyles[props.$status].color};
    animation: ${props =>
      props.$status === 'CONNECTING'
        ? css`
            ${clipPath} 1.5s linear infinite
          `
        : 'none'};
    ${props => props.$status === 'DISCONNECTED' && tw`animate-pulse`}
  }
`;

const Wrapper = styled.div`
  ${tw`flex flex-row w-fit items-center gap-1 z-10 px-4 overflow-hidden m-[3px]
  text-sm font-bold bg-gray-bg rounded-lg`}
`;

const ConnectionStatus = ({ status }) => {
  const statusProps = statusStyles[status];
  const colorsArray = Array(3).fill(statusProps.color);

  return (
    <Border $status={status}>
      <Wrapper>
        <Radio
          width={25}
          height={25}
          colors={colorsArray}
          wrapperClass="wifi-indicator"
        />
        {statusProps.title}
      </Wrapper>
    </Border>
  );
};

export default ConnectionStatus;
