import {
  useCallback,
  useState,
  useLayoutEffect,
  useRef,
  useMemo,
  memo,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import TitleBox from 'components/ui-kit/TitleBox';
import Table from 'components/ui-kit/Table';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import Loader from 'components/ui-kit/Loader';
import { StartServiceContainer, StartServiceCard } from './BasicInfosForm';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import Formatter from 'utils/formatter';
import useAuth from 'hooks/useAuth';

import { STANDARD_ERROR, NO_DATA_FOUND } from 'utils/messages';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit gap-5 p-2 items-center`}
`;

const Text = styled.span`
  ${tw`text-sm text-center italic text-disabled-dark-contrast truncate font-semibold`}
`;

const defaultColumns = [
  {
    label: 'Data',
    field: 'date',
    display: 'fmtdDate',
  },
  { label: 'Evento', field: 'event' },
  { label: 'Operação', field: 'operation' },
  { label: 'Informação anterior', field: 'prevInfo' },
  { label: 'Nova informação', field: 'newInfo' },
  {
    label: 'Usuário',
    field: 'userName',
  },
];

const History = ({
  routingIsLoading = false,
  canEdit = false,
  canDelete = false,
  occurrenceInfo = {},
  serviceHasChanged = false,
  syncData = async () => {},
  startService = async () => {},
  resetService = () => {},
  onDeleteOccurrence = () => {},
}) => {
  const [historyList, setHistoryList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef(null);

  const { user } = useAuth();
  const { defaultDateFormat } = new Formatter();

  const getHistory = useCallback(async id => {
    setIsLoading(true);
    setHistoryList([]);

    try {
      const { data: response, status } = await api.get(
        `occurrencies/${id}/history/events`,
        {
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      const historyListLog = response.data.map(item => ({
        date: item?.created_on,
        event: item?.event,
        operation: item?.operation,
        prevInfo: item?.old_value,
        newInfo: item?.new_value,
        userName: item?.user?.display_name,
      }));

      setHistoryList(historyListLog);
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const dataTable = useMemo(() => {
    return historyList.map(item => ({
      ...item,
      fmtdDate: item.date ? defaultDateFormat(item.date) : '-',
      event: item.event || '-',
      operation: item?.operation || '-',
      prevInfo: item?.prevInfo || '-',
      newInfo: item?.newInfo || '-',
      userName: item?.userName || '-',
    }));
  }, [historyList]);

  useLayoutEffect(() => {
    if (serviceHasChanged) return;

    const fetchHistory = async () => await getHistory(occurrenceInfo?.id);
    fetchHistory();

    return () => {
      toast.dismiss();
    };
  }, [serviceHasChanged, occurrenceInfo?.id]);

  return (
    <Container>
      {serviceHasChanged && occurrenceInfo?.isBeingServedBy?.id ? (
        <StartServiceContainer>
          <StartServiceCard>
            <span className="title">Atenção!</span>
            <span className="observation">
              Esta ocorrência está em atendimento por
              <span style={{ fontWeight: 700 }}>
                {`: ${occurrenceInfo.isBeingServedBy.name}.`}
              </span>
              {canEdit && (
                <>
                  <br />
                  Se você assumir, todas as informações preenchidas
                  anteriormente serão descartadas.
                  <br />
                  Você deseja prosseguir e assumir o atendimento?
                </>
              )}
            </span>
            {canEdit && (
              <PrimaryButton
                type="button"
                onClick={async () => {
                  await syncData().then(async () => {
                    await startService();
                    await getHistory(occurrenceInfo?.id);
                  });
                }}>
                Sim, assumir atendimento
              </PrimaryButton>
            )}
          </StartServiceCard>
        </StartServiceContainer>
      ) : (
        serviceHasChanged && (
          <StartServiceContainer>
            <StartServiceCard>
              <span className="title">Atenção!</span>
              <span className="observation">
                O atendimento desta ocorrência foi iniciado por outro usuário.
                <br />
                Você deseja sincronizar as modificações feitas?
              </span>
              <PrimaryButton
                type="button"
                onClick={async () =>
                  await syncData().then(async () => {
                    resetService();
                    await getHistory(occurrenceInfo?.id);
                  })
                }>
                Sim, sincronizar alterações
              </PrimaryButton>
            </StartServiceCard>
          </StartServiceContainer>
        )
      )}

      <TitleBox
        title={{ text: 'Histórico de Interações' }}
        style={{
          minHeight: '20rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {historyList.length ? (
          <Table ref={tableRef} columns={defaultColumns} data={dataTable} />
        ) : isLoading ? (
          <Loader center full />
        ) : (
          <Text>Nenhum histórico encontrado...</Text>
        )}
      </TitleBox>

      {canDelete && (
        <PrimaryButton
          maxWidth="300px"
          bg="red"
          onClick={onDeleteOccurrence}
          disabled={routingIsLoading}>
          Excluir Ocorrência
        </PrimaryButton>
      )}
    </Container>
  );
};

export default memo(History);
