import { memo, useState, useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import DefaultModal from 'components/ui-kit/DefaultModal';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import api from 'services/api';
import { BEARER } from 'utils/constants';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const Container = styled.div`
  ${tw`flex flex-col w-full h-full items-center gap-4 overflow-auto`}

  .title {
    ${tw`text-lg text-disabled-dark font-bold`}
  }

  .text {
    ${tw`text-sm text-disabled-dark font-semibold`}
  }

  & > * {
    ${tw`last:mt-8`}
  }
`;

const OccurrenceClosureModal = ({
  isOpen = false,
  occurrenceInfo = {},
  onClose = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const onClosureOccurrence = useCallback(async () => {
    setIsLoading(true);
    try {
      const { status } = await api.post(
        `/occurrencies/${occurrenceInfo?.id}/close`,
        null,
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      toast.success('Ocorrência encerrada com sucesso');
      onClose();
    } catch {
      toast.error('Falha ao encerrar Ocorrência');
    } finally {
      setIsLoading(true);
      resetProgress();
    }
  }, [user, isOpen]);

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      onBackgroundClick={onClose}
      onEscapeKeyDown={onClose}>
      <LoadingScreen isLoading={isLoading} progress={progress} />
      <Container>
        <span className="title">Aviso! </span>
        <span className="text">
          Todos os tratamentos relacionados a ocorrência
          <span style={{ fontWeight: 700 }}>
            {` N° ${occurrenceInfo?.numProtocol} `}
          </span>
          já foram realizados.
          <br />
          Se os serviços prestados foram concluídos, por favor, encerre a
          ocorrência.
        </span>
        <PrimaryButton
          maxWidth="300px"
          type="button"
          bg="green"
          onClick={onClosureOccurrence}
          disabled={isLoading}>
          Encerrar Ocorrência
        </PrimaryButton>
      </Container>
    </DefaultModal>
  );
};

export default memo(OccurrenceClosureModal);
