export const breakpoints = [480, 768, 900, 1024, 1280, 1366, 1600, 1920];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.tbt = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.dktp = breakpoints[4];
breakpoints['xl'] = breakpoints[5];
breakpoints['2xl'] = breakpoints[6];
breakpoints['3xl'] = breakpoints[7];
