import { getItem } from 'providers/storage';

export default class SSEManager {
  constructor(getStatus = () => {}) {
    this.es = undefined;
    this.eventListeners = [];
    this.getStatus = status => {
      getStatus(status);
    };
  }

  isConnecting = false;

  init = (options = {}) => {
    if (this.isConnecting) return;

    this.isConnecting = true;
    this.getStatus('CONNECTING');

    const { endpoint = '', params = {}, eventListeners = [] } = options;
    const host = process.env.REACT_APP_SSE_URL; // eslint-disable-line
    const token = getItem('token');

    const urlParams = new URLSearchParams({ ...params, token });
    const url = `${host}/${endpoint}?${urlParams}`;

    this.es = new EventSource(url);

    eventListeners.forEach(({ name, handler }) => {
      this.es.addEventListener(name, handler);
    });

    this.es.onerror = () => {
      this.getStatus('DISCONNECTED');

      this.init(options);
    };

    this.es.onopen = () => {
      this.getStatus('CONNECTED');
    };
  };

  close = () => {
    if (this.es) {
      this.eventListeners.forEach(({ name, handler }) => {
        this.es.removeEventListener(name, handler);
      });
      this.es.close();
      this.es = undefined;
      this.getStatus('DISCONNECTED');
      this.isConnecting = false;
    }
  };
}
