import styled from 'styled-components';
import tw from 'twin.macro';
import ModalBase from 'styled-react-modal';

import { BallTriangle } from 'react-loader-spinner';

const Container = ModalBase.styled`
  ${tw`flex flex-col justify-center items-center w-screen h-screen bg-transparent gap-2 z-50 cursor-wait`}
`;

const ProgressBarWrapper = styled.div`
  ${tw`flex [width: 250px] [height: 20px] bg-white rounded-3xl relative overflow-hidden`}
`;

const ProgressBar = styled.div`
  ${tw`h-full border-solid border-2 border-white rounded-2xl`}
  background-color: #0A253C;
  width: ${props => `${props.width}%` || 0};
`;

const Text = styled.p`
  ${tw`text-base text-white font-bold`}
`;

const renderMessage = (progress, enableText, text) => {
  if (!enableText) return;

  if (progress > 0 && progress < 100) return `${parseInt(progress)}%`;
  else if (progress >= 100) return text?.finishMsg;

  return text?.startMsg;
};

const LoadingScreen = ({
  isLoading,
  progress = 0,
  enableText = true,
  text = {
    startMsg: 'Conectando...',
    progressMsg: 'Processando informações...',
    finishMsg: 'Informações processadas com sucesso!',
  },
}) => {
  return (
    <Container isOpen={isLoading}>
      {progress > 0 ? (
        <>
          {enableText && <Text>{text.progressMsg}</Text>}
          <ProgressBarWrapper>
            <ProgressBar width={progress} />
          </ProgressBarWrapper>
        </>
      ) : (
        <BallTriangle visible width={60} height={60} color="#FFFF" />
      )}

      <Text>{renderMessage(progress, enableText, text)}</Text>
    </Container>
  );
};

export default LoadingScreen;
