import { useState, useLayoutEffect, useCallback, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';
import BudgetForm from 'components/pages/Financial/Payments/Form/BudgetForm';

import api from 'services/api';
import { BEARER, SUBROUTES, MODULES, UPDATE } from 'utils/constants';
import { STANDARD_ERROR } from 'utils/messages';
import { getUniqueValues, hasPermission } from 'utils/functions';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const { subroutes } = SUBROUTES.financial;
const { main, payments } = MODULES.financial;

import { ReactComponent as StatusIcon } from 'assets/images/svg/status.svg';
import { ReactComponent as EditUserIcon } from 'assets/images/svg/edit_user.svg';
import { ReactComponent as CarIcon } from 'assets/images/svg/vehicle_front.svg';
import { ReactComponent as CheckedUserIcon } from 'assets/images/svg/user_checked.svg';
import { ReactComponent as UserIcon } from 'assets/images/svg/user-fill.svg';

const Container = styled.div`
  ${tw`flex flex-col h-fit w-full rounded-2xl bg-white gap-5 px-2 py-4`}
`;

const Grid = styled.div`
  ${tw`flex flex-wrap w-full justify-center gap-5`}
`;

const CardInfo = styled.div`
  ${tw`flex flex-col min-h-[10rem] h-fit w-full max-w-[15rem] items-center justify-center px-2 py-4 border-2 border-[var(--gray-dark)] rounded-2xl gap-2 shadow-lg overflow-hidden
  [background: var(--blue-gradient)] text-white text-center`}

  .title {
    ${tw`text-base font-semibold leading-none`}
  }
  .value {
    ${tw`text-xl font-bold truncate max-w-full`}
  }
`;

const Text = styled.span`
  ${tw`text-xl text-center font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#084D8D] to-[#0A1B24] drop-shadow-lg`}
`;

const iconsConfig = {
  flexShrink: 0,
  height: '40px',
  width: '40px',
  fill: '#fff',
};

const Form = () => {
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { id } = useParams();

  const getPayment = useCallback(
    async ({ occurrenceId, starting }) => {
      setIsLoading(true);
      setData({});

      try {
        const { data: response, status } = await api.get(
          `occurrencies/${occurrenceId}/budgets/payment`,
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );

        if (status !== 200) throw new Error();

        const { budgets: budgets, occurrency: occurrence } = response.data;

        const budgetsDetailsLog = {
          cards: [],
          occurrenceId: occurrence?.id,
          numProtocol: occurrence?.nr_protocol,
          occurrenceDesc: occurrence?.description || 'Não inserida',
          occurrenceCompleted: occurrence?.completed || false,
          budgets:
            budgets?.map(item => ({
              budgetId: item?.id,
              typeName: item?.type_name || '-',
              serviceProvider: item?.name_provider,
              commentsBudget: item?.description || 'Não inserido',
              budgetValue: item?.cost_budget,
              closedValue: item?.payments?.cost_final,
              excessValue: item?.payments?.cost_excess,
              associationValue: item?.payments?.cost_covered,
              paymentMethodId: item?.payments?.method?.id || undefined,
              paymentMethodName: item?.payments?.method?.name,
              paymentDate: item?.payments?.date_payment,
              paymentStatusId: item?.payments?.status?.id || undefined,
              paymentStatusName: item?.payments?.status?.name,
              commentsPayment: item?.payments?.description || '',
              filesBudget:
                item?.files?.map(file => ({
                  id: file?.id,
                  name: file?.name || '-',
                  extension: file?.extension,
                  isSaved: file?.created_on ? true : false,
                })) || [],
              filesPayment:
                item?.payments?.files?.map(file => ({
                  id: file?.id,
                  name: file?.name || '-',
                  extension: file?.extension,
                  isSaved: file?.created_on ? true : false,
                })) || [],
            })) || [],
        };

        if (occurrence?.status?.name) {
          budgetsDetailsLog.cards.push({
            icon: StatusIcon,
            title: 'Status da ocorrência:',
            value: occurrence?.status?.name || '-',
          });
        }

        if (occurrence?.users?.created?.display_name) {
          budgetsDetailsLog.cards.push({
            icon: EditUserIcon,
            title: 'Usuário que criou a ocorrência:',
            value: occurrence?.users?.created?.display_name || '-',
          });
        }

        if (occurrence?.users?.closed?.display_name) {
          budgetsDetailsLog.cards.push({
            icon: CheckedUserIcon,
            title: 'Usuário que encerrou a ocorrência:',
            value: occurrence.users.closed.display_name || '-',
          });
        }

        if (occurrence?.vehicles?.length) {
          budgetsDetailsLog.cards.push({
            icon: UserIcon,
            title: 'Associado(s):',
            value: getUniqueValues(
              occurrence?.vehicles || [],
              'client',
              item => item?.display_name || '-',
            ),
          });
        }

        if (occurrence?.vehicles?.length) {
          budgetsDetailsLog.cards.push({
            icon: CarIcon,
            title: 'Placa(s):',
            value: occurrence?.vehicles?.map(v => v?.plate || '-').join(', '),
          });
        }

        setData(budgetsDetailsLog);

        const paymentsIsFinish = budgetsDetailsLog.budgets.every(
          budget => budget.paymentStatusId !== 1,
        );
        if (paymentsIsFinish && !starting) {
          toast.info('Pagamentos desta Ocorrência Finalizados');

          setTimeout(() => {
            navigate(subroutes.payments.path);

            setIsLoading(false);
            resetProgress();
          }, 3000);
        } else {
          setIsLoading(false);
          resetProgress();
        }
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 422:
            errorMessage = 'Erro ao buscar informações sobre o Pagamento';
            break;
          default:
            errorMessage = STANDARD_ERROR;
        }
        toast.error(errorMessage);

        setTimeout(() => {
          navigate(subroutes.payments.path);

          setIsLoading(false);
          resetProgress();
        }, 3000);
      }
    },
    [user],
  );

  useLayoutEffect(() => {
    const fetchData = async () => {
      if (id) await getPayment({ occurrenceId: id, starting: true });
    };
    fetchData();

    return () => {
      toast.dismiss();
    };
  }, [id]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={[
          'Financeiro',
          'Pagamentos',
          data?.numProtocol && `Ocorrência N°: ${data.numProtocol}`,
        ]}
        addRedirects={[
          {
            title: 'Voltar',
            disabled: isLoading,
            path: subroutes.payments.path,
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container>
        {data?.cards?.length && (
          <TitleBox
            title={{ text: 'Informações da Ocorrência' }}
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
            }}>
            <Grid>
              {data.cards?.map(card => (
                <Fragment key={card.title}>
                  <CardInfo
                    data-tooltip-id={card.title}
                    data-tooltip-content={card.value}>
                    <card.icon style={iconsConfig} />
                    <div className="title">{card.title}</div>
                    <div className="value">{card.value}</div>
                  </CardInfo>
                  <Tooltip id={card.title} place="bottom" />
                </Fragment>
              ))}
            </Grid>
            <Text>Descrição: {data?.occurrenceDesc}</Text>
          </TitleBox>
        )}

        {data?.budgets?.length && (
          <TitleBox title={{ text: 'Informações dos Orçamentos' }}>
            <Grid>
              {data.budgets?.map((budget, index) => (
                <BudgetForm
                  key={index}
                  data={budget}
                  occurrenceId={data.occurrenceId}
                  isDisabled={
                    data.occurrenceCompleted ||
                    !hasPermission(user?.role, main, payments, UPDATE)
                  }
                  afterUpdate={async id =>
                    await getPayment({ occurrenceId: id, starting: false })
                  }
                />
              ))}
            </Grid>
          </TitleBox>
        )}
      </Container>
    </PageContainer>
  );
};

export default Form;
