import { useState, useCallback } from 'react';

const useLoadingProgress = () => {
  const [progress, setProgress] = useState(0);

  const resetProgress = useCallback(() => {
    setProgress(0);
  }, []);

  const onProgress = useCallback(event => {
    const percent = (event.loaded / event.total) * 100;
    setProgress(percent);
  }, []);

  return { progress, resetProgress, onProgress };
};

export default useLoadingProgress;
