import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';
import { areEqual } from 'react-window';

import Loader from 'components/ui-kit/Loader';
import FixedList from 'components/ui-kit/FixedList';
import Card from './Card';
import Filter from './Filter';

import { SUBROUTES } from 'utils/constants';

import { ReactComponent as AlertIcon } from 'assets/images/svg/alert.svg';
import { ReactComponent as CheckIcon } from 'assets/images/svg/double_check.svg';
import { ReactComponent as FilterIcon } from 'assets/images/svg/filter.svg';

import { variant } from 'utils/functions';

const Container = styled.div`
  ${tw`flex flex-col w-full lg:w-1/2 max-w-4xl min-h-full h-full overflow-hidden
  rounded-md shadow-lg shadow-black
  [background: var(--dark-blue-gradient)]`}
`;

const Header = styled.div`
  ${tw`flex h-fit w-full px-2 md:px-4 py-2 rounded-t-md items-center justify-between
  top-0 sticky border-solid border-b-2 border-b-white shadow-md shadow-black z-[1]
  [background: var(--blue-gradient)]`}
`;

const Text = styled.span`
  ${tw`text-lg text-center font-bold text-white truncate`}

  ${({ $variant }) =>
    variant({
      notFound: tw`text-base font-semibold italic`,
    })({ $variant })}
`;

const Wrapper = styled.div`
  ${tw`flex h-full w-full p-2 justify-center items-center overflow-hidden`}
`;

const StyledFixedList = styled(FixedList)`
  .fixed-list {
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }
`;

const Amount = styled.div`
  ${tw`flex flex-row w-fit bg-transparent px-2 py-1 
    shadow-sm shadow-black gap-1 items-center
    rounded-md border-solid border-2 border-white`}

  .label {
    ${tw`text-lg font-bold text-white truncate`}
  }
  .value {
    ${tw`text-lg font-extrabold text-white`}
  }
`;

const FilterContainer = styled.div`
  ${tw`flex relative items-center`}
`;

const FilterButton = styled.button`
  ${tw`outline-none fill-white w-[20px] h-[20px]`}
`;

const iconStyles = {
  width: '25px',
  height: '25px',
  fill: '#FFF',
  flexShrink: 0,
};

const getTitle = value => {
  switch (value) {
    case 'opened':
      return { text: 'Em Aberto', icon: <AlertIcon style={iconStyles} /> };
    case 'finalizations':
      return {
        text: 'Aguardando Finalizações',
        icon: <CheckIcon style={iconStyles} />,
      };
  }
};

const renderCard = memo(({ data, index, style }) => {
  const navigate = useNavigate();

  return (
    <div style={{ ...style, paddingInline: '4px', overflow: 'hidden' }}>
      <Card
        data={data[index]}
        isDisabled={data[index].isDisabled}
        onClick={() => {
          if (data[index].isDisabled) return;
          navigate(`${SUBROUTES.occurrences.path}/${data[index].id}`);
        }}
      />
    </div>
  );
}, areEqual);

const Grid = ({
  theme = '',
  isLoading = false,
  list = [],
  filterIsModified = false,
  searchInput,
  filterOptions = [],
  onSearchChange = () => {},
  onFilterChange = () => {},
}) => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <Container>
      <Header $variant={theme} onMouseLeave={() => setOpenFilter(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.5rem',
            overflow: 'hidden',
          }}>
          {getTitle(theme).icon}
          <Text>{getTitle(theme).text}</Text>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
          }}>
          <FilterContainer>
            <span
              style={{
                fontWeight: 700,
                color: '#fff',
                visibility: filterIsModified ? 'visible' : 'hidden',
              }}>
              *
            </span>

            <FilterButton
              data-tooltip-id="filter"
              data-tooltip-content="Filtrar"
              onClick={() => setOpenFilter(!openFilter)}>
              <FilterIcon height="100%" width="100%" />
              <Tooltip id="filter" />
            </FilterButton>
            <Filter
              isOpen={openFilter}
              searchInput={searchInput}
              options={filterOptions}
              onSearchChange={onSearchChange}
              onOptionChange={onFilterChange}
            />
          </FilterContainer>
          <Amount>
            <span className="label">Total:</span>
            <span className="value">{list.length}</span>
          </Amount>
        </div>
      </Header>
      <Wrapper>
        {list.length > 0 ? (
          <StyledFixedList items={list} itemSize={160}>
            {renderCard}
          </StyledFixedList>
        ) : isLoading ? (
          <Loader center />
        ) : (
          <Text $variant="notFound">Nenhuma ocorrência encontrada...</Text>
        )}
      </Wrapper>
    </Container>
  );
};

export default Grid;
