import styled from 'styled-components';
import tw from 'twin.macro';

import { ReactComponent as Arrow } from 'assets/images/svg/chevron-right.svg';

const ArrowButton = styled.button`
  ${tw`absolute top-0 bottom-0 left-0 right-0 w-fit h-fit cursor-pointer [z-index: 10] 
      disabled:[opacity: 0.5] disabled:cursor-not-allowed`}

  ${props =>
    props.$prev
      ? tw`[margin: auto auto auto .5rem]`
      : tw`[margin: auto .5rem auto auto]`}
`;

const SliderArrow = ({
  currentSlide,
  slideCount,
  isPrev = false,
  ...props
}) => {
  return (
    <ArrowButton
      {...props}
      $prev={isPrev}
      aria-hidden="true"
      aria-disabled={
        isPrev ? currentSlide === 0 : currentSlide === slideCount - 1
      }
      disabled={isPrev ? currentSlide === 0 : currentSlide === slideCount - 1}
      type="button">
      <Arrow
        width={15}
        height={15}
        fill="var(--gray-dark)"
        style={{
          transform: isPrev ? 'rotate(-180deg)' : '',
          pointerEvents: 'none',
        }}
      />
    </ArrowButton>
  );
};

export default SliderArrow;
