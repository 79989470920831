import { useState, useRef, useEffect, useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import Select from 'components/ui-kit/Select';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import TitleBox from 'components/ui-kit/TitleBox';
import ActivationsInfo from './ActivationsInfo';

import LocationsInsert from './LocationsInsert';

import { variant } from 'utils/functions';

const InsertContainer = styled.div`
  ${tw`flex flex-col lg:flex-row w-full gap-x-4 gap-y-6 items-center justify-center mb-4`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-col w-full max-w-[210px] items-center justify-center relative m-0`}
`;

const Text = styled.span`
  ${tw`text-sm text-center text-disabled-dark`}

  ${({ $variant }) =>
    variant({
      label: tw`font-semibold`,
      value: tw`truncate`,
    })({ $variant })}
`;

const Row = styled.div`
  ${tw`flex flex-col sm:flex-row justify-center items-center h-fit w-full max-w-full gap-y-2 gap-x-5 py-2 px-4 bg-[var(--gray-theme)] rounded-lg overflow-hidden`}
`;

const Item = styled.div`
  ${tw`flex h-fit w-fit max-w-full items-center gap-1 overflow-hidden`}
`;

const getAllLocations = loc => {
  const locations = [];
  const cityIds = new Set();

  loc.forEach(item => {
    if (item.locations && Array.isArray(item.locations)) {
      item.locations.forEach(location => {
        const addIfUnique = city => {
          if (
            !cityIds.has(city.cityId) &&
            !!city.cityId &&
            !!city.cityName &&
            !!city.stateShortCode
          ) {
            const newLocation = {
              ...city,
              label: `${city.cityName} / ${city.stateShortCode}`,
              value: city.cityId,
            };
            locations.push(newLocation);
            cityIds.add(city.cityId);
          }
        };

        addIfUnique(location.origin);
        addIfUnique(location.destiny);
      });
    }
  });

  return locations;
};

const VehiclesInsert = ({
  typeId,
  vehiclesInserted = [],
  onVehiclesAndLocationsChange = () => {},
  isDisabled = false,
  error = false,
  serviceHasChanged = false,
}) => {
  const [insertedVehicles, setInsertedVehicles] = useState([]);
  const vehiclesUpdatedRef = useRef(false);

  const [associateId, setAssociateId] = useState();
  const [vehicle, setVehicle] = useState({});

  const associateSelect = useRef(null);
  const vehicleSelect = useRef(null);

  const memoizedData = useMemo(
    () => vehiclesInserted,
    [JSON.stringify(vehiclesInserted)],
  );

  const handleAddVehicle = useCallback(() => {
    if (insertedVehicles.some(item => item?.id === vehicle?.id)) {
      toast.warning('Esta placa já foi adicionada');
    } else if (insertedVehicles.length >= 5) {
      toast.warning('Limite de veículos atingido!');
    } else {
      setInsertedVehicles(prevVehicles => [...prevVehicles, vehicle]);
      associateSelect.current.clearValue();
      vehicleSelect.current.clearValue();
      setVehicle({});
      vehiclesUpdatedRef.current = true;
    }
  }, [insertedVehicles, vehicle]);

  const handleRemoveVehicle = useCallback(
    id => {
      const newVehicles = insertedVehicles.filter(vehicle => vehicle.id !== id);
      setInsertedVehicles(newVehicles);
      vehiclesUpdatedRef.current = true;
    },
    [insertedVehicles],
  );

  const onLocationsChange = useCallback(
    (data, id) => {
      setInsertedVehicles(prev =>
        prev.map(vehicle =>
          vehicle.id === id ? { ...vehicle, ...data } : vehicle,
        ),
      );
      vehiclesUpdatedRef.current = true;
    },
    [setInsertedVehicles],
  );

  useEffect(() => {
    if (isDisabled || !vehiclesUpdatedRef.current) return;

    onVehiclesAndLocationsChange(insertedVehicles);

    vehiclesUpdatedRef.current = false;
  }, [isDisabled, insertedVehicles, onVehiclesAndLocationsChange]);

  useEffect(() => {
    setInsertedVehicles(memoizedData);
  }, [memoizedData]);

  return (
    <>
      {!isDisabled && (
        <InsertContainer>
          <Select
            ref={associateSelect}
            size="sm"
            maxWidth="300px"
            menuMaxHeight={200}
            fieldToOptionValue="id"
            fieldToOptionLabel="display_name"
            endpoint="clients/all"
            onOptionChange={option => {
              vehicleSelect.current.clearValue();
              setVehicle({});
              setAssociateId(option.value);
            }}
            placeholder=""
            label={{
              text: 'Selecione um Associado',
            }}
          />
          <Wrapper>
            <Select
              ref={vehicleSelect}
              isRequired
              size="sm"
              menuMaxHeight={200}
              fieldToIsActive="active"
              fieldToOptionValue="id"
              fieldToOptionLabel="plate"
              endpoint={`vehicles/?client_id=${associateId}`}
              onOptionChange={option => {
                if (!associateId) return;

                setVehicle({
                  id: option.value,
                  plate: option.label,
                  associateId: option?.client?.id,
                  associateName: option?.client?.display_name,
                });
              }}
              placeholder=""
              label={{
                text: 'Selecione uma Placa',
              }}
              error={{
                isActive: error && !vehicle?.id,
                message: 'Uma placa deve ser adicionada.',
              }}
              isDisabled={!associateId}
            />
            {vehicle?.id && (
              <ActivationsInfo typeId={typeId} vehiclePlate={vehicle?.plate} />
            )}
          </Wrapper>
          <PrimaryButton
            type="button"
            onClick={handleAddVehicle}
            disabled={!vehicle?.id}>
            Adicionar Veículo
          </PrimaryButton>
        </InsertContainer>
      )}
      <>
        {insertedVehicles.map(item => (
          <TitleBox
            key={item?.id}
            title={{ text: 'Veículo' }}
            style={{
              flexDirection: 'column',
              gap: '8px',
              padding: '8px 0 8px 0',
              alignItems: 'center',
            }}>
            <Row>
              <Item>
                <Text $variant="label">Associado:</Text>
                <Text
                  $variant="value"
                  data-tooltip-id={String(item?.id)}
                  data-tooltip-content={item?.associateName}>
                  {item?.associateName}
                </Text>
              </Item>
              <Item>
                <Text $variant="label">Placa:</Text>
                <Text $variant="value">{item?.plate}</Text>
              </Item>
              <Tooltip id={String(item?.id)} place="bottom" />
            </Row>
            <LocationsInsert
              locationsInserted={item?.locations}
              allLocations={getAllLocations(insertedVehicles)}
              onLocationsChange={locations =>
                onLocationsChange(locations, item?.id)
              }
              isDisabled={isDisabled}
              serviceHasChanged={serviceHasChanged}
            />
            {!isDisabled && (
              <PrimaryButton
                type="button"
                bg="red"
                onClick={() => handleRemoveVehicle(item?.id)}>
                Excluir Veículo
              </PrimaryButton>
            )}
          </TitleBox>
        ))}
      </>
    </>
  );
};

export default memo(VehiclesInsert);
