import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import Input from 'components/ui-kit/Input';
import Select from 'components/ui-kit/Select';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';

import api from 'services/api';
import { BEARER, SUBROUTES, MODULES, UPDATE, CREATE } from 'utils/constants';
import { buildPayload, hasPermission } from 'utils/functions';

import {
  BASIC_INFOS,
  FIELD_IS_REQUIRED,
  ALREADY_SAVED_INFORMATION,
  SUCCESS_CAUSE,
  ERROR_CAUSE,
  ERROR_ALREADY_EXISTS,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const Container = styled.form`
  ${tw`h-fit w-full rounded-2xl bg-white px-2 py-4`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center items-start py-2 gap-5`}
`;

const validationSchema = yup.object({
  name: yup.string().required(FIELD_IS_REQUIRED('Nome')),
  typeId: yup.number().required(FIELD_IS_REQUIRED('Tipo de Ocorrência')),
});

const Form = () => {
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const typeSelectRef = useRef(null);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const canEdit = hasPermission(user?.role, main, sub.causes, UPDATE);
  const canCreate = hasPermission(user?.role, main, sub.causes, CREATE);

  const getCause = useCallback(
    async causeId => {
      setIsLoading(true);
      setData({});
      try {
        const { data: response, status } = await api.get(
          `occurrencies/incidents/${causeId}`,
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );

        if (status !== 200) throw new Error();

        const {
          id,
          name,
          category: { id: typeId, name: typeName } = {},
        } = response?.data || {};

        setValues({
          name,
          typeId,
        });
        setData({
          id,
          name,
          typeId,
        });

        if (typeId !== null && typeName !== null) {
          typeSelectRef.current?.setValue({
            value: typeId,
            label: typeName,
          });
        }

        resetProgress();
        setIsLoading(false);
      } catch {
        toast.error(ERROR_CAUSE('buscar'));

        setTimeout(() => {
          setIsLoading(false);
          resetProgress();
          navigate(subroutes.causes.path, {
            state: { currentPage: state?.currentPage },
          });
        }, 3500);
      }
    },
    [typeSelectRef],
  );

  const onSubmit = useCallback(
    async values => {
      setIsLoading(true);

      const keyMapping = {
        name: 'name',
        typeId: 'category_id',
      };
      const payload = buildPayload(keyMapping, values, data);

      if (Object.keys(payload).length === 0) {
        toast.success(ALREADY_SAVED_INFORMATION);
        setIsLoading(false);
        return;
      }

      try {
        const { status } =
          Object.keys(data).length > 0
            ? await api.put(
                `occurrencies/incidents/${data.id}`,
                { data: payload },
                {
                  onProgress,
                  headers: {
                    Authorization: BEARER + user.token,
                  },
                },
              )
            : await api.post(
                'occurrencies/incidents/',
                { data: payload },
                {
                  onProgress,
                  headers: {
                    Authorization: BEARER + user.token,
                  },
                },
              );

        if (status !== 200 && status !== 201) throw new Error();

        let successMessage =
          Object.keys(data).length > 0
            ? SUCCESS_CAUSE('editada')
            : SUCCESS_CAUSE('criada');

        toast.success(successMessage);

        setTimeout(() => {
          navigate(subroutes.causes.path, {
            state: { currentPage: state?.currentPage },
          });
          resetProgress();
          setIsLoading(false);
        }, [2000]);
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 409:
            errorMessage = ERROR_ALREADY_EXISTS;
            break;
          default:
            errorMessage =
              Object.keys(data).length > 0
                ? ERROR_CAUSE('editar')
                : ERROR_CAUSE('criar');
        }
        toast.error(errorMessage);
        resetProgress();
        setIsLoading(false);
      }
    },
    [data, state],
  );

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    handleChange,
    submitCount,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: '',
      typeId: undefined,
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    const activeErrors = Object.keys(errors)
      .filter(error => errors[error] && submitCount > 0)
      .map(error => errors[error]);

    if (activeErrors.length > 0) {
      toast.error(activeErrors.join('\n'));
    }
  }, [submitCount]);

  useLayoutEffect(() => {
    if (id === undefined) return;

    const fetchCause = async () => {
      await getCause(id);
    };
    fetchCause();

    return () => {
      toast.dismiss();
    };
  }, [id]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={[
          'Cadastros',
          'Causas',
          data?.name ? `Editando: ${data.name}` : 'Nova Causa',
        ]}
        addRedirects={[
          {
            title: 'Voltar',
            disabled: isLoading,
            path: subroutes.causes.path,
            state: { currentPage: state?.currentPage },
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container onSubmit={handleSubmit}>
        <TitleBox
          title={{ text: BASIC_INFOS }}
          error={
            (submitCount > 0 && errors.name) ||
            (submitCount > 0 && errors.typeId)
          }
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}>
          <Wrapper>
            <Input
              isRequired
              id="name"
              maxWidth="300px"
              value={values.name}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Nome',
              }}
              error={{
                isActive: submitCount > 0 && errors.name,
                message: errors.name,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />

            <Select
              isRequired
              id="typeId"
              ref={typeSelectRef}
              size="sm"
              maxWidth="300px"
              placeholder=""
              label={{
                text: 'Tipo de Ocorrência',
              }}
              options={[
                { label: 'Acidente', value: 1 },
                { label: 'Pane', value: 2 },
                { label: 'Vidro', value: 3 },
              ]}
              onOptionChange={option => {
                setFieldValue('typeId', option.value);
              }}
              error={{
                isActive: submitCount > 0 && errors.typeId,
                message: errors.typeId,
              }}
              isDisabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
          </Wrapper>

          {((Object.keys(data).length && canEdit) ||
            (!Object.keys(data).length && canCreate)) && (
            <PrimaryButton
              type="submit"
              maxWidth="300px"
              bg="green"
              disabled={isLoading}>
              Salvar
            </PrimaryButton>
          )}
        </TitleBox>
      </Container>
    </PageContainer>
  );
};

export default Form;
