import { memo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import DefaultModal from 'components/ui-kit/DefaultModal';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import api from 'services/api';
import { BEARER, SUBROUTES } from 'utils/constants';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const Container = styled.div`
  ${tw`flex flex-col w-full h-full items-center gap-4 overflow-auto text-disabled-dark text-center`}

  .title {
    ${tw`text-lg font-bold`}
  }

  .text {
    ${tw`text-sm font-semibold`}
  }

  & > * {
    ${tw`last:mt-8`}
  }
`;

const OccurrenceDeleteModal = ({
  isOpen = false,
  occurrenceInfo = {},
  onClose = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();

  const handleDeleteOccurrence = useCallback(async () => {
    setIsLoading(true);
    try {
      const { status } = await api.delete(
        `occurrencies/${occurrenceInfo?.id}`,
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );
      if (status !== 200) throw new Error();

      toast.success('Ocorrência excluída com sucesso');

      setTimeout(() => {
        navigate(SUBROUTES.occurrences.path);

        setIsLoading(false);
        resetProgress();
      }, 2000);
    } catch {
      toast.error('Falha ao excluir Ocorrência');

      setIsLoading(false);
      resetProgress();
    }
  }, [occurrenceInfo, user]);

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      onBackgroundClick={onClose}
      onEscapeKeyDown={onClose}>
      <LoadingScreen isLoading={isLoading} progress={progress} />
      <Container>
        <span className="title">Aviso! </span>
        <span className="text">
          Deseja realmente excluir a ocorrência
          <span style={{ fontWeight: 700 }}>
            {` N° ${occurrenceInfo?.numProtocol}`}
          </span>
          ?
          <br />
          Todas as informações serão descartadas.
        </span>
        <PrimaryButton
          maxWidth="300px"
          type="button"
          bg="red"
          onClick={handleDeleteOccurrence}
          disabled={isLoading}>
          Excluir Ocorrência
        </PrimaryButton>
      </Container>
    </DefaultModal>
  );
};

export default memo(OccurrenceDeleteModal);
