import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import Chart from 'react-apexcharts';
import randomColor from 'randomcolor';

import Select from 'components/ui-kit/Select';

import { variant } from 'utils/functions';

import { ReactComponent as PauseIcon } from 'assets/images/svg/pause.svg';
import { ReactComponent as PlayIcon } from 'assets/images/svg/mover-dir-1.svg';

const Container = styled.div`
  ${tw`flex flex-col h-fit w-full bg-white items-center justify-center p-2 box-border border-2 border-gray-400 rounded-xl gap-4 shadow-lg`}
`;

const Row = styled.div`
  ${tw`flex flex-wrap h-fit w-full gap-4 items-center justify-around drop-shadow`}
`;

const TransitionButton = styled.button`
  ${tw`flex flex-nowrap w-fit h-fit py-2 px-6 gap-2 items-center [background: var(--blue-gradient)] rounded-lg text-white font-semibold`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-col sm:flex-row w-full h-fit items-center justify-center border-4 border-[var(--dark-blue-theme)] rounded-3xl sm:rounded-4xl p-1 shadow-md gap-2
    text-transparent bg-clip-text bg-gradient-to-b from-[#084D8D] to-[#0A1B24] font-bold text-center
  `}

  .button {
    ${tw`w-full bg-transparent px-4 py-2`}
  }
`;

const ActiveButton = styled(motion.div)`
  ${tw`w-full text-white [background: var(--blue-gradient)] rounded-4xl px-4 py-2 shadow-md`}
`;

const Text = styled.span`
  ${tw`text-sm text-center font-semibold`}

  ${({ $variant }) =>
    variant({
      title: tw`text-2xl md:text-3xl font-bold text-[var(--dark-blue-theme)] drop-shadow-lg`,
      desc: tw`text-base text-[var(--gray-dark)]`,
    })({ $variant })}
`;

const getTopK = (arr, k, prop, order) => {
  const partition = (arr, low, high) => {
    let pivot = arr[high] ? arr[high][prop] : 0;
    let i = low - 1;

    for (let j = low; j <= high - 1; j++) {
      if (
        arr[j] &&
        arr[j][prop] !== undefined &&
        (order === 'desc' ? arr[j][prop] >= pivot : arr[j][prop] <= pivot)
      ) {
        i++;
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
    }
    if (arr[i + 1] && arr[high]) {
      [arr[i + 1], arr[high]] = [arr[high], arr[i + 1]];
    }
    return i + 1;
  };

  const quickSelect = (arr, low, high, k) => {
    if (low === high) return;

    let pi = partition(arr, low, high);

    if (k === pi) {
      return;
    } else if (k < pi) {
      quickSelect(arr, low, pi - 1, k);
    } else {
      quickSelect(arr, pi + 1, high, k);
    }
  };

  quickSelect(arr, 0, arr.length - 1, k - 1);

  let topKElements = arr.slice(0, k);

  topKElements.sort((a, b) =>
    order === 'desc' ? b[prop] - a[prop] : a[prop] - b[prop],
  );

  return topKElements;
};

const createTopKMemo = (arr, prop, order) => {
  return useMemo(
    () =>
      arr
        ? order === 'asc' || order === 'desc'
          ? getTopK([...arr], 25, prop, order)
          : [...arr]
        : [],
    [arr, order],
  );
};

const graphOptions = [
  {
    id: 0,
    label: 'Causas Mensais',
    desc: 'Quantidade de Causas Mensais no período',
  },
  {
    id: 1,
    label: 'Total de Causas',
    desc: 'Quantidade Total de Causas no período',
  },
  {
    id: 2,
    label: 'N° de Orçamentos',
    desc: 'Quantidade de Orçamentos Mensais no período',
  },
  {
    id: 3,
    label: 'Total de Orçamentos',
    desc: 'Quantidade Total de Orçamentos no período',
  },
];

const colorsRange = randomColor({ count: 100, luminosity: 'random' });

const ChartsContainer = ({ graphData }) => {
  const [automaticTransition, setAutomaticTransition] = useState(false);
  const [activeButton, setActiveButton] = useState(graphOptions[0]);
  const [order, setOrder] = useState();

  const topMonthlyCauses = createTopKMemo(
    graphData?.monthlyCauses,
    'total',
    order,
  );
  const topCauses = createTopKMemo(graphData?.totalCauses, 'amount', order);
  const topNumberBudgets = createTopKMemo(
    graphData?.numberBudgets,
    'total',
    order,
  );
  const topBudgets = createTopKMemo(graphData?.totalBudgets, 'amount', order);

  const chartMonthlyCauses = useMemo(() => {
    return {
      options: {
        chart: {
          id: 'chart0',
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        colors: colorsRange,
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
          },
        },
        dataLabels: {
          enabled: true,
          formatter: value => value.toLocaleString('pt-br'),
          style: {
            fontSize: '13px',
            fontWeight: 'bold',
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.7,
            color: '#000000',
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                formatter: value => value.toLocaleString('pt-br'),
                style: {
                  fontSize: '16px',
                  fontWeight: 'bold',
                },
              },
            },
          },
        },
        xaxis: {
          categories: topMonthlyCauses?.map(item => item.date) || [],
          labels: {
            style: {
              colors: undefined,
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        },
        yaxis: {
          labels: {
            formatter: value => value.toLocaleString('pt-br'),
          },
        },
        tooltip: {
          y: {
            formatter: value => value.toLocaleString('pt-br'),
          },
        },
        legend: {
          position: 'right',
        },
      },
      series: Array.from(
        new Set(
          topMonthlyCauses.flatMap(graph => graph.causesMonth.map(o => o.name)),
        ),
      ).map(name => ({
        name,
        data: topMonthlyCauses.map(
          graph => graph.causesMonth.find(o => o.name === name)?.amount || 0,
        ),
      })),
    };
  }, [topMonthlyCauses]);

  const chartTotalCauses = useMemo(() => {
    return {
      options: {
        chart: {
          id: 'chart1',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        colors: colorsRange,
        fill: {
          type: 'gradient',
        },
        labels: topCauses?.map(item => item.name) || [],
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  color: '#000',
                  fontSize: 'large',
                  fontWeight: 700,
                },
                value: {
                  show: true,
                  color: '#000',
                  fontSize: 'large',
                  fontWeight: 700,
                  formatter: value => value.toLocaleString('pt-br'),
                },
              },
            },
          },
        },
        legend: {
          position: 'right',
        },
      },
      series: topCauses?.map(item => item.amount) || [],
    };
  }, [topCauses]);

  const chartNumberBudgets = useMemo(() => {
    return {
      options: {
        chart: {
          id: 'chart2',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
              customIcons: [],
            },
          },
        },
        colors: colorsRange,
        stroke: {
          curve: 'smooth',
        },
        dataLabels: {
          enabled: true,
          formatter: val => val?.toLocaleString('pt-br') || 0,
        },
        markers: {
          size: 1,
        },
        legend: {
          position: 'bottom',
        },
        xaxis: {
          categories: topNumberBudgets?.map(item => item.date) || [],
        },
        yaxis: {
          labels: {
            formatter: val => val?.toLocaleString('pt-br') || 0,
          },
        },
        tooltip: {
          y: {
            formatter: val => val?.toLocaleString('pt-br') || 0,
          },
        },
      },
      series: Array.from(
        new Set(
          topNumberBudgets.flatMap(month =>
            month.budgetsMonth.map(b => b.name),
          ),
        ),
      ).map(name => ({
        name,
        data: topNumberBudgets.map(
          month => month.budgetsMonth.find(b => b.name === name)?.amount || 0,
        ),
      })),
    };
  }, [topNumberBudgets]);

  const chartTotalBudgets = useMemo(() => {
    return {
      options: {
        chart: {
          id: 'chart3',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        colors: colorsRange,
        fill: {
          type: 'gradient',
        },
        labels: topBudgets?.map(item => item.name) || [],
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  color: '#000',
                  fontSize: 'large',
                  fontWeight: 700,
                },
                value: {
                  show: true,
                  color: '#000',
                  fontSize: 'large',
                  fontWeight: 700,
                  formatter: value => value.toLocaleString('pt-br'),
                },
              },
            },
          },
        },
        legend: {
          position: 'right',
        },
      },
      series: topBudgets?.map(item => item.amount) || [],
    };
  }, [topBudgets]);

  useEffect(() => {
    let intervalId;
    if (automaticTransition) {
      intervalId = setInterval(() => {
        setActiveButton(prev => {
          const nextId = prev.id === graphOptions.length - 1 ? 0 : prev.id + 1;
          return graphOptions.find(option => option.id === nextId);
        });
      }, 2500);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [automaticTransition]);

  return (
    <Container>
      <Text $variant="title">{activeButton.label}</Text>

      <Text $variant="desc">{activeButton.desc}</Text>

      <div
        style={{
          backgroundColor: 'var(--gray-theme)',
          borderRadius: '10px',
          padding: '0.5rem',
          height: '400px',
          width: '100%',
          overflow: 'visible',
        }}>
        {activeButton?.id === 0 && (
          <Chart
            options={chartMonthlyCauses.options}
            series={chartMonthlyCauses.series}
            type="bar"
            height="100%"
            width="100%"
          />
        )}
        {activeButton?.id === 1 && (
          <Chart
            options={chartTotalCauses.options}
            series={chartTotalCauses.series}
            type="donut"
            height="100%"
            width="100%"
          />
        )}
        {activeButton?.id === 2 && (
          <Chart
            options={chartNumberBudgets.options}
            series={chartNumberBudgets.series}
            type="area"
            height="100%"
            width="100%"
          />
        )}
        {activeButton?.id === 3 && (
          <Chart
            options={chartTotalBudgets.options}
            series={chartTotalBudgets.series}
            type="donut"
            height="100%"
            width="100%"
          />
        )}
      </div>

      <Row>
        <TransitionButton
          onClick={() => setAutomaticTransition(!automaticTransition)}>
          Transição automática
          {automaticTransition ? (
            <PauseIcon fill="#FFF" width={18} height={18} />
          ) : (
            <PlayIcon fill="#FFF" width={18} height={18} />
          )}
        </TransitionButton>
        <Select
          options={[
            { label: 'Maior pro menor', value: 'desc' },
            { label: 'Menor pro maior', value: 'asc' },
          ]}
          onOptionChange={option => {
            setOrder(option.value);
          }}
          placeholder="Selecione a Ordem"
          size="sm"
          maxWidth="250px"
        />
      </Row>

      <ButtonsContainer>
        {graphOptions.map(item => (
          <div key={item.id} style={{ width: '100%' }}>
            {activeButton?.id === item.id ? (
              <ActiveButton
                layoutId="bubble"
                transition={{ type: 'spring', duration: 0.5 }}>
                {item.label}
              </ActiveButton>
            ) : (
              <button
                className="button"
                onClick={() => {
                  setActiveButton(item);
                }}>
                {item.label}
              </button>
            )}
          </div>
        ))}
      </ButtonsContainer>
    </Container>
  );
};

export default ChartsContainer;
