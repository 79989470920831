import styled from 'styled-components';
import tw from 'twin.macro';

const TextareaComponent = styled.textarea`
  ${tw`border border-themes-gray rounded-lg w-full box-border p-2 text-themes-dark-gray placeholder:text-themes-dark-gray text-sm font-medium
  disabled:bg-[var(--gray-theme)] disabled:cursor-not-allowed focus:border-[solid 1.5px var(--gray-dark)]`};

  max-width: ${({ $maxWidth }) => $maxWidth};
  height: ${({ $height }) => $height};
  resize: ${({ $resize }) => $resize};
`;

/**
 * @typedef {Object} TextareaProps
 * @property {string} [size=''] - Adiciona um padding interno.
 */

/**
 * @type {React.FC<TextareaProps>}
 */

const Textarea = ({
  resize = 'none',
  height = 'fit-content',
  maxWidth = '100%',
  ...rest
}) => {
  return (
    <TextareaComponent
      $resize={resize}
      $height={height}
      $maxWidth={maxWidth}
      {...rest}
    />
  );
};

export default Textarea;
