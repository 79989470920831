import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { subMonths } from 'date-fns';
import { IMaskInput } from 'react-imask';

import Table from 'components/ui-kit/Table';
import PageContainer from 'components/ui-kit/PageContainer';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import PageHeader from 'components/ui-kit/PageHeader';
import FilterTool from 'components/ui-kit/FilterTool';
import StatusDisplay from 'components/ui-kit/StatusDisplay';

import api from 'services/api';
import { BEARER, SUBROUTES } from 'utils/constants';
import useQuery from 'hooks/useQuery';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';
import {
  defaultDateFormat,
  exportDateFormat,
  getInitialDates,
  getExportData,
  getBackendDateFormat,
  getUniqueValues,
} from 'utils/functions';

import {
  NO_DATA_FOUND,
  STANDARD_ERROR,
  GETTING_PDF,
  GETTING_XLS,
  PDF_ERROR,
  PDF_READY,
  XLS_ERROR,
  XLS_READY,
} from 'utils/messages';
import exportXLS from 'services/xls';
import exportPDF from 'services/pdf';

import { ReactComponent as AddIcon } from 'assets/images/svg/add.svg';
import { ReactComponent as ViewIcon } from 'assets/images/svg/contracts-rel.svg';

const defaultColumns = [
  { label: 'N° Protocolo', field: 'numProtocol' },
  {
    label: 'Data da Ocorrência',
    field: 'occurrenceDate',
    display: 'fmtdOccurrenceDate',
  },
  { label: 'Associado(s)', field: 'associateName' },
  { label: 'Placa(s)', field: 'vehiclePlate' },
  { label: 'Causa(s)', field: 'causes' },
  { label: 'Origem(ns)', field: 'origin' },
  { label: 'Destino(s)', field: 'destiny' },
  {
    label: 'Status da Ocorrência',
    field: 'occurrenceStatusId',
    display: 'occurrenceStatusDisplay',
  },
  { label: 'Ver Ocorrência', field: 'view', disableSort: true },
];

const Occurrences = () => {
  const [occurrencesList, setOccurrencesList] = useState([]);

  const [numProtocolInput, setNumProtocolInput] = useState();
  const [associate, setAssociate] = useState();
  const [vehicle, setVehicle] = useState();
  const [type, setType] = useState();
  const [causes, setCauses] = useState();

  const { params } = useQuery();
  const [statusOccurrence, setStatusOccurrence] = useState(
    params.get('statusId'),
  );
  const [searchHistory, setSearchHistory] = useState({});

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSeletRef = useRef(null);
  const statusSeletRef = useRef(null);
  const tableRef = useRef(null);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const inputs = [
    {
      onAccept: value => setNumProtocolInput(value.toUpperCase()),
      as: IMaskInput,
      value: numProtocolInput,
      mask: 'a-0000000',
      placeholder: 'N° Protocolo',
      size: 'sm',
      height: '33px',
      maxWidth: '180px',
      $variant: 'registry',
    },
    {
      type: 'select',
      maxWidth: '300px',
      endpoint: 'clients/all?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Associado',
      onOptionChange: option => {
        if (vehicle?.id) vehicleSeletRef.current?.clearValue();

        setAssociate(option);
      },
    },
    {
      ref: vehicleSeletRef,
      type: 'select',
      maxWidth: '220px',
      endpoint: associate?.id
        ? `vehicles/?client_id=${associate?.id}`
        : 'vehicles/',
      fieldToOptionLabel: 'plate',
      fieldToOptionValue: 'id',
      placeholder: 'Selecione uma Placa',
      onOptionChange: option => setVehicle(option),
    },
    {
      type: 'select',
      maxWidth: '250px',
      options: [
        { label: 'Acidente', value: 1 },
        { label: 'Pane', value: 2 },
        { label: 'Vidro', value: 3 },
      ],
      placeholder: 'Tipo de Ocorrência',
      onOptionChange: option => setType(option),
    },
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: type?.value
        ? `occurrencies/incidents/?category_id=${type?.value}&active=true`
        : 'occurrencies/incidents/?active=true',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Causas',
      onOptionChange: option => setCauses(option),
    },
    {
      ref: statusSeletRef,
      type: 'select',
      onOptionChange: option => {
        setStatusOccurrence(option.value);
      },
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Status da Ocorrência',
      endpoint: 'occurrencies/status/',
      size: 'sm',
      maxWidth: '230px',
      autoComplete: true,
      getEndpointOptions: options => {
        if (options.length === 1) {
          setStatusOccurrence(options.value);
        }

        const targetStatusId = params.get('statusId');

        const option = options.find(
          ({ value }) => value === parseInt(targetStatusId),
        );

        if (!option) return;

        if (targetStatusId && statusSeletRef) {
          statusSeletRef?.current?.setValue(option);
        }
      },
    },
  ];

  const getOccurrences = useCallback(async () => {
    setIsLoading(true);

    setOccurrencesList([]);
    setSearchHistory({});

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get('reports/occurrences', {
        onDownloadProgress: onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          created_on_from: dates?.startDate,
          created_on_to: dates?.endDate,
          nr_protocol: formatParam(numProtocolInput),
          associate_id: associate?.value,
          vehicle_id: vehicle?.value,
          category_id: type?.value,
          types_ids: causes?.value,
          status_id: statusOccurrence,
        },
      });

      if (status !== 200) throw new Error();

      const occurrencesLog = response?.data?.map(item => {
        return {
          id: item.id,
          numProtocol: item?.nr_protocol,
          occurrenceDate: item?.date,
          associateName: getUniqueValues(
            item?.vehicles || [],
            'client',
            item => item?.display_name || '-',
          ),
          vehiclePlate: item?.vehicles?.map(v => v?.plate || '-').join(', '),
          causes: item?.accidents?.map(a => a.name).join(', '),
          origin: getUniqueValues(
            item?.vehicles?.flatMap(v => v.locales) || [],
            'from_city',
            item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
          ),
          destiny: getUniqueValues(
            item?.vehicles?.flatMap(v => v.locales) || [],
            'to_city',
            item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
          ),
          occurrenceStatusId: item?.status?.id,
          occurrenceStatusName: item?.status?.name,
        };
      });

      if (occurrencesLog.length > 0) {
        setSearchHistory({
          startDate: dates?.startDate,
          endDate: dates?.endDate,
          associateName: associate?.label,
          vehiclePlate: vehicle?.label,
          type: type?.label,
          causes: causes?.label,
        });
      }

      setOccurrencesList(occurrencesLog);

      setIsLoading(false);
      resetProgress();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
      setIsLoading(false);
      resetProgress();
    }
  }, [
    dates,
    numProtocolInput,
    associate,
    vehicle,
    type,
    causes,
    statusOccurrence,
    params,
  ]);

  const dataTable = useMemo(() => {
    const occurrencesData = occurrencesList.map(item => ({
      ...item,
      numProtocol: item?.numProtocol || '-',
      occurrenceDate: item.occurrenceDate,
      fmtdOccurrenceDate: item.occurrenceDate
        ? defaultDateFormat(item.occurrenceDate)
        : '-',
      associateName: item?.associateName || '-',
      vehiclePlate: item?.vehiclePlate || '-',
      causes: item?.causes || '-',
      origin: item?.origin || '-',
      destiny: item?.destiny || '-',
      occurrenceStatusDisplay: (
        <StatusDisplay
          config="occurrenceStatus"
          value={item.occurrenceStatusId}
        />
      ),
      view: (
        <Link
          to={`${SUBROUTES.occurrences.path}/${item.id}`}
          data-tooltip-id={String(item.id)}
          data-tooltip-content={'Ver Ocorrência'}>
          <ViewIcon height={20} width={20} fill="var(--royal-blue-theme)" />
          <Tooltip id={String(item.id)} place="left" />
        </Link>
      ),
    }));

    return occurrencesData;
  }, [occurrencesList]);

  const handleExportData = () => {
    const exportData = tableRef.current?.getData().map(item => {
      const occurrence = occurrencesList.find(a => a.id === item.id) || {};
      return {
        ...item,
        occurrenceStatusId: occurrence.occurrenceStatusName || '-',
      };
    });

    const formatting = {
      occurrenceDate: defaultDateFormat,
    };

    const cols = columns.filter(col => col.field !== 'view');

    try {
      const { items, visibleCols } = getExportData(exportData, cols, {
        formatting,
        validateColumns: true,
      });

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      (searchHistory.associateName
        ? 'Associado: ' + searchHistory.associateName + '\n'
        : '') +
      (searchHistory.vehiclePlate
        ? 'Placa: ' + searchHistory.vehiclePlate + '\n'
        : '') +
      (searchHistory?.type
        ? 'Tipo de Ocorrência: ' + searchHistory.type + '\n'
        : '') +
      (searchHistory.causes ? 'Causa(s): ' + searchHistory.causes + '\n' : '') +
      'Período: ' +
      defaultDateFormat(searchHistory.startDate) +
      ' a ' +
      defaultDateFormat(searchHistory.endDate) +
      '\nProcessado em: ' +
      defaultDateFormat(new Date()) +
      '\nTotal de registros: ' +
      data.length;

    const exportData = {
      title: 'Relatório de Ocorrências',
      description,
      headers,
      data,
    };

    exportPDF(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: PDF_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() =>
        toast.update(id, {
          render: PDF_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        }),
      );
  };

  const onExportXLS = async () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      headers,
      title: 'Relatório de Ocorrências',
      data,
    };

    exportXLS(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: XLS_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: XLS_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    getOccurrences();

    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Relatórios', 'Ocorrências']}
        onExportPDF={occurrencesList.length > 0 && onExportPDF}
        onExportXLS={occurrencesList.length > 0 && onExportXLS}
        addRedirects={[
          {
            title: 'Criar Ocorrência',
            path: SUBROUTES.occurrences.subroutes.new.path,
            icon: <AddIcon width={20} height={20} />,
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter
        inputs={inputs}
        defaultColumns={columns}
        onSubmit={getOccurrences}
        onFilterColumns={setColumns}
        useDatePicker
        initialDates={getInitialDates({
          months: 1,
        })}
      />
      {occurrencesList.length > 0 && (
        <Table ref={tableRef} columns={columns} data={dataTable} />
      )}
    </PageContainer>
  );
};

export default Occurrences;
