import styled from 'styled-components';
import tw from 'twin.macro';

import { ReactComponent as CheckIcon } from 'assets/images/svg/check.svg';

const Container = styled.div`
  ${tw`flex flex-row gap-2 pt-1 items-center w-fit overflow-hidden relative`}
`;

const Box = styled.input`
  ${tw`outline-0 appearance-none cursor-pointer border-solid disabled:cursor-not-allowed`}

  min-width: ${({ $size }) => ($size ? $size : defaultStyles.check.size)};
  height: ${({ $size }) => ($size ? $size : defaultStyles.check.size)};
  background: ${({ $bg }) => ($bg ? $bg : defaultStyles.check.bg)};

  border-color: ${({ $boxColor }) =>
    $boxColor ? $boxColor : defaultStyles.box.color};
  border-radius: ${({ $boxRadius }) =>
    $boxRadius ? $boxRadius : defaultStyles.box.radius};
  border-width: ${({ $boxWidth }) =>
    $boxWidth ? $boxWidth : defaultStyles.box.width};

  & + svg {
    ${tw`absolute left-0 hidden translate-x-1 -translate-y-1`}
    min-width: ${({ $size }) =>
      $size
        ? `calc(${$size} - 2px)`
        : `calc(${defaultStyles.check.size} - 2px)`};
    height: ${({ $size }) =>
      $size
        ? `calc(${$size} - 2px)`
        : `calc(${defaultStyles.check.size} - 2px)`};
    fill: ${({ $color }) => ($color ? $color : defaultStyles.check.color)};
  }

  &:checked + svg {
    ${tw`block pointer-events-none overflow-visible`}
    filter: ${({ $border }) =>
      $border
        ? `drop-shadow(0 -2px 0 ${$border}) 
    drop-shadow(-2px 0 0 ${$border})
    drop-shadow(0 2px 0 ${$border})
    drop-shadow(2px 0 0 ${$border})`
        : `drop-shadow(0 -2px 0 ${defaultStyles.check.border}) 
    drop-shadow(-2px 0 0 ${defaultStyles.check.border})
    drop-shadow(0 2px 0 ${defaultStyles.check.border})
    drop-shadow(2px 0 0 ${defaultStyles.check.border})`};
  }
`;

const Label = styled.label`
  ${tw`text-sm font-bold truncate cursor-pointer select-none pb-1`};

  color: ${({ $color }) => ($color ? $color : defaultStyles.label.color)};

  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;

const defaultStyles = {
  check: {
    bg: '#fff',
    color: 'var(--royal-blue-theme)',
    size: '22px',
    border: '#fff',
  },
  box: {
    color: 'var(--royal-blue-theme)',
    radius: '0.375rem',
    width: '2px',
  },
  label: { text: 'label', color: 'var(--royal-blue-theme)', props: {} },
};

const Checkbox = ({
  id = '',
  isChecked = false,
  isDisabled = false,
  check = { bg: '', color: '', size: '', border: '' },
  box = { color: '', radius: '', width: '' },
  label = { text: '', color: '', props: {} },
  onChange = () => {},
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Box
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
        $bg={check.bg}
        $color={check.color}
        $size={check.size}
        $border={check.border}
        $boxColor={box.color}
        $boxRadius={box.radius}
        $boxWidth={box.width}
      />
      {isChecked && <CheckIcon />}
      {label.text && (
        <Label
          htmlFor={id}
          $isDisabled={isDisabled}
          $color={label.color}
          {...label.props}>
          {label.text}
        </Label>
      )}
    </Container>
  );
};

export default Checkbox;
