import { useState, useLayoutEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import Input from 'components/ui-kit/Input';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';
import IconButton from 'components/ui-kit/IconButton';

import api from 'services/api';
import {
  BEARER,
  SUBROUTES,
  MODULES,
  UPDATE,
  CREATE,
  DELETE,
} from 'utils/constants';
import { buildPayload, hasPermission, variant } from 'utils/functions';

import {
  BASIC_INFOS,
  FIELD_IS_REQUIRED,
  ALREADY_SAVED_INFORMATION,
  ERROR_MODULE,
  SUCCESS_MODULE,
  ERROR_ALREADY_EXISTS,
  SUCCESS_SUBMODULE,
  ERROR_SUBMODULE,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const Container = styled.form`
  ${tw`h-fit w-full rounded-2xl bg-white px-2 py-4`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center items-center py-2 gap-5`}
`;

const SubmoduleInsert = styled.div`
  ${tw`flex flex-col w-full gap-2 items-center`}

  .notFound {
    ${tw`flex flex-col w-full min-h-[100px] justify-center items-center`}
  }
`;

const Row = styled.div`
  ${tw`flex flex-wrap justify-between items-center h-fit w-full max-w-3xl gap-1 py-2 px-4 bg-[var(--gray-theme)] rounded-lg`}
`;

const Item = styled.div`
  ${tw`flex h-full w-full items-center gap-1 overflow-hidden`}

  max-width: ${({ $maxWidth }) => $maxWidth || 'fit-content'};
`;

const Text = styled.span`
  ${tw`text-sm text-center text-disabled-dark`}

  ${({ $variant }) =>
    variant({
      label: tw`font-semibold`,
      value: tw`truncate`,
      notFound: tw`italic text-[var(--gray-dark)] font-semibold truncate`,
    })({ $variant })}
`;

const keyMapping = {
  identifier: 'name_key',
  name: 'name',
  submodules: 'sub',
};

const getPayload = (values, data) => {
  const transformSubmodulesData = data =>
    data['submodules']?.map(sub => ({
      ...(sub?.id && { id: sub.id }),
      name_key: sub?.identifier,
      name: sub?.name,
    }));

  let newData = { ...data };
  let newValues = { ...values };

  newValues['name'] = formatName(values?.name);
  newData['submodules'] = transformSubmodulesData(newData);
  newValues['submodules'] = transformSubmodulesData(newValues);

  return buildPayload(keyMapping, newValues, newData);
};

const formatName = value => {
  if (!value) return undefined;

  return value.trim();
};

const validationSchema = yup.object({
  identifier: yup.string().required(FIELD_IS_REQUIRED('Identificador')),
  name: yup.string().required(FIELD_IS_REQUIRED('Nome')),
  submodules: yup.array(yup.object()),
});

const Form = () => {
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [submoduleIdentifier, setSubmoduleIdentifier] = useState('');
  const [submoduleName, setSubmoduleName] = useState('');
  const [editSubmodule, setEditSubmodule] = useState({});

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const canEdit = hasPermission(user?.role, main, sub.modules, UPDATE);
  const canDelete = hasPermission(user?.role, main, sub.modules, DELETE);
  const canCreate = hasPermission(user?.role, main, sub.modules, CREATE);

  const getModule = useCallback(async moduleId => {
    setIsLoading(true);
    setData({});

    try {
      const { data: response, status } = await api.get(
        `permissions/modules/${moduleId}`,
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      const {
        id,
        name_key: identifier,
        name,
        sub: submodules,
      } = response?.data || {};

      const submodulesLog =
        submodules?.map(item => ({
          id: item?.id,
          identifier: item?.name_key ?? '',
          name: item?.name ?? '',
          isActive: item?.active || false,
        })) || [];

      setValues({
        identifier: identifier ?? '',
        name: name ?? '',
        submodules: submodulesLog,
      });
      setData({
        id,
        identifier: identifier ?? '',
        name: name ?? '',
        submodules: submodulesLog,
      });

      resetProgress();
      setIsLoading(false);
    } catch {
      toast.error(ERROR_MODULE('buscar'));

      setTimeout(() => {
        setIsLoading(false);
        resetProgress();
        navigate(subroutes.modules.path, {
          state: { currentPage: state?.currentPage },
        });
      }, 3500);
    }
  }, []);

  const onSubmit = useCallback(
    async values => {
      setIsLoading(true);

      const payload = getPayload(values, data);

      if (!Object.keys(payload).length) {
        toast.success(ALREADY_SAVED_INFORMATION);
        setIsLoading(false);
        return;
      }

      try {
        const { status } = Object.keys(data).length
          ? await api.put(
              `permissions/modules/${data.id}`,
              { data: payload },
              {
                headers: {
                  Authorization: BEARER + user.token,
                },
              },
            )
          : await api.post(
              'permissions/modules',
              { data: payload },
              {
                headers: {
                  Authorization: BEARER + user.token,
                },
              },
            );

        if (status !== 200 && status !== 201) throw new Error();

        let successMessage = Object.keys(data).length
          ? SUCCESS_MODULE('editado')
          : SUCCESS_MODULE('criado');

        toast.success(successMessage);

        setTimeout(() => {
          navigate(subroutes.modules.path, {
            state: { currentPage: state?.currentPage },
          });
          resetProgress();
          setIsLoading(false);
        }, [2000]);
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 409:
            errorMessage = ERROR_ALREADY_EXISTS;
            break;
          default:
            errorMessage = Object.keys(data).length
              ? ERROR_MODULE('editar')
              : ERROR_MODULE('criar');
        }
        toast.error(errorMessage);
        resetProgress();
        setIsLoading(false);
      }
    },
    [data, state],
  );

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    handleChange,
    submitCount,
    values,
    errors,
  } = useFormik({
    initialValues: {
      identifier: '',
      name: '',
      submodules: [],
    },
    onSubmit,
    validationSchema,
  });

  const handleAddSubmodule = useCallback(
    ({ isEditing = false }) => {
      const newIdentifier = submoduleIdentifier;
      const newName = formatName(submoduleName);

      if (isEditing) {
        if (
          editSubmodule.identifier !== newIdentifier ||
          editSubmodule.name !== newName
        ) {
          const isDuplicate = values.submodules?.some(
            submodule =>
              submodule.id !== editSubmodule.id &&
              (submodule.identifier === newIdentifier ||
                submodule.name === newName),
          );

          if (isDuplicate) {
            toast.warning('Identificador ou Nome já adicionado.');
            return;
          }

          const updatedSubmodules = values.submodules.map(submodule =>
            submodule.id === editSubmodule.id
              ? {
                  ...submodule,
                  identifier: newIdentifier,
                  name: newName,
                }
              : submodule,
          );

          setValues(prev => ({
            ...prev,
            submodules: updatedSubmodules,
          }));

          toast.success(SUCCESS_SUBMODULE('atualizado'));
        } else {
          toast.info('Nenhuma alteração detectada.');
        }
      } else {
        const isDuplicate = values.submodules?.some(
          submodule =>
            submodule.identifier === newIdentifier ||
            submodule.name === newName,
        );

        if (isDuplicate) {
          toast.warning('Identificador ou Nome já adicionado.');
          return;
        }

        const newSubmodule = {
          identifier: newIdentifier,
          name: newName,
        };

        setValues(prev => ({
          ...prev,
          submodules: [...prev.submodules, newSubmodule],
        }));

        toast.success(SUCCESS_SUBMODULE('adicionado'));
      }

      setEditSubmodule({});
      setSubmoduleIdentifier('');
      setSubmoduleName('');
    },
    [submoduleIdentifier, submoduleName, values.submodules, editSubmodule],
  );

  const handleRemoveSubmodule = useCallback(indexToRemove => {
    setValues(prev => {
      const updatedSubmodules = prev.submodules.filter(
        (_, index) => index !== indexToRemove,
      );

      return {
        ...prev,
        submodules: updatedSubmodules,
      };
    });
  }, []);

  const updateStatusSubmodule = async item => {
    setIsLoading(true);
    const config = {
      autoclose: 600,
    };

    try {
      const { status } = await api.put(
        `permissions/modules/${data.id}/sub/${item.id}`,
        {
          data: { active: !item.isActive },
        },
        {
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      toast.success(
        SUCCESS_SUBMODULE(!item.isActive ? 'ativado' : 'desativado'),
        config,
      );

      const updatedList = [...values.submodules];
      const index = values.submodules.findIndex(data => data.id === item.id);

      updatedList[index].isActive = !updatedList[index].isActive;

      setFieldValue('submodules', updatedList);
    } catch {
      toast.error(ERROR_SUBMODULE('alterar'), config);
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (id === undefined) return;

    const fetchModule = async () => {
      await getModule(id);
    };
    fetchModule();

    return () => {
      toast.dismiss();
    };
  }, [id]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={[
          'Cadastros',
          'Módulos',
          data?.name ? `Editando: ${data.name}` : 'Novo Módulo',
        ]}
        addRedirects={[
          {
            title: 'Voltar',
            disabled: isLoading,
            path: subroutes.modules.path,
            state: { currentPage: state?.currentPage },
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container onSubmit={handleSubmit}>
        <TitleBox
          title={{ text: BASIC_INFOS }}
          error={submitCount > 0 && errors.name}
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}>
          <Wrapper>
            <Input
              isRequired
              id="identifier"
              maxWidth="150px"
              value={values.identifier}
              onChange={e => {
                if (!e.target.value.includes(' '))
                  setFieldValue('identifier', e.target.value);
              }}
              placeholder=""
              label={{
                text: 'Identificador',
              }}
              error={{
                isActive: submitCount > 0 && errors.identifier,
                message: errors.identifier,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate) ||
                (Object.keys(data).length && values?.identifier === main)
              }
            />
            <Input
              isRequired
              id="name"
              maxWidth="300px"
              value={values.name}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Nome',
              }}
              error={{
                isActive: submitCount > 0 && errors.name,
                message: errors.name,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
          </Wrapper>

          <TitleBox type="line" title={{ text: 'Submódulo(s)' }} />

          <SubmoduleInsert>
            {((!!Object.keys(data).length && canEdit) ||
              (!Object.keys(data).length && canCreate)) && (
              <Wrapper>
                <Input
                  id="submoduleIdentifier"
                  maxWidth="150px"
                  value={submoduleIdentifier}
                  maxLength={50}
                  onChange={e => {
                    if (!e.target.value.includes(' '))
                      setSubmoduleIdentifier(e.target.value);
                  }}
                  placeholder=""
                  label={{
                    text: 'Identificador',
                  }}
                />
                <Input
                  id="submoduleName"
                  maxWidth="300px"
                  value={submoduleName}
                  maxLength={50}
                  onChange={e => setSubmoduleName(e.target.value)}
                  placeholder=""
                  label={{
                    text: 'Nome',
                  }}
                />
                <PrimaryButton
                  type="button"
                  disabled={!submoduleIdentifier || !submoduleName}
                  onClick={() =>
                    Object.keys(editSubmodule).length
                      ? handleAddSubmodule({ isEditing: true })
                      : handleAddSubmodule({ isEditing: false })
                  }>
                  {Object.keys(editSubmodule).length
                    ? 'Editar Submódulo'
                    : 'Adicionar Submódulo'}
                </PrimaryButton>
              </Wrapper>
            )}

            {values.submodules?.length > 0 ? (
              values.submodules.map((item, index) => (
                <Row key={index}>
                  <Item $maxWidth="30%">
                    <Text $variant="label">Identificador:</Text>
                    <Text $variant="value">{item?.identifier || '-'}</Text>
                  </Item>

                  <Item $maxWidth="30%">
                    <Text $variant="label">Nome:</Text>
                    <Text $variant="value">{item?.name || '-'}</Text>
                  </Item>

                  {!!item?.id && (
                    <Item $maxWidth="20%">
                      <Text $variant="label">Status:</Text>
                      <Text $variant="value">
                        {item?.isActive === true ? 'Ativo' : 'Inativo'}
                      </Text>
                    </Item>
                  )}

                  {!!Object.keys(data).length &&
                    canEdit &&
                    item?.identifier !== sub.modules &&
                    item?.identifier !== sub.permissions && (
                      <Item>
                        <IconButton
                          type="edit"
                          onClick={() => {
                            setEditSubmodule(item);
                            setSubmoduleIdentifier(item?.identifier);
                            setSubmoduleName(item?.name);
                          }}
                        />

                        {!!item?.id && item?.isActive && canDelete && (
                          <IconButton
                            type="deactivate"
                            onClick={() => updateStatusSubmodule(item)}
                          />
                        )}
                        {!!item?.id && !item?.isActive && canEdit && (
                          <IconButton
                            type="activate"
                            onClick={() => updateStatusSubmodule(item)}
                          />
                        )}
                        {canDelete && (
                          <IconButton
                            type="delete"
                            onClick={() => handleRemoveSubmodule(index)}
                          />
                        )}
                      </Item>
                    )}
                </Row>
              ))
            ) : (
              <div className="notFound">
                <Text $variant="notFound">Nenhum Submódulo cadastrado</Text>
              </div>
            )}
          </SubmoduleInsert>

          {((Object.keys(data).length && canEdit) ||
            (!Object.keys(data).length && canCreate)) && (
            <PrimaryButton
              type="submit"
              maxWidth="300px"
              bg="green"
              disabled={isLoading}>
              Salvar
            </PrimaryButton>
          )}
        </TitleBox>
      </Container>
    </PageContainer>
  );
};

export default Form;
