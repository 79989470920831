import { Navigate } from 'react-router-dom';

import ListPayments from 'pages/Main/pages/Financial/Payments/List';
import FormPayments from 'pages/Main/pages/Financial/Payments/Form';

import PrivateRoute from 'routes/PrivateRoute';

import { SUBROUTES, MODULES, VISUALIZE } from 'utils/constants';

const { path, subroutes } = SUBROUTES.financial;
const { main, sub } = MODULES.financial;

const FinancialRoutes = [
  {
    path: subroutes.payments.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.payments}
        permission={VISUALIZE}>
        <ListPayments />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.payments.subroutes.id.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.payments}
        permission={VISUALIZE}>
        <FormPayments />
      </PrivateRoute>
    ),
  },
  {
    path,
    element: <Navigate to={subroutes.payments.path} replace />,
  },
];

export default FinancialRoutes;
