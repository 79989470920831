import {
  useState,
  useRef,
  useCallback,
  memo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { areEqual } from 'react-window';

import FixedList from './FixedList';
import FileModal from './FileModal';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import { getFileName, variant } from 'utils/functions';

import useAuth from 'hooks/useAuth';

import { ReactComponent as DeleteIcon } from 'assets/images/svg/close.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/svg/download.svg';
import { ReactComponent as UploadIcon } from 'assets/images/svg/upload.svg';
import { ReactComponent as ViewIcon } from 'assets/images/svg/showPassword.svg';

const FileInputArea = styled.div`
  ${tw`flex flex-col w-full justify-center items-center bg-white border-2 border-dashed shadow-md
  rounded-lg px-1 pt-4 pb-1 mt-3 select-none relative overflow-visible`}

  ${props => (props.$isDisabled ? tw`cursor-auto` : tw`cursor-pointer`)}

  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
  height: ${({ $height }) => $height || 'fit-content'};
  border-color: ${({ $color }) => $color};
  color: ${({ $color }) => $color};
  background-color: ${({ $bg }) => $bg};
`;

const FileContainer = styled.div`
  ${tw`flex flex-col h-full max-h-full w-full max-w-full gap-1 overflow-hidden`}
`;

const FileList = styled(FixedList)`
  .fixed-list {
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const Input = styled.input`
  ${tw`hidden`}
`;

const FileItem = styled.div`
  ${tw`flex flex-row w-full h-fit justify-between items-center gap-2 px-1`}
`;

const FileName = styled.div`
  ${tw`flex flex-row w-full text-sm items-center justify-start gap-0 truncate`}

  color: ${({ $color }) => $color};

  .file-name {
    ${tw`inline-block truncate`}
  }

  .file-extension {
    ${tw`inline-block`}
  }
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-row h-full w-fit justify-center items-center gap-2 pl-1`}
`;

const Button = styled.button`
  ${tw`flex bg-[var(--red-theme)] rounded-circle w-[22px] h-[22px] p-1 justify-center items-center`}

  ${({ $variant }) =>
    variant({
      download: tw`bg-[var(--green-theme)]`,
      view: tw`bg-[var(--basic-primary)]`,
    })({ $variant })}
`;

const Text = styled.span`
  ${tw`text-sm font-semibold text-center`}

  color: ${({ $color }) => $color};
  border-color: ${({ $color }) => $color};
  background-color: ${({ $bg }) => $bg};
  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};

  ${({ $variant }) =>
    variant({
      title: tw`absolute top-0 transform -translate-y-1/2 border-solid border-x-2 px-1 truncate`,
      notFound: tw`text-xs italic opacity-60`,
    })({ $variant })}
`;

const iconStyle = {
  fill: '#FFF',
  width: '100%',
  height: '100%',
};

// extensões de arquivos são permitidas para visualização
const allowedExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'webp',
  'mp4',
  'avi',
  'mov',
  'wmv',
  'mkv',
  'pdf',
];

const isFileChanged = async file => {
  try {
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve(reader.result !== file.lastModified.toString());
      reader.onerror = reject;
      reader.readAsDataURL(file.slice(0, 1));
    });
    return false;
  } catch {
    return true;
  }
};

/**
 * @typedef {Object} FileInputContainerProps
 * @property {string} [text=''] - Texto exibido quando nenhum arquivo é selecionado.
 * @property {string} [fileType=''] - Tipos de arquivos que podem ser selecionados.
 * @property {boolean} [multiple={}] - Se múltiplos arquivos podem ser selecionados.
 * @property {string} [maxWidth=''] - Largura máxima do componente.
 * @property {string} [height=''] - Altura do componente.
 * @property {string} [color=''] - Cor do componente.
 * @property {Array} [files=[]] - Arquivos selecionados ex: [{name: 'nome_do_arquivo', isSaved: true}] isSaved é a verificação se o arquivo está salvo no servidor.
 * @property {Function} [setFiles=() => {}] - Função para atualizar os arquivos selecionados.
 * @property {Function} [onDownload=() => {}] - Função chamada quando o botão de download é clicado.
 */

/**
 * @type {React.FC<FileInputContainerProps>}
 */

const FileInput = forwardRef(
  (
    {
      text = {
        title: 'Clique ou arraste seus arquivos aqui',
        notFound: 'Nenhum arquivo adicionado.',
      },
      fileType = '*',
      multiple = true,
      maxWidth = '',
      height = '',
      color = 'var(--dark-blue-theme)',
      bg = '#FFF',
      files = [],
      setFiles = () => {},
      isDisabled = false,
      onDownload,
      onProgress = () => {},
      ...rest
    },
    ref,
  ) => {
    const [modalData, setModalData] = useState({});

    const fileInputRef = useRef(null);

    const { user } = useAuth();

    const handleDragOver = useCallback(e => {
      if (isDisabled) return;

      e.preventDefault();
    }, []);

    const handleFileDrop = useCallback(event => {
      if (isDisabled) return;

      event.preventDefault();
      if (event.dataTransfer.items) {
        fileInputRef.current.files = event.dataTransfer.files;
        setFiles(Array.from(event.dataTransfer.files));
      }
    }, []);

    const handleFileClick = useCallback(() => {
      fileInputRef.current.click();
    }, []);

    const handleFileChange = useCallback(
      event => {
        const newFiles = Array.from(event.target.files);

        const duplicates = [];
        const nonDuplicates = newFiles.filter(newFile => {
          const isDuplicate = files.some(existingFile => {
            if (existingFile.name === newFile.name) {
              duplicates.push(newFile.name);
              return true;
            }
            return false;
          });
          return !isDuplicate;
        });

        if (duplicates.length) {
          toast.warning(
            `Não foi(ram) inserido(s) o(s) seguinte(s) arquivo(s): ${duplicates.join(', ')}.\nJá existe(m) arquivo(s) com o mesmo nome.`,
            { autoClose: duplicates.length * 2500 },
          );
        }
        setFiles(prevFiles => [...prevFiles, ...nonDuplicates]);
        fileInputRef.current.value = '';
      },
      [files],
    );

    const handleFileDeletion = useCallback((event, index) => {
      if (isDisabled) return;

      event.stopPropagation();
      setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    }, []);

    const handleDownload = async id => {
      const toastId = toast.loading('Carregando arquivo');

      try {
        const {
          data: response,
          status,
          headers,
        } = await api.get(`files/streaming/${id}`, {
          headers: {
            Authorization: BEARER + user.token,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
          responseType: 'arraybuffer',
        });

        if (status !== 200) throw new Error();

        const type = headers['content-type'];
        const urlBlob = window.URL.createObjectURL(
          new Blob([response], { type, encoding: 'UTF-8' }),
        );
        const filename = getFileName(headers['content-disposition']);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.dismiss(toastId);
      } catch {
        toast.update(toastId, {
          render: 'Falha ao baixar arquivo',
          type: 'error',
          isLoading: false,
        });
      }
    };

    const sendFiles = async files => {
      try {
        const formData = new FormData();

        for (const file of files) {
          if (await isFileChanged(file)) {
            toast.warning(
              `Arquivo ${file.name} foi alterado durante o envio. Por favor, selecione novamente.`,
            );
            return [];
          }
          formData.append('file', file);
        }

        const { data: response, status } = await api.post(
          'files/upload',
          formData,
          {
            onUploadProgress: onProgress,
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: BEARER + user.token,
            },
          },
        );

        if (status !== 201) throw new Error();

        const fileIds = response.data.ids;

        return fileIds;
      } catch {
        toast.error('Falha ao enviar arquivos');
        return [];
      }
    };

    const deleteFiles = async files => {
      for (const file of files) {
        const { id, name } = file;
        try {
          await api.delete(`files/${id}`, {
            headers: {
              Authorization: BEARER + user.token,
            },
          });
        } catch {
          toast.error(`Falha ao excluir arquivo: ${name}`);
          return;
        }
      }
    };

    const renderFile = memo(({ data, index, style }) => {
      const file = data[index];
      const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

      return (
        <div style={style}>
          <FileItem key={index}>
            <FileName $color={color}>
              <span className="file-name">{fileName}</span>
              <span className="file-extension">{fileExtension}</span>
            </FileName>
            {(file?.isSaved || data.length > 0) && (
              <ButtonsContainer>
                <>
                  {file?.isSaved &&
                    allowedExtensions.includes(
                      file.extension.toLowerCase(),
                    ) && (
                      <Button
                        type="button"
                        $variant="view"
                        data-tooltip-id="actions"
                        data-tooltip-content="Visualizar"
                        onClick={async event => {
                          event.stopPropagation();

                          setModalData({
                            isOpen: true,
                            file: file,
                            files: data.filter(file => file.isSaved),
                            onDownload: handleDownload,
                          });
                        }}>
                        <ViewIcon style={iconStyle} />
                      </Button>
                    )}
                </>
                <>
                  {file?.isSaved && (
                    <Button
                      type="button"
                      $variant="download"
                      data-tooltip-id="actions"
                      data-tooltip-content="Baixar"
                      onClick={async event => {
                        event.stopPropagation();

                        if (typeof onDownload === 'function') {
                          await onDownload(file);
                        } else {
                          await handleDownload(file?.id);
                        }
                      }}>
                      <DownloadIcon style={iconStyle} />
                    </Button>
                  )}
                </>
                {!isDisabled && (
                  <Button
                    type="button"
                    data-tooltip-id="actions"
                    data-tooltip-content="Excluir"
                    onClick={event => handleFileDeletion(event, index)}>
                    <DeleteIcon style={iconStyle} />
                  </Button>
                )}
              </ButtonsContainer>
            )}
          </FileItem>
          <Tooltip id="actions" place="left" />
        </div>
      );
    }, areEqual);

    useImperativeHandle(ref, () => ({ sendFiles, deleteFiles }));

    return (
      <>
        <FileModal {...modalData} onClose={() => setModalData({})} />

        <FileInputArea
          {...rest}
          ref={ref}
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
          onClick={handleFileClick}
          $maxWidth={maxWidth}
          $height={height}
          $color={color}
          $bg={bg}
          $isDisabled={isDisabled}>
          <Input
            type="file"
            accept={fileType}
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple={multiple}
            disabled={isDisabled}
          />
          <Text $variant="title" $color={color} $bg={bg}>
            {text.title}
          </Text>
          {files.length > 0 ? (
            <FileContainer>
              <FileList items={files} itemSize={27}>
                {renderFile}
              </FileList>
            </FileContainer>
          ) : (
            <>
              {!isDisabled && (
                <UploadIcon
                  width={30}
                  height={30}
                  stroke={color}
                  strokeOpacity={0.6}
                />
              )}
              <Text $variant="notFound" $color={color}>
                {text.notFound}
              </Text>
            </>
          )}
        </FileInputArea>
      </>
    );
  },
);

export default memo(FileInput);
