import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import api from 'services/api';
import useAuth from 'hooks/useAuth';

import { Select as SelectBase } from 'react-functional-select';

import { ReactComponent as TrashIcon } from 'assets/images/svg/trash.svg';

import { BEARER, COLORS } from 'utils/constants';
import { renameObjectProperties, variant } from 'utils/functions';

const Container = styled.div`
  ${tw`flex flex-col w-full relative`}
  max-width: ${props => props.$maxWidth || '350px'};
  height: ${props => props.height || 'fit-content'};
  cursor: ${props => (props.$isDisabled ? 'not-allowed' : 'auto')};
`;

const Label = styled('label')(
  props => ({
    position: 'absolute',
    color: props.$error ? 'var(--red-theme)' : 'var(--gray-dark)',
    fontSize: '14px',
    transition: 'all 250ms',
    margin: '0',
    padding: '0',
    lineHeight: '100%',
    marginLeft: '8px',
    marginTop: props.marginTop || '14px',
    boxSizing: 'content-box',
    fontWeight: 500,
    pointerEvents: 'none',
    zIndex: 5,
  }),
  props =>
    ({ $variant }) =>
      variant({
        focused: {
          transform: props.translateY || 'translateY(-20px)',
          padding: '0 5px',
          background: props.$isDisabled
            ? 'transparent'
            : props.background || '#fff',
          color: props.$error ? 'var(--red-theme)' : '#002DA0',
        },
      })({ $variant }),
);

const Text = styled('p')(
  {
    width: 'fit-content',
    height: 'fit-content',
    margin: 0,
    marginRight: '5px',
    padding: 0,
    color: COLORS.theme.dark_gray,
    fontSize: '10px',

    '&:last-child': {
      margin: 0,
    },
  },
  ({ $variant }) =>
    variant({
      green: {
        color: COLORS.theme.green,
      },
      red: {
        color: COLORS.theme.red,
      },
      error: {
        fontSize: '12px',
        color: COLORS.theme.red,
        margin: 0,
        padding: 0,
        lineHeight: '100%',
        marginLeft: '4px !important',
        marginTop: '4px !important',
      },
    })({ $variant }),
);

/**
 *  @typedef {Object} Label
 *  @property {string} text - Texto do label.
 *  @property {string} $variant - Variante do label.
 *  @property {string} background - Cor de fundo do label.
 *  @property {string} marginTop - Margem superior do label.
 *  @property {string} translateY - Valor de translação Y do label.
 */

/**
 *  @typedef {Object} Error
 * @property {boolean} isActive - Indica se há um erro ativo.
 * @property {string} message - Mensagem de erro.
 */

/**
 *  @typedef {Object} Option
 * @property {any} label - Label do select
 * @property {any} value - valor do select.
 */

/**
 * @typedef {Object} SelectProps
 * @property {'none' | 'nano' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'} size - Adiciona um padding interno.
 * @property {string} maxWidth - Largura máxima.
 * @property {string} height - Altura.
 * @property {string} endpoint - Endpoint para obter dados através de uma requisição.
 * @property {string} selectId - ID do select.
 * @property {string} filterField - Campo usado para filtrar os dados no endpoint.
 * @property {string} filterValue - Valor de filtro.
 * @property {Object} customTheme - Estilos CSS personalizados para o select (objeto CSS).
 * @property {string} fieldToIsActive- Campo para validar se o item está ativo ou desativado, aplicando estilo especial no select (usado com #endpoint).
 * @property {string} fieldToOptionValue - Campo para selecionar o valor no #endpoint.
 * @property {string} fieldToOptionLabel - Campo para selecionar o rótulo no #endpoint.
 * @property {string} noOptionsMsg - Mensagem exibida quando não há opções no select.
 * @property {string} placeholder - Texto do placeholder.
 * @property {boolean} isLoading - Indica se está carregando.
 * @property {boolean} clear - Limpa o valor do select.
 * @property {boolean} isMulti - Permite selecionar múltiplos itens.
 * @property {boolean} isDisabled - Desabilita o select.
 * @property {boolean} isRequired - Torna o campo obrigatório.
 * @property {boolean} isClearable - Adiciona a opção de limpar o valor escolhido.
 * @property {boolean} isSearchable - Permite filtrar através de pesquisa interna.
 * @property {boolean} autoComplete - Usado com #endpoint para autopreencher caso a resposta venha somente com 1 item.
 * @property {boolean} disableIsActive - Desabilita o uso de fieldToIsActive quando o #endpoint está em uso.
 * @property {Array} extraEndpointOptions - Adiciona mais opções junto com #endpoint.
 * @property {boolean} showCounters - Exibe contadores para as opções (usado com #endpoint).
 * @property {Option[]} options - Array de opções { label: campo para visualização, value: valor }.
 * @property {Option} initialValue - Valor inicial.
 * @property {() => {}} onBlur - Função chamada quando o foco é retirado do select.
 * @property {() => {}} onFocus - Função chamada quando o select recebe foco.
 * @property {() => {}} onRequestEnd - Função chamada ao terminar a requisição do #endpoint.
 * @property {() => {}} isRequestLoading - Função chamada durante o carregamento do #endpoint.
 * @property {() => {}} onOptionChange - Função chamada quando a opção é alterada.
 * @property {() => {}} getEndpointSize - Função para obter o tamanho dos itens no #endpoint.
 * @property {() => {}} getEndpointOptions - Função para obter as opções do #endpoint.
 * @property {() => {}} filterFn - Função para filtrar a resposta, usado com #endpoint.
 * @property {Label} label - Configurações para um label animado.
 * @property {Error} error - Configurações para mensagens de erro.
 * @property {...*} rest - Outras propriedades adicionais.
 */

/**
 * @type {React.FC<SelectProps>}
 */

const Select = forwardRef(
  (
    {
      size = '', // size serve para adicionar um padding interno
      maxWidth = '',
      height = '',
      endpoint = '', // endpoint automaticamente capta dados atraves de uma requisicao
      selectId = '', // id
      filterField = '',
      filterValue = '',
      customTheme, // aplicar estilos CSS no select => CSS OBJECT
      fieldToIsActive = '', // campo para validacao se o item esta ativo ou desativo aplicando estilo especial no select, #endpoint
      fieldToOptionValue = '', // selecionar o campo value no #endpoint
      fieldToOptionLabel = '', // selecionar o campo label no #endpoint
      noOptionsMsg = 'Não há opções', // caso nao haja options no select
      placeholder = 'Escolha uma opção',
      isLoading = false,
      clear = false, // limpar o valor do select
      isMulti = false, // para selecionar multiplos itens
      isDisabled = false,
      isRequired = false,
      isClearable = true, // adiciona a opção de limpar o valor escolhido atraves de um botao de lixeira
      isSearchable = true, // para filtrar atraves de pesquisa interna
      autoComplete = false, // usado com o #endpoint para autopreencher caso a resposta da request venha somente com 1 item
      disableIsActive = false, // desabilitar o filterToIsActive caso use o #endpoint
      extraEndpointOptions = [], // adiciona mais options juntamente com o #endpoint
      showCounters = false, // exibir contadores para as options usado com #endpoint
      options = [{ label: '', value: '' }], // array de opções { label: campo para visualização, value: valor }
      initialValue, // valor inicial
      renameFields = {}, // propriedade para renomear os campos dentro do option usado com #endpoint
      filterFn = () => {},
      onBlur = () => {},
      onFocus = () => {},
      onRequestEnd = () => {}, // ao terminar o #endpoint request
      isRequestLoading = () => {}, // getting #endpoint loader
      onOptionChange = () => {},
      getEndpointSize = () => {}, // pegar o tamanho dos itens no #endpoint
      getEndpointOptions = () => {}, // pegar as opções do #endpoint
      label = {
        text: '',
        $variant: '',
        background: '',
        marginTop: '',
        translateY: '',
      }, // label animado
      error = {
        isActive: false,
        message: '',
      },
      ...rest
    },
    ref,
  ) => {
    const [block, setBlock] = useState();
    const [controller, setController] = useState();
    const [isSelectLoading, setIsSelectLoading] = useState(false);
    const [initialEndpoint, setInitialEndpoint] = useState('');
    const [endpointOptions, setEndpointOptions] = useState([]);
    const [hasValue, setHasValue] = useState(initialValue ? true : false);
    const [isFocused, setIsFocused] = useState(false);
    const [counters, setCounters] = useState({
      total: 0,
      actives: 0,
      inactives: 0,
    });

    const selectRef = useRef(null);

    const { user } = useAuth();

    const setSize = () => {
      if (customTheme) return;

      switch (size) {
        case 'none':
          return '0';
        case 'nano':
          return '1.5px';
        case 'sm':
          return '5px';
        case 'md':
          return '8px';
        case 'lg':
          return '12px';
        case 'xl':
          return '16px';
        case 'xxl':
          return '20px';
        default:
          return '6px';
      }
    };

    const sortByActive = array => {
      const actives = array.filter(item => item.isActive);
      const inactives = array.filter(item => !item.isActive);

      return actives.concat(inactives);
    };

    const theme = {
      color: {
        border: error.isActive ? 'var(--red-theme)' : 'var(--gray-theme)',
        danger: 'var(--gray-theme)',
        disabled: 'var(--gray-theme)',
        dangerLight: 'rgba(220, 53, 69, 0.25)',
        primary: 'var(--royal-blue-theme)',
        iconSeparator: 'transparent',
        placeholder: 'var(--gray-dark)',
      },
      select: {
        css: `
        width: 100%;
        color: var(--gray-dark) !important;
      `,
      },
      icon: {
        padding: '0 16px',
        clear: {
          width: '25px',
          height: '25px',
          css: `
              border: solid 1px blue;
            `,
        },
      },
      input: {
        css: `color: rgba(39, 52, 68, 0.6) !important;`,
      },
      control: {
        minHeight: '10px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '8px',
        padding: setSize(),
        boxShadow: undefined,
        boxShadowColor: undefined,
        focusedBorderColor: 'var(--gray-dark)',
        css: `
            cursor: pointer;
            box-sizing: border-box;
            padding: 0 0 0 7px;

            .isDisabled {
                color: var(--red-theme) !important;
            }
          `,
      },
      menu: {
        width: '100%',
        padding: undefined,
        margin: undefined,
        borderRadius: '2px',
        option: {
          textAlign: 'left',
          padding: '.625em .75em',
        },
        css: `
                font-size: 14px;
                border-radius: 2px;
                color: rgba(39, 52, 68, 0.6) !important;
                overflow: hidden;
                cursor: pointer;
                z-index: 15;
        
                * {
                    box-sizing: border-box;
                }
                
                .isDisabled {
                  color: var(--red-theme) !important;
                }
                `,
      },
    };

    const onInputFocus = event => {
      if (label) setIsFocused(true);

      onFocus(event);
    };

    const onInputBlur = event => {
      if (label) setIsFocused(false);

      onBlur(event);
    };

    const reset = () => {
      ref?.current.clearValue();
      setEndpointOptions([]);
      controller.abort();
      setCounters({
        total: 0,
        actives: 0,
        inactives: 0,
      });
    };

    const getDataFromEndpoint = useCallback(async () => {
      if (!endpoint || !user || !controller) return;

      setIsSelectLoading(true);
      isRequestLoading(true);

      try {
        const { data: response, status } = await api.get(`/${endpoint}`, {
          signal: controller.signal,
          headers: {
            Authorization: BEARER + user.token,
          },
        });

        if (status !== 200) throw new Error();

        const res = response.data || response;
        let data = res;

        // Filter the data based on the values of filterValue and filterField
        if (filterValue && filterField) {
          data = data.filter(item => item[filterField].includes(filterValue));
        }

        // based on the fields given on props, we map a new array to build our select options
        const buildOptions = data.map(item => {
          let optionData = item;

          if (Object.keys(renameFields).length > 0) {
            optionData = renameObjectProperties(item, renameFields);
          }

          const option = {
            ...optionData,
            label:
              typeof fieldToOptionLabel === 'function'
                ? fieldToOptionLabel(item)
                : item[fieldToOptionLabel],
            value: item[fieldToOptionValue],
          };

          if (fieldToIsActive) {
            option.isActive = item[fieldToIsActive];
          }

          return option;
        });

        // searching for items to include at start of options array
        const startOptions = extraEndpointOptions.filter(
          item => item.order === 'start',
        );
        // searching for items to add not at the top level of options array
        const extraOptions = extraEndpointOptions.filter(
          item => item.order !== 'start',
        );
        // first adding the additionals options to array of options then apply a sort for alphabetic order
        const sortByAlphabetic = buildOptions
          .concat(extraOptions)
          .sort((a, b) => {
            const labelA = a.label.toLowerCase();
            const labelB = b.label.toLowerCase();

            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0;
          });

        const validation =
          fieldToIsActive &&
          endpoint &&
          !disableIsActive &&
          buildOptions.some(item => !item.isActive)
            ? sortByActive(buildOptions.concat(extraOptions)) // The active sort only works for users who are not of the client type
            : sortByAlphabetic;

        if (startOptions.length > 0) {
          // after sorting and if there is a item on toStart, we add the fields to the top level of options array, to order correctly
          startOptions.forEach(item =>
            validation.unshift({
              label: item.label,
              value: item.value,
              isActive: item.isActive,
            }),
          );
        }

        setCounters({
          total: res.length,
          actives: res.filter(item => item[fieldToIsActive]).length,
          inactives: res.filter(item => !item[fieldToIsActive]).length,
        });

        const filteredResponse = filterFn(validation) || validation;

        setEndpointOptions(filteredResponse);
        getEndpointSize(buildOptions.length);

        if (autoComplete && buildOptions.length === 1) {
          const [target] = buildOptions;

          ref?.current?.setValue(target);
          setBlock(true);
        }

        setIsSelectLoading(false);
        isRequestLoading(false);
      } catch {
        setIsSelectLoading(false);
        isRequestLoading(false);
        setEndpointOptions([]);
      }
    }, [endpoint, autoComplete, user, controller]);

    useEffect(() => {
      const createController = () => {
        const control = new AbortController();
        control.signal.addEventListener('abort', createController);

        setController(control);
      };

      createController();
    }, []);

    useEffect(() => {
      if (isDisabled || !endpoint) return;

      getDataFromEndpoint();
      onRequestEnd();
      setInitialEndpoint(endpoint);
    }, [endpoint, controller]);

    useEffect(() => {
      if (!endpoint) return;
      getEndpointOptions(endpointOptions);
    }, [endpointOptions, endpoint]);

    useEffect(() => {
      if (isSelectLoading && isDisabled) controller.abort('disarm');
    }, [isSelectLoading, isDisabled]);

    useEffect(() => {
      if (!isClearable || (autoComplete && endpointOptions.length === 1))
        return;

      if (!ref?.current) return;

      if (clear) {
        ref?.current.clearValue();
      }
    }, [ref, clear, isClearable, autoComplete, endpointOptions]);

    useImperativeHandle(
      ref,
      () => ({
        ...selectRef?.current,
        reset,
        reload: getDataFromEndpoint,
      }),
      [],
    );

    return (
      <Container
        ref={ref}
        $maxWidth={maxWidth}
        height={height}
        $isDisabled={isDisabled}>
        <Label
          $error={error.isActive}
          $isDisabled={isDisabled}
          marginTop={label.marginTop}
          background={label.background}
          translateY={label.translateY}
          $variant={isFocused || hasValue ? 'focused' : label?.$variant}>
          {label?.text}
        </Label>

        <SelectBase
          {...rest}
          ref={selectRef}
          memoOptions
          filterIgnoreCase
          blurInputOnSelect
          closeMenuOnSelect
          filterIgnoreAccents
          isMulti={isMulti}
          selectId={selectId}
          isLoading={isLoading || isSelectLoading}
          required={isRequired}
          placeholder={placeholder}
          isClearable={isClearable}
          noOptionsMsg={noOptionsMsg}
          isSearchable={isSearchable}
          isDisabled={(autoComplete && block) || isDisabled}
          themeConfig={customTheme ? { ...theme, ...customTheme } : theme}
          options={endpoint ? endpointOptions : options}
          initialValue={initialValue}
          loadingMsg="Buscando dados..."
          onInputFocus={onInputFocus}
          onInputBlur={onInputBlur}
          clearIcon={
            <TrashIcon fill="var(--red-theme)" width={16} height={16} />
          }
          onOptionChange={option => {
            if (option?.label || option?.value) setHasValue(true);
            else setHasValue(false);

            if (!option?.autoSelection) {
              onOptionChange(
                option || {
                  value: undefined,
                  label: undefined,
                },
              );
            }

            if (endpoint && controller && endpoint !== initialEndpoint)
              controller.abort('disarm');
          }}
          renderOptionLabel={data => (
            <p
              className={
                fieldToIsActive && !data.isActive && endpoint
                  ? 'isDisabled'
                  : ''
              }>
              {data.label}
            </p>
          )}
        />

        {showCounters && endpointOptions.length > 0 && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: 'fit-content',
              marginTop: '4px',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Text>
              Total: <b>{counters.total}</b>
            </Text>

            <Text $variant="green">
              Ativos: <b>{counters.actives}</b>
            </Text>

            <Text $variant="red">
              Inativos: <b>{counters.inactives}</b>
            </Text>
          </div>
        )}

        {error?.isActive && <Text $variant="error">{error?.message}</Text>}
      </Container>
    );
  },
);

export default React.memo(Select);
