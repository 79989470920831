import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

const Main = lazy(() => import('pages/Main'));
const Login = lazy(() => import('pages/Login'));
const NotFound = lazy(() => import('pages/NotFound'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const Home = lazy(() => import('pages/Main/pages/Home'));
const Occurrences = lazy(() => import('pages/Main/pages/Occurrences'));
const Financial = lazy(() => import('pages/Main/pages/Financial'));
const Register = lazy(() => import('pages/Main/pages/Register'));
const Bi = lazy(() => import('pages/Main/pages/Bi'));
const Reports = lazy(() => import('pages/Main/pages/Reports'));
const Profile = lazy(() => import('pages/Main/pages/Profile'));

import OccurrencesRoutes from './subroutes/Occurrences';
import FinancialRoutes from './subroutes/Financial';
import RegisterRoutes from './subroutes/Register';
import ReportsRoutes from './subroutes/Reports';
import BiRoutes from './subroutes/BI';

import LoadingScreen from 'components/ui-kit/LoadingScreen';
import { ROUTES, SUBROUTES } from 'utils/constants';
import PrivateRoute from './PrivateRoute';

const { HOME, LOGIN, FORGOT_PASSWORD } = ROUTES;
const { path: OCCURRENCES } = SUBROUTES.occurrences;
const { path: FINANCIAL } = SUBROUTES.financial;
const { path: REGISTER } = SUBROUTES.register;
const { path: REPORTS } = SUBROUTES.reports;
const { path: BI } = SUBROUTES.bi;

const SuspenseWrapper = ({ children }) => (
  <Suspense fallback={<LoadingScreen isLoading={true} />}>{children}</Suspense>
);

const renderRoutes = (routes = []) => {
  return routes.map(route => (
    <Route
      key={route.path}
      path={route.path}
      element={<SuspenseWrapper>{route.element}</SuspenseWrapper>}
    />
  ));
};

const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <SuspenseWrapper>
            <PrivateRoute module="main">
              <Main />
            </PrivateRoute>
          </SuspenseWrapper>
        }>
        <Route
          path={OCCURRENCES}
          element={
            <SuspenseWrapper>
              <Occurrences />
            </SuspenseWrapper>
          }>
          {renderRoutes(OccurrencesRoutes)}
        </Route>
        <Route
          path={FINANCIAL}
          element={
            <SuspenseWrapper>
              <Financial />
            </SuspenseWrapper>
          }>
          {renderRoutes(FinancialRoutes)}
        </Route>
        <Route
          path={REGISTER}
          element={
            <SuspenseWrapper>
              <Register />
            </SuspenseWrapper>
          }>
          {renderRoutes(RegisterRoutes)}
        </Route>
        <Route
          path={REPORTS}
          element={
            <SuspenseWrapper>
              <Reports />
            </SuspenseWrapper>
          }>
          {renderRoutes(ReportsRoutes)}
        </Route>
        <Route
          path={BI}
          element={
            <SuspenseWrapper>
              <Bi />
            </SuspenseWrapper>
          }>
          {renderRoutes(BiRoutes)}
        </Route>
        <Route
          path={SUBROUTES.profile.path}
          element={
            <SuspenseWrapper>
              <Profile />
            </SuspenseWrapper>
          }
        />
        <Route
          path={HOME}
          element={
            <SuspenseWrapper>
              <Home />
            </SuspenseWrapper>
          }
        />
      </Route>

      <Route
        path={LOGIN}
        element={
          <SuspenseWrapper>
            <PrivateRoute invertValidation>
              <Login />
            </PrivateRoute>
          </SuspenseWrapper>
        }
      />

      <Route
        path={FORGOT_PASSWORD}
        element={
          <SuspenseWrapper>
            <ForgotPassword />
          </SuspenseWrapper>
        }
      />

      <Route
        path="*"
        element={
          <SuspenseWrapper>
            <NotFound />
          </SuspenseWrapper>
        }
      />
    </Routes>
  );
};

export default Router;
