import api from 'services/api';

import { saveAs } from 'file-saver';
import { BEARER } from 'utils/constants';

import { getItem } from 'providers/storage';

const baseContent = {
  title: '',
  description: '',
  data: [['']],
  headers: [''],
};

const exportPDF = async (content = baseContent, filename = '') => {
  const payload = {
    data: {
      headers: content.headers,
      rows: content.data,
      title: content.title,
      description: content.description,
    },
  };

  const token = getItem('token');

  if (!token) throw new Error();

  try {
    const response = await api.post(
      '/providers/pdf/generate',
      JSON.stringify(payload),
      {
        headers: {
          Authorization: BEARER + token,
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      },
    );

    const { data } = response;

    const blob = new Blob([data], {
      type: 'application/pdf;charset=utf-8',
    });

    saveAs(blob, `${filename}.pdf`);

    return { end: true, status: response.status };
  } catch ({ message }) {
    return { message, end: true, error: true };
  }
};

export default exportPDF;
