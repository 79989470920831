import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import Input from 'components/ui-kit/Input';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';

import api from 'services/api';
import { BEARER, SUBROUTES, MODULES, UPDATE, CREATE } from 'utils/constants';
import { buildPayload, hasPermission } from 'utils/functions';

import {
  BASIC_INFOS,
  FIELD_IS_REQUIRED,
  ALREADY_SAVED_INFORMATION,
  ERROR_PAYMENT_METHODS,
  SUCCESS_PAYMENT_METHODS,
  ERROR_ALREADY_EXISTS,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const Container = styled.form`
  ${tw`h-fit w-full rounded-2xl bg-white px-2 py-4`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center items-start py-2 gap-5`}
`;

const validationSchema = yup.object({
  name: yup.string().required(FIELD_IS_REQUIRED('Nome')),
});

const Form = () => {
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const canEdit = hasPermission(user?.role, main, sub.paymentMethods, UPDATE);
  const canCreate = hasPermission(user?.role, main, sub.paymentMethods, CREATE);

  const getPaymentMethod = useCallback(async methodId => {
    setIsLoading(true);
    setData({});

    try {
      const { data: response, status } = await api.get(
        `payments/methods/${methodId}`,
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      const { id, name } = response?.data || {};

      setValues({
        name,
      });
      setData({
        id,
        name,
      });

      resetProgress();
      setIsLoading(false);
    } catch {
      toast.error(ERROR_PAYMENT_METHODS('buscar'));

      setTimeout(() => {
        setIsLoading(false);
        resetProgress();
        navigate(subroutes.paymentMethods.path, {
          state: { currentPage: state?.currentPage },
        });
      }, 3500);
    }
  }, []);

  const onSubmit = useCallback(
    async values => {
      setIsLoading(true);

      const keyMapping = {
        name: 'name',
      };

      const payload = buildPayload(keyMapping, values, data);

      if (Object.keys(payload).length === 0) {
        toast.success(ALREADY_SAVED_INFORMATION);
        setIsLoading(false);
        return;
      }

      try {
        const { status } = Object.keys(data).length
          ? await api.put(
              `payments/methods/${data.id}`,
              { data: payload },
              {
                headers: {
                  Authorization: BEARER + user.token,
                },
              },
            )
          : await api.post(
              'payments/methods/',
              { data: payload },
              {
                headers: {
                  Authorization: BEARER + user.token,
                },
              },
            );

        if (status !== 200 && status !== 201) throw new Error();

        let successMessage =
          Object.keys(data).length > 0
            ? SUCCESS_PAYMENT_METHODS('editado')
            : SUCCESS_PAYMENT_METHODS('criado');

        toast.success(successMessage);

        setTimeout(() => {
          navigate(subroutes.paymentMethods.path, {
            state: { currentPage: state?.currentPage },
          });
          resetProgress();
          setIsLoading(false);
        }, [2000]);
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 409:
            errorMessage = ERROR_ALREADY_EXISTS;
            break;
          default:
            errorMessage =
              Object.keys(data).length > 0
                ? ERROR_PAYMENT_METHODS('editar')
                : ERROR_PAYMENT_METHODS('criar');
        }
        toast.error(errorMessage);
        resetProgress();
        setIsLoading(false);
      }
    },
    [data, state],
  );

  const { handleSubmit, setValues, handleChange, submitCount, values, errors } =
    useFormik({
      initialValues: {
        name: '',
      },
      onSubmit,
      validationSchema,
    });

  useEffect(() => {
    if (errors.name && submitCount > 0) {
      toast.error(errors.name);
    }
  }, [submitCount]);

  useLayoutEffect(() => {
    if (id === undefined) return;

    const fetchPaymentMethod = async () => {
      await getPaymentMethod(id);
    };
    fetchPaymentMethod();

    return () => {
      toast.dismiss();
    };
  }, [id]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={[
          'Cadastros',
          'Métodos de Pagamento',
          data?.name ? `Editando: ${data.name}` : 'Novo Método de Pagamento',
        ]}
        addRedirects={[
          {
            title: 'Voltar',
            disabled: isLoading,
            path: subroutes.paymentMethods.path,
            state: { currentPage: state?.currentPage },
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container onSubmit={handleSubmit}>
        <TitleBox
          title={{ text: BASIC_INFOS }}
          error={submitCount > 0 && errors.name}
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}>
          <Wrapper>
            <Input
              isRequired
              id="name"
              maxWidth="300px"
              value={values.name}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Nome',
              }}
              error={{
                isActive: submitCount > 0 && errors.name,
                message: errors.name,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
          </Wrapper>

          {((Object.keys(data).length && canEdit) ||
            (!Object.keys(data).length && canCreate)) && (
            <PrimaryButton
              type="submit"
              maxWidth="300px"
              bg="green"
              disabled={isLoading}>
              Salvar
            </PrimaryButton>
          )}
        </TitleBox>
      </Container>
    </PageContainer>
  );
};

export default Form;
