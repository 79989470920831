import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const editParam = (param, newValue) => {
    params.set(param, newValue);
    navigate(`${pathname}?${params.toString()}`);
  };

  const removeParam = param => {
    params.delete(param);
    navigate(`${pathname}?${params.toString()}`);
  };

  return { params, editParam, removeParam };
};

export default useQuery;
