import { useCallback, useEffect, useRef, useState, memo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { IMaskInput } from 'react-imask';

import DefaultModal from 'components/ui-kit/DefaultModal';
import Map from 'components/ui-kit/Map';
import Select from 'components/ui-kit/Select';
import Input from 'components/ui-kit/Input';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import api from 'services/api';
import { BEARER } from 'utils/constants';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import { Marker, Tooltip } from 'react-leaflet';
import { IconFactory } from 'components/ui-kit/Map/Icon';

const Container = styled.div`
  ${tw`flex flex-col w-full h-full gap-5 m-0 overflow-auto`}
`;

const InsertContainer = styled.div`
  ${tw`flex flex-wrap w-full h-fit items-center justify-center pt-4 gap-5`}
`;

const MapContainer = styled.div`
  ${tw`flex flex-col h-[600px] w-full overflow-hidden rounded-lg`}
`;

const VehicleMarker = ({ position = [], isStart = false, ...rest }) => {
  if (position.length === 0) return;

  const factory = new IconFactory();

  const icon = isStart
    ? factory.TruckIcon('red', {
        iconSize: [50, 50],
        iconAnchor: [25, 50],
      })
    : factory.FlagIcon('green', {
        iconSize: [50, 50],
        iconAnchor: [25, 50],
      });

  return (
    <Marker {...rest} position={position} alt="vehicle-marker" icon={icon}>
      <Tooltip permanent={false} direction="top" offset={[0, -45]}>
        {isStart ? 'Origem' : 'Destino'}
      </Tooltip>
    </Marker>
  );
};

const LocationModal = ({
  isOpen = false,
  type,
  title = '',
  allLocations = [],
  isDisabled = false,
  onAddLocation = () => {},
  onClose = () => {},
}) => {
  const [zipCode, setZipCode] = useState('');

  const [countryId, setCountryId] = useState(1);
  const [state, setState] = useState({});
  const [city, setCity] = useState({});

  const mapRef = useRef(null);
  const countrySelectRef = useRef(null);
  const stateSelectRef = useRef(null);
  const citySelectRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const onSearchByZipCode = useCallback(
    async zipCode => {
      setIsLoading(true);

      try {
        const { data: response, status } = await api.get(
          '/georef/ceps/' + zipCode,
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );

        if (status !== 200) throw new Error();

        const { city } = response.data;

        countrySelectRef?.current?.setValue({
          value: city?.state?.country?.id,
          label: city?.state?.country?.name,
          autoSelection: true,
        });

        stateSelectRef?.current?.setValue({
          value: city?.state?.id,
          label: city?.state?.name,
          autoSelection: true,
        });

        setState({
          shortCode: city?.state?.short_code,
          name: city?.state?.name,
          id: city?.state?.id,
        });

        citySelectRef?.current?.setValue({
          value: city?.id,
          label: city?.name,
          autoSelection: true,
        });

        setCity({
          name: city?.name,
          id: city?.id,
          position:
            !!city?.latitude && !!city?.longitude
              ? [city?.latitude, city?.longitude]
              : [],
        });
      } catch {
        toast.error('CEP não encontrado');
      } finally {
        resetProgress();
        setIsLoading(false);
      }
    },
    [citySelectRef, stateSelectRef],
  );

  const handleAddLocation = useCallback(() => {
    if (!city?.id) {
      toast.warning('É necessário selecionar uma Cidade para adicioná-la');
      return;
    } else if (isDisabled) {
      toast.warning('Não é possível adicionar mais um local, limite atingido!');
      return;
    }
    onAddLocation(
      {
        cityId: city?.id,
        cityName: city?.name,
        position: city?.position,
        stateId: state?.id,
        stateName: state?.name,
        stateShortCode: state?.shortCode,
      },
      type,
    );
    handleClose();
  }, [city, state, onAddLocation]);

  const handleClose = () => {
    setZipCode('');
    setState({});
    setCity({});
    stateSelectRef?.current?.clearValue();
    citySelectRef?.current?.clearValue();
    toast.dismiss();
    onClose();
  };

  useEffect(() => {
    if (zipCode?.length !== 8) return;

    const fetchSearchByZipCode = async () => {
      await onSearchByZipCode(zipCode);
    };

    fetchSearchByZipCode();
  }, [zipCode]);

  useEffect(() => {
    if (!isOpen) return;

    const enterKeyPressHandler = event => {
      if (event.key === 'Enter') {
        handleAddLocation();
      }
    };
    window.addEventListener('keypress', enterKeyPressHandler);

    return () => {
      window.removeEventListener('keypress', enterKeyPressHandler);
    };
  }, [isOpen, city, state]);

  return (
    <DefaultModal
      isOpen={isOpen}
      title={title}
      onClose={handleClose}
      maxWidth="90vw"
      onBackgroundClick={handleClose}
      onEscapeKeyDown={handleClose}>
      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container>
        <InsertContainer>
          <Select
            size="sm"
            maxWidth="250px"
            options={allLocations}
            onOptionChange={option => {
              if (!option?.value) return;

              setCountryId(option?.countryId);
              setState({
                shortCode: option.stateShortCode,
                name: option.stateName,
                id: option.stateId,
              });
              setCity({
                name: option?.cityName,
                id: option?.cityId,
                position: option?.position,
              });
              countrySelectRef.current?.setValue({
                value: option?.countryId,
                label: option?.countryName,
                autoSelection: true,
              });
              stateSelectRef.current?.setValue({
                value: option?.stateId,
                label: option?.stateName,
                autoSelection: true,
              });
              citySelectRef.current?.setValue({
                value: option?.cityId,
                label: option?.cityName,
                autoSelection: true,
              });
            }}
            placeholder=""
            label={{
              text: 'Locais já adicionados',
            }}
          />

          <Input
            id="zipCode"
            as={IMaskInput}
            unmask={true}
            mask="00000-000"
            maxWidth="150px"
            placeholder=""
            label={{ text: 'Buscar por CEP' }}
            value={zipCode}
            onAccept={value => setZipCode(value)}
          />

          <Select
            ref={countrySelectRef}
            size="sm"
            maxWidth="200px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint="georef/countries"
            onOptionChange={option => {
              if (state?.id) {
                stateSelectRef.current?.clearValue();
              }

              if (zipCode && option?.value !== countryId) setZipCode();

              setCountryId(option?.value);
            }}
            placeholder=""
            label={{
              text: 'Filtrar por País',
            }}
            initialValue={{
              label: 'Brasil',
              value: 1,
            }}
          />

          <Select
            ref={stateSelectRef}
            size="sm"
            maxWidth="250px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint={`georef/states/?country_id=${countryId}`}
            onOptionChange={option => {
              if (city?.id) {
                setCity({});
                citySelectRef.current.clearValue();
              }
              if (zipCode && option?.value !== state?.id) setZipCode();

              setState({
                shortCode: option.short_code,
                name: option.label,
                id: option.value,
              });
            }}
            placeholder=""
            label={{ text: 'Fitrar por Estado' }}
            isDisabled={!countryId}
          />
          <Select
            ref={citySelectRef}
            isRequired
            size="sm"
            maxWidth="250px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint={`georef/cities/?uf_id=${state?.id}`}
            onOptionChange={option => {
              if (zipCode && option?.value !== city?.id) setZipCode();

              setCity({
                name: option.label,
                id: option.value,
                position:
                  option?.latitude && option?.longitude
                    ? [option?.latitude, option?.longitude]
                    : [],
              });

              setState({
                shortCode: option?.state?.short_code,
                name: option?.state?.name,
                id: option?.state?.id,
              });
            }}
            placeholder=""
            label={{
              text: 'Selecione uma Cidade',
            }}
            isDisabled={!state?.id}
          />

          <PrimaryButton type="button" onClick={handleAddLocation}>
            Adicionar
          </PrimaryButton>
        </InsertContainer>

        <MapContainer>
          <Map
            ref={mapRef}
            center={city?.position?.length > 0 ? city?.position : [0, 0]}>
            <VehicleMarker
              isStart={type === 'origin'}
              position={city?.position}
            />
          </Map>
        </MapContainer>
      </Container>
    </DefaultModal>
  );
};

export default memo(LocationModal);
