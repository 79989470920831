import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { subMonths } from 'date-fns';

import PageHeader from 'components/ui-kit/PageHeader';
import PageContainer from 'components/ui-kit/PageContainer';
import FilterTool from 'components/ui-kit/FilterTool';
import Table from 'components/ui-kit/Table';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import {
  getInitialDates,
  getExportData,
  getBackendDateFormat,
} from 'utils/functions';
import Formatter from 'utils/formatter';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import {
  STANDARD_ERROR,
  NO_DATA_FOUND,
  GETTING_PDF,
  GETTING_XLS,
  PDF_ERROR,
  PDF_READY,
  XLS_ERROR,
  XLS_READY,
} from 'utils/messages';
import exportPDF from 'services/pdf';
import exportXLS from 'services/xls';

const defaultColumns = [
  { label: 'Tipo de Ocorrência', field: 'typeOccurrence' },
  { label: 'Qtd. de Atendimentos', field: 'amountServices' },
  { label: 'Valor Orçado', field: 'valueBudget', display: 'valueBudgetBRL' },
  { label: 'Valor Fechado', field: 'valueClosed', display: 'valueClosedBRL' },
  { label: 'Pago Excedente', field: 'excessPaid', display: 'excessPaidBRL' },
  {
    label: 'Pago Associação',
    field: 'associationPaid',
    display: 'associationPaidBRL',
  },
];

const Financial = () => {
  const [financialList, setFinancialList] = useState([]);

  const [typeOccurrence, setTypeOccurrence] = useState();
  const [searchHistory, setSearchHistory] = useState({});

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef(null);

  const { user } = useAuth();
  const { defaultDateFormat, exportDateFormat, formatToBRL } = new Formatter();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const inputs = [
    {
      type: 'select',
      maxWidth: '230px',
      options: [
        { label: 'Acidente', value: 1 },
        { label: 'Pane', value: 2 },
        { label: 'Vidro', value: 3 },
      ],
      placeholder: 'Tipo de Ocorrência',
      onOptionChange: option => setTypeOccurrence(option),
    },
  ];

  const getFinancialData = useCallback(async () => {
    setIsLoading(true);

    setFinancialList([]);
    setSearchHistory({});

    try {
      const { data: response, status } = await api.get('reports/budgetary', {
        onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          created_begin: dates?.startDate,
          created_end: dates?.endDate,
          category_id: typeOccurrence?.value,
        },
      });

      if (status !== 200) throw new Error();

      const financialLog = response.data.map(item => ({
        typeOccurrence: item?.category_name,
        amountServices: item?.total_attended || 0,
        valueBudget: item?.cost_budget || 0,
        valueClosed: item?.cost_final || 0,
        excessPaid: item?.cost_excess || 0,
        associationPaid: item?.cost_covered || 0,
      }));

      if (financialLog.length > 0) {
        setSearchHistory({
          startDate: dates?.startDate,
          endDate: dates?.endDate,
          typeOccurrence: typeOccurrence?.label,
        });
      }

      setFinancialList(financialLog);

      setIsLoading(false);
      resetProgress();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
      setIsLoading(false);
      resetProgress();
    }
  }, [dates, typeOccurrence, searchHistory]);

  const dataTable = useMemo(() => {
    return financialList.map(item => ({
      ...item,
      typeOccurrence: item?.typeOccurrence || '-',
      amountServices: item?.amountServices.toLocaleString('pt-br'),
      valueBudget: item.valueBudget,
      valueBudgetBRL: formatToBRL(item.valueBudget),
      valueClosed: item.valueClosed,
      valueClosedBRL: formatToBRL(item.valueClosed),
      excessPaid: item.excessPaid,
      excessPaidBRL: formatToBRL(item.excessPaid),
      associationPaid: item.associationPaid,
      associationPaidBRL: formatToBRL(item.associationPaid),
    }));
  }, [financialList]);

  const handleExportData = () => {
    const formatting = {
      amountServices: value => value.toLocaleString('pt-br'),
      valueBudget: formatToBRL,
      valueClosed: formatToBRL,
      excessPaid: formatToBRL,
      associationPaid: formatToBRL,
    };

    try {
      const { items, visibleCols } = getExportData(
        tableRef.current?.getData(),
        columns,
        {
          formatting,
          validateColumns: true,
        },
      );

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      (searchHistory?.typeOccurrence
        ? 'Tipo de Ocorrência: ' + searchHistory.typeOccurrence + '\n'
        : '') +
      'Período: ' +
      defaultDateFormat(searchHistory.startDate) +
      ' a ' +
      defaultDateFormat(searchHistory.endDate) +
      '\nProcessado em: ' +
      defaultDateFormat(new Date()) +
      '\nTotal de registros: ' +
      data.length;

    const exportData = {
      headers,
      title: 'Relatório Financeiro',
      description,
      data,
    };

    exportPDF(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: PDF_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: PDF_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  const onExportXLS = async () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      headers,
      title: 'Relatório Financeiro',
      data,
    };

    exportXLS(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: XLS_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: XLS_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    getFinancialData();

    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Relatórios', 'Financeiro']}
        onExportPDF={financialList.length > 0 && onExportPDF}
        onExportXLS={financialList.length > 0 && onExportXLS}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter={true}
        disableFilter={financialList.length === 0}
        inputs={inputs}
        defaultColumns={columns}
        disableSearch={false}
        onSubmit={getFinancialData}
        onFilterColumns={setColumns}
        initialDates={getInitialDates({
          months: 1,
        })}
      />

      {financialList.length > 0 && (
        <Table ref={tableRef} columns={columns} data={dataTable} />
      )}
    </PageContainer>
  );
};

export default Financial;
