import {
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import Grid from 'components/pages/Occurrences/List/Grid';

import { SUBROUTES, MODULES, CREATE, VISUALIZE } from 'utils/constants';
import { addItem, getItem, removeItem } from 'providers/storage';
import { getUniqueValues, hasPermission } from 'utils/functions';
import Formatter from 'utils/formatter';

import useAuth from 'hooks/useAuth';

import SSEManager from 'services/sse';
import {
  addOccurrences,
  updateOccurrences,
  deleteOccurrences,
  resetOccurrences,
} from 'store/slices/occurrences';

const { occurrences, reports } = MODULES;

import { ReactComponent as AddIcon } from 'assets/images/svg/add.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/check.svg';

const Container = styled.div`
  ${tw`flex flex-col lg:flex-row h-full w-full items-center lg:justify-center gap-10`}
`;

const searchForFields = (item, value) => {
  const fieldsToSearch = [
    'numProtocol',
    'createdOn',
    'associateName',
    'vehiclePlate',
    'origin',
    'destiny',
    'createdByName',
  ];
  return fieldsToSearch.some(field =>
    item[field].toLowerCase().includes(value.trim().toLowerCase()),
  );
};

const buildOccurrence = data => {
  const item = JSON.parse(data);

  const { defaultDateFormat } = new Formatter();

  const occurrence = {
    id: item?.id,
    numProtocol: item?.nr_protocol || '-',
    createdOn: defaultDateFormat(item?.created_on),
    statusId: item?.status_id,
    statusName: item?.status_name || '-',
    statusDesc: item?.status_description || '-',
    associateName: getUniqueValues(
      item?.vehicles || [],
      'client',
      item => item?.display_name || '-',
    ),
    vehiclePlate: item?.vehicles?.map(v => v?.plate || '-').join(', '),
    origin:
      getUniqueValues(
        item?.vehicles?.flatMap(v => v.locales) || [],
        'from_city',
        item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
      ) || 'Não inserido(s)',
    destiny:
      getUniqueValues(
        item?.vehicles?.flatMap(v => v.locales) || [],
        'to_city',
        item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
      ) || 'Não inserido(s)',
    description: item?.description || 'Não inserida',
    createdById: item?.user_created?.id,
    createdByName: item?.user_created?.display_name,
    isBeingServedByName: item?.user_attended?.display_name,
  };

  return occurrence;
};

const List = () => {
  const [status, setStatus] = useState();

  const { user } = useAuth();
  const { init, close } = new SSEManager(setStatus);
  const { list } = useSelector(selector => selector.occurrences);

  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState({
    opened: '',
    finalizations: '',
  });

  const [filterOptions, setFilterOptions] = useState(() => {
    const savedFilterOptions = getItem('filterOptions');
    if (savedFilterOptions) {
      return JSON.parse(savedFilterOptions);
    }
    return {
      opened: [
        { id: 'userOpened', label: 'Criadas por mim', selected: true },
        {
          id: 'pendingInformation',
          label: 'Pendente Informações',
          selected: true,
        },
        { id: 'pendingBudgets', label: 'Pendente Orçamentos', selected: true },
      ],
      finalizations: [
        { id: 'userFinalizations', label: 'Criadas por mim', selected: true },
        {
          id: 'financialPending',
          label: 'Pendente Financeiro',
          selected: true,
        },
        {
          id: 'closingPending',
          label: 'Pendente Encerramento',
          selected: true,
        },
      ],
    };
  });

  const handleFilterChange = useCallback((theme, optionId) => {
    setFilterOptions(prevOptions => {
      const newOptions = {
        ...prevOptions,
        [theme]: prevOptions[theme].map(option =>
          option.id === optionId
            ? { ...option, selected: !option.selected }
            : option,
        ),
      };
      const allSelected = newOptions[theme].every(option => option.selected);

      if (!allSelected) {
        addItem(`filterIsModified_${theme}`, true);
      } else {
        removeItem(`filterIsModified_${theme}`);
      }
      return newOptions;
    });
  }, []);

  const getFilteredOccurrences = useCallback(
    (list, filterOptions, statusIds) => {
      const optionMap = filterOptions?.reduce((map, option) => {
        map[option.id] = option.selected;
        return map;
      }, {});

      return list
        .map(occurrence => ({
          ...occurrence,
          isDisabled: status === 'DISCONNECTED',
        }))
        .filter(occurrence => {
          return (
            (statusIds.includes(occurrence.statusId) &&
              (optionMap?.userOpened || optionMap?.userFinalizations) &&
              occurrence.createdById === user?.id) ||
            (optionMap?.pendingInformation && occurrence.statusId === 1) ||
            (optionMap?.pendingBudgets && occurrence.statusId === 2) ||
            (optionMap?.financialPending && occurrence.statusId === 3) ||
            (optionMap?.closingPending && occurrence.statusId === 4)
          );
        });
    },
    [user?.id, status],
  );

  const openedOccurrences = useMemo(() => {
    if (!list) return [];
    const filteredList = getFilteredOccurrences(
      list,
      filterOptions.opened,
      [1, 2],
    ).sort((a, b) => a.statusId - b.statusId);

    if (searchInput.opened.trim()) {
      return filteredList.filter(item =>
        searchForFields(item, searchInput.opened),
      );
    } else return filteredList;
  }, [list, searchInput, filterOptions, getFilteredOccurrences]);

  const finalizationsOccurrences = useMemo(() => {
    if (!list) return [];
    const filteredList = getFilteredOccurrences(
      list,
      filterOptions.finalizations,
      [3, 4],
    ).sort((a, b) => b.statusId - a.statusId);

    if (searchInput.finalizations.trim()) {
      return filteredList.filter(item =>
        searchForFields(item, searchInput.finalizations),
      );
    } else return filteredList;
  }, [list, searchInput, filterOptions, getFilteredOccurrences]);

  const options = {
    endpoint: 'occurrences',
    eventListeners: [
      {
        name: 'storage',
        handler: event => {
          const occurrence = buildOccurrence(event.data);
          dispatch(addOccurrences(occurrence));
        },
      },
      {
        name: 'create',
        handler: event => {
          const occurrence = buildOccurrence(event.data);
          dispatch(addOccurrences(occurrence));
        },
      },
      {
        name: 'update',
        handler: event => {
          const occurrence = buildOccurrence(event.data);
          dispatch(updateOccurrences(occurrence));
        },
      },
      {
        name: 'delete',
        handler: event => {
          const { id } = JSON.parse(event.data);
          dispatch(deleteOccurrences(id));
        },
      },
      {
        name: 'error',
        handler: () => setStatus('DISCONNECTED'),
      },
    ],
  };

  useEffect(() => {
    switch (status) {
      case 'CONNECTED':
        toast.success('Buscando informações automaticamente');
        break;
      case 'DISCONNECTED':
        toast.error('Conexão perdida com a transmissão de dados.');
        break;

      default:
        break;
    }
  }, [status]);

  useLayoutEffect(() => {
    init(options);

    return () => {
      close();
      dispatch(resetOccurrences());
      toast.dismiss();
    };
  }, []);

  useEffect(() => {
    if (
      getItem('filterIsModified_opened') ||
      getItem('filterIsModified_finalizations')
    ) {
      addItem('filterOptions', JSON.stringify(filterOptions));
    } else {
      removeItem('filterOptions');
    }
  }, [filterOptions]);

  return (
    <PageContainer style={{ height: '100%' }}>
      <PageHeader
        titles={['Ocorrências']}
        connectionStatus={status}
        addRedirects={[
          ...(hasPermission(user?.role, occurrences.main, null, CREATE)
            ? [
                {
                  title: 'Nova Ocorrência',
                  disabled: status === 'CONNECTING',
                  path: SUBROUTES.occurrences.subroutes.new.path,
                  icon: <AddIcon />,
                },
              ]
            : []),
          ...(hasPermission(
            user?.role,
            reports.main,
            reports.sub.occurrences,
            VISUALIZE,
          )
            ? [
                {
                  title: 'Encerradas',
                  disabled: status === 'CONNECTING',
                  path: `${SUBROUTES.reports.subroutes.occurrences.path}?statusId=5`,
                  icon: <CheckedIcon />,
                },
              ]
            : []),
        ]}
      />

      <Container>
        <Grid
          theme="opened"
          list={openedOccurrences}
          isLoading={status === 'CONNECTING'}
          filterIsModified={
            getItem('filterIsModified_opened') || searchInput.opened.trim()
          }
          searchInput={searchInput.opened}
          filterOptions={filterOptions.opened}
          onFilterChange={optionId => handleFilterChange('opened', optionId)}
          onSearchChange={value =>
            setSearchInput(prev => ({ ...prev, opened: value }))
          }
        />
        <Grid
          theme="finalizations"
          list={finalizationsOccurrences}
          isLoading={status === 'CONNECTING'}
          filterIsModified={
            getItem('filterIsModified_finalizations') ||
            searchInput.finalizations.trim()
          }
          searchInput={searchInput.finalizations}
          filterOptions={filterOptions.finalizations}
          onFilterChange={optionId =>
            handleFilterChange('finalizations', optionId)
          }
          onSearchChange={value =>
            setSearchInput(prev => ({ ...prev, finalizations: value }))
          }
        />
      </Container>
    </PageContainer>
  );
};

export default List;
