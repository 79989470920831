import styled from 'styled-components';
import tw from 'twin.macro';

import PrimaryButton from '../PrimaryButton';

import { ReactComponent as SearchIcon } from 'assets/images/svg/search.svg';
import { ReactComponent as FilterIcon } from 'assets/images/svg/filter.svg';

const Container = styled.div`
  ${tw`flex flex-wrap items-center justify-center w-fit h-fit gap-3 ml-auto`}
`;

const ClearButton = styled(PrimaryButton)`
  ${tw`p-0 shadow-none`}
`;

const iconStyle = {
  fill: '#FFF',
  width: '16px',
  height: '16px',
  flexShrink: 0,
};

const Filter = ({
  children,
  useColumnFilter = false,
  useSearch = true,
  useClear = true,
  disableSearch = false,
  disableFilter = false,
  onSearch = () => {},
  onClearFilter = () => {},
  onOpenFilterModal = () => {},
}) => {
  return (
    <Container>
      {children}

      {useSearch && (
        <>
          {useClear && (
            <ClearButton
              type="button"
              bg="transparent"
              color="var(--gray-dark)"
              onClick={onClearFilter}>
              Limpar Campos
            </ClearButton>
          )}

          {useColumnFilter && (
            <PrimaryButton
              type="button"
              onClick={onOpenFilterModal}
              disabled={disableFilter}
              tooltip={{ text: 'Filtrar Colunas', place: 'bottom' }}>
              <FilterIcon style={iconStyle} />
            </PrimaryButton>
          )}

          <PrimaryButton
            type="button"
            onClick={onSearch}
            disabled={disableSearch}>
            <SearchIcon style={iconStyle} />
            Buscar
          </PrimaryButton>
        </>
      )}
    </Container>
  );
};

export default Filter;
