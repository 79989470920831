export const addItem = (name = '', data) => {
  localStorage.setItem(name, data);
};

export const removeItem = (name = '') => {
  localStorage.removeItem(name);
};

export const getItem = (name = '') => {
  const item = localStorage.getItem(name);

  return item;
};
