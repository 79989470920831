import { useContext } from 'react';
import AuthContext from 'context/Auth';

/**
 * @typedef {Object} User
 * @property {string} token - The user's authentication token.
 * @property {string} name - The user's display name.
 * @property {string} email - The user's email address.
 * @property {string} id - The user's ID.
 * @property {string[]} modules - An array of user modules.
 */

/**
 * @typedef {Object} AuthHook
 * @property {string | null} error - Any error associated with authentication.
 * @property {User | null} user - The authenticated user.
 * @property {boolean} isSessionExpired - Boolean if session is expired.
 * @property {() => void} login - Function to initiate user login.
 * @property {() => void} logout - Function to log the user out.
 * @property {() => void} resetError - Function to reset any authentication error.
 * @property {() => void} onSessionExpires - when session expires.
 */

/**
 * @returns {AuthHook} The authentication hook object.
 */

const useAuth = () => {
  const {
    user,
    userLoading,
    error,
    isSessionExpired,
    login,
    logout,
    resetError,
    onSessionExpires,
  } = useContext(AuthContext);

  return {
    user,
    userLoading,
    error,
    isSessionExpired,
    login,
    logout,
    resetError,
    onSessionExpires,
  };
};

export default useAuth;
