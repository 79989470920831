import ModalBase from 'styled-react-modal';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';

const Modal = ModalBase.styled`
  ${tw`flex flex-col bg-white w-11/12 rounded-xl box-border p-3 relative transition-all max-h-screen`}

  max-width: ${props => props.maxWidth || '400px'};
  height: ${props => props.$height || 'auto'};

  & .modal-buttons-container {
    ${tw`flex w-fit h-fit items-center ml-auto`}
  }

  & * {
    &::-webkit-scrollbar {
      width: 6px;
    }
  }

`;

const Header = styled.div`
  ${tw`w-full h-fit flex justify-between items-center mb-1`}
`;

const Title = styled.h2`
  ${tw`text-xl font-bold m-0 p-0 truncate pb-1`}

  color: ${props => props.color || 'var(--royal-blue-theme)'};
`;

const Button = styled.button`
  ${tw`w-fit h-fit border-none bg-transparent flex items-center justify-center`}
`;

export const ModalBackground = styled.div`
  ${tw`flex w-screen h-full top-0 left-0 bg-black bg-opacity-70 fixed z-[100] backdrop-blur-sm justify-center items-center`}
`;

const DefaultModal = ({
  children,
  buttons,
  height,
  title = '',
  maxWidth = '',
  theme = {},
  isOpen = false,
  hideHeader = false,
  onClose = () => {},
  onEscapeKeyDown = () => {},
  onBackgroundClick = () => {},
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      isOpen={isOpen}
      $height={height}
      onBackgroundClick={onBackgroundClick}
      onEscapeKeydown={onEscapeKeyDown}
      maxWidth={maxWidth}>
      {!hideHeader && (
        <Header>
          <Title color={theme?.titleColor}>{title}</Title>

          <div className="modal-buttons-container">
            {buttons}

            <Button
              type="button"
              onClick={onClose}
              data-tooltip-id="close-modal-button"
              data-tooltip-content="Fechar">
              <CloseIcon fill="var(--gray-dark)" width={20} height={20} />

              <Tooltip id="close-modal-button" place="right" />
            </Button>
          </div>
        </Header>
      )}
      {children}
    </Modal>
  );
};

export default DefaultModal;
