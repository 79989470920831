import { memo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as ClockIcon } from 'assets/images/svg/clock.svg';
import { ReactComponent as StatusIcon } from 'assets/images/svg/status.svg';

import { variant } from 'utils/functions';

const Container = styled.button`
  ${tw`flex flex-col h-fit w-full max-w-full rounded-md p-2 gap-1
    bg-gray-100 hover:bg-gray-200 transition-all overflow-hidden
    shadow-md shadow-black
    border-solid border-4 border-gray-400 hover:border-black`}

  ${({ $isDisabled }) => $isDisabled && tw`opacity-50 cursor-not-allowed`}

  ${({ $variant }) =>
    variant({
      1: tw`hover:border-[var(--red-theme)]`,
      2: tw`hover:border-[var(--warning-dark)]`,
      3: tw`hover:border-[var(--green-theme)]`,
      4: tw`hover:border-[var(--basic-primary)]`,
    })({ $variant })}
`;

const Row = styled.div`
  ${tw`flex h-fit w-full justify-between gap-2 items-center overflow-hidden`}
`;

const Col = styled.div`
  ${tw`flex h-fit w-1/2 overflow-hidden`}
`;

const Text = styled.span`
  ${tw`text-xs truncate text-black font-semibold`}

  .label {
    ${tw`font-bold text-black`}

    ${({ $wainting }) => $wainting && tw`animate-pulse`}

    ${({ $variant }) =>
      variant({
        1: tw`text-[var(--red-theme)]`,
        2: tw`text-[var(--warning-dark)]`,
        3: tw`text-[var(--green-theme)]`,
        4: tw`text-[var(--basic-primary)]`,
      })({ $variant })}
  }
`;

const RoundedWrapper = styled.div`
  ${tw`flex flex-row items-center gap-1 w-fit px-2 py-1 
    shadow-md bg-black rounded-md overflow-hidden
    `}

  .text {
    ${tw`text-sm font-bold text-white truncate`}
  }

  ${({ $variant }) =>
    variant({
      1: tw`bg-[var(--red-theme)]`,
      2: tw`bg-[var(--warning-dark)]`,
      3: tw`bg-[var(--green-theme)]`,
      4: tw`bg-[var(--basic-primary)]`,
    })({ $variant })}
`;

const Hr = styled.hr`
  ${tw`h-px w-full border-0 bg-gray-400`}
`;

const iconStyles = {
  width: '18px',
  height: '18px',
  fill: '#FFF',
  flexShrink: 0,
};

const Card = ({ data = {}, isDisabled = false, onClick = () => {} }) => {
  return (
    <Container
      $variant={data?.statusId}
      $isDisabled={isDisabled}
      onClick={onClick}>
      <Row>
        <RoundedWrapper $variant={data?.statusId}>
          <span className="text">Nº {data?.numProtocol}</span>
        </RoundedWrapper>

        <RoundedWrapper $variant={data?.statusId}>
          <ClockIcon style={iconStyles} />
          <span className="text">{data?.createdOn}</span>
        </RoundedWrapper>

        <RoundedWrapper
          $variant={data?.statusId}
          data-tooltip-id={String(data.id)}
          data-tooltip-html={`<div style="max-width: 150px">${data?.statusDesc}</div>`}>
          <StatusIcon style={iconStyles} />
          <span className="text">{data?.statusName}</span>
        </RoundedWrapper>
      </Row>

      <Hr />

      <Row>
        <Col>
          <Text
            $variant={data?.statusId}
            data-tooltip-id={String(data.id)}
            data-tooltip-content={data?.associateName}>
            <span className="label">Associado(s): </span>
            <span className="value">{data?.associateName}</span>
          </Text>
        </Col>
        <Col>
          <Text
            $variant={data?.statusId}
            data-tooltip-id={String(data.id)}
            data-tooltip-content={data?.vehiclePlate}>
            <span className="label">Placa(s): </span>
            <span className="value">{data?.vehiclePlate}</span>
          </Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text
            $variant={data?.statusId}
            data-tooltip-id={String(data.id)}
            data-tooltip-content={data?.origin}>
            <span className="label">Origem(ns): </span>
            <span className="value">{data?.origin}</span>
          </Text>
        </Col>
        <Col>
          <Text
            $variant={data?.statusId}
            data-tooltip-id={String(data.id)}
            data-tooltip-content={data?.destiny}>
            <span className="label">Destino(s): </span>
            <span className="value">{data?.destiny}</span>
          </Text>
        </Col>
      </Row>

      <Row>
        <Text
          $variant={data?.statusId}
          data-tooltip-id={String(data.id)}
          data-tooltip-html={`<div style="max-width: 300px; font-size: small">${data?.description}</div>`}>
          <span className="label">Descrição: </span>
          <span className="value">{data?.description}</span>
        </Text>
      </Row>

      <Hr />

      <Row>
        <Col>
          <Text
            $variant={data?.statusId}
            data-tooltip-id={String(data.id)}
            data-tooltip-content={data?.createdByName}>
            <span className="label">Criado por: </span>
            <span className="value">{data?.createdByName}</span>
          </Text>
        </Col>

        <Col>
          {data?.isBeingServedByName && data?.statusId < 4 ? (
            <Text
              $variant={data?.statusId}
              data-tooltip-id={String(data.id)}
              data-tooltip-content={data?.isBeingServedByName}>
              <span className="label">Em atendimento por: </span>
              <span className="value">{data?.isBeingServedByName}</span>
            </Text>
          ) : (
            data?.statusId < 3 && (
              <Text $variant={data?.statusId} $wainting>
                <span className="label">Aguardando atendimento...</span>
              </Text>
            )
          )}
        </Col>
      </Row>

      {!isDisabled && <Tooltip id={data?.id} />}
    </Container>
  );
};

export default memo(Card);
