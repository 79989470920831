import { useState, useEffect, memo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import CurrencyInput from 'react-currency-input-field';
import { Tooltip } from 'react-tooltip';

import Input from 'components/ui-kit/Input';
import PrimaryButton from 'components/ui-kit/PrimaryButton';

import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';

const Container = styled(motion.div)`
  ${tw`h-fit w-[450px] z-[1] top-1/3 right-40 absolute`}
`;

const Box = styled(motion.div)`
  ${tw`flex flex-col w-full h-full bg-white gap-4 p-2 items-center
  rounded-lg border-solid border-2 border-[var(--gray-dark)]`}
`;

const InsertContainer = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center items-center gap-4`}
`;

const CloseButton = styled.button`
  ${tw`w-fit h-fit border-none bg-transparent flex items-center absolute top-0 right-0 m-2 z-10`}
`;

const Text = styled.span`
  ${tw`min-h-[22px] text-lg text-center font-semibold text-[var(--royal-blue-theme)] truncate max-w-[calc(100% - 15px)]`}
`;

const boxVariants = {
  open: {
    clipPath: 'inset(0% 0% 0% 0%)',
    transition: {
      type: 'spring',
      duration: 0.4,
    },
  },
  closed: {
    clipPath: 'inset(10% 50% 90% 50%)',
    transition: {
      type: 'spring',
      duration: 0.1,
    },
  },
};

const toFloat = value => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(',', '.'));
  } else if (value === undefined) {
    return 0;
  }
  return value;
};

const ServiceProviderValues = ({
  isOpen = false,
  associatePays = false,
  data = {},
  addInQuotation = () => {},
  onClose = () => {},
}) => {
  const [budgetValue, setBudgetValue] = useState(0);
  const [closedValue, setClosedValue] = useState(0);
  const [associateValue, setAssociateValue] = useState(0);
  const [associationValue, setAssociationValue] = useState(0);

  const handleClose = () => {
    setBudgetValue();
    setClosedValue();
    onClose();
  };

  useEffect(() => {
    if (!closedValue) {
      setAssociateValue(0);
      setAssociationValue(0);
      return;
    }
    setAssociateValue((toFloat(closedValue) * 0.3).toFixed(2));
    setAssociationValue((toFloat(closedValue) * 0.7).toFixed(2));
  }, [closedValue]);

  useEffect(() => {
    setBudgetValue(data?.budgetValue || 0);
    setClosedValue(data?.closedValue || 0);
  }, [data]);

  return (
    <Container
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      variants={{
        open: { opacity: 1, display: 'flex' },
        closed: {
          opacity: 0,
          transitionEnd: {
            display: 'none',
          },
        },
      }}>
      <Box variants={boxVariants}>
        <CloseButton
          type="button"
          data-tooltip-id="close-button"
          data-tooltip-content="Fechar"
          onClick={handleClose}>
          <CloseIcon fill="var(--gray-dark)" width={15} height={15} />

          <Tooltip id="close-button" place="left" />
        </CloseButton>

        <Text
          data-tooltip-id={`editing-${data?.id}`}
          data-tooltip-content={data.name}>
          {data.name}
        </Text>

        <InsertContainer>
          <CurrencyInput
            id="budgetValue"
            customInput={Input}
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            maxWidth="200px"
            placeholder=""
            label={{ text: 'Valor Orçado' }}
            value={budgetValue}
            onValueChange={value => setBudgetValue(value)}
            onBlur={() => setClosedValue(budgetValue)}
            disabled={!data?.id}
          />
          <CurrencyInput
            id="closedValue"
            customInput={Input}
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            maxWidth="200px"
            placeholder=""
            label={{ text: 'Valor Fechado' }}
            value={closedValue}
            onValueChange={value => setClosedValue(value)}
            disabled={!data?.id}
          />
          {associatePays && (
            <>
              <CurrencyInput
                customInput={Input}
                decimalsLimit={2}
                decimalScale={2}
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                maxWidth="200px"
                label={{ text: 'Pago Associado (30%)' }}
                placeholder=""
                value={associateValue}
                disabled={true}
              />
              <CurrencyInput
                customInput={Input}
                decimalsLimit={2}
                decimalScale={2}
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                maxWidth="200px"
                label={{ text: 'Pago Associação (70%)' }}
                placeholder=""
                value={associationValue}
                disabled={true}
              />
            </>
          )}
        </InsertContainer>

        <PrimaryButton
          type="button"
          disabled={
            toFloat(budgetValue) < 1 || toFloat(closedValue) < 1 || !data?.id
          }
          onClick={() =>
            addInQuotation(
              {
                serviceProviderId: data?.id,
                serviceProviderName: data?.name,
                address: data?.address,
                contacts: data?.contacts,
                budgetValue: toFloat(budgetValue),
                closedValue: toFloat(closedValue),
                ...(associatePays
                  ? {
                      associateValue: toFloat(associateValue),
                      associationValue: toFloat(associationValue),
                    }
                  : {}),
              },
              false,
              true,
            )
          }>
          Inserir valores
        </PrimaryButton>
        <Tooltip id={`editing-${data?.id}`} place="bottom" />
      </Box>
    </Container>
  );
};

export default memo(ServiceProviderValues);
