import styled, { css } from 'styled-components';
import tw from 'twin.macro';

import { fadeIn } from 'helpers/animations';

const Container = styled.div`
  ${tw`flex flex-col w-full py-6 px-4`}

  ${({ $hidden }) =>
    !$hidden &&
    css`
      opacity: 1;
      visibility: visible;
      animation: ${fadeIn} 400ms ease-in;
    `}
  
  ${({ $hidden }) =>
    $hidden &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
    
  & > * {
    ${tw`mb-3 last:mb-0`}
  }
`;

const PageContainer = ({ children, hidden = false, ...rest }) => {
  return (
    <Container $hidden={hidden} {...rest}>
      {children}
    </Container>
  );
};

export default PageContainer;
