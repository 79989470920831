import { useCallback, memo, Fragment } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';

import TitleBox from 'components/ui-kit/TitleBox';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import { StartServiceContainer, StartServiceCard } from './BasicInfosForm';

import { BEARER } from 'utils/constants';
import axios from 'axios';
import { getFileName, variant } from 'utils/functions';

import useAuth from 'hooks/useAuth';

import { ReactComponent as DocxIcon } from 'assets/images/svg/word.svg';
import { ReactComponent as PdfIcon } from 'assets/images/svg/pdf_adobe.svg';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit gap-5 p-2 items-center`}
`;

const Row = styled.div`
  ${tw`flex flex-col sm:flex-row justify-center items-center h-fit w-full max-w-full gap-y-2 gap-x-5 py-2 px-4 bg-[var(--gray-theme)] rounded-lg overflow-hidden`}
`;

const Item = styled.div`
  ${tw`flex h-fit w-fit justify-center items-center gap-1 overflow-hidden`}
`;

const Text = styled.span`
  ${tw`text-sm text-center text-disabled-dark font-semibold w-fit max-w-full`}

  ${({ $variant }) =>
    variant({
      value: tw`font-normal truncate`,
      notFound: tw`italic text-[var(--gray-dark)] font-semibold truncate`,
    })({ $variant })}
`;

const AuthorizationCard = styled.div`
  ${tw`flex flex-row w-full h-fit max-w-2xl shadow-lg overflow-hidden rounded-xl`}

  .label {
    ${tw`flex items-center justify-center min-h-full w-[24px] bg-[var(--royal-blue-theme)]
      text-sm font-semibold text-white select-none`}
  }

  .content {
    ${tw`flex flex-col w-full h-full gap-1 p-1 items-center overflow-hidden 
    border-y-2 border-r-2 border-solid border-[var(--gray-theme)] rounded-r-xl`}
  }
`;

const Button = styled(PrimaryButton)`
  ${props => props.$docx && tw`[background: var(--light-cyan-gradient)]`}
  ${props => props.$pdf && tw`[background: var(--red-gradient)]`}
`;

const iconStyle = {
  fill: '#FFF',
  width: '16px',
  height: '16px',
  flexShrink: 0,
};

const Documentations = ({
  routingIsLoading = false,
  canEdit = false,
  canDelete = false,
  data = [],
  occurrenceInfo,
  serviceHasChanged = false,
  syncData = async () => {},
  startService = async () => {},
  resetService = () => {},
  onDeleteOccurrence = () => {},
}) => {
  const { user } = useAuth();

  const handleDownload = useCallback(
    async url => {
      const toastId = toast.loading('Carregando arquivo');

      try {
        const {
          data: response,
          status,
          headers,
        } = await axios.get(url, {
          headers: {
            Authorization: BEARER + user.token,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
          responseType: 'arraybuffer',
        });

        if (status !== 200) throw new Error();

        const type = headers['content-type'];
        const urlBlob = window.URL.createObjectURL(
          new Blob([response], { type, encoding: 'UTF-8' }),
        );
        const filename = getFileName(headers['content-disposition']);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.dismiss(toastId);
      } catch {
        toast.update(toastId, {
          render: 'Falha ao baixar arquivo',
          type: 'error',
          isLoading: false,
        });
      }
    },
    [user, occurrenceInfo],
  );

  return (
    <Container>
      {serviceHasChanged && occurrenceInfo?.isBeingServedBy?.id ? (
        <StartServiceContainer>
          <StartServiceCard>
            <span className="title">Atenção!</span>
            <span className="observation">
              Esta ocorrência está em atendimento por
              <span style={{ fontWeight: 700 }}>
                {`: ${occurrenceInfo.isBeingServedBy.name}.`}
              </span>
              {canEdit && (
                <>
                  <br />
                  Se você assumir, todas as informações preenchidas
                  anteriormente serão descartadas.
                  <br />
                  Você deseja prosseguir e assumir o atendimento?
                </>
              )}
            </span>
            {canEdit && (
              <PrimaryButton
                type="button"
                onClick={async () => {
                  await syncData().then(async () => await startService());
                }}>
                Sim, assumir atendimento
              </PrimaryButton>
            )}
          </StartServiceCard>
        </StartServiceContainer>
      ) : (
        serviceHasChanged && (
          <StartServiceContainer>
            <StartServiceCard>
              <span className="title">Atenção!</span>
              <span className="observation">
                O atendimento desta ocorrência foi iniciado por outro usuário.
                <br />
                Você deseja sincronizar as modificações feitas?
              </span>
              <PrimaryButton
                type="button"
                onClick={async () =>
                  await syncData().then(() => resetService())
                }>
                Sim, sincronizar alterações
              </PrimaryButton>
            </StartServiceCard>
          </StartServiceContainer>
        )
      )}

      <TitleBox
        title={{ text: 'Documentações de Serviço' }}
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2rem',
          padding: '1rem 0 1rem 0',
        }}>
        {data.length > 0 ? (
          <>
            {data?.map((item, index) => (
              <AuthorizationCard key={index}>
                <div className="label">
                  <div style={{ rotate: '-90deg' }}>
                    {item?.category?.toUpperCase()}
                  </div>
                </div>

                <div className="content">
                  <Row>
                    <Item>
                      <Text>Associado(s):</Text>
                      <Text
                        $variant="value"
                        data-tooltip-id={String(index)}
                        data-tooltip-content={item.associateName}>
                        {item.associateName}
                      </Text>
                    </Item>

                    <Item>
                      <Text>Placa(s):</Text>
                      <Text
                        $variant="value"
                        data-tooltip-id={String(index)}
                        data-tooltip-content={item.plate}>
                        {item.plate}
                      </Text>
                    </Item>
                    <Tooltip id={String(index)} place="bottom" />
                  </Row>

                  <Row>
                    <Item>
                      <Text>Associação:</Text>
                      <Text
                        $variant="value"
                        data-tooltip-id={String(index)}
                        data-tooltip-content={item.associationName}>
                        {item.associationName}
                      </Text>
                    </Item>

                    <Item>
                      <Text>Tipo:</Text>
                      <Text
                        $variant="value"
                        data-tooltip-id={String(index)}
                        data-tooltip-content={item.type}>
                        {item.type}
                      </Text>
                    </Item>
                    <Tooltip id={String(index)} place="bottom" />
                  </Row>

                  <Row
                    style={{ background: 'transparent', padding: '0.5rem 0' }}>
                    {item?.downloads?.map((a, index) => (
                      <Fragment key={index}>
                        {a?.type === 'docx' && a?.url && (
                          <Button $docx onClick={() => handleDownload(a?.url)}>
                            <DocxIcon style={iconStyle} />
                            Baixar DOCX
                          </Button>
                        )}
                        {a?.type === 'pdf' && a?.url && (
                          <Button $pdf onClick={() => handleDownload(a?.url)}>
                            <PdfIcon style={iconStyle} />
                            Baixar PDF
                          </Button>
                        )}
                      </Fragment>
                    ))}
                  </Row>
                </div>
              </AuthorizationCard>
            ))}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              height: '80px',
              alignItems: 'center',
              maxWidth: '100%',
            }}>
            <Text $variant="notFound">Nenhuma autorização encontrada</Text>
          </div>
        )}
      </TitleBox>

      {canDelete && (
        <PrimaryButton
          maxWidth="300px"
          bg="red"
          onClick={onDeleteOccurrence}
          disabled={routingIsLoading}>
          Excluir Ocorrência
        </PrimaryButton>
      )}
    </Container>
  );
};

export default memo(Documentations);
