import { useState, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { ReactComponent as EyeOpenIcon } from 'assets/images/svg/showPassword.svg';
import { ReactComponent as EyeCloseIcon } from 'assets/images/svg/hiddenPassword.svg';
import { COLORS } from 'utils/constants';

import { isEmpty, variant } from 'utils/functions';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit relative`}
  max-width: ${({ $maxWidth }) => $maxWidth || 'fit-content'};
`;

export const Label = styled('label')(
  props => ({
    position: 'absolute',
    color: props.$error ? 'var(--red-theme)' : 'var(--gray-dark)',
    fontSize: '14px',
    transition: 'all 250ms',
    margin: '0',
    padding: '0',
    lineHeight: '100%',
    marginLeft: '8px',
    marginTop: props.marginTop || '14px',
    boxSizing: 'content-box',
    fontWeight: 500,
    userSelect: 'none',
    pointerEvents: 'none',
    zIndex: 5,
  }),
  props =>
    ({ $variant }) =>
      variant({
        focused: {
          transform: `translateY(${props.translateY || '-20px'})`,
          padding: '0 5px',
          background: props.$isDisabled
            ? 'transparent'
            : props.background || '#fff',
          color: props.$error ? 'var(--red-theme)' : 'var(--royal-blue-theme)',
        },
      })({ $variant }),
);

const InputBase = styled('input')(
  props => ({
    fontSize: '14px',
    padding: '0 8px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    color: COLORS.theme.dark_gray,
    backgroundColor: props.background || 'white',
    width: props.width || '100%',
    height: props.height || '40px',
    marginBottom: props.$mb ? '5px' : 0,
    border: 'solid 1.5px var(--gray-theme)',

    '&:focus': {
      outline: 'none',
      border: 'solid 1.5px var(--gray-dark)',
    },

    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: 'var(--gray-theme)',
      color: 'rgba(0, 0, 0, .7)',
    },

    '&::placeholder': {
      color: COLORS.theme.dark_gray,
      fontWeight: 400,
    },
    /* Cor do texto do autocomplete */
    '&:-webkit-autofill': {
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': '#ffffff99',
      transition: 'background-color 5000s ease-in-out 0s',
      'box-shadow': 'inset 0 0 20px 20px #23232329',
    },

    '&::-ms-reveal': {
      display: 'none',
    },
  }),
  ({ $variant }) =>
    variant({
      error: {
        borderColor: 'var(--red-theme)',
      },
      registry: {
        border: 'solid 1.5px var(--gray-theme)',

        '&::placeholder': {
          color: 'var(--gray-dark)',
          fontSize: '16px',
        },

        '&:focus': {
          border: 'solid 1.5px var(--gray-dark)',
        },
      },
    })({ $variant }),
);

const Text = styled.p`
  display: ${props => (!props.$isActive ? 'none' : '')};

  ${tw`[font-size: 12px] text-themes-red m-0 p-0 [line-height: 100%] ml-1`}
`;

const PasswordButton = styled.button`
  ${tw`w-fit h-fit absolute inset-0 border-none flex items-center justify-center cursor-pointer ml-auto mr-2 mt-[6px]`};
`;

/**
 * @typedef {Object} InputProps
 * @property {string} [$variant=''] - Variante do input.
 * @property {string} [value=''] - Valor do input.
 * @property {string} containerId - ID do container.
 * @property {Object} [containerStyle={}] - Estilo do container (objeto CSS).
 * @property {string} [height] - Altura do input.
 * @property {boolean} [isPassword=false] - Indica se o componente é do tipo password e deve exibir um ícone de olho.
 * @property {boolean} [disabled=false] - Indica se o input está desabilitado.
 * @property {boolean} [clear=false] - Limpa o valor do input.
 * @property {string} [maxWidth] - Largura máxima do input.
 * @property {string} [background=''] - Cor de fundo do input.
 * @property {Object} [label] - Configurações para um label associado ao input.
 * @property {string} [label.text=''] - Texto do label.
 * @property {string} [label.$variant=''] - Variante do label.
 * @property {string} [label.background=''] - Cor de fundo do label.
 * @property {string} [label.marginTop=''] - Margem superior do label.
 * @property {string} [label.translateY=''] - Valor de translação Y do label.
 * @property {Object} [error] - Configurações para mensagens de erro associadas ao input.
 * @property {boolean} [error.isActive=false] - Indica se há um erro ativo.
 * @property {string} [error.message=''] - Mensagem de erro.
 * @property {string} [error.class=''] - Classe CSS adicional para estilo de erro.
 * @property {function} [onBlur=() => {}] - Função chamada quando o foco é retirado do input.
 * @property {function} [onFocus=() => {}] - Função chamada quando o input recebe foco.
 * @property {function} [onChange=() => {}] - Função chamada quando o valor do input é alterado.
 * @property {...*} [rest] - Outras propriedades adicionais.
 */

/**
 * @type {React.FC<InputProps>}
 */

const Input = forwardRef(
  (
    {
      $variant = '',
      value = '',
      containerId,
      containerStyle = {},
      height,
      isPassword = false,
      isRequired = false,
      disabled = false,
      clear = false,
      maxWidth,
      background = '',
      label = {
        text: '',
        $variant: '',
        background: '',
        marginTop: '',
        translateY: '',
      },
      error = {
        isActive: false,
        message: '',
        class: '',
      },
      onBlur = () => {},
      onFocus = () => {},
      onChange = () => {},
      ...rest
    },
    ref,
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const onInputFocus = event => {
      if (label) setIsFocused(true);

      onFocus(event);
    };

    const onInputBlur = event => {
      if (label) setIsFocused(false);

      onBlur(event);
    };

    const togglePasswordVisibility = () => {
      setIsPasswordVisible(!isPasswordVisible);
    };

    useEffect(() => {
      if (clear) {
        onChange({ target: { value: '' } });
        rest?.onAccept('');
      }
    }, [clear, onChange, rest]);

    return (
      <Container id={containerId} $maxWidth={maxWidth} style={containerStyle}>
        <Label
          $error={error.isActive}
          $isDisabled={disabled}
          marginTop={label.marginTop}
          background={label.background}
          translateY={label.translateY}
          $variant={isFocused || !isEmpty(value) ? 'focused' : label?.$variant}>
          {label?.text}
        </Label>

        <InputBase
          {...rest}
          ref={ref}
          type={isPassword && !isPasswordVisible ? 'password' : 'text'}
          disabled={disabled}
          required={isRequired}
          background={background}
          height={height}
          value={value}
          $mb={error?.isActive}
          $variant={error.isActive ? 'error' : $variant}
          onChange={onChange}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
        />

        {isPassword && (
          <PasswordButton
            type="button"
            onClick={() => togglePasswordVisibility()}>
            {isPasswordVisible ? (
              <EyeCloseIcon
                height={25}
                width={25}
                fill={rest?.eyeColor || 'var(--gray-dark)'}
              />
            ) : (
              <EyeOpenIcon
                height={25}
                width={25}
                fill={rest?.eyeColor || 'var(--gray-dark)'}
              />
            )}
          </PasswordButton>
        )}

        <Text $isActive={error?.isActive} className={error?.class}>
          {error?.message}
        </Text>
      </Container>
    );
  },
);

export default Input;
