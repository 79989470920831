import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';

import { fadeIn } from 'helpers/animations';

import { variant } from 'utils/functions';

import { ReactComponent as ArrowLeftIcon } from 'assets/images/svg/arrow_left_circle.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/svg/arrow_right_circle.svg';

const Container = styled.div`
  ${tw`h-fit w-full p-2 bg-white rounded-xl shadow-lg relative`}
`;

const CarouselContainer = styled.div`
  ${tw`flex flex-row w-full h-56 md:h-64 items-center justify-start gap-4 overflow-x-auto overflow-y-hidden p-2`}
  cursor: grab;
`;

const Card = styled.div`
  ${tw`flex flex-col h-full min-w-[300px] md:min-w-[350px] bg-white items-center justify-center p-2 box-border border-2 border-gray-400 rounded-xl gap-2 shadow-md`}
`;

const ArrowButton = styled.button`
  ${tw`flex absolute m-0 h-fit w-fit [z-index: 1] bg-white rounded-circle shadow-md items-center justify-center`}
  animation: ${fadeIn} 400ms ease-in;
  ${props => props.right && tw`right-2 md:right-8`}
  ${props => props.left && tw`left-2 md:left-8`}
`;

const Text = styled.span`
  ${tw`text-sm text-center text-[var(--gray-dark)] font-semibold max-w-full`}

  ${({ $variant }) =>
    variant({
      title: tw`text-2xl md:text-3xl font-bold text-[var(--dark-blue-theme)]`,
      detailsValue: tw`text-2xl md:text-3xl font-bold truncate text-transparent bg-clip-text bg-gradient-to-b from-[#084D8D] to-[#0A1B24] drop-shadow-lg`,
      value: tw`text-3xl md:text-4xl font-bold truncate text-transparent bg-clip-text bg-gradient-to-b from-[#084D8D] to-[#0A1B24] drop-shadow-lg`,
    })({ $variant })}
`;

const CarouselCards = ({ cardData }) => {
  const carouselRef = useRef();
  const [isAtLeftEdge, setIsAtLeftEdge] = useState(true);
  const [isAtRightEdge, setIsAtRightEdge] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  const checkScrollPosition = () => {
    const container = carouselRef.current;
    setIsAtLeftEdge(container.scrollLeft === 0);
    setIsAtRightEdge(
      container.scrollLeft + container.offsetWidth >= container.scrollWidth,
    );
    setHasOverflow(container.offsetWidth < container.scrollWidth);
  };

  const handleScroll = direction => {
    const width = carouselRef.current.offsetWidth;
    const percentage = 0.3;
    const scrollAmount = width * percentage;

    carouselRef.current.scrollBy({
      top: 0,
      left: direction === 'right' ? scrollAmount : -scrollAmount,
      behavior: 'smooth',
    });
  };

  const handleMouseDown = e => {
    const container = carouselRef.current;

    const startX = e.pageX - container.offsetLeft;
    const scrollLeft = container.scrollLeft;

    const onMouseMove = e => {
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 1;
      container.scrollLeft = scrollLeft - walk;
    };

    container.addEventListener('mousemove', onMouseMove);
    container.addEventListener(
      'mouseup',
      () => {
        container.removeEventListener('mousemove', onMouseMove);
      },
      { once: true },
    );
  };

  useEffect(() => {
    const container = carouselRef.current;
    container.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition();
    return () => {
      container.removeEventListener('scroll', checkScrollPosition);
    };
  }, [hasOverflow]);

  return (
    <Container>
      <CarouselContainer ref={carouselRef} onMouseDown={handleMouseDown}>
        {cardData.length > 0 &&
          cardData?.map(item => (
            <Card key={item.title}>
              <Text $variant="title">{item.title}</Text>
              <Text>{item.description}</Text>
              {!!item.detailsValues && (
                <Text
                  $variant="detailsValue"
                  data-tooltip-id={item.title}
                  data-tooltip-content={item.detailsValues}>
                  {item.detailsValues}
                </Text>
              )}
              <Text $variant="value">{item.value}</Text>
              {!!item.detailsValues && (
                <Tooltip id={item.title} place="bottom" />
              )}
            </Card>
          ))}
        {hasOverflow && !isAtLeftEdge && (
          <ArrowButton left onClick={() => handleScroll('left')}>
            <ArrowLeftIcon
              height={40}
              width={40}
              fill="var(--dark-blue-theme)"
            />
          </ArrowButton>
        )}
        {hasOverflow && !isAtRightEdge && (
          <ArrowButton right onClick={() => handleScroll('right')}>
            <ArrowRightIcon
              height={40}
              width={40}
              fill="var(--dark-blue-theme)"
            />
          </ArrowButton>
        )}
      </CarouselContainer>
    </Container>
  );
};

export default CarouselCards;
