import { useState, useCallback, useMemo, useLayoutEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import FilterTool from 'components/ui-kit/FilterTool';
import Table from 'components/ui-kit/Table';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import IconButton from 'components/ui-kit/IconButton';
import StatusDisplay from 'components/ui-kit/StatusDisplay';

import api from 'services/api';
import {
  BEARER,
  SUBROUTES,
  MODULES,
  UPDATE,
  CREATE,
  DELETE,
  DEV_ID,
} from 'utils/constants';
import { getExportData, hasPermission } from 'utils/functions';
import Formatter from 'utils/formatter';

import {
  NO_DATA_FOUND,
  GETTING_PDF,
  PDF_ERROR,
  PDF_READY,
  GETTING_XLS,
  XLS_ERROR,
  XLS_READY,
  ERROR_PERMISSION,
  SUCCESS_PERMISSION,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import exportXLS from 'services/xls';
import exportPDF from 'services/pdf';

import { ReactComponent as AddIcon } from 'assets/images/svg/add.svg';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const ActionsContainer = styled.div`
  ${tw`flex flex-row p-2 h-fit w-fit gap-2`}
`;

const defaultColumns = [
  { label: 'Nome', field: 'name' },
  {
    label: 'Cadastrado em',
    field: 'registeredIn',
    display: 'registeredInDisplay',
  },
  {
    label: 'Status',
    field: 'isActive',
    display: 'statusDisplay',
  },
  {
    label: 'Ações',
    field: 'actions',
    disableSort: true,
  },
];

const List = () => {
  const [permissionsList, setPermissionsList] = useState([]);

  const [nameInput, setNameInput] = useState();
  const [statusPermissions, setStatusPermissions] = useState();

  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const { defaultDateFormat, exportDateFormat } = new Formatter();

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const { state } = useLocation();
  const navigate = useNavigate();

  const tableRef = useRef(null);

  const canCreate = hasPermission(user?.role, main, sub.permissions, CREATE);
  const canEdit = hasPermission(user?.role, main, sub.permissions, UPDATE);
  const canDelete = hasPermission(user?.role, main, sub.permissions, DELETE);

  const inputs = [
    {
      onChange: e => setNameInput(e.target.value),
      value: nameInput,
      placeholder: 'Buscar por nome',
      size: 'sm',
      height: '33px',
      maxWidth: '300px',
      $variant: 'registry',
    },
    {
      type: 'select',
      placeholder: 'Status',
      options: [
        {
          label: 'Ativos',
          value: true,
        },
        {
          label: 'Inativos',
          value: false,
        },
      ],
      onOptionChange: option => setStatusPermissions(option.value),
      size: 'sm',
      maxWidth: '200px',
    },
  ];

  const getPermissions = useCallback(async () => {
    setIsLoading(true);

    setPermissionsList([]);

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get(
        'permissions/modules/roles/',
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
          params: {
            name: formatParam(nameInput),
            active: statusPermissions,
          },
        },
      );

      if (status !== 200) throw new Error();

      const permissionsLog =
        response?.data?.map(item => ({
          id: item?.role_id,
          name: item?.display_name,
          registeredIn: item?.created_on,
          isActive: item?.active || false,
        })) || [];

      setPermissionsList(permissionsLog);
    } catch (error) {
      toast.error(NO_DATA_FOUND);
    } finally {
      resetProgress();
      setIsLoading(false);
    }
  }, [nameInput, statusPermissions, state]);

  const updateStatus = async item => {
    setIsLoading(true);
    const config = {
      autoclose: 600,
    };

    try {
      const { status } = await api.put(
        `permissions/roles/${item.id}`,
        {
          data: { active: !item.isActive },
        },
        {
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      toast.success(
        SUCCESS_PERMISSION(!item.isActive ? 'ativada' : 'desativada'),
        config,
      );

      const updatedList = [...permissionsList];
      const index = permissionsList.findIndex(data => data.id === item.id);

      updatedList[index].isActive = !updatedList[index].isActive;

      setPermissionsList(updatedList);
    } catch {
      toast.error(ERROR_PERMISSION('alterar'), config);
    } finally {
      setIsLoading(false);
    }
  };

  const tableData = useMemo(() => {
    return permissionsList.map(item => ({
      ...item,
      name: item?.name || '-',
      registeredInDisplay: defaultDateFormat(item?.registeredIn) || '-',
      isActive: item.isActive === true ? 'Ativo' : 'Inativo',
      statusDisplay: <StatusDisplay config="isActive" value={item?.isActive} />,
      actions: (
        <ActionsContainer>
          {((item?.id !== DEV_ID && user?.role?.id !== DEV_ID) ||
            user?.role?.id === DEV_ID) && (
            <IconButton
              type={canEdit ? 'edit' : 'view'}
              onClick={() =>
                navigate(`${subroutes.permissions.path}/${item?.id}`, {
                  state: {
                    currentPage: tableRef.current?.getCurrentPage(),
                  },
                })
              }
            />
          )}

          {item.isActive &&
          canDelete &&
          item?.id !== DEV_ID &&
          item?.id !== user?.role?.id ? (
            <IconButton type="deactivate" onClick={() => updateStatus(item)} />
          ) : (
            !item.isActive &&
            canEdit && (
              <IconButton type="activate" onClick={() => updateStatus(item)} />
            )
          )}
        </ActionsContainer>
      ),
    }));
  }, [permissionsList, tableRef, user]);

  const handleExportData = () => {
    const cols = columns.filter(col => col.field !== 'actions');
    try {
      const { items, visibleCols } = getExportData(
        tableRef.current?.getData(),
        cols,
        {
          formatting: {
            registeredIn: value => defaultDateFormat(value) || '-',
          },
          validateColumns: true,
        },
      );

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      'Processado em: ' +
      defaultDateFormat(new Date()) +
      '\n' +
      'Total de registros: ' +
      data.length;

    const exportData = {
      headers,
      title: 'Cadastros de Permissões',
      description,
      data,
    };

    try {
      const { status } = await exportPDF(
        exportData,
        `${exportData.title} - ${exportDateFormat(new Date())}`,
      );

      if (status !== 200) throw new Error();

      toast.update(id, {
        render: PDF_READY,
        type: 'success',
        isLoading: false,
        autoClose: 1500,
      });
    } catch {
      toast.update(id, {
        render: PDF_ERROR,
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  const onExportXLS = async () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      headers,
      title: 'Cadastros de Permissões',
      data,
    };

    try {
      const { status } = await exportXLS(
        exportData,
        `${exportData.title} - ${exportDateFormat(new Date())}`,
      );

      if (status !== 200) throw new Error();

      toast.update(id, {
        render: XLS_READY,
        type: 'success',
        isLoading: false,
        autoClose: 1500,
      });
    } catch {
      toast.update(id, {
        render: XLS_ERROR,
        type: 'error',
        isLoading: false,
        autoClose: 1500,
      });
    }
  };

  useLayoutEffect(() => {
    const fetchPermissions = async () => {
      await getPermissions();
    };

    fetchPermissions();
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Cadastros', 'Permissões']}
        onExportPDF={permissionsList.length > 0 && onExportPDF}
        onExportXLS={permissionsList.length > 0 && onExportXLS}
        addRedirects={[
          ...(canCreate
            ? [
                {
                  title: 'Nova Permissão',
                  disabled: isLoading,
                  path: subroutes.permissions.subroutes.new.path,
                  icon: <AddIcon />,
                  state: {
                    field: 'currentPage',
                    getValue: () => tableRef.current?.getCurrentPage(),
                  },
                },
              ]
            : []),
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        inputs={inputs}
        useColumnFilter={true}
        defaultColumns={columns}
        disableSearch={false}
        useDatePicker={false}
        disableInputs={true}
        onSubmit={getPermissions}
        onFilterColumns={setColumns}
      />
      {permissionsList.length > 0 && (
        <Table
          ref={tableRef}
          columns={columns}
          data={tableData}
          startPage={state?.currentPage}
        />
      )}
    </PageContainer>
  );
};

export default List;
