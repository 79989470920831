import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
`;

export const clipPath = keyframes`
    100% {
        clip-path: inset(0 0 95% 0);
    }
    75% {
        clip-path: inset(0 95% 0 0);
    }
    50% {
        clip-path: inset(95% 0 0 0);
    }
    25% {
        clip-path: inset(0 0 0 95%);
    }
    0% {
        clip-path: inset(0 0 95% 0);
    }
`;

export const shake = keyframes`
    0% { 
        transform: rotate(0deg); 
    }
    25% { 
        transform: rotate(5deg); 
    }
    50% { 
        transform: rotate(0eg); 
    }
    75% { 
        transform: rotate(-5deg); 
    }
    100% { 
        transform: rotate(0deg); 
    }
`;

export const heartBeat = keyframes`
    0% {
    transform: scale(1);
    }
    14% {
    transform: scale(1.3);
    }
    28% {
    transform: scale(1);
    }
    42% {
    transform: scale(1.3);
    }
    70% {
    transform: scale(1);
    }
`;
