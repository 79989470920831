import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import styled from 'styled-components';
import tw from 'twin.macro';
import { animateScroll as scroll } from 'react-scroll';
import { areEqual } from 'react-window';
import { fadeIn } from 'helpers/animations';

import { ReactComponent as ArrowIcon } from 'assets/images/svg/arrow_down.svg';

import { variant } from 'utils/functions';

const Wrapper = styled.div`
  ${tw`relative max-w-full overflow-hidden flex flex-col 
        bg-white w-full rounded-2xl z-0 shadow-[0px 0px 9px -2px rgba(0,0,0,0.25)]`}
  animation: ${fadeIn} 300ms ease-in;
`;

const TableBase = styled.table`
  ${tw`w-full !overflow-y-hidden !overflow-x-auto`}
`;

const Head = styled.thead`
  ${tw`w-full h-fit mb-2 bg-[var(--navy-blue-theme)]`}
`;

const Row = styled.tr`
  ${tw`flex border-b-2 border-b-themes-gray border-collapse`}

  ${props => (props.$head ? tw`h-full` : tw`h-fit last:border-none`)}

  ${props => props.$addHover && tw`hover:bg-themes-gray cursor-pointer`}
`;

const HeadCell = styled.th`
  ${tw`[min-width: 125px] h-12 p-3 gap-1 flex text-[#D6D6D6] justify-center select-none box-border font-bold text-center items-center shadow-xl`}

  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  overflow-wrap: break-word;
`;

const Body = styled.tbody`
  ${tw`w-11/12 !overflow-x-hidden mb-2`}
`;

const Cell = styled.td`
  ${tw`[min-width: 125px] flex items-center justify-center flex-wrap box-border p-3 [font-size: 13px]
      overflow-hidden text-center last:border-none break-normal [white-space: wrap]`}

  width: ${props => props.width};
  max-width: ${props => props.maxWidth};

  cursor: ${props => (props.$pointer ? 'pointer' : '')};
  color: ${props => props.color};
`;

const NormalText = styled.p`
  ${tw`[min-width: 125px] flex items-center justify-center flex-wrap box-border p-3 [font-size: 13px]
      overflow-hidden text-center last:border-none break-normal [white-space: wrap]`}

  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
`;

const Footer = styled.div`
  ${tw`w-full h-fit box-border px-4 py-2 bg-transparent`}
`;

const ButtonsContainer = styled.div`
  ${tw`w-fit h-fit my-auto ml-auto flex items-center`}
`;

const Button = styled.button`
  ${tw`bg-[var(--navy-blue-theme)] select-none font-semibold drop-shadow`}

  ${tw`[width: 35px] [height: 35px] mr-1 flex items-center justify-center 
        text-white text-xl rounded-circle disabled:bg-themes-gray disabled:text-black disabled:cursor-not-allowed`}
`;

const PagesContainer = styled.div`
  ${tw`flex flex-col w-fit h-fit ml-5`}
`;

const Text = styled('p')(
  {
    fontSize: '14px',
    margin: 0,
    padding: 0,
    lineHeight: '100%',
    height: 'fit-content',
    width: 'fit-content',
  },
  ({ $variant }) =>
    variant({
      theme: {
        // eslint-disable-next-line no-undef
        color: '#002DA0',
        fontWeight: 700,
        fontSize: '15px',
      },
    })({ $variant }),
);

const Input = styled.input`
  ${tw`[width: 40px] h-fit focus:outline-0 
      overflow-hidden box-border pl-2 bg-transparent text-black border-b border-b-black`}
`;

const SummaryContainer = styled.footer`
  ${tw`w-full flex flex-col h-fit border-t-2 border-t-themes-gray items-center`}

  & > .columns-container {
    ${tw`w-full flex h-fit mt-2`}
  }

  & > p {
    ${tw`mt-2 font-bold`}
  }
`;

/**
 * @typedef {Object} ColumnConfig
 * @property {string} label - O texto do cabeçalho da coluna.
 * @property {boolean} hide - Se a coluna deve ser ocultada.
 * @property {string} field - O campo do dado que esta coluna deve exibir.
 * @property {string} display - O campo que deve ser exibido no lugar do field padrão. Útil se deseja exibir um componente e ordenar por outro.
 * @property {boolean} disableSort - Se a coluna não pode ser ordenada.
 * @property {function} onClickCell - Uma função a ser chamada quando uma célula nesta coluna é clicada.
 * @property {boolean} usePointer - Se o cursor do mouse deve mudar para um ponteiro ao passar sobre as células desta coluna.
 */

/**
 * @typedef {Object} TableProps
 * @property {Object[]} data - Os dados a serem exibidos na tabela.
 * @property {ColumnConfig[]} columns - As colunas da tabela.
 * @property {number} perPage - O número de linhas a serem exibidas por página.
 * @property {string[]} summary - Um resumo a ser exibido no rodapé da tabela.
 * @property {boolean} useActionRow - Se a tabela deve usar uma linha de ação.
 * @property {boolean} disableFooter - Se o rodapé da tabela deve ser desabilitado.
 * @property {function} onRowClick - Uma função a ser chamada quando uma linha é clicada.
 * @property {function} onCellClick - Uma função a ser chamada quando uma célula é clicada.
 * @property {function} onMouseEnterRow - Uma função a ser chamada quando o mouse entra em uma linha.
 * @property {function} getSortedData - Uma função que obtém os dados ordenados.
 */

/**
 * @type {React.FC<TableProps>}
 */

const Table = forwardRef(
  (
    {
      data = [],
      columns = [],
      perPage = 15,
      startPage = 1,
      summary = [],
      useActionRow = false,
      disableFooter = false,
      onRowClick = () => {},
      onCellClick = () => {},
      onMouseEnterRow = () => {},
    },
    ref,
  ) => {
    const [initialPage, setInitialPage] = useState(
      data.length > perPage ? startPage - 1 : 0,
    );
    const [value, setValue] = useState(1); // value padrao input de paginas
    const [tableRef, setTableRef] = useState();
    const [isDragging, setIsDragging] = useState(false);
    const [lastMouseX, setLastMouseX] = useState(null);
    const [config, setConfig] = useState({
      enabled: false,
      size: 0,
      maxSize: 0,
    });

    const cols = useMemo(
      () =>
        columns.map(column => ({
          Header: column.label,
          isHidden: column.hide,
          accessor: column.field,
          disableSortBy: column.disableSort,
          Cell: ({ row }) => {
            const value = column.display
              ? row.original[column.display]
              : row.original[column.field];
            return value;
          },
          cellClick: column.onClickCell,
          usePointer: column.usePointer,
        })),
      [columns],
    ); // constroi as colunas para a table Header e acessor sao indispensaveis

    const renderSummary = useMemo(() => {
      return summary.map(summary => (
        <NormalText key={`table-summary-${summary}`} width={config.maxSize}>
          <b>{summary}</b>
        </NormalText>
      ));
    }, [summary, config]); // responsavel por renderizar o sumario da tabela

    const {
      prepareRow,
      getTableProps,
      getTableBodyProps,
      rows,
      page,
      gotoPage,
      nextPage,
      pageCount,
      setPageSize,
      canNextPage,
      pageOptions,
      headerGroups,
      previousPage,
      canPreviousPage,
      state: { pageIndex },
    } = useTable(
      {
        columns: cols,
        data,
        initialState: {
          pageIndex: initialPage,
          pageSize: perPage,
        }, // Configuração inicial
      },
      useSortBy,
      usePagination, // Aplicar as configurações de paginação
    ); // hooks do react-table

    const handleScroll = useCallback(() => {
      const element = tableRef;
      const { scrollWidth, clientWidth } = element;
      const useScroll = scrollWidth > clientWidth;

      const visibles = columns.filter(column => !column.hide);

      setConfig({
        enabled: useScroll,
        size: clientWidth,
        maxSize: useScroll
          ? `${clientWidth / visibles.length}px`
          : `calc(100% / ${visibles.length})`,
      });
    }, [tableRef, columns]); // funcoes responsaveis por definir usabilidade do scroll

    const onMouseDown = () => {
      setIsDragging(true);
    };

    const onMouseUp = () => {
      setIsDragging(false);
      setLastMouseX(null);
    };

    const onMouseHolding = event => {
      if (!isDragging || !config?.enabled) return;
      event.preventDefault();

      const mouseX = event.clientX;

      if (lastMouseX !== null) {
        // Calcular a diferença entre a posição atual do mouse e a posição anterior
        const deltaX = mouseX - lastMouseX;

        // Rolar na direção da diferença
        scroll.scrollMore(deltaX, {
          containerId: 'table-scrollable-wrapper',
          smooth: true,
          duration: 0,
          horizontal: true,
        });
      }

      // Atualizar a última posição do mouse
      setLastMouseX(mouseX);
    }; // sistema de drag to overflow, util quando a tabela possui varias colunas

    const getCurrentPage = useCallback(() => pageIndex + 1, [pageIndex]);
    const getData = useCallback(() => rows.map(row => row.original), [rows]);

    useEffect(() => {
      setPageSize(perPage); // seta a quantidade de itens por página
    }, [perPage]);

    useEffect(() => {
      if (!tableRef) return;

      handleScroll();
    }, [tableRef]);

    useEffect(() => {
      setInitialPage(pageIndex);
    }, [pageIndex]);

    useImperativeHandle(ref, () => ({
      getCurrentPage,
      getData,
    }));

    return (
      <Wrapper
        id="table-scrollable-wrapper"
        style={{
          overflowX: 'auto',
          cursor: lastMouseX !== null ? 'grab' : '',
        }}
        ref={setTableRef}
        onMouseMove={onMouseHolding}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseUp}
        onMouseUp={onMouseUp}>
        <TableBase {...getTableProps()} ref={ref}>
          <Head>
            {headerGroups.map(headerGroup => {
              const headerGroupProps = headerGroup.getHeaderGroupProps();
              const { key, ...restHeaderGroupProps } = headerGroupProps;

              return (
                <Row key={key} {...restHeaderGroupProps} $head>
                  {headerGroup.headers.map(column => {
                    if (column.isHidden) column.toggleHidden(column.isHidden);

                    const columnProps = column.getHeaderProps(
                      column.disableSortBy ? {} : column.getSortByToggleProps(),
                    );
                    const { key, ...restColumnProps } = columnProps;

                    return (
                      <HeadCell
                        key={key}
                        {...restColumnProps}
                        hidden={column.isHidden}
                        width={config.maxSize}
                        title={
                          column.disableSortBy ? '' : 'Clique para ordenar'
                        }>
                        {column.isSorted && (
                          <ArrowIcon
                            fill="#FFF"
                            width="13px"
                            height="13px"
                            style={{
                              flexShrink: 0,
                              transform: column.isSortedDesc
                                ? 'rotate(180deg)'
                                : '',
                              transition: 'transform 0.2s',
                            }}
                          />
                        )}

                        <p>{column.render('Header')}</p>
                      </HeadCell>
                    );
                  })}
                </Row>
              );
            })}
          </Head>

          <Body {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              const { key, ...restRowProps } = rowProps;
              return (
                <Row
                  key={key}
                  {...restRowProps}
                  onClick={() => {
                    if (lastMouseX !== null) return;

                    onRowClick(row.original);
                  }}
                  onMouseEnter={() => onMouseEnterRow(row.original)}
                  $addHover={lastMouseX === null && useActionRow}>
                  {row.cells.map(cell => {
                    const cellProps = cell.getCellProps();
                    const { key, ...restCellProps } = cellProps;
                    return (
                      <Cell
                        key={key}
                        {...restCellProps}
                        width={config.maxSize}
                        $pointer={cell.column.usePointer}
                        onClick={onCellClick}>
                        {cell.render('Cell')}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </Body>
        </TableBase>
        <>
          {summary.length > 0 && (
            <SummaryContainer>
              <Text>Resumo</Text>

              <div className="columns-container">{renderSummary}</div>
            </SummaryContainer>
          )}
        </>
        <>
          {!disableFooter && pageCount > 1 && (
            <Footer>
              <div
                style={{
                  width: '100%',
                  height: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Text $variant="theme">{data.length} resultados</Text>

                <ButtonsContainer>
                  <Button
                    onClick={() => gotoPage(0)}
                    disabled={pageIndex === 0}>
                    {'<<'}
                  </Button>

                  <Button onClick={previousPage} disabled={!canPreviousPage}>
                    {'<'}
                  </Button>

                  <Button onClick={nextPage} disabled={!canNextPage}>
                    {'>'}
                  </Button>

                  <Button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={pageIndex === pageCount - 1}>
                    {'>>'}
                  </Button>
                </ButtonsContainer>

                <PagesContainer>
                  <Text>
                    Página{' '}
                    <b>
                      {pageIndex + 1} de {pageOptions.length}
                    </b>{' '}
                  </Text>

                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Text>Ir para:</Text>

                    <Input
                      value={value}
                      onChange={event => {
                        const { value: inputValue } = event.target;

                        if (!inputValue.match(/^[\d\s]*$/)) return;

                        const page = inputValue ? parseInt(inputValue) - 1 : 0;

                        gotoPage(page);
                        setValue(inputValue);
                      }}
                    />
                  </span>
                </PagesContainer>
              </div>
            </Footer>
          )}
        </>
      </Wrapper>
    );
  },
);

export default memo(Table, areEqual);
