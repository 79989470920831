import { Navigate } from 'react-router-dom';

import List from 'pages/Main/pages/Occurrences/List';
import Form from 'pages/Main/pages/Occurrences/Form';

import PrivateRoute from 'routes/PrivateRoute';

import { SUBROUTES, MODULES, VISUALIZE, CREATE } from 'utils/constants';

const { path, subroutes } = SUBROUTES.occurrences;
const { main } = MODULES.occurrences;

const OccurrencesRoutes = [
  {
    path: SUBROUTES.occurrences.path,
    element: (
      <PrivateRoute module={main} permission={VISUALIZE}>
        <List />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.new.path,
    element: (
      <PrivateRoute module={main} permission={CREATE}>
        <Form />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.id.path,
    element: (
      <PrivateRoute module={main} permission={VISUALIZE}>
        <Form />
      </PrivateRoute>
    ),
  },
  {
    path,
    element: <Navigate to={SUBROUTES.occurrences.path} replace />,
  },
];

export default OccurrencesRoutes;
