import { configureStore } from '@reduxjs/toolkit';

import occurrences from './slices/occurrences';

const store = configureStore({
  reducer: {
    occurrences,
  },
});

export default store;
