import { useState, useEffect } from 'react';

const getSizes = () => {
  const { innerWidth, innerHeight } = window;

  return {
    width: innerWidth,
    height: innerHeight,
  };
};

const useWindowSizesProps = {
  triggerOnce: false,
};

const useWindowSizes = ({ triggerOnce } = useWindowSizesProps) => {
  const [windowSize, setWindowSize] = useState(getSizes());

  const resize = () => {
    setWindowSize(getSizes());
  };

  useEffect(() => {
    if (triggerOnce) resize();
    else {
      window.addEventListener('resize', resize);

      return () => window.removeEventListener('resize', resize);
    }
  }, [triggerOnce]);

  return windowSize;
};

export default useWindowSizes;
