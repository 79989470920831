import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';

import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const TabContainer = styled.div`
  ${tw`flex flex-col w-full overflow-y-visible`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-wrap min-h-[40px] gap-3 pb-1`}
`;

const Button = styled.button`
  ${tw`flex items-center justify-center h-10 px-2 py-1 gap-2 max-w-full transition-all
      shadow-md shadow-background-dark w-full md:w-fit rounded-lg text-base text-center font-bold`}

  ${({ $isActive, theme }) =>
    $isActive
      ? css`
          background-color: ${theme?.bg};
          border: 2px solid ${theme?.bg};
          color: ${theme?.color};
          :hover {
            opacity: 0.9;
          }
          .icon {
            fill: ${theme?.color};
            width: 20px;
            height: 20px;
          }
          :hover .icon {
            fill: ${theme?.color};
          }
        `
      : css`
          background-color: ${theme?.color};
          border: 2px solid ${theme?.bg};
          color: ${theme?.bg};
          :hover {
            background-color: ${theme?.bg};
            color: ${theme?.color};
          }
          .icon {
            fill: ${theme?.bg};
            width: 20px;
            height: 20px;
          }
          :hover .icon {
            fill: ${theme?.color};
          }
        `}
`;

const TabContent = styled(motion.div)`
  ${tw`flex w-full box-border p-2 relative rounded-xl bg-white overflow-visible
        mt-2 md:mt-1 shadow-[0px 0px 9px -2px rgba(0,0,0,0.25)]`}
`;

const variants = {
  hidden: {
    opacity: 0,
    x: '+100%',
    transition: { type: 'spring', duration: 0.5 },
  },
  visible: {
    opacity: 1,
    x: '0%',
    transition: { type: 'spring', duration: 0.5 },
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: { type: 'spring', duration: 0.5 },
  },
};

/**
 * @typedef {Object} Tab
 * @property {string} name - Nome da aba.
 * @property {string} label - Título para ser exibido na aba.
 * @property {React.Component} Icon - Ícone da aba (componente React).
 */

/**
 * @typedef {Object} TabsTheme
 * @property {string} bg - Cor de fundo para os botões.
 * @property {string} color - Cor do texto nos botões.
 */

/**
 * @typedef {Object} TabsProps
 * @property {React.Component} children - Componente filho.
 * @property {string} startTab - Aba inicial.
 * @property {Tab[]} tabs - Lista de abas.
 * @property {React.HTMLAttributes<HTMLDivElement>} tabProps - Props passadas para o componente tab
 * @property {(tab: string) => void} getActiveTab - Função chamada quando a aba ativa muda.
 * @property {() => void} onSwitchTab - Função chamada ao trocar de aba.
 * @property {TabsTheme} theme - Tema personalizado para os botões.
 */

/**
 * @type {React.FC<TabsProps>}
 */

const Tabs = forwardRef(
  (
    {
      children,
      startTab = '', // aba inicial, necessário que dê match com algum name dentro do tabs
      tabs = [], // todas as abas almejadas [{ name: string, Icon: React Component }]
      tabProps = {},
      getActiveTab = tab => {}, // eslint-disable-line no-unused-vars
      onSwitchTab = () => {}, // ao trocar de aba
      theme = {
        bg: '#0A253C', // theme personalizado para os buttons
        color: '#FFF',
      },
      blockSwitching = false,
      blockMessage = 'Você tem certeza que deseja trocar de aba?',
      ...rest
    },
    ref,
  ) => {
    const [activeTab, setActiveTab] = useState(startTab);

    useEffect(() => {
      getActiveTab(activeTab);
    }, [activeTab]);

    const renderTabs = useMemo(() => {
      if (!Array.isArray(tabs)) return;

      return tabs.map((tab, index) => {
        const { name, label, Icon } = tab;

        return (
          <Button
            type="button"
            key={`${name}-${index}`}
            $isActive={activeTab === name}
            theme={theme}
            onClick={() => {
              if (blockSwitching && !window.confirm(blockMessage)) {
                return;
              }
              setActiveTab(name);
              onSwitchTab(name);
            }}>
            {Icon && <Icon className="icon" />}

            {label ?? name}
          </Button>
        );
      });
    }, [tabs, theme, activeTab]);

    const setTab = target => {
      setActiveTab(target);
    };

    const getCurrentTab = () => {
      return activeTab;
    };

    useImperativeHandle(
      ref,
      () => ({
        setTab, // metodo acessivel pela ref do componente, para alteracao de abas a força
        getCurrentTab,
      }),
      [],
    );

    return (
      <TabContainer {...rest} ref={ref}>
        <ButtonsContainer>{renderTabs}</ButtonsContainer>

        {React?.Children?.map(children, child => {
          const tabName = child?.props?.tabName;

          return (
            tabName === activeTab && (
              <AnimatePresence mode="wait">
                <TabContent
                  {...tabProps}
                  key={tabName}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={variants}>
                  {child}
                </TabContent>
              </AnimatePresence>
            )
          );
        })}
      </TabContainer>
    );
  },
);

export default Tabs;
