import { useRef, useState, useEffect, useCallback, memo, useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PrimaryButton from 'components/ui-kit/PrimaryButton';
import Select from 'components/ui-kit/Select';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import { StartServiceContainer, StartServiceCard } from './BasicInfosForm';

import ServiceProviderForm from './ServiceProviderForm';
import AdditionalForm from './AdditionalForm';

import api from 'services/api';
import { getItem } from 'providers/storage';
import { BEARER } from 'utils/constants';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const Container = styled.div`
  ${tw`flex flex-col w-full min-h-full h-full gap-6 p-2 items-center`}

  .notFound {
    ${tw`flex h-64 items-center
    text-sm text-center italic text-disabled-dark-contrast truncate font-semibold`}
  }
`;

const InsertContainer = styled.div`
  ${tw`flex flex-wrap w-full gap-4 items-center justify-center mt-4`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-wrap gap-4 w-full justify-around h-fit items-center`}
`;

const BudgetForms = ({
  routingIsLoading = false,
  canEdit = false,
  canDelete = false,
  data = [],
  occurrenceInfo = {},
  serviceHasChanged = false,
  syncData = async () => {},
  startService = async () => {},
  resetService = () => {},
  plateOptions = [],
  onDeleteOccurrence = () => {},
}) => {
  const [budgetType, setBudgetType] = useState({});

  const [insertedBudgets, setInsertedBudgets] = useState([]);

  const budgetSelectRef = useRef(null);
  const plateSelectRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const statusIdRef = useRef(occurrenceInfo?.statusId);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const memoizedData = useMemo(() => data, [JSON.stringify(data)]);

  const handleAcceptBudgets = useCallback(async () => {
    setIsLoading(true);
    let isValid = true;

    for (const budget of insertedBudgets) {
      if (budget.benefitedPlates.length === 0) {
        toast.warning(
          `Orçamento de ${budget.typeName} sem placa vinculada.\n Por favor, vincule ou remova este orçamento.`,
          { autoClose: 3000 },
        );
        isValid = false;
        break;
      }
    }

    if (isValid) {
      const allPlateIds = new Set(
        insertedBudgets.flatMap(budget =>
          budget.benefitedPlates.map(plate => plate.id),
        ),
      );

      for (const option of plateOptions) {
        if (!allPlateIds.has(option.value)) {
          toast.warning(
            `Placa ${option.label} não está associada a um orçamento.\n Verifique se deseja removê-la ou vinculá-la a um dos orçamentos disponíveis.`,
            { autoClose: 3000 },
          );
          isValid = false;
          break;
        }
      }
    }

    if (isValid) {
      try {
        const { status } = await api.post(
          `occurrencies/${occurrenceInfo?.id}/budgets/accepted`,
          null,
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );
        if (status !== 200) throw new Error();
        toast.success(
          'Orçamentos aceitos e enviados para o módulo Financeiro com sucesso',
        );
        await syncData();
      } catch {
        toast.error('Falha ao aceitar orçamentos');
      } finally {
        resetProgress();
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [occurrenceInfo, plateOptions, insertedBudgets, user]);

  const createBudget = useCallback(async () => {
    if (insertedBudgets.length >= 10) {
      toast.warning('Você atingiu o limite máximo de orçamentos');
      return;
    }

    const newBudget = {
      typeId: budgetType?.id,
      typeName: budgetType?.name,
    };
    setInsertedBudgets([...insertedBudgets, newBudget]);
    budgetSelectRef?.current?.clearValue();
    plateSelectRef?.current?.clearValue();

    if (!getItem('serviceId')) await startService();
  }, [insertedBudgets, budgetType, budgetSelectRef, plateSelectRef]);

  const deleteBudget = useCallback(
    async index => {
      const updatedContainers = insertedBudgets.filter((_, i) => i !== index);
      setInsertedBudgets(updatedContainers);
    },
    [insertedBudgets],
  );

  useEffect(() => {
    setInsertedBudgets(memoizedData);
  }, [memoizedData]);

  useEffect(() => {
    if (statusIdRef.current === 3 && occurrenceInfo?.statusId === 2) {
      toast.warning(
        'Orçamento alterado. Por favor, aceite os orçamentos novamente para enviá-los ao módulo Financeiro',
        { autoClose: 5000 },
      );
      statusIdRef.current = occurrenceInfo?.id;
    }
  }, [occurrenceInfo?.statusId]);

  return (
    <Container>
      <LoadingScreen isLoading={isLoading} progress={progress} />

      {serviceHasChanged && occurrenceInfo?.isBeingServedBy?.id ? (
        <StartServiceContainer>
          <StartServiceCard>
            <span className="title">Atenção!</span>
            <span className="observation">
              Esta ocorrência está em atendimento por
              <span style={{ fontWeight: 700 }}>
                {`: ${occurrenceInfo.isBeingServedBy.name}.`}
              </span>
              {canEdit && (
                <>
                  <br />
                  Se você assumir, todas as informações preenchidas
                  anteriormente serão descartadas.
                  <br />
                  Você deseja prosseguir e assumir o atendimento?
                </>
              )}
            </span>
            {canEdit && (
              <PrimaryButton
                type="button"
                onClick={async () => {
                  await syncData().then(async () => await startService());
                }}>
                Sim, assumir atendimento
              </PrimaryButton>
            )}
          </StartServiceCard>
        </StartServiceContainer>
      ) : (
        serviceHasChanged && (
          <StartServiceContainer>
            <StartServiceCard>
              <span className="title">Atenção!</span>
              <span className="observation">
                O atendimento desta ocorrência foi iniciado por outro usuário.
                <br />
                Você deseja sincronizar as modificações feitas?
              </span>
              <PrimaryButton
                type="button"
                onClick={async () =>
                  await syncData().then(() => resetService())
                }>
                Sim, sincronizar alterações
              </PrimaryButton>
            </StartServiceCard>
          </StartServiceContainer>
        )
      )}

      {canEdit && !serviceHasChanged && (
        <InsertContainer>
          <Select
            ref={budgetSelectRef}
            size="sm"
            maxWidth="250px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint="occurrencies/budgets/types"
            onOptionChange={option => {
              setBudgetType({
                name: option.label,
                id: option.value,
              });
            }}
            placeholder=""
            label={{
              text: 'Tipos de Orçamento',
            }}
          />
          <PrimaryButton
            disabled={!budgetType?.id}
            onClick={createBudget}
            style={{ maxWidth: 'fit-content' }}>
            Adicionar Orçamento
          </PrimaryButton>
        </InsertContainer>
      )}

      {insertedBudgets.length > 0 ? (
        <>
          {insertedBudgets.map((budget, index) => {
            if (
              budget.typeId === 3 ||
              budget.typeId === 7 ||
              budget.typeId === 8
            ) {
              return (
                <ServiceProviderForm
                  key={index}
                  index={index}
                  budgetType={{ id: budget.typeId, name: budget.typeName }}
                  plateOptions={plateOptions}
                  budgetData={data[index]}
                  occurrenceInfo={occurrenceInfo}
                  isDisabled={!canEdit || serviceHasChanged}
                  deleteBudget={index => deleteBudget(index)}
                  syncData={syncData}
                  serviceId={getItem('serviceId')}
                  serviceHasChanged={serviceHasChanged}
                  startService={startService}
                />
              );
            } else {
              return (
                <AdditionalForm
                  key={index}
                  index={index}
                  budgetType={{ id: budget.typeId, name: budget.typeName }}
                  plateOptions={plateOptions}
                  budgetData={data[index]}
                  occurrenceInfo={occurrenceInfo}
                  isDisabled={!canEdit || serviceHasChanged}
                  deleteBudget={index => deleteBudget(index)}
                  syncData={syncData}
                  serviceId={getItem('serviceId')}
                  startService={startService}
                />
              );
            }
          })}
        </>
      ) : (
        <div className="notFound">Nenhum orçamento encontrado</div>
      )}

      {(canDelete || canEdit) && (
        <ButtonsContainer>
          {canDelete && (
            <PrimaryButton
              maxWidth="300px"
              bg="red"
              onClick={onDeleteOccurrence}
              disabled={routingIsLoading}>
              Excluir Ocorrência
            </PrimaryButton>
          )}
          {canEdit && occurrenceInfo?.statusId === 2 && !!data.length && (
            <PrimaryButton
              maxWidth="300px"
              bg="green"
              onClick={handleAcceptBudgets}
              disabled={routingIsLoading || isLoading}>
              Aceitar Orçamentos
            </PrimaryButton>
          )}
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default memo(BudgetForms);
