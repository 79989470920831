import {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import Input from 'components/ui-kit/Input';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';
import Checkbox from 'components/ui-kit/Checkbox';
import Loader from 'components/ui-kit/Loader';

import api from 'services/api';
import {
  BEARER,
  SUBROUTES,
  DEV_ID,
  MODULES,
  UPDATE,
  CREATE,
} from 'utils/constants';
import { buildPayload, hasPermission, variant } from 'utils/functions';

import {
  BASIC_INFOS,
  FIELD_IS_REQUIRED,
  ALREADY_SAVED_INFORMATION,
  ERROR_PERMISSION,
  SUCCESS_PERMISSION,
  ERROR_ALREADY_EXISTS,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const Container = styled.form`
  ${tw`h-fit w-full rounded-2xl bg-white px-2 py-4 max-w-full overflow-hidden`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full max-w-full md:max-w-4xl h-fit py-2 px-4 bg-[var(--gray-theme)] rounded-lg`}
`;

const SubmoduleInsert = styled.div`
  ${tw`flex flex-col w-full gap-2 items-center`}

  .notFound {
    ${tw`flex flex-col w-full min-h-[100px] justify-center items-center`}
  }
`;

const Row = styled.div`
  ${tw`flex flex-col md:flex-row items-center h-fit w-full max-w-full py-2
  first:border-t-0 border-t-2 border-solid border-[var(--gray-dark)]`}
`;

const Item = styled.div`
  ${tw`flex flex-wrap h-full w-full max-w-full md:max-w-[50%] gap-1`}
`;

const Text = styled.span`
  ${tw`text-sm text-center text-disabled-dark`}

  ${({ $variant }) =>
    variant({
      label: tw`font-semibold`,
      value: tw`truncate`,
      notFound: tw`italic text-[var(--gray-dark)] font-semibold truncate`,
    })({ $variant })}
`;

const Line = styled.div`
  ${tw`border-r-2 border-solid border-[var(--gray-dark)] min-h-full h-full right-1/2 mx-6`}
`;

const keyMapping = {
  name: 'name',
  modules: 'modules',
};

const checkPermissions = modules => {
  for (let module of modules) {
    if (
      module.permissions &&
      Object.values(module.permissions).includes(true)
    ) {
      return true;
    }
    if (module.submodules && module.submodules.length > 0) {
      if (checkPermissions(module.submodules)) {
        return true;
      }
    }
  }
  return false;
};

const getPayload = (values, data) => {
  const transformModulesData = data =>
    data['modules']?.map(module => ({
      id: module?.id,
      name: module?.name,
      perms: {
        visualize: module?.permissions?.visualize || false,
        create: module?.permissions?.create || false,
        update: module?.permissions?.update || false,
        delete: module?.permissions?.delete || false,
      },
      ...(module?.submodules?.length && {
        sub: module?.submodules?.map(sub => ({
          id: sub?.id,
          name: sub?.name,
          perms: {
            visualize: sub?.permissions?.visualize || false,
            create: sub?.permissions?.create || false,
            update: sub?.permissions?.update || false,
            delete: sub?.permissions?.delete || false,
          },
        })),
      }),
    })) || [];

  let newData = { ...data };
  let newValues = { ...values };

  newValues['name'] = formatName(values?.name);
  newData['modules'] = transformModulesData(newData);
  newValues['modules'] = transformModulesData(newValues);

  return buildPayload(keyMapping, newValues, newData);
};

const formatName = value => {
  if (!value) return undefined;

  return value.trim();
};

const validationSchema = yup.object({
  name: yup.string().required(FIELD_IS_REQUIRED('Nome')),
  modules: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
        permissions: yup.object().shape({
          visualize: yup.boolean(),
          create: yup.boolean(),
          update: yup.boolean(),
          delete: yup.boolean(),
        }),
        submodules: yup.array().of(
          yup.object().shape({
            id: yup.number().required(),
            name: yup.string().required(),
            permissions: yup.object().shape({
              visualize: yup.boolean(),
              create: yup.boolean(),
              update: yup.boolean(),
              delete: yup.boolean(),
            }),
          }),
        ),
      }),
    )
    .test(
      'at-least-one-permission',
      'Pelo menos uma permissão deve ser habilitada',
      value => checkPermissions(value),
    ),
});

const Form = () => {
  const [data, setData] = useState({});

  const [modules, setModules] = useState([]);
  const [isLoadingModules, setIsLoadingModules] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const canEdit = hasPermission(user?.role, main, sub.permissions, UPDATE);
  const canCreate = hasPermission(user?.role, main, sub.permissions, CREATE);

  const modulesUnique = useMemo(() => {
    const uniqueModulesMap = new Map();

    modules.forEach(module => {
      if (!uniqueModulesMap.has(module.id)) {
        uniqueModulesMap.set(module.id, {
          ...module,
          submodules: [],
        });
      }
      const existingModule = uniqueModulesMap.get(module.id);

      module.submodules.forEach(submodule => {
        if (!existingModule.submodules.find(sm => sm.id === submodule.id)) {
          existingModule.submodules.push(submodule);
        }
      });
      uniqueModulesMap.set(module.id, {
        ...existingModule,
        submodules: [...existingModule.submodules],
      });
    });
    return Array.from(uniqueModulesMap.values());
  }, [modules]);

  const getPermission = useCallback(async permissionId => {
    setIsLoading(true);
    setData({});

    try {
      const { data: response, status } = await api.get(
        `permissions/modules/roles/${permissionId}`,
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (status !== 200) throw new Error();

      const { role_id: id, display_name: name, modules } = response?.data || {};

      const modulesLog =
        modules?.map(item => ({
          id: item?.id,
          identifier: item?.name_key,
          name: item?.name,
          permissions: {
            visualize: item?.perms?.visualize || false,
            create: item?.perms?.create || false,
            update: item?.perms?.update || false,
            delete: item?.perms?.delete || false,
          },
          submodules:
            item?.sub?.map(sub => ({
              id: sub?.id,
              identifier: sub?.name_key,
              name: sub?.name,
              permissions: {
                visualize: sub?.perms?.visualize || false,
                create: sub?.perms?.create || false,
                update: sub?.perms?.update || false,
                delete: sub?.perms?.delete || false,
              },
            })) || [],
        })) || [];

      setModules(modulesLog);

      setValues({
        name,
        modules: modulesLog,
      });
      setData({
        id,
        name,
        modules: modulesLog,
      });

      resetProgress();
      setIsLoading(false);
    } catch {
      toast.error(ERROR_PERMISSION('buscar'));

      setTimeout(() => {
        setIsLoading(false);
        resetProgress();
        navigate(subroutes.permissions.path, {
          state: { currentPage: state?.currentPage },
        });
      }, 3500);
    }
  }, []);

  const onSubmit = useCallback(
    async values => {
      setIsLoading(true);

      const payload = getPayload(values, data);

      if (Object.keys(payload).length === 0) {
        toast.success(ALREADY_SAVED_INFORMATION);
        setIsLoading(false);
        return;
      }

      try {
        const { status } =
          Object.keys(data).length > 0
            ? await api.put(
                `permissions/modules/roles/${data.id}`,
                { data: payload },
                {
                  headers: {
                    Authorization: BEARER + user.token,
                  },
                },
              )
            : await api.post(
                'permissions/modules/roles/',
                { data: payload },
                {
                  headers: {
                    Authorization: BEARER + user.token,
                  },
                },
              );

        if (status !== 200 && status !== 201) throw new Error();

        let successMessage =
          Object.keys(data).length > 0
            ? SUCCESS_PERMISSION('editada')
            : SUCCESS_PERMISSION('criada');

        toast.success(successMessage);

        setTimeout(() => {
          navigate(subroutes.permissions.path, {
            state: { currentPage: state?.currentPage },
          });
          resetProgress();
          setIsLoading(false);
        }, [2000]);
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 409:
            errorMessage = ERROR_ALREADY_EXISTS;
            break;
          default:
            errorMessage =
              Object.keys(data).length > 0
                ? ERROR_PERMISSION('editar')
                : ERROR_PERMISSION('criar');
        }
        toast.error(errorMessage);
        resetProgress();
        setIsLoading(false);
      }
    },
    [data, state],
  );

  const getModules = useCallback(async () => {
    setIsLoadingModules(true);

    try {
      const { data: response, status } = await api.get('permissions/modules', {
        headers: {
          Authorization: BEARER + user.token,
        },
        params:
          DEV_ID !== user?.role?.id ? { active: true, sub_active: true } : {},
      });

      if (status !== 200) throw new Error();

      const modulesLog =
        response?.data?.map(item => ({
          id: item?.id,
          identifier: sub?.name_key,
          name: item?.name,
          permissions: {
            visualize: false,
            create: false,
            update: false,
            delete: false,
          },
          submodules:
            item?.sub?.map(sub => ({
              id: sub?.id,
              identifier: sub?.name_key,
              name: sub?.name,
              permissions: {
                visualize: false,
                create: false,
                update: false,
                delete: false,
              },
            })) || [],
        })) || [];

      setModules(prev => [...prev, ...modulesLog]);
    } catch {
      return;
    } finally {
      setIsLoadingModules(false);
    }
  }, [user, id]);

  const { handleSubmit, setValues, handleChange, submitCount, values, errors } =
    useFormik({
      initialValues: {
        name: '',
        modules: [],
      },
      onSubmit,
      validationSchema,
    });

  const handleCheckboxChange = (
    moduleIndex,
    submoduleIndex,
    permissionType,
  ) => {
    const updatedModules = modulesUnique.map((module, idx) => {
      if (idx !== moduleIndex) {
        return module;
      }

      const updatedModule = {
        ...module,
        permissions: { ...module.permissions },
      };

      if (typeof submoduleIndex === 'undefined') {
        updatedModule.permissions[permissionType] =
          !updatedModule.permissions[permissionType];

        updatedModule.submodules = updatedModule.submodules.map(submodule => {
          if (
            (id === user?.role?.id &&
              submodule.identifier === sub.permissions) ||
            (id === DEV_ID && DEV_ID !== user?.role?.id) ||
            (DEV_ID !== user?.role?.id && submodule.identifier === sub.modules)
          ) {
            return submodule;
          }

          return {
            ...submodule,
            permissions: {
              ...submodule.permissions,
              [permissionType]: updatedModule.permissions[permissionType],
            },
          };
        });
      } else {
        updatedModule.submodules = updatedModule.submodules.map(
          (submodule, subIdx) => {
            if (subIdx !== submoduleIndex) {
              return submodule;
            }

            if (
              (id === user?.role?.id &&
                submodule.identifier === sub.permissions) ||
              (id === DEV_ID && DEV_ID !== user?.role?.id) ||
              (DEV_ID !== user?.role?.id &&
                submodule.identifier === sub.modules)
            ) {
              return submodule;
            }

            return {
              ...submodule,
              permissions: {
                ...submodule.permissions,
                [permissionType]: !submodule.permissions[permissionType],
              },
            };
          },
        );

        updatedModule.permissions[permissionType] =
          updatedModule.submodules.some(sub => sub.permissions[permissionType]);
      }

      return updatedModule;
    });

    setValues(prev => ({ ...prev, modules: updatedModules }));
    setModules(updatedModules);
  };

  useEffect(() => {
    if (errors.modules && submitCount > 0) {
      toast.error(errors.modules);
    }
  }, [submitCount]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      if (id) await getPermission(id);
      await getModules();
    };
    fetchData();

    return () => {
      toast.dismiss();
    };
  }, [id]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={[
          'Cadastros',
          'Permissões',
          data?.name ? `Editando: ${data.name}` : 'Nova Permissão',
        ]}
        addRedirects={[
          {
            title: 'Voltar',
            disabled: isLoading,
            path: subroutes.permissions.path,
            state: { currentPage: state?.currentPage },
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container onSubmit={handleSubmit}>
        <TitleBox
          title={{ text: BASIC_INFOS }}
          error={submitCount > 0 && errors.name}
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}>
          <SubmoduleInsert>
            <Wrapper
              style={{ background: 'transparent', justifyContent: 'center' }}>
              <Input
                isRequired
                id="name"
                maxWidth="300px"
                value={values.name}
                maxLength={50}
                onChange={handleChange}
                placeholder=""
                label={{
                  text: 'Nome',
                }}
                error={{
                  isActive: submitCount > 0 && errors.name,
                  message: errors.name,
                }}
                disabled={
                  (id === DEV_ID && DEV_ID !== user?.role?.id) ||
                  (Object.keys(data).length && !canEdit) ||
                  (!Object.keys(data).length && !canCreate)
                }
              />
            </Wrapper>

            <TitleBox
              type="line"
              title={{ text: 'Permissões' }}
              style={{
                margin: '1rem 0',
              }}
              error={submitCount > 0 && errors.modules}
            />

            {isLoadingModules ? (
              <Loader center />
            ) : modulesUnique.length === 0 ? (
              <div className="notFound">
                <Text $variant="notFound">Nenhum Módulo encontrado</Text>
              </div>
            ) : (
              modulesUnique.map((module, moduleIndex) => (
                <Wrapper key={moduleIndex}>
                  <Row>
                    <Item>
                      <Text $variant="label">Módulo:</Text>
                      <Text $variant="value">{module?.name || '-'}</Text>
                    </Item>

                    <Item style={{ gap: '0 1rem', justifyContent: 'center' }}>
                      <Checkbox
                        isDisabled={
                          (id === DEV_ID && DEV_ID !== user?.role?.id) ||
                          !canEdit
                        }
                        id={`${moduleIndex}visualize`}
                        isChecked={module?.permissions?.visualize === true}
                        onChange={() =>
                          handleCheckboxChange(
                            moduleIndex,
                            undefined,
                            'visualize',
                          )
                        }
                        label={{ text: 'Visualizar' }}
                        check={{
                          bg: 'var(--gray-theme)',
                          border: 'var(--gray-theme)',
                        }}
                      />

                      {module?.name !== 'Relatórios' &&
                        module?.name !== 'BI' && (
                          <>
                            {module?.name !== 'Financeiro' && (
                              <Checkbox
                                isDisabled={
                                  (id === DEV_ID &&
                                    DEV_ID !== user?.role?.id) ||
                                  !canEdit
                                }
                                id={`${moduleIndex}create`}
                                isChecked={module?.permissions?.create === true}
                                onChange={() =>
                                  handleCheckboxChange(
                                    moduleIndex,
                                    undefined,
                                    'create',
                                  )
                                }
                                label={{ text: 'Criar' }}
                                check={{
                                  bg: 'var(--gray-theme)',
                                  border: 'var(--gray-theme)',
                                }}
                              />
                            )}
                            <Checkbox
                              isDisabled={
                                (id === DEV_ID && DEV_ID !== user?.role?.id) ||
                                !canEdit
                              }
                              id={`${moduleIndex}update`}
                              isChecked={module?.permissions?.update === true}
                              onChange={() =>
                                handleCheckboxChange(
                                  moduleIndex,
                                  undefined,
                                  'update',
                                )
                              }
                              label={{ text: 'Modificar' }}
                              check={{
                                bg: 'var(--gray-theme)',
                                border: 'var(--gray-theme)',
                              }}
                            />
                            {module?.name !== 'Financeiro' && (
                              <Checkbox
                                isDisabled={
                                  (id === DEV_ID &&
                                    DEV_ID !== user?.role?.id) ||
                                  !canEdit
                                }
                                id={`${moduleIndex}delete`}
                                isChecked={module?.permissions?.delete === true}
                                onChange={() =>
                                  handleCheckboxChange(
                                    moduleIndex,
                                    undefined,
                                    'delete',
                                  )
                                }
                                label={{ text: 'Excluir' }}
                                check={{
                                  bg: 'var(--gray-theme)',
                                  border: 'var(--gray-theme)',
                                }}
                              />
                            )}
                          </>
                        )}
                    </Item>
                  </Row>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}>
                    <Line />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      {module?.submodules?.map((submodules, submoduleIndex) => (
                        <Row key={submoduleIndex}>
                          <Item>
                            <Text $variant="label">Submódulo:</Text>
                            <Text $variant="value">
                              {submodules?.name || '-'}
                            </Text>
                          </Item>

                          <Item
                            style={{
                              gap: '0 1rem',
                              justifyContent: 'center',
                            }}>
                            <Checkbox
                              isDisabled={
                                (id === DEV_ID && DEV_ID !== user?.role?.id) ||
                                (id === user?.role?.id &&
                                  submodules.identifier === sub.permissions) ||
                                !canEdit ||
                                (DEV_ID !== user?.role?.id &&
                                  submodules.identifier === sub.modules)
                              }
                              id={`${moduleIndex}${submoduleIndex}visualize`}
                              isChecked={
                                submodules?.permissions?.visualize === true
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  moduleIndex,
                                  submoduleIndex,
                                  'visualize',
                                )
                              }
                              label={{ text: 'Visualizar' }}
                              check={{
                                bg: 'var(--gray-theme)',
                                border: 'var(--gray-theme)',
                              }}
                            />

                            {module?.name !== 'Relatórios' &&
                              module?.name !== 'BI' && (
                                <>
                                  {module?.name !== 'Financeiro' && (
                                    <Checkbox
                                      isDisabled={
                                        (id === DEV_ID &&
                                          DEV_ID !== user?.role?.id) ||
                                        (id === user?.role?.id &&
                                          submodules.identifier ===
                                            sub.permissions) ||
                                        !canEdit ||
                                        (DEV_ID !== user?.role?.id &&
                                          submodules.identifier === sub.modules)
                                      }
                                      id={`${moduleIndex}${submoduleIndex}create`}
                                      isChecked={
                                        submodules?.permissions?.create === true
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(
                                          moduleIndex,
                                          submoduleIndex,
                                          'create',
                                        )
                                      }
                                      label={{ text: 'Criar' }}
                                      check={{
                                        bg: 'var(--gray-theme)',
                                        border: 'var(--gray-theme)',
                                      }}
                                    />
                                  )}
                                  <Checkbox
                                    isDisabled={
                                      (id === DEV_ID &&
                                        DEV_ID !== user?.role?.id) ||
                                      (id === user?.role?.id &&
                                        submodules.identifier ===
                                          sub.permissions) ||
                                      !canEdit ||
                                      (DEV_ID !== user?.role?.id &&
                                        submodules.identifier === sub.modules)
                                    }
                                    id={`${moduleIndex}${submoduleIndex}update`}
                                    isChecked={
                                      submodules?.permissions?.update === true
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        moduleIndex,
                                        submoduleIndex,
                                        'update',
                                      )
                                    }
                                    label={{ text: 'Modificar' }}
                                    check={{
                                      bg: 'var(--gray-theme)',
                                      border: 'var(--gray-theme)',
                                    }}
                                  />
                                  {module?.name !== 'Financeiro' && (
                                    <Checkbox
                                      isDisabled={
                                        (id === DEV_ID &&
                                          DEV_ID !== user?.role?.id) ||
                                        (id === user?.role?.id &&
                                          submodules.identifier ===
                                            sub.permissions) ||
                                        !canEdit ||
                                        (DEV_ID !== user?.role?.id &&
                                          submodules.identifier === sub.modules)
                                      }
                                      id={`${moduleIndex}${submoduleIndex}delete`}
                                      isChecked={
                                        submodules?.permissions?.delete === true
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(
                                          moduleIndex,
                                          submoduleIndex,
                                          'delete',
                                        )
                                      }
                                      label={{ text: 'Excluir' }}
                                      check={{
                                        bg: 'var(--gray-theme)',
                                        border: 'var(--gray-theme)',
                                      }}
                                    />
                                  )}
                                </>
                              )}
                          </Item>
                        </Row>
                      ))}
                    </div>
                  </div>
                </Wrapper>
              ))
            )}
          </SubmoduleInsert>

          {((Object.keys(data).length && canEdit) ||
            (!Object.keys(data).length && canCreate)) && (
            <PrimaryButton
              type="submit"
              maxWidth="300px"
              bg="green"
              disabled={isLoading}>
              Salvar
            </PrimaryButton>
          )}
        </TitleBox>
      </Container>
    </PageContainer>
  );
};

export default Form;
