import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { subMonths } from 'date-fns';

import PageHeader from 'components/ui-kit/PageHeader';
import PageContainer from 'components/ui-kit/PageContainer';
import FilterTool from 'components/ui-kit/FilterTool';
import Table from 'components/ui-kit/Table';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import StatusDisplay from 'components/ui-kit/StatusDisplay';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import {
  getInitialDates,
  getExportData,
  getBackendDateFormat,
} from 'utils/functions';
import Formatter from 'utils/formatter';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import {
  STANDARD_ERROR,
  NO_DATA_FOUND,
  GETTING_PDF,
  GETTING_XLS,
  PDF_ERROR,
  PDF_READY,
  XLS_ERROR,
  XLS_READY,
} from 'utils/messages';
import exportPDF from 'services/pdf';
import exportXLS from 'services/xls';

const defaultColumns = [
  { label: 'N° Protocolo', field: 'numProtocol' },
  {
    label: 'Data Criação Ocorrência',
    field: 'creationDateOccurrence',
    display: 'fmtdCreationDateOccurrence',
  },
  {
    label: 'Data Encerramento Ocorrência',
    field: 'closingDateOccurrence',
    display: 'fmtdClosingDateOccurrence',
  },
  { label: 'Usuário que criou', field: 'userWhoCreated' },
  { label: 'Usuário que Finalizou', field: 'userWhoCompleted' },
  { label: 'Associado(s)', field: 'associateName' },
  { label: 'Placa(s)', field: 'vehiclePlate' },
  { label: 'Causa(s)', field: 'causes' },
  {
    label: 'Status da Ocorrência',
    field: 'occurrenceStatusId',
    display: 'occurrenceStatusDisplay',
  },
];

const AttendancesPerUser = () => {
  const [attendancesList, setAttendancesList] = useState([]);

  const [userOccurrence, setUserOccurrence] = useState();
  const [associate, setAssociate] = useState();
  const [vehicle, setVehicle] = useState();
  const [statusOccurrence, setStatusOccurrence] = useState();
  const [searchHistory, setSearchHistory] = useState({});

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSeletRef = useRef(null);
  const tableRef = useRef(null);

  const { user } = useAuth();
  const { defaultDateFormat, exportDateFormat } = new Formatter();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const inputs = [
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: 'users/?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Usuário',
      onOptionChange: option => setUserOccurrence(option),
    },
    {
      type: 'select',
      maxWidth: '300px',
      endpoint: 'clients/all?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Associado',
      onOptionChange: option => {
        if (vehicle?.id) vehicleSeletRef.current?.clearValue();

        setAssociate(option);
      },
    },
    {
      ref: vehicleSeletRef,
      type: 'select',
      maxWidth: '220px',
      isDisabled: !associate?.id,
      endpoint: `vehicles/?client_id=${associate?.id}`,
      fieldToOptionLabel: 'plate',
      fieldToOptionValue: 'id',
      placeholder: 'Selecione uma Placa',
      onOptionChange: option => setVehicle(option),
    },
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: 'occurrencies/status/',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Status da Ocorrência',
      onOptionChange: option => setStatusOccurrence(option),
    },
  ];

  const getAttendances = useCallback(async () => {
    setIsLoading(true);

    setAttendancesList([]);
    setSearchHistory({});

    try {
      const { data: response, status } = await api.get(
        'reports/occurrences/users',
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
          params: {
            created_since: dates?.startDate,
            created_before: dates?.endDate,
            user: userOccurrence?.value,
            client: associate?.value,
            vehicle: vehicle?.value,
            status: statusOccurrence?.value,
          },
        },
      );

      if (status !== 200) throw new Error();

      const attendancesLog = response.data.map(item => ({
        id: item?.id,
        numProtocol: item?.nr_protocol,
        creationDateOccurrence: item?.created_on,
        closingDateOccurrence: item?.closed_on,
        userWhoCreated: item?.author?.display_name,
        userWhoCompleted: item?.closing_user?.display_name,
        associateName: item?.vehicles
          ?.filter(
            (a, index, self) =>
              self.findIndex(t => t.client.id === a.client.id) === index,
          )
          .map(a => a?.client?.display_name || '-')
          .join(', '),
        vehiclePlate: item?.vehicles?.map(v => v?.plate || '-').join(', '),
        causes: item?.accidents?.map(a => a.name).join(', '),
        occurrenceStatusId: item?.status?.id,
        occurrenceStatusName: item?.status?.name,
      }));

      if (attendancesLog.length > 0) {
        setSearchHistory({
          startDate: dates?.startDate,
          endDate: dates?.endDate,
          userOccurrenceName: userOccurrence?.label,
          associateName: associate?.label,
          vehiclePlate: vehicle?.label,
        });
      }

      setAttendancesList(attendancesLog);

      setIsLoading(false);
      resetProgress();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
      setIsLoading(false);
      resetProgress();
    }
  }, [
    dates,
    userOccurrence,
    associate,
    vehicle,
    statusOccurrence,
    searchHistory,
  ]);

  const dataTable = useMemo(() => {
    return attendancesList.map(item => ({
      ...item,
      numProtocol: item?.numProtocol || '-',
      creationDateOccurrence: item.creationDateOccurrence,
      fmtdCreationDateOccurrence: item.creationDateOccurrence
        ? defaultDateFormat(item.creationDateOccurrence)
        : '-',
      closingDateOccurrence: item.closingDateOccurrence,
      fmtdClosingDateOccurrence: item.closingDateOccurrence
        ? defaultDateFormat(item.closingDateOccurrence)
        : '-',
      userWhoCreated: item?.userWhoCreated || '-',
      userWhoCompleted: item?.userWhoCompleted || '-',
      associateName: item?.associateName || '-',
      vehiclePlate: item?.vehiclePlate || '-',
      causes: item?.causes || '-',
      occurrenceStatusDisplay: (
        <StatusDisplay
          config="occurrenceStatus"
          value={item.occurrenceStatusId}
        />
      ),
    }));
  }, [attendancesList]);

  const handleExportData = () => {
    const exportData = tableRef.current?.getData().map(item => {
      const index = attendancesList.findIndex(a => a.id === item.id);
      return {
        ...item,
        occurrenceStatusId:
          index !== -1 && attendancesList[index].occurrenceStatusName
            ? attendancesList[index].occurrenceStatusName
            : '-',
      };
    });

    const cols = columns.filter(col => col.field !== 'viewOccurrence');

    const formatting = {
      creationDateOccurrence: defaultDateFormat,
      closingDateOccurrence: defaultDateFormat,
    };

    try {
      const { items, visibleCols } = getExportData(exportData, cols, {
        formatting,
        validateColumns: true,
      });

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      (searchHistory.userOccurrenceName
        ? 'Usuário: ' + searchHistory.userOccurrenceName + '\n'
        : '') +
      (searchHistory.associateName
        ? 'Associado: ' + searchHistory.associateName + '\n'
        : '') +
      (searchHistory.vehiclePlate
        ? 'Placa: ' + searchHistory.vehiclePlate + '\n'
        : '') +
      'Período: ' +
      defaultDateFormat(searchHistory.startDate) +
      ' a ' +
      defaultDateFormat(searchHistory.endDate) +
      '\nProcessado em: ' +
      defaultDateFormat(new Date()) +
      '\nTotal de registros: ' +
      data.length;

    const exportData = {
      headers,
      title: 'Relatório de Atendimentos por Usuário',
      description,
      data,
    };

    exportPDF(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: PDF_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: PDF_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  const onExportXLS = async () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      headers,
      title: 'Relatório de Atendimentos por Usuário',
      data,
    };

    exportXLS(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: XLS_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: XLS_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    getAttendances();

    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Relatórios', 'Atendimentos por Usuário']}
        onExportPDF={attendancesList.length > 0 && onExportPDF}
        onExportXLS={attendancesList.length > 0 && onExportXLS}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter={true}
        disableFilter={attendancesList.length === 0}
        inputs={inputs}
        defaultColumns={columns}
        disableSearch={false}
        onSubmit={getAttendances}
        onFilterColumns={setColumns}
        initialDates={getInitialDates({
          months: 1,
        })}
      />

      {attendancesList.length > 0 && (
        <Table ref={tableRef} columns={columns} data={dataTable} />
      )}
    </PageContainer>
  );
};

export default AttendancesPerUser;
