import { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { subMonths, startOfMonth, endOfMonth, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { IMaskInput } from 'react-imask';

import { getInitialDates, getBackendDateFormat } from 'utils/functions';

import PageHeader from 'components/ui-kit/PageHeader';
import PageContainer from 'components/ui-kit/PageContainer';
import FilterTool from 'components/ui-kit/FilterTool';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import CarouselCards from 'components/pages/BI/Occurences/CarouselCards';
import ChartsContainer from 'components/pages/BI/Occurences/ChartsContainer';
import MapOccurrences from 'components/pages/BI/Occurences/MapOccurrences';

import api from 'services/api';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';
import { fadeIn } from 'helpers/animations';
import { BEARER } from 'utils/constants';
import { STANDARD_ERROR, NO_DATA_FOUND } from 'utils/messages';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit gap-6`}
  animation: ${fadeIn} 200ms ease-in;
`;

const Occurrences = () => {
  const [occurrencesData, setOccurrencesData] = useState({});

  const [numProtocolInput, setNumProtocolInput] = useState('');
  const [associate, setAssociate] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [type, setType] = useState({});
  const [causes, setCauses] = useState({});

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSeletRef = useRef(null);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const inputs = [
    {
      onAccept: value => setNumProtocolInput(value.toUpperCase()),
      as: IMaskInput,
      value: numProtocolInput,
      mask: 'a-0000000',
      placeholder: 'N° Protocolo',
      size: 'sm',
      height: '33px',
      maxWidth: '180px',
      $variant: 'registry',
    },
    {
      type: 'select',
      maxWidth: '300px',
      endpoint: 'clients/all?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Associado',
      onOptionChange: option => {
        if (vehicle?.id) vehicleSeletRef.current?.clearValue();

        setAssociate(option);
      },
    },
    {
      ref: vehicleSeletRef,
      type: 'select',
      maxWidth: '220px',
      isDisabled: !associate?.id,
      endpoint: `vehicles/?client_id=${associate?.id}`,
      fieldToOptionLabel: 'plate',
      fieldToOptionValue: 'id',
      placeholder: 'Selecione uma Placa',
      onOptionChange: option => setVehicle(option),
    },
    {
      type: 'select',
      maxWidth: '250px',
      options: [
        { label: 'Acidente', value: 1 },
        { label: 'Pane', value: 2 },
        { label: 'Vidro', value: 3 },
      ],
      placeholder: 'Tipo de Ocorrência',
      onOptionChange: option => setType(option),
    },
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: type?.value
        ? `occurrencies/incidents/?category_id=${type?.value}&active=true`
        : 'occurrencies/incidents/?active=true',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Causas',
      onOptionChange: option => setCauses(option),
    },
  ];

  const getOccurrences = useCallback(async () => {
    setIsLoading(true);

    setOccurrencesData({});

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get('bi/occurrences', {
        onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          nr_protocol: formatParam(numProtocolInput),
          associate_id: associate?.value,
          vehicle_id: vehicle?.value,
          category_id: type?.value,
          types_ids: causes?.value,
          created_on_from: dates?.startDate,
          created_on_to: dates?.endDate,
        },
      });

      if (status !== 200) throw new Error();

      const {
        graphs: monthlyData,
        total_types_occurrences: totalCauses,
        total_types_budgets: totalBudgets,
        metrics: cards,
      } = response.data;

      const formatDate = item =>
        format(new Date(item.year, item.month - 1), 'MMMM yyyy', {
          locale: ptBR,
        });

      const occurrencesLog = {
        graphData: {
          monthlyCauses:
            monthlyData?.map(item => {
              const totalCausesceMonth =
                item.total_types_occurrences_month.reduce(
                  (sum, occurrence) => sum + occurrence.amount,
                  0,
                );
              return {
                date: formatDate(item),
                total: totalCausesceMonth,
                causesMonth: item.total_types_occurrences_month.map(
                  occurrence => ({
                    name: occurrence?.name || '-',
                    amount: occurrence?.amount || 0,
                  }),
                ),
              };
            }) || [],

          totalCauses:
            totalCauses.map(item => ({
              name: item?.name || '-',
              amount: item?.amount || 0,
            })) || [],

          numberBudgets:
            monthlyData?.map(item => {
              const totalBudgetsMonth = item.total_types_budgets_month.reduce(
                (sum, occurrence) => sum + occurrence.amount,
                0,
              );
              return {
                date: formatDate(item),
                total: totalBudgetsMonth,
                budgetsMonth: item.total_types_budgets_month.map(budget => ({
                  name: budget?.name || '-',
                  amount: budget?.amount || 0,
                })),
              };
            }) || [],

          totalBudgets:
            totalBudgets.map(item => ({
              name: item?.name || '-',
              amount: item?.amount || 0,
            })) || [],
        },

        cardData: [
          {
            title: 'N° de Ocorrências',
            description: 'Total de ocorrências no período',
            value: `Qtd: ${(cards?.total_occurrences || 0).toLocaleString('pt-br')}`,
          },
          {
            title: 'Maior Causa',
            description: 'Causa mais frequente no período',
            detailsValues: cards?.infos_occurrences?.most_occurred?.name || '-',
            value: `Qtd: ${(
              cards?.infos_occurrences?.most_occurred?.amount || 0
            ).toLocaleString('pt-br')}`,
          },
          {
            title: 'Menor Causa',
            description: 'Causa menos frequente no período',
            detailsValues:
              cards?.infos_occurrences?.least_occurred?.name || '-',
            value: `Qtd: ${(
              cards?.infos_occurrences?.least_occurred?.amount || 0
            ).toLocaleString('pt-br')}`,
          },
          {
            title: 'N° de Veículos',
            description: 'Total de Veículos atendidos no período',
            value: `Qtd: ${(cards?.total_vehicles || 0).toLocaleString('pt-br')}`,
          },
          {
            title: 'N° de Guinchos',
            description: 'Total de Guinchos solicitados no período',
            value: `Qtd: ${(cards?.amount_tow || 0).toLocaleString('pt-br')}`,
          },
          {
            title: 'N° de Cargas',
            description: 'Total de Cargas solicitadas no período',
            value: `Qtd: ${(cards?.amount_is_loaded || 0).toLocaleString('pt-br')}`,
          },
          {
            title: 'N° de Hotéis',
            description: 'Total de Hotéis solicitados no período',
            value: `Qtd: ${(cards?.amount_need_hotel || 0).toLocaleString('pt-br')}`,
          },
          {
            title: 'N° de Táxis',
            description: 'Total de Táxis solicitados no período',
            value: `Qtd: ${(cards?.amount_need_taxi || 0).toLocaleString('pt-br')}`,
          },
        ],
      };

      setOccurrencesData(occurrencesLog);

      setIsLoading(false);
      resetProgress();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
      setIsLoading(false);
      resetProgress();
    }
  }, [dates, numProtocolInput, associate, vehicle, type, causes]);

  useEffect(() => {
    getOccurrences();
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader titles={['BI', 'Ocorrências']} />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter={false}
        inputs={inputs}
        onSubmit={getOccurrences}
        useDatePicker
        initialDates={getInitialDates({
          months: 1,
        })}
        maxRange={365}
        disableInputs={true}
        customPreSelection={[
          {
            label: 'Mês atual',
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
          },
          {
            label: 'Mês passado',
            startDate: startOfMonth(subMonths(new Date(), 1)),
            endDate: endOfMonth(subMonths(new Date(), 1)),
          },
          {
            label: 'Últimos 3 Meses',
            startDate: subMonths(new Date(), 3),
            endDate: new Date(),
          },
          {
            label: 'Últimos 6 Meses',
            startDate: subMonths(new Date(), 6),
            endDate: new Date(),
          },
          {
            label: 'Últimos 12 Meses',
            startDate: subMonths(new Date(), 12),
            endDate: new Date(),
          },
        ]}
      />
      {Object.keys(occurrencesData).length > 0 && (
        <Container>
          <CarouselCards cardData={occurrencesData?.cardData} />

          <ChartsContainer graphData={occurrencesData?.graphData} />

          <MapOccurrences
            params={{
              numProtocol: numProtocolInput,
              associateId: associate?.value,
              vehicleId: vehicle?.value,
              categoryOccurrenceId: type?.value,
              causesId: causes?.value,
              startDate: dates?.startDate,
              endDate: dates?.endDate,
            }}
          />
        </Container>
      )}
    </PageContainer>
  );
};

export default Occurrences;
