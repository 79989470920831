import { useRef, useState, memo, useCallback, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { marker as MarkerBase } from 'leaflet';
import L from 'leaflet';
import { ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import Select from 'components/ui-kit/Select';
import Map from 'components/ui-kit/Map';
import TitleBox from 'components/ui-kit/TitleBox';
import { IconFactory } from 'components/ui-kit/Map/Icon';

import Formatter from 'utils/formatter';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import { variant } from 'utils/functions';

import useAuth from 'hooks/useAuth';

import { fadeIn, clipPath } from 'helpers/animations';

const LoadingBorder = styled.div`
  ${tw`flex bg-white rounded-lg relative w-full items-center justify-center`}
  padding: 0.4em;
  animation: ${fadeIn} 1s ease-in-out;

  &::before {
    content: '';
    ${tw`w-full h-full rounded-xl absolute bg-[var(--dark-blue-theme)]`}
    opacity: ${props => (props.$isLoading ? 1 : 0)};
    transition: opacity 0.5s;
    animation: ${props =>
      props.$isLoading
        ? css`
            ${clipPath} 2s linear infinite
          `
        : 'none'};
  }
`;

const Container = styled.div`
  ${tw`h-[650px] w-full max-w-full m-0 overflow-hidden relative rounded-xl z-[1]`}
`;

const FiltersContainer = styled.div`
  ${tw`flex flex-wrap w-full max-w-[calc(100% - 16px)] h-fit bg-white justify-center items-center gap-3 top-0 absolute m-2 z-[1] rounded-lg shadow-lg pb-2 px-2`}
`;

const Cluster = styled.div`
  ${tw`flex items-center justify-center bg-[var(--royal-blue-theme)] text-white font-bold border-solid border-2 border-white rounded-circle`}
  width: 30px;
  height: 30px;

  ${({ $variant }) =>
    variant({
      green: tw`bg-[green]`,
    })({ $variant })}
`;

const createMarker = (position, isPlate = false) => {
  const factory = new IconFactory();

  const icon = isPlate
    ? factory.TruckIcon('red', {
        iconSize: [50, 50],
        iconAnchor: [25, 50],
      })
    : factory.WinchIcon('#002DA0', {
        iconSize: [50, 50],
        iconAnchor: [25, 50],
      });

  const offset = 0.009 * Math.random();

  const adjustedPosition = isPlate
    ? position
    : [position[0] + offset, position[1] + offset];

  return MarkerBase(adjustedPosition, {
    draggable: false,
    bounceOnAdd: false,
    icon,
  });
};

const iconCreateFunction = (cluster, isPlate = false) => {
  const backgroundColor = isPlate
    ? 'var(--green-theme)'
    : 'var(--royal-blue-theme)';

  const icon = L.divIcon({
    html: renderToString(
      <Cluster $variant={isPlate ? 'green' : 'default'}>
        {cluster.getChildCount()}
      </Cluster>,
    ),
    className: 'custom-cluster-icon',
    style: {
      backgroundColor,
    },
  });

  return icon;
};

const ServiceProviderMap = ({
  isOpen = false,
  occurrenceInfo = {},
  plateOptions = [],
  onAddProvider = () => {},
}) => {
  const [providersList, setProvidersList] = useState([]);

  const [plate, setPlate] = useState(plateOptions[0] || {});
  const [typeServiceId, setTypeServiceId] = useState();
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [provider, setProvider] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const plateSelectRef = useRef(null);
  const countrySelectRef = useRef(null);
  const stateSelectRef = useRef(null);
  const citySelectRef = useRef(null);
  const providerSelectRef = useRef(null);

  const mapRef = useRef(null);
  const circlesRef = useRef(null);
  const plateMarkersRef = useRef(null);
  const providerMarkersRef = useRef(null);

  const { user } = useAuth();

  const { phoneMask } = new Formatter();

  const getNearbyProviders = useCallback(
    async ({ vehicleId, typeServiceId }) => {
      setIsLoading(true);
      setProvidersList([]);

      try {
        const { data: response, status } = await api.get(
          `occurrencies/${occurrenceInfo?.id}/tows/${vehicleId}/distances`,
          {
            headers: {
              Authorization: BEARER + user.token,
            },
            params: {
              equipament_id: typeServiceId,
              active: true,
            },
          },
        );

        if (status !== 200) throw new Error();

        const providersLog =
          response?.data?.map(item => ({
            value: item?.tow?.id,
            label: item?.tow?.name,
            address: `${item?.tow?.locality?.address ? item?.tow.locality.address + ', ' : ''}${item?.tow?.locality?.complement ? item?.tow.locality.complement + ', ' : ''}${item?.tow?.locality?.place_number ? item?.tow.locality.place_number + ', ' : ''}${item?.tow?.locality?.neighborhood ? item?.tow.locality.neighborhood + ', ' : ''}${item?.tow?.locality?.city?.name ? item?.tow.locality.city.name + ' / ' : ''}${item?.tow?.locality?.city?.state?.short_code ? item?.tow.locality.city.state.short_code : ''}`,
            position: [
              item?.tow?.locality?.city?.latitude,
              item?.tow?.locality?.city?.longitude,
            ],
            contacts:
              item?.tow?.contacts?.map(item => ({
                id: item?.id,
                name: item?.name,
                number: item?.telephone,
                ddi: item?.ddi,
              })) || [],
          })) || [];

        setProvidersList(providersLog);

        toast.info(
          'Exibindo prestadores próximos em um raio de 100 km do local de origem do veículo',
          { autoClose: 5000 },
        );
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 404:
            errorMessage = 'Nenhum Prestador de Serviço encontrado';
            break;
          default:
            errorMessage = 'Falha ao buscar Prestadores próximos';
        }
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [user, occurrenceInfo],
  );

  const getProviders = useCallback(
    async ({ typeServiceId, countryId, stateId, cityId }) => {
      setIsLoading(true);
      setProvidersList([]);

      try {
        const { data: response, status } = await api.get(
          'services-providers/',
          {
            headers: {
              Authorization: BEARER + user.token,
            },
            params: {
              active: true,
              equipament_id: typeServiceId,
              country_id: countryId,
              uf_id: stateId,
              city_id: cityId,
            },
          },
        );

        if (status !== 200) throw new Error();

        const providersLog =
          response?.data?.map(item => ({
            value: item?.id,
            label: item?.name,
            address: `${item?.locality?.address ? item.locality.address + ', ' : ''}${item?.locality?.complement ? item.locality.complement + ', ' : ''}${item?.locality?.place_number ? item.locality.place_number + ', ' : ''}${item?.locality?.neighborhood ? item.locality.neighborhood + ', ' : ''}${item?.locality?.city?.name ? item.locality.city.name + ' / ' : ''}${item?.locality?.city?.state?.short_code ? item.locality.city.state.short_code : ''}`,
            position: [
              item?.locality?.city?.latitude,
              item?.locality?.city?.longitude,
            ],
            contacts:
              item?.contacts?.map(item => ({
                id: item?.id,
                name: item?.name,
                number: item?.telephone,
                ddi: item?.ddi,
              })) || [],
          })) || [];

        setProvidersList(providersLog);
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 404:
            errorMessage = 'Nenhum Prestador de Serviço encontrado';
            break;
          default:
            errorMessage = 'Falha ao buscar Prestadores';
        }
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    },
    [user],
  );

  useEffect(() => {
    if (!mapRef.current) return;

    if (circlesRef.current) {
      circlesRef.current.forEach(circle => {
        mapRef.current.removeLayer(circle);
      });
      circlesRef.current = [];
    }

    const firstPlate = plate?.locations?.[0]?.origin?.position;
    if (firstPlate) {
      mapRef.current.setView(firstPlate, 7);
    } else if (providersList.length) {
      mapRef.current.setView(providersList[0].position, 4);
    }

    if (plate?.locations) {
      circlesRef.current = plate.locations.reduce((acc, location) => {
        const position = location?.origin?.position;
        if (position && position.length) {
          const circle = L.circle(position, {
            color: '#002DA0',
            fillColor: '#002DA0',
            fillOpacity: 0.2,
            radius: 100000,
          }).addTo(mapRef.current);
          acc.push(circle);
        }
        return acc;
      }, []);
    }

    if (providerMarkersRef.current) {
      mapRef.current.removeLayer(providerMarkersRef.current);
      providerMarkersRef.current.clearLayers();
    }

    const providerMarkers = L.markerClusterGroup({
      iconCreateFunction: cluster => iconCreateFunction(cluster, false),
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: 17,
    });

    providersList.forEach(item => {
      const marker = createMarker(item?.position, false);
      const popupContent = document.createElement('div');

      popupContent.innerHTML = `
      <div>
        <span>
          <b>Prestador:</b> ${item.label}
        </span>
        <br />
        <span>
          <b>Endereço:</b> ${item.address}
        </span>
        <br />
        <b>Contatos:</b>
        <ul>
          ${item.contacts
            ?.map(
              c => `
              <li>
                <span>${c?.name ? `${c.name}: ` : ''}</span>
                <span style="cursor: pointer;" class="contact-item" data-ddi="${c.ddi}" data-number="${c.number}">
                  ${c.ddi} ${phoneMask(c?.number)}
                </span>
              </li>
            `,
            )
            .join('')}
        </ul>
        <br />
        <div style="display: flex; width: 100%; justify-content: center;">
          <button
            type="button"
            style="
              font-weight: 700;
              font-size: 0.800rem;
              color: white;
              width: fit-content;
              background-color: var(--royal-blue-theme);
              padding: 0.4rem 0.8rem;
              border-radius: 0.5rem;
            ">
            Adicionar
          </button>
        </div>
      </div>
    `;

      const button = popupContent.querySelector('button');
      button.addEventListener('click', () => onAddProvider(item));

      const contactItems = popupContent.querySelectorAll('.contact-item');
      contactItems.forEach(contactItem => {
        contactItem.addEventListener('click', () => {
          const ddi = contactItem.getAttribute('data-ddi');
          const number = contactItem.getAttribute('data-number');
          navigator.clipboard.writeText(ddi + number);
          toast.success('Contato copiado para área de transferência.');
        });
      });

      marker.bindTooltip(item.label, {
        permanent: false,
        direction: 'top',
        className: 'provider-tooltip',
        offset: L.point(0, -45),
      });

      marker.bindPopup(popupContent, {
        className: 'custom-popup',
        offset: L.point(0, -30),
      });
      providerMarkers.addLayer(marker);
    });

    mapRef.current.addLayer(providerMarkers);
    providerMarkersRef.current = providerMarkers;

    if (plateMarkersRef.current) {
      mapRef.current.removeLayer(plateMarkersRef.current);
      plateMarkersRef.current.clearLayers();
    }

    const plateMarkers = L.layerGroup({
      iconCreateFunction: cluster => iconCreateFunction(cluster, true),
    });

    if (plate?.locations) {
      plate.locations.forEach(location => {
        const position = location?.origin?.position;
        if (position && position.length) {
          const marker = createMarker(position, true);
          marker.bindPopup(
            `<b>Placa:</b> ${plate.label}<br><b>Localização:</b> ${location?.origin?.cityName || '-'} / ${location?.origin?.stateShortCode || '-'}`,
            {
              offset: L.point(0, -30),
            },
          );
          plateMarkers.addLayer(marker);

          marker.bindTooltip(plate.label, {
            permanent: false,
            direction: 'top',
            className: 'plate-tooltip',
            offset: L.point(0, -45),
          });
        }
      });
    }

    mapRef.current.addLayer(plateMarkers);
    plateMarkersRef.current = plateMarkers;
  }, [providersList, mapRef, plate]);

  useEffect(() => {
    if (!plate?.value) return;

    const fetchNearbyProviders = async () => {
      await getNearbyProviders({
        vehicleId: plate?.value,
        typeServiceId: typeServiceId,
      });
    };

    fetchNearbyProviders();
  }, [typeServiceId, plate]);

  useEffect(() => {
    if (!isOpen || !Object.keys(occurrenceInfo).length || !plateOptions.length)
      return;

    plateSelectRef.current?.setValue({
      ...plateOptions[0],
      autoSelection: true,
    });

    setPlate(plateOptions[0]);

    return () => {
      toast.dismiss();
    };
  }, [isOpen, occurrenceInfo, plateOptions]);

  useEffect(() => {
    if (plate?.value) return;

    const fetchProviders = async () => {
      await getProviders({
        typeServiceId: typeServiceId,
        countryId: countryId,
        stateId: stateId,
        cityId: cityId,
      });
    };

    fetchProviders();
  }, [typeServiceId, countryId, stateId, cityId, plate]);

  useEffect(() => {
    if (!provider?.value || !providersList.length) return;

    const markerToOpen = providerMarkersRef.current
      .getLayers()
      .find(marker => marker.getTooltip().getContent() === provider.label);

    if (markerToOpen) {
      mapRef.current.setView(
        [provider.position[0] + 0.002, provider.position[1]],
        17,
      );
      markerToOpen.openPopup();
    }
  }, [provider, providersList]);

  return (
    <LoadingBorder $isLoading={isLoading}>
      <Container>
        <FiltersContainer>
          <TitleBox type="line" title={{ text: 'Filtros' }} />

          <Select
            ref={plateSelectRef}
            size="sm"
            maxWidth="190px"
            options={plateOptions}
            onOptionChange={option => {
              if (countryId && option?.value) {
                countrySelectRef.current?.clearValue();
                setCountryId();
              }
              if (stateId && option?.value) {
                stateSelectRef.current?.clearValue();
                setStateId();
              }
              if (cityId && option?.value) {
                citySelectRef?.current?.clearValue();
                setCityId();
              }
              if (provider?.value && option?.value) {
                providerSelectRef.current?.clearValue();
                setProvider({});
              }

              setPlate(option);
            }}
            placeholder=""
            label={{ text: 'Próximo ao Veículo' }}
          />

          <Select
            size="sm"
            maxWidth="220px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint="services-providers/equipaments/?active=true"
            onOptionChange={option => {
              setTypeServiceId(option?.value);
            }}
            placeholder=""
            label={{
              text: 'Tipo de Serviço Prestado',
            }}
          />

          <Select
            ref={countrySelectRef}
            size="sm"
            maxWidth="180px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint="georef/countries"
            onOptionChange={option => {
              if (stateId) {
                stateSelectRef.current?.clearValue();
                setStateId();
              }
              if (plate?.value && option?.value) {
                plateSelectRef.current?.clearValue();
                setPlate({});
              }
              setCountryId(option?.value);
            }}
            placeholder=""
            label={{
              text: 'País',
            }}
          />

          <Select
            ref={stateSelectRef}
            size="sm"
            maxWidth="250px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint={`georef/states/?country_id=${countryId}`}
            onOptionChange={option => {
              if (cityId) {
                citySelectRef?.current?.clearValue();
                setCityId();
              }
              if (plate?.value && option?.value) {
                plateSelectRef.current?.clearValue();
                setPlate({});
              }
              setStateId(option.value);
            }}
            placeholder=""
            label={{
              text: 'Estado',
            }}
            isDisabled={!countryId}
          />
          <Select
            ref={citySelectRef}
            size="sm"
            maxWidth="250px"
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint={
              !stateId ? 'georef/cities/' : `georef/cities/?uf_id=${stateId}`
            }
            onOptionChange={option => {
              if (plate?.value && option?.value) {
                plateSelectRef.current?.clearValue();
                setPlate({});
              }
              setCityId(option.value);
            }}
            placeholder=""
            label={{
              text: 'Cidade',
            }}
            isDisabled={!stateId}
          />

          <Select
            ref={providerSelectRef}
            isRequired
            size="sm"
            maxWidth="300px"
            options={providersList}
            onOptionChange={option => {
              if (plate?.value && option?.value) {
                plateSelectRef.current?.clearValue();
                setPlate({});
              }
              setProvider(option);
            }}
            placeholder=""
            label={{
              text: 'Prestador',
            }}
          />
        </FiltersContainer>

        <Map
          ref={mapRef}
          tileLayer={{
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
              '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          }}>
          <ZoomControl position="bottomleft" />
        </Map>
      </Container>
    </LoadingBorder>
  );
};

export default memo(ServiceProviderMap);
