import {
  hoursToSeconds,
  isValid,
  millisecondsToSeconds,
  minutesToSeconds,
  secondsToHours,
  secondsToMinutes,
  format,
} from 'date-fns';

const dateOptions = {
  entryType: 'full' || 'no-time',
};

const removeMaskOptions = {
  responseType: 'number' || 'text' || '',
};

const adjustDate = date => {
  const fragment = date.split('-');

  const year = fragment[0];
  const month = fragment[1];
  const day = fragment[2];

  const correctDate = new Date(`${month}/${day}/$${year}`);

  return correctDate;
};

export const handleMinutes = value => {
  if (value === 0) return '0';

  if (value < 10) return `0${value}`;

  return value;
};

export default class Formatter {
  extractDateDetails(date, options = dateOptions) {
    let target = new Date(date);

    if (options?.entryType === 'no-time') target = adjustDate(date);

    if (!isValid(target)) return;

    const response = {
      day: target.getDate(),
      month: target.getMonth(),
      year: target.getFullYear(),
      hours: target.getHours(),
      minutes: target.getMinutes(),
      seconds: target.getSeconds(),
    };

    return response;
  }

  removeMask = (value = '', options = removeMaskOptions) => {
    switch (options?.responseType) {
      case 'number':
        return value.replace(/\D/g, '');
      case 'text':
        return value.replace(/[^a-zA-Z]+/gm, '');
      default:
        return value.replace(/[^a-zA-Z0-9]+/gm, '');
    }
  };

  formatDate = (date = new Date()) => {
    if (!isValid(date)) return;

    return format(date, 'dd/MM/yyyy');
  };

  defaultDateFormat = (date, time = true, onlyTime = false, type = '') => {
    let targetDate = new Date(date);

    if (type === 'montly') targetDate = this.formatDate(date);

    if (!isValid(targetDate)) return;

    if (onlyTime) return format(targetDate, 'HH:mm:ss');
    else if (!time) return format(targetDate, 'dd/MM/yyyy');

    return format(targetDate, 'dd/MM/yyyy - HH:mm');
  };

  exportDateFormat = (date, time = true) => {
    let targetDate = new Date(date);

    if (!isValid(targetDate)) return;

    if (!time) return format(targetDate, 'dd_MM_yyyy');

    return format(targetDate, 'dd_MM_yyyy_HH_mm');
  };

  phoneMask(value = '') {
    if (!value) return;

    let phone = value;

    if (value.length === 11) {
      const start = value.substring(0, 2);
      const nine = value.substring(2, 3);
      const mid = value.substring(3, 7);
      const end = value.substring(7, 11);

      phone = `(${start}) ${nine}${mid}-${end}`;
    } else if (value.length === 10) {
      const start = value.substring(0, 2);
      const mid = value.substring(2, 6);
      const end = value.substring(6, 10);

      phone = `(${start}) ${mid}-${end}`;
    }

    return phone;
  }

  cnpjMask(value = '') {
    const numbers = value.replace(/\D/g, '');

    if (numbers.length < 14) return numbers;

    return numbers
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  }

  cpfMask(value = '') {
    const numbers = value.replace(/\D/g, '');

    if (numbers.length < 11) return numbers;

    return numbers
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})/, '$1-$2');
  }

  cepMask(value = '') {
    const numbers = value.replace(/\D/g, '');

    if (numbers.length < 8) return numbers;

    return numbers.replace(/(\d{5})(\d)/, '$1-$2');
  }

  limitStringSize(value = '', options = { maxSize: 30 }) {
    if (!value) return;

    if (value.length < options?.maxSize) return value;

    return `${value.slice(0, options?.maxSize)}...`;
  }

  handleTime(time = 0, initialTimeFormat = 'sec') {
    const validFormats = ['ms', 'sec', 'min', 'hour'];
    const dayInSeconds = 86400;
    const hoursInSeconds = 3600;
    const minInSeconds = 60;
    let seconds = 0;

    if (!validFormats.includes(initialTimeFormat)) return;

    switch (initialTimeFormat) {
      case 'ms':
        seconds = millisecondsToSeconds(time);
        break;
      case 'sec':
        seconds = time;
        break;
      case 'min':
        seconds = minutesToSeconds(time);
        break;
      case 'hour':
        seconds = hoursToSeconds(time);
        break;
    }

    if (seconds >= dayInSeconds) {
      const days = Math.floor(seconds / dayInSeconds);
      const hours = secondsToHours(seconds % dayInSeconds);

      return `${days} ${days > 1 ? 'dias' : 'dia'} ${
        hours > 0 ? hours + ` ${hours > 1 ? 'horas' : 'hora'}` : ''
      }`;
    } else if (seconds >= hoursInSeconds) {
      const hours = secondsToHours(seconds);
      const min = handleMinutes(Math.floor(secondsToMinutes(seconds) % 60));

      return `${hours} ${hours > 1 ? 'horas' : 'hora'} ${
        min === '0' ? '' : `e ${min} ${min > 1 ? 'minutos' : 'minuto'}`
      }`;
    } else if (seconds >= minInSeconds) {
      const minutes = secondsToMinutes(seconds);

      return `${minutes} ${minutes > 1 ? 'minutos' : 'minuto'}`;
    }

    return seconds > 0
      ? `${seconds} ${seconds > 1 ? 'segundos' : 'segundo'}`
      : '00:00';
  }

  formatToBRL(value = 0) {
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
  }

  formatPlate(plate = '', { applyMask = false } = {}) {
    if (!plate) return;

    if (applyMask) {
      const maskedPlate = `${plate.slice(0, 3)}-${plate.slice(
        3,
        plate.length,
      )}`;

      return maskedPlate;
    }

    return plate.replace('-', '');
  }

  formatVoltage(voltage = 0) {
    return voltage + ' V';
  }

  formatDistance(value = 0, measure = 'm') {
    const locale = number => {
      const float = parseFloat(number.toFixed(2));

      return float.toLocaleString('pt-BR');
    };

    if (measure === 'm') {
      return value > 1000 ? locale(value / 1000) + ' km' : locale(value) + ' m';
    } else if (measure === 'km') {
      return value > 1 ? locale(value) + ' km' : locale(value * 1000) + ' m';
    }
  }

  fuelConsumption(value = 0, measure = 'm') {
    const locale = number => {
      const int = parseInt(number);
      return int.toLocaleString('pt-BR');
    };

    if (measure === 'm') {
      return value > 1000
        ? locale(value / 1000) + ' km/l'
        : locale(value) + ' m/l';
    } else if (measure === 'km') {
      return value > 1
        ? locale(value) + ' km/l'
        : locale(value * 1000) + ' m/l';
    }
  }

  formatSpeed(value = 0, measure = 'm') {
    const locale = number => {
      const int = parseInt(number);
      return int.toLocaleString('pt-BR');
    };

    if (measure === 'm') {
      return value > 1000
        ? locale(value / 1000) + ' km/h'
        : locale(value) + ' m/h';
    } else if (measure === 'km') {
      return value > 1
        ? locale(value) + ' km/h'
        : locale(value * 1000) + ' m/h';
    }
  }

  formatVolume(value = 0, measure = 'l') {
    const locale = number => {
      const float = parseFloat(number.toFixed(2));
      return float.toLocaleString('pt-BR');
    };

    if (measure === 'ml') {
      return value > 1000 ? locale(value / 1000) + ' L' : locale(value) + ' ml';
    } else if (measure === 'l') {
      return value > 1 ? locale(value) + ' L' : locale(value * 1000) + ' ml';
    }
  }

  handleName(name = '') {
    if (!name) return '';

    let response = name;

    const removeSpaces = name.trim();
    const fragments = removeSpaces.split(' ');

    if (fragments.length === 1) {
      const firstChar = response[0];

      return `${firstChar?.toUpperCase()}${response?.slice(
        1,
        response?.length,
      )}`;
    }

    const fullName = [];

    fragments.forEach(fragment => {
      const firstLetter = fragment[0];

      if (!fragment) return;

      const value =
        firstLetter.toUpperCase() +
        fragment.slice(1, fragment.length).toLocaleLowerCase();

      fullName.push(value);
    });

    response = fullName.join(' ');

    return response;
  }

  formatPrice = (value = '', centsSize = 2) => {
    const formattedValue = value?.replaceAll(/[R$.]+/gm, '');
    const cents = formattedValue?.split(',');

    if (cents?.length > 0 && cents[1]?.length > centsSize) return;

    const realValue = formattedValue?.replace(',', '.');
    const price = parseFloat(realValue);

    return isNaN(price) ? 0 : price;
  };
}
