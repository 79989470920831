import styled from 'styled-components';
import tw from 'twin.macro';
import { Tooltip } from 'react-tooltip';

const Button = styled.button`
  ${tw`flex flex-wrap w-full items-center justify-center gap-2 px-4 py-2 relative
        box-border outline-0 rounded-lg shadow-md
        text-sm font-semibold leading-4
        transition-all not-disabled:hover:-translate-y-1
        disabled:cursor-not-allowed disabled:opacity-75`}

  height: ${({ $height }) => $height || 'fit-content'};
  max-width: ${({ $maxWidth }) => $maxWidth || 'fit-content'};
  color: ${({ $color }) => $color || '#fff'};
  background: ${({ $bg }) =>
    $bg === 'red'
      ? 'var(--red-theme)'
      : $bg === 'green'
        ? 'var(--green-theme)'
        : $bg
          ? $bg
          : 'var(--blue-gradient)'};
`;

const PrimaryButton = ({
  height = '',
  maxWidth = '',
  color = '',
  bg = '',
  tooltip = { text: '', place: '' },
  children,
  ...rest
}) => {
  return (
    <>
      <Button
        {...rest}
        $height={height}
        $maxWidth={maxWidth}
        $color={color}
        $bg={bg}
        data-tooltip-id={tooltip.text}
        data-tooltip-content={tooltip.text}>
        {children}
      </Button>
      <Tooltip id={tooltip.text} place={tooltip.place} />
    </>
  );
};

export default PrimaryButton;
