import {
  useLayoutEffect,
  useState,
  useCallback,
  memo,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import Textarea from 'components/ui-kit/Textarea';
import FileInput from 'components/ui-kit/FileInput';

import VehiclesInsert from './VehiclesInsert';
import CausesInsert from './CausesInsert';

import api from 'services/api';
import { getItem } from 'providers/storage';
import { BEARER, SUBROUTES } from 'utils/constants';
import { buildPayload } from 'utils/functions';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import { ReactComponent as CheckIcon } from 'assets/images/svg/check.svg';

const Form = styled.form`
  ${tw`flex flex-col w-full min-h-full h-full gap-6 p-2 items-center`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-col lg:flex-row h-fit w-full gap-4 items-end`}

  .col {
    ${tw`flex h-full w-full max-w-full lg:max-w-[50%]`}
  }
`;

export const StartServiceContainer = styled.div`
  ${tw`flex justify-center items-center absolute inset-0 bg-black bg-opacity-20 backdrop-blur-md rounded-lg w-full h-full overflow-hidden z-[15] p-2`}
`;

export const StartServiceCard = styled.div`
  ${tw`flex flex-col w-full max-w-xl h-fit items-center gap-4 bg-white rounded-xl px-2 py-4 shadow-xl
  text-disabled-dark text-center`}

  .title {
    ${tw`text-lg font-bold`}
  }

  .observation {
    ${tw`text-sm font-semibold`}
  }
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-wrap gap-4 w-full justify-around h-fit items-center`}
`;

const TypeContainer = styled.div`
  ${tw`flex flex-col gap-2 w-full items-center justify-center my-4 text-base sm:text-xl font-bold text-[var(--royal-blue-theme)]`}
`;

const TypeWrapper = styled.div`
  ${tw`flex flex-wrap gap-x-4 gap-y-2 w-full justify-center`}

  input {
    ${tw`hidden`}
  }

  label {
    ${tw`relative rounded-lg w-full max-w-[9rem] p-1 shadow-md ring-2 ring-[var(--gray-theme)] transition-all
    text-center text-base font-semibold text-[var(--gray-dark)]`}
  }

  input:checked + label,
  input:checked + label:active {
    ${tw`[background: var(--blue-gradient)] text-white ring-0`}
  }

  svg {
    ${tw`absolute z-[1] left-0 top-0 shrink-0 fill-white w-[1.5rem] h-[1.5rem] rounded-circle p-[2px] [background: var(--blue-gradient)] ring-2 ring-[var(--dark-blue-theme)] border-2 border-solid border-white translate-x-2 -translate-y-1`}
  }

  input:not(:disabled, :checked) + label {
    ${tw`hover:-translate-y-1 cursor-pointer`}
  }

  input:disabled + label {
    ${tw`cursor-not-allowed`}
  }
`;

const typesOccurrence = [
  { id: 1, name: 'Acidente' },
  { id: 2, name: 'Pane' },
  { id: 3, name: 'Vidro' },
];

const keyMapping = {
  typeId: 'category_id',
  vehicles: 'vehicles',
  causesIds: 'type_ids',
  description: 'description',
};

const getPayload = (values, data) => {
  const transformVehiclesData = data =>
    data['vehicles']?.map(vehicle => ({
      id: vehicle.id,
      locales:
        vehicle?.locations?.map(loc => ({
          ...(loc?.id && { id: loc.id }),
          from_city_id: loc?.origin?.cityId || null,
          to_city_id: loc?.destiny?.cityId || null,
        })) || [],
    }));

  let newData = { ...data };
  let newValues = { ...values };

  newData['vehicles'] = transformVehiclesData(newData);
  newValues['vehicles'] = transformVehiclesData(newValues);
  newData['causesIds'] = newData?.causes?.map(cause => cause.id);

  return buildPayload(keyMapping, newValues, newData);
};

const validationSchema = yup.object({
  vehicles: yup
    .array(yup.object().required())
    .min(1, 'Pelo menos um veículo deve ser adicionado'),
  causesIds: yup.array(yup.number().notRequired()),
  description: yup.string().notRequired(),
});

const BasicInfosForm = ({
  routingIsLoading = false,
  canCreate = false,
  canEdit = false,
  canDelete = false,
  data = {},
  occurrenceInfo = {},
  serviceHasChanged = false,
  syncData = async () => {},
  startService = async () => {},
  resetService = () => {},
  plateOptions = [],
  setPlateOptions = () => {},
  onDeleteOccurrence = () => {},
}) => {
  const [files, setFiles] = useState([]);

  const filesRef = useRef(null);
  const causesRef = useRef(null);

  const [causesIsLoading, setCausesIsLoading] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();

  const onSubmit = async values => {
    setIsLoading(true);

    const payload = getPayload(values, data);

    const newFiles = files.filter(file => !file?.id);
    const currentFileIds = files.reduce((ids, file) => {
      if (file?.id) {
        ids.push(file.id);
      }
      return ids;
    }, []);

    let allFileIds = [...currentFileIds];
    if (newFiles.length > 0) {
      const newFileIds = await filesRef.current?.sendFiles(newFiles);
      allFileIds = [...allFileIds, ...newFileIds];
    }

    const initialFileIds =
      data?.files?.map(file => ({
        id: file.id,
        name: file.name,
      })) || [];

    const removedFiles = initialFileIds.filter(
      file => !currentFileIds.includes(file.id),
    );

    if (removedFiles.length) {
      await filesRef.current?.deleteFiles(removedFiles);
    }

    const fileIdsChanged =
      JSON.stringify(data?.files?.map(file => file?.id).sort()) !==
      JSON.stringify(allFileIds.sort());

    if (fileIdsChanged) {
      payload['files_ids'] = allFileIds;
    }

    if (Object.keys(payload).length === 0) {
      toast.success('Ocorrência já salva');
      setIsLoading(false);
      return;
    }

    try {
      if (occurrenceInfo?.id) {
        const { status } = await api.put(
          `/occurrencies/${occurrenceInfo?.id}`,
          { data: payload },
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );
        if (status !== 200) throw new Error();

        toast.success('Ocorrência salva com sucesso');

        setTimeout(async () => {
          await syncData();
          setIsLoading(false);
          resetProgress();
        }, 1500);
      } else {
        const { data: response, status } = await api.post(
          `/occurrencies/`,
          { data: payload },
          {
            onProgress,
            headers: {
              Authorization: BEARER + user.token,
            },
          },
        );
        if (status !== 200) throw new Error();

        toast.success(
          `Ocorrência N°${response?.data?.nr_protocol} criada com sucesso`,
          { autoClose: 3000 },
        );

        setTimeout(() => {
          navigate(SUBROUTES.occurrences.path);

          setIsLoading(false);
          resetProgress();
        }, 3000);
      }
    } catch (error) {
      let errorMessage;
      if (occurrenceInfo?.id) {
        errorMessage = 'Falha ao editar Ocorrência';
      } else {
        errorMessage = 'Falha ao criar Ocorrência';
      }

      toast.error(errorMessage);

      setIsLoading(false);
      resetProgress();
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    setValues,
    setErrors,
    values,
    submitCount,
    errors,
  } = useFormik({
    initialValues: {
      typeId: 1,
      vehicles: [],
      causesIds: [],
      description: '',
    },
    onSubmit,
    validationSchema,
  });

  const onVehiclesAndLocationsChange = useCallback(
    values => {
      const vehicles = values?.map(
        vehicle =>
          ({
            id: vehicle.id,
            locations: vehicle?.locations?.map(loc => ({
              ...(loc?.id && { id: loc.id }),
              origin: { cityId: loc?.origin?.cityId },
              destiny: { cityId: loc?.destiny?.cityId },
            })),
          }) || [],
      );
      setFieldValue('vehicles', vehicles);

      const platesLog =
        values?.map(item => ({
          value: item?.id,
          label: item?.plate,
          associateId: item?.associateId,
          associateName: item?.associateName,
          locations: item?.locations,
        })) || [];

      if (JSON.stringify(platesLog) !== JSON.stringify(plateOptions)) {
        setPlateOptions(platesLog);
      }
    },
    [plateOptions],
  );

  const onChangingCauses = useCallback(causesIds => {
    setFieldValue('causesIds', causesIds);
  }, []);

  useLayoutEffect(() => {
    if (Object.keys(data).length === 0) return;

    const vehicles = data?.vehicles?.map(
      vehicle =>
        ({
          id: vehicle.id,
          locations: vehicle?.locations?.map(loc => ({
            ...(loc?.id && { id: loc.id }),
            origin: { cityId: loc?.origin?.cityId },
            destiny: { cityId: loc?.destiny?.cityId },
          })),
        }) || [],
    );

    const causesIds = data?.causes?.map(type => type.id) || [];

    setValues(prev => ({
      ...prev,
      typeId: data?.typeId,
      vehicles,
      causesIds,
      description: data?.description,
    }));

    setErrors({});

    setFiles(data?.files);
  }, [data]);

  useEffect(() => {
    if (submitCount > 0 && errors?.vehicles) {
      toast.error(errors.vehicles);
    }
  }, [submitCount, errors]);

  useEffect(() => {
    if (!Object.keys(data).length) return;

    if (isMounted && !getItem('serviceId')) {
      const payload = getPayload(values, data);

      if (Object.keys(payload).length > 0) startService();

      if (data?.files && JSON.stringify(files) !== JSON.stringify(data?.files))
        startService();
    }
    if (!isMounted) setIsMounted(true);
  }, [values, files]);

  return (
    <Form onSubmit={handleSubmit}>
      <LoadingScreen isLoading={isLoading} progress={progress} />

      {serviceHasChanged && occurrenceInfo?.isBeingServedBy?.id ? (
        <StartServiceContainer>
          <StartServiceCard>
            <span className="title">Atenção!</span>
            <span className="observation">
              Esta ocorrência está em atendimento por
              <span style={{ fontWeight: 700 }}>
                {`: ${occurrenceInfo.isBeingServedBy.name}.`}
              </span>
              {canEdit && (
                <>
                  <br />
                  Se você assumir, todas as informações preenchidas
                  anteriormente serão descartadas.
                  <br />
                  Você deseja prosseguir e assumir o atendimento?
                </>
              )}
            </span>
            {canEdit && (
              <PrimaryButton
                type="button"
                onClick={async () => {
                  await syncData().then(async () => await startService());
                }}>
                Sim, assumir atendimento
              </PrimaryButton>
            )}
          </StartServiceCard>
        </StartServiceContainer>
      ) : (
        serviceHasChanged && (
          <StartServiceContainer>
            <StartServiceCard>
              <span className="title">Atenção!</span>
              <span className="observation">
                O atendimento desta ocorrência foi iniciado por outro usuário.
                <br />
                Você deseja sincronizar as modificações feitas?
              </span>
              <PrimaryButton
                type="button"
                onClick={async () =>
                  await syncData().then(() => resetService())
                }>
                Sim, sincronizar alterações
              </PrimaryButton>
            </StartServiceCard>
          </StartServiceContainer>
        )
      )}

      <TypeContainer>
        Tipo de Ocorrência:
        <TypeWrapper>
          {typesOccurrence.map((item, index) => (
            <Fragment key={index}>
              <input
                type="radio"
                id={item.id}
                checked={values.typeId === item.id}
                onChange={() => {
                  setFieldValue('typeId', item.id);
                  causesRef.current?.clearSelectedCauses();
                }}
                disabled={
                  (canCreate && occurrenceInfo?.id) ||
                  (!canEdit && occurrenceInfo?.id) ||
                  serviceHasChanged ||
                  causesIsLoading
                }
              />

              <label htmlFor={item.id}>
                {item.name}
                {values.typeId === item.id && <CheckIcon />}
              </label>
            </Fragment>
          ))}
        </TypeWrapper>
      </TypeContainer>

      <VehiclesInsert
        typeId={values.typeId}
        vehiclesInserted={data?.vehicles}
        onVehiclesAndLocationsChange={onVehiclesAndLocationsChange}
        isDisabled={
          (canCreate && occurrenceInfo?.id) ||
          (!canEdit && occurrenceInfo?.id) ||
          serviceHasChanged
        }
        error={submitCount > 0 && errors.vehicles}
        serviceHasChanged={serviceHasChanged}
      />

      <CausesInsert
        ref={causesRef}
        causesInserted={data?.causes}
        typeId={values.typeId}
        causesIsLoading={setCausesIsLoading}
        onChangingCauses={onChangingCauses}
        isDisabled={
          (canCreate && occurrenceInfo?.id) ||
          (!canEdit && occurrenceInfo?.id) ||
          serviceHasChanged
        }
      />

      <TitleBox title={{ text: 'Detalhes' }}>
        <Wrapper>
          <div className="col">
            <Textarea
              id="description"
              placeholder="Descrição da Ocorrência"
              value={values.description}
              onChange={handleChange}
              style={{ height: '150px' }}
              disabled={
                (canCreate && occurrenceInfo?.id) ||
                (!canEdit && occurrenceInfo?.id) ||
                serviceHasChanged
              }
            />
          </div>
          <div className="col">
            <FileInput
              ref={filesRef}
              text={{
                title:
                  !canEdit && occurrenceInfo?.id
                    ? 'Arquivos da Ocorrência'
                    : 'Clique ou arraste arquivos da Ocorrência aqui',
                notFound: 'Nenhum arquivo adicionado',
              }}
              height="150px"
              fileType=".xlsx, .xls, image/*, video/*, .doc, .docx, .txt, .pdf"
              files={files}
              setFiles={setFiles}
              onProgress={onProgress}
              isDisabled={
                (canCreate && occurrenceInfo?.id) ||
                (!canEdit && occurrenceInfo?.id) ||
                serviceHasChanged
              }
            />
          </div>
        </Wrapper>
      </TitleBox>

      {(canEdit || canCreate || canDelete) && (
        <ButtonsContainer>
          {occurrenceInfo?.id ? (
            <>
              {canDelete && (
                <PrimaryButton
                  type="button"
                  maxWidth="300px"
                  bg="red"
                  onClick={onDeleteOccurrence}
                  disabled={routingIsLoading}>
                  Excluir Ocorrência
                </PrimaryButton>
              )}
              {canEdit && (
                <PrimaryButton
                  type="submit"
                  maxWidth="300px"
                  bg="green"
                  disabled={routingIsLoading || isLoading}>
                  Salvar informações
                </PrimaryButton>
              )}
            </>
          ) : (
            canCreate && (
              <PrimaryButton
                type="submit"
                maxWidth="300px"
                bg="green"
                disabled={routingIsLoading || isLoading}>
                Criar Ocorrência
              </PrimaryButton>
            )
          )}
        </ButtonsContainer>
      )}
    </Form>
  );
};

export default memo(BasicInfosForm);
