import { Navigate } from 'react-router-dom';

import ListPaymentMethods from 'pages/Main/pages/Register/PaymentMethods/List';
import FormPaymentMethods from 'pages/Main/pages/Register/PaymentMethods/Form';

import ListModules from 'pages/Main/pages/Register/Modules/List';
import FormModules from 'pages/Main/pages/Register/Modules/Form';

import ListPermissions from 'pages/Main/pages/Register/Permissions/List';
import FormPermissions from 'pages/Main/pages/Register/Permissions/Form';

import ListServicesProviders from 'pages/Main/pages/Register/ServicesProviders/List';
import FormServicesProviders from 'pages/Main/pages/Register/ServicesProviders/Form';

import ListCauses from 'pages/Main/pages/Register/Causes/List';
import FormCauses from 'pages/Main/pages/Register/Causes/Form';

import ListTypesServicesProvided from 'pages/Main/pages/Register/TypesServicesProvided/List';
import FormTypesServicesProvided from 'pages/Main/pages/Register/TypesServicesProvided/Form';

import ListUsers from 'pages/Main/pages/Register/Users/List';
import FormUsers from 'pages/Main/pages/Register/Users/Form';

import PrivateRoute from 'routes/PrivateRoute';

import { SUBROUTES, MODULES, VISUALIZE, CREATE } from 'utils/constants';

const { path, subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const RegisterRoutes = [
  {
    path: subroutes.causes.path,
    element: (
      <PrivateRoute module={main} submodule={sub.causes} permission={VISUALIZE}>
        <ListCauses />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.causes.subroutes.new.path,
    element: (
      <PrivateRoute module={main} submodule={sub.causes} permission={CREATE}>
        <FormCauses />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.causes.subroutes.id.path,
    element: (
      <PrivateRoute module={main} submodule={sub.causes} permission={VISUALIZE}>
        <FormCauses />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.paymentMethods.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.paymentMethods}
        permission={VISUALIZE}>
        <ListPaymentMethods />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.paymentMethods.subroutes.new.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.paymentMethods}
        permission={CREATE}>
        <FormPaymentMethods />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.paymentMethods.subroutes.id.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.paymentMethods}
        permission={VISUALIZE}>
        <FormPaymentMethods />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.modules.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.modules}
        permission={VISUALIZE}>
        <ListModules />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.modules.subroutes.new.path,
    element: (
      <PrivateRoute module={main} submodule={sub.modules} permission={CREATE}>
        <FormModules />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.modules.subroutes.id.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.modules}
        permission={VISUALIZE}>
        <FormModules />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.permissions.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.permissions}
        permission={VISUALIZE}>
        <ListPermissions />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.permissions.subroutes.new.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.permissions}
        permission={CREATE}>
        <FormPermissions />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.permissions.subroutes.id.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.permissions}
        permission={VISUALIZE}>
        <FormPermissions />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.servicesProviders.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.servicesProviders}
        permission={VISUALIZE}>
        <ListServicesProviders />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.servicesProviders.subroutes.new.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.servicesProviders}
        permission={CREATE}>
        <FormServicesProviders />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.servicesProviders.subroutes.id.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.servicesProviders}
        permission={VISUALIZE}>
        <FormServicesProviders />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.typesServicesProvided.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.typesServicesProvided}
        permission={VISUALIZE}>
        <ListTypesServicesProvided />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.typesServicesProvided.subroutes.new.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.typesServicesProvided}
        permission={CREATE}>
        <FormTypesServicesProvided />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.typesServicesProvided.subroutes.id.path,
    element: (
      <PrivateRoute
        module={main}
        submodule={sub.typesServicesProvided}
        permission={VISUALIZE}>
        <FormTypesServicesProvided />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.users.path,
    element: (
      <PrivateRoute module={main} submodule={sub.users} permission={VISUALIZE}>
        <ListUsers />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.users.subroutes.new.path,
    element: (
      <PrivateRoute module={main} submodule={sub.users} permission={CREATE}>
        <FormUsers />
      </PrivateRoute>
    ),
  },
  {
    path: subroutes.users.subroutes.id.path,
    element: (
      <PrivateRoute module={main} submodule={sub.users} permission={VISUALIZE}>
        <FormUsers />
      </PrivateRoute>
    ),
  },
  {
    path,
    element: <Navigate to={subroutes.servicesProviders.path} replace />,
  },
];

export default RegisterRoutes;
