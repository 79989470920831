import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import tw from 'twin.macro';
import {
  isValid,
  isSameDay,
  format,
  addDays,
  subDays,
  isSameHour,
} from 'date-fns';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';

import Calendar from 'react-calendar';
import Input from 'components/ui-kit/Input';

import { ReactComponent as TrashIcon } from 'assets/images/svg/trash.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/svg/arrow_down.svg';

import { breakpoints } from 'helpers/breakpoints';
import 'react-calendar/dist/Calendar.css';

const flipAnimation = keyframes`
  0% {
    transform: perspective(400px) rotateX(-90deg);
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotateX(0deg);
  }
  100% {
    opacity: 1;
  }
`;

const iconStyle = {
  width: '48px',
  height: '100%',
  padding: '0px 16px',
};

const Wrapper = styled.div`
  ${tw`relative w-full flex`}

  max-width: ${props => props.$maxWidth};

  .icons {
    ${tw`flex flex-1 items-center shrink-0 w-fit z-10 absolute cursor-pointer m-auto right-0`}
    height: ${props => (props.$error ? 'calc(100% - 17px)' : '100%')};
  }
`;

const Container = styled.div`
  ${tw`flex flex-col h-fit items-center bg-white border border-themes-dark-gray 
  shadow-lg rounded-xl box-border mt-8 sm:ml-1 absolute z-10`}
  animation: ${flipAnimation} 0.5s ease-in-out forwards;
`;

const TimeBox = styled(TimePicker)`
  ${tw`items-center justify-center bg-gray-400 rounded-md box-border py-1 px-2`}

  & .react-time-picker__wrapper {
    ${tw`border-0`}
  }

  & .react-time-picker__inputGroup {
    ${tw`min-w-fit`}
  }

  & .react-time-picker__inputGroup__input {
    ${tw`outline-none`}
  }
`;

const Text = styled.p`
  ${tw`text-base font-semibold text-basic-black m-0 p-0`}
`;

const CalendarBox = styled.div`
  ${tw`w-full sm:w-fit h-fit`}

  @media (max-width: ${breakpoints.lg}px) {
    ${tw`justify-center items-center w-full`}
  }

  & .date-picker {
    ${tw`h-full border rounded-lg border-themes-dark-gray overflow-hidden`}
  }

  & .react-calendar__navigation__label {
    ${tw`font-bold text-white`}

    &:hover {
      color: var(--royal-blue-theme);
    }
  }

  & .react-calendar__tile--active {
    background-color: var(--royal-blue-theme);

    ${tw`text-white`}
  }

  & .react-calendar__tile--active:enabled:focus {
    background-color: var(--royal-blue-theme);

    ${tw`text-white`}
  }

  & .react-calendar__navigation {
    background-color: var(--royal-blue-theme);

    ${tw`bg-opacity-70`}
  }
`;

const formatTime = time => time.toString().padStart(2, '0');

const DEFAULT_TIME = '00:00';

const DateInput = ({
  blockFuture = false, // Se verdadeiro, bloqueia a seleção de datas e horários futuros.
  minDate, // define a data minima a ser alcançada
  maxDate, // Define o intervalo máximo de dias a partir da data atual que pode ser selecionado.
  disableTimes = false, // Se verdadeiro, desabilita a seleção de horário.
  maxWidth = '100%', // Define a largura máxima do input.
  label = {
    text: '',
    background: '',
    marginTop: '',
    translateY: '',
  }, // Define o texto do label do input.
  initialValue, // Define o valor inicial do input.
  isDisabled = false, // Se verdadeiro, desabilita o input.
  placeholder = '',
  closeAfterPick = false, // fechar calendario apos selecionar data
  isClearable = false, // habilitar o botão de limpar
  error = {
    isActive: false,
    message: '',
    class: '',
  }, // habilitar o efeito de erro
  getDates = () => {}, // Função para obter a data selecionada.
}) => {
  const { currentDate, currentTime } = useMemo(() => {
    const response = {
      currentDate: undefined,
      currentTime: DEFAULT_TIME,
    };

    if (isValid(initialValue)) {
      const initialDateTime = new Date(initialValue);
      response.currentDate = initialDateTime;
      const hours = initialDateTime.getHours();
      const minutes = initialDateTime.getMinutes();
      response.currentTime = `${formatTime(hours)}:${formatTime(minutes)}`;
    }

    return response;
  }, [initialValue]);

  const calendarRef = useRef();
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(currentDate);
  const [time, setTime] = useState(currentTime);

  const handleTimeChange = useCallback(
    selectedTime => {
      const currentTime = new Date();
      if (blockFuture && isSameDay(date, currentTime)) {
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const [selectedHours, selectedMinutes] = (selectedTime || DEFAULT_TIME)
          .split(':')
          .map(Number);

        if (
          selectedHours > currentHours ||
          (isSameHour(selectedHours, currentHours) &&
            selectedMinutes > currentMinutes)
        ) {
          return;
        }
      }

      setTime(selectedTime);
    },
    [blockFuture, date],
  );

  const onPickDate = useCallback(
    value => {
      setDate(value);

      if (isSameDay(value, new Date()) && blockFuture) {
        const currentTime = new Date();
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        setTime(`${formatTime(currentHours)}:${formatTime(currentMinutes)}`);
      }

      if (closeAfterPick) setShowCalendar(false);
    },
    [blockFuture, closeAfterPick],
  );

  const onClickTrash = useCallback(() => {
    setShowCalendar(false);
    setDate();
    setTime(DEFAULT_TIME);
    getDates();
  }, []);

  const inputValue = useMemo(() => {
    if (!isValid(date)) return;

    let value = format(date, 'dd/MM/yyyy');

    if (!disableTimes) {
      const [hours, minutes] = (time || DEFAULT_TIME).split(':').map(Number);
      const timeDate = new Date();
      timeDate.setHours(hours, minutes);

      const formattedTime = format(timeDate, 'HH:mm');

      value += ` ${formattedTime}`;
    }

    return value;
  }, [date, time]);

  useEffect(() => {
    if (!isValid(date)) return;

    const selectedDate = new Date(date);

    const timeValue = time || DEFAULT_TIME;
    const [hours, minutes] = timeValue.split(':').map(Number);
    selectedDate.setHours(hours, minutes);

    getDates(selectedDate);
  }, [date, time]);

  useEffect(() => {
    const onClickOutside = event => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener('mousedown', onClickOutside);
    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);

  return (
    <Wrapper $maxWidth={maxWidth} ref={calendarRef} $error={error.isActive}>
      <Input
        readOnly
        placeholder={placeholder}
        label={label}
        value={inputValue}
        disabled={isDisabled}
        maxWidth={maxWidth}
        onClick={() => setShowCalendar(true)}
        style={{
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        }}
        onFocus={() => {
          if (!isDisabled) setShowCalendar(true);
        }}
        error={error}
      />

      <div className="icons">
        {isClearable && inputValue && !isDisabled && (
          <TrashIcon
            fill="var(--red-theme)"
            style={iconStyle}
            onClick={onClickTrash}
          />
        )}

        <ArrowIcon
          fill={showCalendar ? 'var(--royal-blue-theme)' : '#ccc'}
          style={{
            ...iconStyle,
            padding: '0px 14px',
            transform: showCalendar ? 'rotate(180deg)' : '',
            transition: 'transform 0.3s',
          }}
          onClick={() => setShowCalendar(!showCalendar)}
        />
      </div>

      {showCalendar && (
        <Container>
          <CalendarBox>
            <Calendar
              className="date-picker"
              tileClassName="date-picker-tile"
              locale="pt-BR"
              onChange={onPickDate}
              value={date}
              minDate={subDays(new Date(), minDate)}
              maxDate={blockFuture ? new Date() : addDays(new Date(), maxDate)}
            />
          </CalendarBox>

          {!disableTimes && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5em',
                width: 'fit-content',
                alignItems: 'center',
                margin: '0.5em',
              }}>
              <Text>Horário:</Text>
              <TimeBox
                format="HH:mm"
                onChange={handleTimeChange}
                value={time}
                maxTime={
                  blockFuture && isSameDay(date, new Date())
                    ? `${formatTime(new Date().getHours())}:${formatTime(
                        new Date().getMinutes(),
                      )}`
                    : undefined
                }
                disableClock={true}
                clearIcon={null}
                required={true}
              />
            </div>
          )}
        </Container>
      )}
    </Wrapper>
  );
};
export default DateInput;
