import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import tw from 'twin.macro';

const Wrapper = styled.div`
  ${tw`flex justify-center items-center cursor-wait`}
  ${({ $center }) => $center && tw`m-auto`};
  ${({ $full }) => $full && tw`h-full w-full`};
`;

const Loader = ({ center = false, full = false, ...rest }) => {
  return (
    <Wrapper $center={center} $full={full} {...rest}>
      <Oval
        width={80}
        height={80}
        color="var(--basic-primary)"
        secondaryColor="var(--gray-dark)"
      />
    </Wrapper>
  );
};

export default Loader;
