import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef,
} from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';

import PageContainer from 'components/ui-kit/PageContainer';
import PageHeader from 'components/ui-kit/PageHeader';
import Input from 'components/ui-kit/Input';
import Select from 'components/ui-kit/Select';
import PrimaryButton from 'components/ui-kit/PrimaryButton';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import TitleBox from 'components/ui-kit/TitleBox';

import api from 'services/api';
import { BEARER, SUBROUTES, MODULES, UPDATE, CREATE } from 'utils/constants';
import { usernameValidation, passwordValidation } from 'utils/regex';
import { buildPayload, hasPermission } from 'utils/functions';

import {
  BASIC_INFOS,
  FIELD_IS_REQUIRED,
  ALREADY_SAVED_INFORMATION,
  INVALID_EMAIL,
  ERROR_USER,
  SUCCESS_USER,
  ERROR_ALREADY_EXISTS,
} from 'utils/messages';

import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

const { subroutes } = SUBROUTES.register;
const { main, sub } = MODULES.register;

const Container = styled.form`
  ${tw`h-fit w-full rounded-2xl bg-white px-2 py-4`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center items-start py-2 gap-5`}
`;

const validationSchema = yup.object({
  name: yup.string().required(FIELD_IS_REQUIRED('Nome')),
  username: yup
    .string()
    .matches(
      usernameValidation,
      'Utilize apenas letras, números ou ponto para o username',
    )
    .required(FIELD_IS_REQUIRED('Nome de Usuário')),
  email: yup
    .string()
    .email(INVALID_EMAIL)
    .required(FIELD_IS_REQUIRED('E-mail')),
  password: yup
    .string()
    .matches(
      passwordValidation,
      'A senha deve ter no mínimo 8 caracteres, 1 caractere especial, 1 letra minúscula e 1 letra maiúscula.',
    )
    .when('id', (id, schema) => {
      return !id ? schema.required(FIELD_IS_REQUIRED('Senha')) : schema;
    }),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Senhas não coincidem.')
    .when('id', (id, schema) => {
      return !id
        ? schema.required(FIELD_IS_REQUIRED('Confirmação de Senha'))
        : schema;
    }),
  permissionId: yup.string().required(FIELD_IS_REQUIRED('Tipo de Usuário')),
});

const Form = () => {
  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const permissionSelectRef = useRef(null);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const canEdit = hasPermission(user?.role, main, sub.users, UPDATE);
  const canCreate = hasPermission(user?.role, main, sub.users, CREATE);

  const getUser = useCallback(
    async userId => {
      setIsLoading(true);
      setData({});

      try {
        const { data: response, status } = await api.get(`users/${userId}`, {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        });

        if (status !== 200) throw new Error();

        const {
          id,
          display_name: name,
          username,
          email,
          role: { id: permissionId, display_name: permissionName } = {},
        } = response?.data || {};

        setValues({
          id,
          name,
          username,
          email,
          permissionId,
        });
        setData({
          id,
          name,
          username,
          email,
          permissionId,
        });

        if (!!permissionId && !!permissionName) {
          permissionSelectRef.current?.setValue({
            value: permissionId,
            label: permissionName,
          });
        }

        resetProgress();
        setIsLoading(false);
      } catch {
        toast.error(ERROR_USER('buscar'));

        setTimeout(() => {
          setIsLoading(false);
          resetProgress();
          navigate(subroutes.users.path, {
            state: { currentPage: state?.currentPage },
          });
        }, 3500);
      }
    },
    [permissionSelectRef],
  );

  const onSubmit = useCallback(
    async values => {
      setIsLoading(true);

      const keyMapping = {
        name: 'display_name',
        username: 'username',
        email: 'email',
        permissionId: 'role_id',
        ...(!data.id ? { password: 'password' } : {}),
      };

      const payload = buildPayload(keyMapping, values, data);

      if (Object.keys(payload).length === 0) {
        toast.success(ALREADY_SAVED_INFORMATION);
        setIsLoading(false);
        return;
      }

      try {
        const { status } =
          Object.keys(data).length > 0
            ? await api.put(
                `users/${data.id}`,
                { data: payload },
                {
                  headers: {
                    Authorization: BEARER + user.token,
                  },
                },
              )
            : await api.post(
                'users/',
                { data: payload },
                {
                  headers: {
                    Authorization: BEARER + user.token,
                  },
                },
              );

        if (status !== 200 && status !== 201) throw new Error();

        let successMessage =
          Object.keys(data).length > 0
            ? SUCCESS_USER('editado')
            : SUCCESS_USER('criado');

        toast.success(successMessage);

        setTimeout(() => {
          navigate(subroutes.users.path, {
            state: { currentPage: state?.currentPage },
          });
          resetProgress();
          setIsLoading(false);
        }, [2000]);
      } catch (error) {
        let errorMessage;
        switch (error.response?.status) {
          case 409:
            errorMessage = ERROR_ALREADY_EXISTS;
            break;
          default:
            errorMessage =
              Object.keys(data).length > 0
                ? ERROR_USER('editar')
                : ERROR_USER('criar');
        }
        toast.error(errorMessage);
        resetProgress();
        setIsLoading(false);
      }
    },
    [data, state],
  );

  const {
    handleSubmit,
    setValues,
    setFieldValue,
    handleChange,
    submitCount,
    touched,
    values,
    errors,
  } = useFormik({
    initialValues: {
      name: '',
      username: '',
      email: '',
      permissionId: undefined,
      password: '',
      confirmPassword: '',
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (errors.name && submitCount > 0) {
      toast.error(errors.name);
    }
  }, [submitCount]);

  useLayoutEffect(() => {
    if (id === undefined) return;

    const fetchUser = async () => {
      await getUser(id);
    };
    fetchUser();

    return () => {
      toast.dismiss();
    };
  }, [id]);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={[
          'Cadastros',
          'Usuários',
          data?.name ? `Editando: ${data.name}` : 'Novo Usuário',
        ]}
        addRedirects={[
          {
            title: 'Voltar',
            disabled: isLoading,
            path: subroutes.users.path,
            state: { currentPage: state?.currentPage },
          },
        ]}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Container onSubmit={handleSubmit}>
        <TitleBox
          title={{ text: BASIC_INFOS }}
          error={
            submitCount > 0 &&
            (errors.name ||
              errors.username ||
              errors.email ||
              errors.password ||
              errors.confirmPassword ||
              errors.permissionId)
          }
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
          }}>
          <Wrapper>
            <Input
              isRequired
              id="name"
              maxWidth="300px"
              value={values.name}
              maxLength={150}
              onChange={handleChange}
              placeholder=""
              label={{
                text: 'Nome completo',
              }}
              error={{
                isActive: submitCount > 0 && errors.name,
                message: errors.name,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
            <Input
              isRequired
              id="username"
              maxWidth="300px"
              value={values.username}
              maxLength={50}
              onChange={e => {
                if (!e.target.value.match(usernameValidation)) return;

                setFieldValue('username', e.target.value);
              }}
              placeholder=""
              label={{
                text: 'Username',
              }}
              error={{
                isActive: submitCount > 0 && errors.username,
                message: errors.username,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
            <Input
              isRequired
              id="email"
              maxWidth="300px"
              value={values.email}
              maxLength={254}
              onChange={e => {
                if (!e.target.value.includes(' '))
                  setFieldValue('email', e.target.value);
              }}
              placeholder=""
              label={{
                text: 'E-mail',
              }}
              error={{
                isActive: submitCount > 0 && errors.email,
                message: errors.email,
              }}
              disabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
            <Select
              isRequired
              id="permissionId"
              ref={permissionSelectRef}
              size="sm"
              maxWidth="300px"
              label={{ text: 'Permissão' }}
              placeholder=""
              endpoint="roles/"
              fieldToOptionValue="id"
              fieldToOptionLabel="display_name"
              onOptionChange={({ value }) => {
                setFieldValue('permissionId', value);
              }}
              error={{
                isActive: errors.permissionId && touched.permissionId,
                message: errors.permissionId,
              }}
              isDisabled={
                (Object.keys(data).length && !canEdit) ||
                (!Object.keys(data).length && !canCreate)
              }
            />
            {!Object.keys(data).length && (
              <>
                <Input
                  isPassword
                  isRequired
                  id="password"
                  maxWidth="300px"
                  autoComplete="on"
                  value={values.password}
                  maxLength={128}
                  onChange={e => {
                    if (!e.target.value.includes(' '))
                      setFieldValue('password', e.target.value);
                  }}
                  placeholder=""
                  label={{
                    text: 'Senha',
                  }}
                  error={{
                    isActive: submitCount > 0 && errors.password,
                    message: errors.password,
                  }}
                  disabled={!Object.keys(data).length && !canCreate}
                />
                <Input
                  isPassword
                  isRequired
                  id="confirmPassword"
                  maxWidth="300px"
                  autoComplete="on"
                  value={values.confirmPassword}
                  maxLength={128}
                  onChange={e => {
                    if (!e.target.value.includes(' '))
                      setFieldValue('confirmPassword', e.target.value);
                  }}
                  placeholder=""
                  label={{
                    text: 'Confirmar Senha',
                  }}
                  error={{
                    isActive: submitCount > 0 && errors.confirmPassword,
                    message: errors.confirmPassword,
                  }}
                  disabled={!Object.keys(data).length && !canCreate}
                />
              </>
            )}
          </Wrapper>

          {((Object.keys(data).length && canEdit) ||
            (!Object.keys(data).length && canCreate)) && (
            <PrimaryButton
              type="submit"
              maxWidth="300px"
              bg="green"
              disabled={isLoading}>
              Salvar
            </PrimaryButton>
          )}
        </TitleBox>
      </Container>
    </PageContainer>
  );
};

export default Form;
