import { memo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { FixedSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const Container = styled.div`
  ${tw`flex w-full h-full`}
`;

const FixedGrid = ({
  children,
  items = [],
  columnCount = 3,
  columnWidth = 100,
  rowCount = 3,
  rowHeight = 100,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <AutoSizer>
        {({ width, height }) => {
          return (
            <FixedSizeGrid
              width={width}
              height={height}
              itemData={items}
              columnCount={columnCount}
              columnWidth={columnWidth}
              rowCount={rowCount}
              rowHeight={rowHeight}
              className="fixed-grid">
              {children}
            </FixedSizeGrid>
          );
        }}
      </AutoSizer>
    </Container>
  );
};

export default memo(FixedGrid);
