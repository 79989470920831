import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'context/Auth';
import { Provider } from 'react-redux';
import { ModalProvider } from 'styled-react-modal';
import { ToastContainer } from 'react-toastify';
import { ModalBackground } from 'components/ui-kit/DefaultModal';

import Router from 'routes';
import store from './store';

import 'react-tooltip/dist/react-tooltip.css';

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <ModalProvider backgroundComponent={ModalBackground}>
            <Router />
            <ToastContainer
              closeButton
              closeOnClick
              autoClose={2000}
              rtl={false}
              draggable={false}
              newestOnTop={false}
              pauseOnHover={false}
              hideProgressBar={false}
              pauseOnFocusLoss={false}
              limit={3}
              theme="light"
              position="bottom-right"
            />
          </ModalProvider>
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
