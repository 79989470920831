import { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { subMonths, startOfMonth, endOfMonth, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { IMaskInput } from 'react-imask';

import { getInitialDates, getBackendDateFormat } from 'utils/functions';

import PageHeader from 'components/ui-kit/PageHeader';
import PageContainer from 'components/ui-kit/PageContainer';
import FilterTool from 'components/ui-kit/FilterTool';
import LoadingScreen from 'components/ui-kit/LoadingScreen';

import CarouselCards from 'components/pages/BI/Budgets/CarouselCards';
import ChartsContainer from 'components/pages/BI/Budgets/ChartsContainer';

import api from 'services/api';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';
import Formatter from 'utils/formatter';
import { fadeIn } from 'helpers/animations';
import { BEARER } from 'utils/constants';
import { STANDARD_ERROR, NO_DATA_FOUND } from 'utils/messages';

const Container = styled.div`
  ${tw`flex flex-col w-full h-fit gap-6`}
  animation: ${fadeIn} 200ms ease-in;
`;

const Budgets = () => {
  const [budgetsData, setBudgetsData] = useState({});

  const [numProtocolInput, setNumProtocolInput] = useState();
  const [associateId, setAssociateId] = useState();
  const [vehicleId, setVehicleId] = useState();
  const [budgetTypeId, setBudgetTypeId] = useState();
  const [paymentStatusId, setPaymentStatusId] = useState(3);

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSeletRef = useRef(null);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();
  const { formatToBRL } = new Formatter();

  const inputs = [
    {
      onAccept: value => setNumProtocolInput(value.toUpperCase()),
      as: IMaskInput,
      value: numProtocolInput,
      mask: 'a-0000000',
      placeholder: 'N° Protocolo',
      size: 'sm',
      height: '33px',
      maxWidth: '180px',
      $variant: 'registry',
    },
    {
      type: 'select',
      maxWidth: '300px',
      endpoint: 'clients/all?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Associado',
      onOptionChange: option => {
        if (vehicleId) vehicleSeletRef.current?.clearValue();

        setAssociateId(option.value);
      },
    },
    {
      ref: vehicleSeletRef,
      type: 'select',
      maxWidth: '220px',
      isDisabled: !associateId,
      endpoint: `vehicles/?client_id=${associateId}`,
      fieldToOptionLabel: 'plate',
      fieldToOptionValue: 'id',
      placeholder: 'Selecione uma Placa',
      onOptionChange: option => setVehicleId(option.value),
    },
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: 'occurrencies/budgets/types',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Tipo de Orçamento',
      onOptionChange: option => setBudgetTypeId(option?.value),
    },
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: 'payments/status/',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Status do pagamento',
      onOptionChange: option => setPaymentStatusId(option?.value),
      initialValue: {
        label: 'Pago',
        value: 3,
      },
    },
  ];

  const getBudgets = useCallback(async () => {
    setIsLoading(true);

    setBudgetsData({});

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get('bi/budgets', {
        onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          nr_protocol: formatParam(numProtocolInput),
          associate_id: associateId,
          vehicle_id: vehicleId,
          types_id: budgetTypeId,
          status_payment_id: paymentStatusId,
          created_on_from: dates?.startDate,
          created_on_to: dates?.endDate,
        },
      });

      if (status !== 200) throw new Error();

      const { graphs: monthlyData, metrics: cards } = response.data;

      const formatDate = item =>
        format(new Date(item.year, item.month - 1), 'MMMM yyyy', {
          locale: ptBR,
        });

      const mapMonthlyData = (item, valueKey) => ({
        date: formatDate(item),
        value: item[valueKey] || 0,
      });

      const occurrencesLog = {
        graphData: {
          monthlyBudgets:
            monthlyData?.map(item => {
              const amountbBudgetsMonth = item.costs_budgets.reduce(
                (sum, occurrence) => sum + occurrence.cost,
                0,
              );
              return {
                date: formatDate(item),
                total: amountbBudgetsMonth,
                budgetsMonth: item.costs_budgets.map(budget => ({
                  name: budget?.name || '-',
                  value: budget?.cost || 0,
                })),
              };
            }) || [],
          budgetedValue:
            monthlyData?.map(item => mapMonthlyData(item, 'cost_budget')) || [],
          closedValue:
            monthlyData?.map(item => mapMonthlyData(item, 'cost_closed')) || [],
          excessPaid:
            monthlyData?.map(item => mapMonthlyData(item, 'cost_excess')) || [],
          associationPaid:
            monthlyData?.map(item => mapMonthlyData(item, 'cost_covered')) ||
            [],
        },

        cardData: [
          {
            title: 'N° de Orçamentos',
            description: 'Total de Orçamentos criados no período',
            value: `Qtd: ${(cards?.amount_budgets || 0).toLocaleString('pt-br')}`,
          },

          {
            title: 'Maior Orçamento',
            description: 'Orçamento mais frequente no período',
            detailsValues: cards?.most_type?.name || '-',
            value: `Qtd: ${(cards?.most_type?.amount || 0).toLocaleString(
              'pt-br',
            )}`,
          },
          {
            title: 'Menor Orçamento',
            description: 'Orçamento menos frequente no período',
            detailsValues: cards?.least_type?.name || '-',
            value: `Qtd: ${(cards?.least_type?.amount || 0).toLocaleString(
              'pt-br',
            )}`,
          },
          {
            title: 'Valor Orçado',
            description: 'Valor total Orçado no período',
            value: formatToBRL(cards?.cost_budget || 0),
          },
          {
            title: 'Valor Fechado',
            description: 'Valor total Fechado no período',
            value: formatToBRL(cards?.cost_closed || 0),
          },
          {
            title: 'Pago Excedente',
            description: 'Valor total Pago Excedente no período',
            value: formatToBRL(cards?.cost_excess || 0),
          },
          {
            title: 'Pago Associação',
            description: 'Valor total Pago Associação no período',
            value: formatToBRL(cards?.cost_covered || 0),
          },
        ],
      };

      if (cards?.budget_payment > 0) {
        occurrencesLog.cardData.splice(1, 0, {
          title: 'N° Pagos',
          description: 'Total de Orçamentos Pagos no período',
          value: `Qtd: ${(cards?.budget_payment || 0).toLocaleString('pt-br')}`,
        });
      }

      if (cards?.payment_canceled > 0) {
        occurrencesLog.cardData.splice(cards?.budget_payment > 0 ? 2 : 1, 0, {
          title: 'N° Cancelados',
          description: 'Total de Orçamentos Cancelados no período',
          value: `Qtd: ${(cards?.payment_canceled || 0).toLocaleString('pt-br')}`,
        });
      }

      setBudgetsData(occurrencesLog);

      setIsLoading(false);
      resetProgress();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
      setIsLoading(false);
      resetProgress();
    }
  }, [
    dates,
    numProtocolInput,
    associateId,
    vehicleId,
    budgetTypeId,
    paymentStatusId,
  ]);

  useEffect(() => {
    getBudgets();
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader titles={['BI', 'Orçamentos']} />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter={false}
        inputs={inputs}
        onSubmit={getBudgets}
        useDatePicker
        initialDates={getInitialDates({
          months: 1,
        })}
        maxRange={365}
        disableInputs={true}
        customPreSelection={[
          {
            label: 'Mês atual',
            startDate: startOfMonth(new Date()),
            endDate: new Date(),
          },
          {
            label: 'Mês passado',
            startDate: startOfMonth(subMonths(new Date(), 1)),
            endDate: endOfMonth(subMonths(new Date(), 1)),
          },
          {
            label: 'Últimos 3 Meses',
            startDate: subMonths(new Date(), 3),
            endDate: new Date(),
          },
          {
            label: 'Últimos 6 Meses',
            startDate: subMonths(new Date(), 6),
            endDate: new Date(),
          },
          {
            label: 'Últimos 12 Meses',
            startDate: subMonths(new Date(), 12),
            endDate: new Date(),
          },
        ]}
      />
      {Object.keys(budgetsData).length > 0 && (
        <Container>
          <CarouselCards cardData={budgetsData?.cardData} />

          <ChartsContainer graphData={budgetsData?.graphData} />
        </Container>
      )}
    </PageContainer>
  );
};

export default Budgets;
