import api from 'services/api';

import { saveAs } from 'file-saver';
import { BEARER } from 'utils/constants';
import { getItem } from 'providers/storage';

const baseContent = {
  title: '',
  data: [['']],
  headers: [''],
};

const exportXLS = async (
  content = baseContent,
  filename = '',
  isMultiple = false,
) => {
  const payload = {
    data: {
      title: filename,
      sheets: [
        { headers: content.headers, rows: content.data, name: content.title },
      ],
    },
  };

  const token = getItem('token');
  const XLS = isMultiple ? content : payload;

  try {
    const response = await api.post(
      `/providers/xlsx/generate`,
      JSON.stringify(XLS),
      {
        headers: {
          Authorization: BEARER + token,
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      },
    );

    const { data, status } = response;

    if (status !== 200) throw new Error();

    const blob = new Blob([data], {
      type: response.headers['content-type'],
    });

    saveAs(blob, `${filename}.xlsx`);

    return { end: true, status: response.status };
  } catch ({ message }) {
    return { message, end: true, error: true };
  }
};

export default exportXLS;
