import { useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import DefaultModal from '../DefaultModal';
import Checkbox from '../Checkbox';

const Container = styled.div`
  ${tw`flex flex-col w-full h-full max-h-[400px] gap-4 py-1 overflow-hidden`}
`;

const CheckboxContainer = styled.div`
  ${tw`h-full w-full overflow-auto`}

  & > * {
    ${tw`mb-3 last:mb-0`}
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const ColumnFilterModal = ({
  isOpen = false,
  columns = [{}],
  onClose = () => {},
  onSetColumns = () => {},
}) => {
  const onChange = value => {
    const update = columns.map(column => {
      if (value.label === 'Todas') {
        return {
          ...column,
          hide: false,
        };
      }

      if (column.label !== value.label) return column;

      const positives = columns.filter(data => !data.hide);

      if (positives.length === 1) return { ...column, hide: false };

      return {
        ...column,
        hide: !column.hide,
      };
    });

    const removeAll = update.filter(column => column.label !== 'Todas');

    onSetColumns(removeAll);
  };

  const renderCheckboxes = useMemo(() => {
    if (columns.length === 0) return;

    if (!columns.some(column => column.label === 'Todas')) {
      columns.unshift({
        label: 'Todas',
        field: 'all',
        hide: columns.some(column => column.hide === true) ? true : false,
      });
    }

    return columns.map(column => {
      if (!column) return;

      return (
        <Checkbox
          key={column.field}
          id={column.field}
          isChecked={!column.hide}
          onChange={() => onChange(column)}
          label={{ text: column.label }}
        />
      );
    });
  }, [columns]);

  return (
    <DefaultModal
      isOpen={isOpen}
      title="Escolha as colunas visíveis"
      maxWidth="350px"
      onClose={onClose}
      onBackgroundClick={onClose}
      onEscapeKeyDown={onClose}>
      <Container>
        <CheckboxContainer>{renderCheckboxes}</CheckboxContainer>
      </Container>
    </DefaultModal>
  );
};

export default ColumnFilterModal;
