import { cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

import PrimaryButton from './PrimaryButton';
import ConnectionStatus from './ConnectionStatus';

import { ReactComponent as XlsIcon } from 'assets/images/svg/excel.svg';
import { ReactComponent as PdfIcon } from 'assets/images/svg/pdf_adobe.svg';

import { variant } from 'utils/functions';

const Container = styled.div`
  ${tw`flex flex-wrap w-full h-fit justify-center sm:justify-between items-center gap-y-5 gap-x-16`}
`;

const TitlesContainer = styled.div`
  ${tw`flex flex-col items-start justify-center w-fit overflow-hidden m-0`}
`;

const Wrapper = styled.div`
  ${tw`flex flex-wrap w-fit max-w-full items-center justify-start gap-2 leading-5`}
`;

const Text = styled.span`
  ${tw`text-gray-600 text-xl font-semibold truncate max-w-full drop-shadow`}

  ${({ $variant }) =>
    variant({
      last: tw`text-3xl font-bold bg-clip-text bg-gradient-to-b from-[#1F53FF] to-[#0A1B24] [-webkit-text-fill-color: transparent;]`,
    })({ $variant })}
`;

const ButtonsWrapper = styled.div`
  ${tw`flex flex-wrap w-fit h-fit items-center justify-center gap-x-5 gap-y-2`}
`;

const Button = styled(PrimaryButton)`
  ${props => props.$red && tw`[background: var(--red-gradient)]`}
  ${props => props.$green && tw`[background: var(--green-gradient)]`}
`;

const iconStyle = {
  fill: '#FFF',
  width: '16px',
  height: '16px',
  flexShrink: 0,
};

const PageHeader = ({
  titles = [],
  onExportPDF, // Export PDF function
  onExportXLS, // Export XLS function
  addButtons = [], // New buttons for any function
  addRedirects = [], // New buttons for any redirect
  connectionStatus = undefined,
}) => {
  const navigate = useNavigate();

  const showButtons = Boolean(
    connectionStatus ||
      onExportPDF ||
      onExportXLS ||
      addButtons.length ||
      addRedirects.length,
  );

  return (
    <Container>
      <TitlesContainer>
        {titles.length > 1 && (
          <Wrapper>
            {titles.map((item, index) => {
              if (index !== titles.length - 1) {
                return <Text key={index}>{`${item} >`}</Text>;
              }
            })}
          </Wrapper>
        )}
        <Text $variant="last">{titles[titles.length - 1]}</Text>
      </TitlesContainer>

      {!!showButtons && (
        <ButtonsWrapper>
          {connectionStatus && <ConnectionStatus status={connectionStatus} />}

          {onExportPDF && (
            <Button $red onClick={onExportPDF}>
              <PdfIcon style={iconStyle} />
              Exportar PDF
            </Button>
          )}

          {onExportXLS && (
            <Button $green onClick={onExportXLS}>
              <XlsIcon style={iconStyle} />
              Exportar XLS
            </Button>
          )}

          {!!addButtons.length &&
            addButtons.map((item, index) => (
              <Button key={index} onClick={item.onClick}>
                {item.icon && cloneElement(item.icon, { style: iconStyle })}
                {item?.title}
              </Button>
            ))}

          {!!addRedirects.length &&
            addRedirects.map((button, index) => {
              if (button?.hide) return null;

              return (
                <PrimaryButton
                  {...button}
                  key={index}
                  onClick={() => {
                    if (button.disabled) return;

                    if (button?.newTab) {
                      window.open(button.path, '_blank');
                    }
                    if (button?.goBack) {
                      navigate(-1);
                    } else {
                      if (typeof button.state?.getValue === 'function') {
                        const value = button.state.getValue();
                        const field = button.state?.field;
                        navigate(button.path, {
                          state: { [field]: value },
                        });
                      } else {
                        navigate(button.path, {
                          state: button.state,
                        });
                      }
                    }
                  }}>
                  {button.icon &&
                    cloneElement(button.icon, { style: iconStyle })}
                  {button.title}
                </PrimaryButton>
              );
            })}
        </ButtonsWrapper>
      )}
    </Container>
  );
};

export default PageHeader;
