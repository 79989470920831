import { useState, memo, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

import DefaultModal from 'components/ui-kit/DefaultModal';
import Table from 'components/ui-kit/Table';
import IconButton from 'components/ui-kit/IconButton';
import ServiceProviderMap from './ServiceProviderMap';
import ServiceProviderValues from './ServiceProviderValues';

import Formatter from 'utils/formatter';

import { ReactComponent as AcceptIcon } from 'assets/images/svg/shaking_hands.svg';

const Container = styled.div`
  ${tw`flex flex-col w-full items-center gap-2 px-2 overflow-auto`}
`;

const Row = styled.div`
  ${tw`flex flex-row w-full max-w-full gap-2 items-center`}
`;

const Item = styled.div`
  ${tw`flex h-fit w-full justify-center overflow-hidden`}
`;

const ButtonsGroup = styled.div`
  ${tw`flex flex-wrap w-fit gap-2`}
`;

const findLowestValues = quotationList => {
  if (quotationList.length === 0) {
    return null;
  }

  return quotationList.reduce(
    (minValues, item) => {
      return {
        budgetValue: Math.min(item.budgetValue, minValues.budgetValue),
        closedValue: Math.min(item.closedValue, minValues.closedValue),
        associateValue: Math.min(item.associateValue, minValues.associateValue),
        associationValue: Math.min(
          item.associationValue,
          minValues.associationValue,
        ),
      };
    },
    {
      budgetValue: Infinity,
      closedValue: Infinity,
      associateValue: Infinity,
      associationValue: Infinity,
    },
  );
};

const ServiceProviderModal = ({
  isOpen = false,
  associatePays = false,
  occurrenceInfo = {},
  plateOptions = [],
  onAddServiceValue = () => {},
  onClose = () => {},
}) => {
  const [quotationList, setQuotationList] = useState([]);
  const [providerInEditing, setProviderInEditing] = useState({});

  const { phoneMask, formatToBRL } = new Formatter();

  const lowestValues = useMemo(
    () => findLowestValues(quotationList),
    [quotationList],
  );

  const defaultColumns = [
    {
      label: 'Prestador de Serviço',
      field: 'serviceProviderName',
    },
    {
      label: 'Endereço',
      field: 'address',
    },
    { label: 'Contato(s)', field: 'contacts' },
    {
      label: 'Valor Orçado',
      field: 'budgetValue',
      display: 'budgetValueDisplay',
    },
    {
      label: 'Valor Fechado',
      field: 'closedValue',
      display: 'closedValueDisplay',
    },
    ...(associatePays
      ? [
          {
            label: 'Pago Associado (30%)',
            field: 'associateValue',
            display: 'associateValueDisplay',
          },
          {
            label: 'Pago Associação (70%)',
            field: 'associationValue',
            display: 'associationValueDisplay',
          },
        ]
      : []),
    {
      label: 'Ações',
      field: 'actions',
      disableSort: true,
    },
  ];

  const handleQuotation = useCallback(
    (value, remove, isEdit) => {
      if (remove) {
        setQuotationList(
          quotationList.filter(
            item => item.serviceProviderId !== value.serviceProviderId,
          ),
        );
      } else if (isEdit) {
        const existingProvider = quotationList.find(
          item => item.serviceProviderId === value.serviceProviderId,
        );
        const updatedList = quotationList.map(item =>
          item.serviceProviderId === existingProvider.serviceProviderId
            ? value
            : item,
        );
        setQuotationList(updatedList);
        toast.success('Valores atualizados');
      }

      setProviderInEditing({});
    },
    [quotationList],
  );

  const handleAcceptServiceValue = useCallback(values => {
    onAddServiceValue(values);
    handleClose();
  }, []);

  const handleAddProvider = useCallback(
    values => {
      setQuotationList(prevList => {
        const existingProvider = prevList.find(
          item => item.serviceProviderId === values.value,
        );

        if (prevList.length >= 10) {
          toast.warning(
            'Limite da tabela de preços atingido, remova algum preço para adicionar mais.',
            { autoClose: 5000 },
          );
          return prevList;
        }

        if (existingProvider) {
          toast.warning('Prestador já adicionado!');
          return prevList;
        }

        return [
          ...prevList,
          {
            serviceProviderId: values.value,
            serviceProviderName: values.label,
            address: values.address,
            contacts: values.contacts,
            budgetValue: 0,
            closedValue: 0,
            ...(associatePays
              ? { associateValue: 0, associationValue: 0 }
              : {}),
          },
        ];
      });
    },
    [associatePays, providerInEditing],
  );

  const handleClose = useCallback(() => {
    setProviderInEditing({});
    setQuotationList([]);
    onClose();
  }, []);

  const tableData = useMemo(() => {
    return quotationList.map(item => ({
      ...item,
      contacts:
        item?.contacts?.map(c => (
          <Row key={c.number}>
            <Item>Nome: {c?.name || '-'}</Item>
            <Item>
              <span
                style={{ cursor: 'pointer' }}
                data-tooltip-id={item?.serviceProviderId}
                data-tooltip-content="Clique para copiar o contato"
                onClick={() => {
                  navigator.clipboard.writeText(c.ddi + c.number);
                  toast.success('Contato copiado para área de transferência.');
                }}>
                Número: {`${c.ddi} ${phoneMask(c.number)}`}
              </span>
            </Item>
            <Tooltip id={item?.serviceProviderId} place="left" />
          </Row>
        )) || '-',
      budgetValueDisplay:
        item?.budgetValue === lowestValues?.budgetValue ? (
          <span style={{ fontWeight: 600, color: 'green' }}>
            {formatToBRL(item?.budgetValue)}
          </span>
        ) : (
          <span style={{ fontWeight: 600, color: 'red' }}>
            {formatToBRL(item?.budgetValue)}
          </span>
        ),
      closedValueDisplay:
        item?.closedValue === lowestValues?.closedValue ? (
          <span style={{ fontWeight: 600, color: 'green' }}>
            {formatToBRL(item?.closedValue)}
          </span>
        ) : (
          <span style={{ fontWeight: 600, color: 'red' }}>
            {formatToBRL(item?.closedValue)}
          </span>
        ),
      ...(associatePays
        ? {
            associateValueDisplay:
              item?.associateValue === lowestValues?.associateValue ? (
                <span style={{ fontWeight: 600, color: 'green' }}>
                  {formatToBRL(item?.associateValue)}
                </span>
              ) : (
                <span style={{ fontWeight: 600, color: 'red' }}>
                  {formatToBRL(item?.associateValue)}
                </span>
              ),
            associationValueDisplay:
              item?.associationValue === lowestValues?.associationValue ? (
                <span style={{ fontWeight: 600, color: 'green' }}>
                  {formatToBRL(item?.associationValue)}
                </span>
              ) : (
                <span style={{ fontWeight: 600, color: 'red' }}>
                  {formatToBRL(item?.associationValue)}
                </span>
              ),
          }
        : {}),
      actions: (
        <ButtonsGroup>
          <IconButton
            type="edit"
            tooltipText={'Editar valores'}
            onClick={() => {
              setProviderInEditing({
                name: item?.serviceProviderName,
                id: item?.serviceProviderId,
                address: item?.address,
                contacts: item?.contacts,
                budgetValue: item?.budgetValue,
                closedValue: item?.closedValue,
              });
            }}
          />
          <IconButton
            type="deactivate"
            tooltipText={'Remover'}
            onClick={() => handleQuotation(item, true, false)}
          />
          <IconButton
            type="activate"
            tooltipText={'Aceitar este orçamento'}
            onClick={() => handleAcceptServiceValue(item)}>
            <AcceptIcon />
          </IconButton>
        </ButtonsGroup>
      ),
    }));
  }, [quotationList, lowestValues, associatePays]);

  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <DefaultModal
      isOpen={isOpen}
      title="Prestador de Serviço"
      onClose={handleClose}
      maxWidth="95vw"
      onBackgroundClick={handleClose}
      onEscapeKeyDown={handleClose}>
      <Container>
        <ServiceProviderMap
          isOpen={isOpen}
          occurrenceInfo={occurrenceInfo}
          plateOptions={plateOptions}
          onAddProvider={handleAddProvider}
        />
        {quotationList.length > 0 && (
          <div
            style={{
              position: 'relative',
              minHeight: '350px',
              width: '100%',
              overflow: 'visible',
            }}>
            <ServiceProviderValues
              isOpen={!!providerInEditing?.id}
              data={providerInEditing}
              associatePays={associatePays}
              addInQuotation={handleQuotation}
              onClose={() => setProviderInEditing({})}
            />
            <Table columns={defaultColumns} data={tableData} />
          </div>
        )}
      </Container>
    </DefaultModal>
  );
};

export default memo(ServiceProviderModal);
