import React, { useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { ReactComponent as UncheckedIcon } from 'assets/images/svg/uncheck.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/check.svg';
import { ReactComponent as ViewAlertIcon } from 'assets/images/svg/view_alert.svg';
import { ReactComponent as WarningIcon } from 'assets/images/svg/warning.svg';
import { ReactComponent as PaymentIcon } from 'assets/images/svg/payment.svg';
import { ReactComponent as ClockIcon } from 'assets/images/svg/clock.svg';
import { ReactComponent as TimeIcon } from 'assets/images/svg/time.svg';

const Label = styled.div`
  ${tw`flex flex-1 items-center justify-center gap-1 m-0`}

  .text {
    ${tw`text-sm font-semibold`}
  }
`;

const iconStyle = {
  flexShrink: 0,
  width: 20,
  height: 20,
};

const predefinedConfigs = {
  isActive: {
    labels: ['Ativo', 'Inativo'],
    valueOptions: ['true', 'false'],
    colors: ['var(--green-theme)', 'var(--red-theme)'],
    icons: [CheckedIcon, UncheckedIcon],
  },
  occurrenceStatus: {
    labels: [
      'Pendente Informações',
      'Pendente Orçamentos',
      'Pendente Financeiro',
      'Pendente Encerramento',
      'Encerrada',
      'Encerrada Automaticamente',
    ],
    valueOptions: ['1', '2', '3', '4', '5', '6'],
    colors: [
      'var(--red-theme)',
      'var(--warning-dark)',
      'var(--green-theme)',
      'var(--basic-primary)',
      'var(--royal-blue-theme)',
      'var(--basic-black)',
    ],
    icons: [
      ViewAlertIcon,
      WarningIcon,
      PaymentIcon,
      ClockIcon,
      CheckedIcon,
      TimeIcon,
    ],
  },
};

const getStatusStyle = (value, config) => {
  const defaultStyle = {
    label: '-',
    color: 'black',
    icon: null,
  };

  const statusConfig =
    typeof config === 'string' ? predefinedConfigs[config] : config;

  if (
    !statusConfig ||
    !statusConfig.labels ||
    !statusConfig.colors ||
    !statusConfig.icons ||
    !statusConfig.valueOptions
  )
    return defaultStyle;

  const { valueOptions, labels, colors, icons } = statusConfig;
  const index = valueOptions.indexOf(String(value));

  if (index === -1) return defaultStyle;

  const {
    label,
    color,
    icon: Icon,
  } = { label: labels[index], color: colors[index], icon: icons[index] };
  const icon = <Icon style={iconStyle} fill={color} />;

  return { label, color, icon };
};

/**
 * @typedef {Object} StatusConfig
 * @property {string[]} valueOptions - Valores de status que correspondem aos índices dos arrays labels, colors e icons.
 * @property {string[]} labels - Textos de status.
 * @property {string[]} colors - Cores de status.
 * @property {React.Component[]} icons - Ícones de status (componentes React).
 */

/**
 * @typedef {Object} StatusDisplayProps
 * @property {(string|StatusConfig)} config - Configuração de status. Pode ser uma string correspondente a uma configuração predefinida ou um objeto personalizado.
 * @property {(boolean|string|number)} value - Valor de status. É convertido para uma string e usada para determinar qual texto, cor e ícone usar da configuração de status.
 */

/**
 * @type {React.FC<StatusDisplayProps>}
 */

const StatusDisplay = ({ config, value }) => {
  const { label, color, icon } = useMemo(
    () => getStatusStyle(value, config),
    [value, config],
  );

  return (
    <Label style={{ color: color }}>
      {icon}
      <span className="text">{label}</span>
    </Label>
  );
};

export default StatusDisplay;
