import React, { forwardRef, memo, useEffect } from 'react';

import L from 'leaflet';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';

const start = [-23.5505, -46.6333];
const southWest = L.latLng(-89.98155760646617, -180);
const northEast = L.latLng(89.99346179538875, 180);
const bounds = L.latLngBounds(southWest, northEast);

const ChangeView = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center);
  }, [center, map]);
  return null;
};

const Map = forwardRef(
  (
    {
      children,
      center = start,
      minZoom = 3,
      maxZoom = 18,
      startZoom = 5,
      maxBounds,
      doubleClickZoom = true,
      tileLayer = {
        url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      },
    },
    ref,
  ) => {
    return (
      <MapContainer
        animate
        boxZoom
        keyboard
        preferCanvas
        doubleClickZoom={doubleClickZoom}
        center={center}
        maxBounds={maxBounds || bounds}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoom={startZoom || 10}
        attributionControl={false}
        bounceAtZoomLimits={false}
        markerZoomAnimation={false}
        maxBoundsViscosity={1.0}
        zoomControl={false}
        ref={ref}
        style={{
          width: '100%',
          height: '100%',
          zIndex: 0,
          border: 'none',
          position: 'relative',

          '&:focus': {
            outline: 0,
          },
        }}>
        <TileLayer {...tileLayer} />
        <ChangeView center={center} />
        {children}
      </MapContainer>
    );
  },
);

export default memo(Map);
