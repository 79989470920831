import { memo } from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const Container = styled.div`
  ${tw`flex w-full h-full`}
`;

const FixedList = ({
  children,
  items = [],
  invisibleItemsCount = 3,
  itemSize = 80,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            width={width}
            height={height}
            itemData={items}
            itemCount={items.length}
            itemSize={itemSize}
            className="fixed-list"
            overScanCount={invisibleItemsCount}>
            {children}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Container>
  );
};

export default memo(FixedList);
