import { useState, useEffect, memo, useCallback, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import useAuth from 'hooks/useAuth';

import tw from 'twin.macro';
import api from 'services/api';
import styled, { css } from 'styled-components';

import L from 'leaflet';
import Map from 'components/ui-kit/Map';
import Select from 'components/ui-kit/Select';

import { toast } from 'react-toastify';
import { BEARER } from 'utils/constants';
import { NO_DATA_FOUND } from 'utils/messages';
import { fadeIn, clipPath } from 'helpers/animations';

import { ReactComponent as WinchIcon } from 'assets/images/svg/winch.svg';
import { ReactComponent as SearchIcon } from 'assets/images/svg/search.svg';

import 'leaflet.heat';
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet.heat/dist/leaflet-heat.js';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

const Container = styled.div`
  ${tw`flex flex-col h-fit w-full bg-white items-center justify-center p-2 box-border border-2 border-gray-400 rounded-xl gap-4 shadow-lg z-0`}
`;

const Text = styled.span`
  ${tw`text-2xl text-center md:text-3xl font-bold text-[var(--dark-blue-theme)]`}
`;

const Cluster = styled.div`
  ${tw`flex items-center justify-center bg-[var(--dark-blue-theme)] text-white font-bold border-solid border-2 border-white rounded-circle`}
  width: 30px;
  height: 30px;
`;

const Row = styled.div`
  ${tw`flex flex-wrap h-fit w-full gap-y-4 gap-x-8 items-center justify-center drop-shadow`}
`;

const SearchButton = styled.button`
  ${tw`flex h-fit w-fit gap-1 px-3 py-1 rounded-lg shadow-md [background: var(--blue-gradient)] items-center justify-center
  text-base text-white font-normal`}
`;

const LoadingBorder = styled.div`
  ${tw`flex bg-white rounded-lg relative w-full items-center justify-center -z-10`}
  padding: 0.4em;
  animation: ${fadeIn} 1s ease-in-out;

  &::before {
    content: '';
    ${tw`w-full h-full rounded-xl absolute bg-[var(--dark-blue-theme)]`}
    opacity: ${props => (props.$loading ? 1 : 0)};
    transition: opacity 0.5s;
    animation: ${props =>
      props.$loading
        ? css`
            ${clipPath} 2s linear infinite
          `
        : 'none'};
  }
`;

const createMarker = (item, icon) => {
  const marker = L.marker([item.latitude, item.longitude], { icon });
  marker.bindPopup(
    renderToString(
      <div
        style={{
          background: '#fff',
          borderRadius: '10px',
        }}>
        {item.vehicles?.map((occurrence, index) => (
          <div key={index}>
            <span style={{ fontWeight: 'bold' }}>Associado: </span>
            {occurrence.associateName}
            <br />
            <span style={{ fontWeight: 'bold' }}>Placa: </span>
            {occurrence.plate}
            <br />
            <hr />
          </div>
        ))}
        <span>
          <span style={{ fontWeight: 'bold' }}>Local: </span>
          {item.location}
        </span>
        <hr />
        <ul>
          <span style={{ fontWeight: 'bold' }}>Causa(s):</span>
          {item.causes?.map((occurrence, index) => (
            <li key={index}>- {occurrence.name}</li>
          ))}
        </ul>
      </div>,
    ),
  );
  return marker;
};

const iconDiv = new L.DivIcon({
  html: renderToString(
    <WinchIcon
      height={30}
      width={30}
      fill="#FFF"
      style={{
        stroke: '#525252',
        strokeWidth: '1px',
        filter: 'drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.6))',
      }}
    />,
  ),
  className: 'custom-icon',
});

const MapOccurrences = ({ params }) => {
  const [mapData, setMapData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typeLocation, setTypeLocation] = useState(true);

  const { user } = useAuth();

  const mapRef = useRef(null);
  const markersRef = useRef(null);
  const heatLayerRef = useRef(null);

  const getMapData = useCallback(async () => {
    setLoading(true);
    setMapData([]);

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get('bi/occurrences/maps', {
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          nr_protocol: formatParam(params?.numProtocol),
          associate_id: params?.associateId,
          vehicle_id: params?.vehicleId,
          category_id: params?.categoryOccurrenceId,
          types_ids: params?.causesId,
          created_on_from: params?.startDate,
          created_on_to: params?.endDate,
          locale_accident: typeLocation,
        },
      });

      if (status !== 200) throw new Error();

      const mapDataLog = response.data?.maps.map(item => ({
        vehicles:
          item?.vehicles?.map(v => ({
            associateName: v?.associate_name || '-',
            plate: v?.plate || '-',
          })) || [],
        location: `${item?.name_city || '-'} / ${item?.short_code || '-'}`,
        latitude: item?.latitude || 0,
        longitude: item?.longitude || 0,
        causes:
          item?.occurrences?.map(type => ({
            name: type?.name || '-',
          })) || [],
      }));

      setTimeout(() => {
        setMapData(mapDataLog);
        setLoading(false);
      }, 1000);
    } catch (error) {
      toast.error(NO_DATA_FOUND);
      setLoading(false);
    }
  }, [params, typeLocation]);

  const iconCreateFunction = useCallback(
    cluster => {
      const icon = L.divIcon({
        html: renderToString(<Cluster>{cluster.getChildCount()}</Cluster>),
        className: 'custom-cluster-icon',
      });
      return icon;
    },
    [mapData],
  );

  useEffect(() => {
    if (mapData.length > 0) {
      mapRef?.current?.setView([mapData[0].latitude, mapData[0].longitude], 5);

      const points = mapData?.map(item => [item.latitude, item.longitude]);

      if (heatLayerRef.current) {
        mapRef?.current?.removeLayer(heatLayerRef.current);
      }

      heatLayerRef.current = L.heatLayer(points, {
        radius: 30,
        blur: 30,
        maxZoom: 5,
        gradient: {
          0.4: '#0000FF',
          0.6: '#FFFF00',
          1.0: '#FF0000',
        },
      }).addTo(mapRef.current);

      if (markersRef.current) {
        mapRef.current.removeLayer(markersRef.current);
      }

      markersRef.current = L.markerClusterGroup({
        iconCreateFunction,
      });

      mapData?.forEach(item => {
        const marker = createMarker(item, iconDiv);
        markersRef.current.addLayer(marker);
      });

      mapRef.current.addLayer(markersRef.current);
    }
  }, [mapData]);

  return (
    <Container>
      <Text>Regiões das Ocorrências</Text>

      <Row>
        <Select
          options={[
            { label: 'Filtrar por: Origem da Ocorrência', value: true },
            { label: 'Filtrar por: Destino da Ocorrência', value: false },
          ]}
          onOptionChange={option => {
            setTypeLocation(option.value);
          }}
          placeholder=""
          size="sm"
          maxWidth="320px"
          isClearable={false}
          initialValue={{
            label: 'Filtrar por: Origem da Ocorrência',
            value: true,
          }}
        />
        <SearchButton onClick={getMapData}>
          Buscar
          <SearchIcon width={15} height={15} fill="#FFF" />
        </SearchButton>
      </Row>

      <LoadingBorder $loading={loading}>
        <div
          style={{
            borderRadius: '8px',
            overflow: 'hidden',
            height: '500px',
            width: '100%',
          }}>
          <Map
            ref={mapRef}
            tileLayer={{
              url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
              attribution:
                '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }}
          />
        </div>
      </LoadingBorder>
    </Container>
  );
};

export default memo(MapOccurrences);
