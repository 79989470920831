import { createContext, useEffect, useState } from 'react';
import api from 'services/api';

import { BEARER } from 'utils/constants';
import {
  INVALID_CREDENTIALS,
  USER_NOT_FOUND,
  STANDARD_ERROR,
} from 'utils/messages';

import { getItem, addItem, removeItem } from 'providers/storage';

const AuthContext = createContext({
  user: {},
  error: '',
  userLoading: false,
  isSessionExpired: false,
  login: async ({ username = '', password = '' }) => {}, // eslint-disable-line no-unused-vars
  logout: () => {},
  resetError: () => {},
  onSessionExpires: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [error, setError] = useState();
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const getSession = async () => {
      try {
        const token = getItem('token');

        if (!token) throw new Error();

        const { data: response, status } = await api.get('/me/about', {
          timeout: 10000, // 10 secs
          headers: {
            Authorization: BEARER + token,
          },
        });

        if (status !== 200) throw new Error();

        const me = response.data;

        const userData = {
          token,
          name: me?.display_name,
          email: me?.email,
          id: me?.id,
          role: {
            id: me?.role?.id,
            name: me?.role?.display_name,
            modules:
              me?.role?.modules?.map(item => ({
                identifier: item?.name_key,
                isActive: item?.active || false,
                permissions: {
                  visualize: item?.perms?.visualize || false,
                  create: item?.perms?.create || false,
                  update: item?.perms?.update || false,
                  delete: item?.perms?.delete || false,
                },
                submodules:
                  item?.sub?.map(sub => ({
                    identifier: sub?.name_key,
                    isActive: item?.active || false,
                    permissions: {
                      visualize: sub?.perms?.visualize || false,
                      create: sub?.perms?.create || false,
                      update: sub?.perms?.update || false,
                      delete: sub?.perms?.delete || false,
                    },
                  })) || [],
              })) || [],
          },
        };

        setUser(userData);
      } catch {
        logout();
      } finally {
        setUserLoading(false);
      }
    };

    getSession();
  }, []);

  const logout = () => {
    removeItem('token');

    setIsSessionExpired(false);
    setUser({});
    setError();
  };

  const resetError = () => {
    setError();
  };

  const login = async ({ username, password }) => {
    resetError();
    setUserLoading(true);

    try {
      const { data: response, status } = await api.post(
        '/auth/token',
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );

      if (status !== 200) throw new Error();

      const { data } = response;

      if (!data?.audience?.includes('web')) {
        const error = new Error('Unauthorized');
        error.response = { status: 401 };
        throw error;
      }

      const { user, jwt } = data;

      const userData = {
        token: jwt,
        name: user?.display_name,
        email: user?.email,
        id: user?.id,
        role: {
          id: user?.role?.id,
          name: user?.role?.display_name,
          modules:
            user?.role?.modules?.map(item => ({
              identifier: item?.name_key,
              isActive: item?.active || false,
              permissions: {
                visualize: item?.perms?.visualize || false,
                create: item?.perms?.create || false,
                update: item?.perms?.update || false,
                delete: item?.perms?.delete || false,
              },
              submodules:
                item?.sub?.map(sub => ({
                  identifier: sub?.name_key,
                  isActive: item?.active || false,
                  permissions: {
                    visualize: sub?.perms?.visualize || false,
                    create: sub?.perms?.create || false,
                    update: sub?.perms?.update || false,
                    delete: sub?.perms?.delete || false,
                  },
                })) || [],
            })) || [],
        },
      };

      setUser(userData);

      addItem('token', userData.token);

      return { success: true };
    } catch (error) {
      let errorMessage;

      switch (error.response?.status) {
        case 401:
          errorMessage = INVALID_CREDENTIALS;
          break;
        case 404:
          errorMessage = USER_NOT_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      setError(errorMessage);

      return { success: false };
    } finally {
      setUserLoading(false);
    }
  };

  const onSessionExpires = () => {
    setIsSessionExpired(true);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userLoading,
        error,
        isSessionExpired,
        login,
        logout,
        resetError,
        onSessionExpires,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
