import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { subMonths } from 'date-fns';
import { IMaskInput } from 'react-imask';

import PageHeader from 'components/ui-kit/PageHeader';
import PageContainer from 'components/ui-kit/PageContainer';
import FilterTool from 'components/ui-kit/FilterTool';
import Table from 'components/ui-kit/Table';
import LoadingScreen from 'components/ui-kit/LoadingScreen';
import StatusDisplay from 'components/ui-kit/StatusDisplay';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import {
  getInitialDates,
  getExportData,
  getBackendDateFormat,
  getUniqueValues,
} from 'utils/functions';
import Formatter from 'utils/formatter';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import {
  STANDARD_ERROR,
  NO_DATA_FOUND,
  GETTING_PDF,
  GETTING_XLS,
  PDF_ERROR,
  PDF_READY,
  XLS_ERROR,
  XLS_READY,
} from 'utils/messages';
import exportPDF from 'services/pdf';
import exportXLS from 'services/xls';

import { ReactComponent as UncheckedIcon } from 'assets/images/svg/uncheck.svg';
import { ReactComponent as ClockIcon } from 'assets/images/svg/clock.svg';
import { ReactComponent as CheckedIcon } from 'assets/images/svg/check.svg';

const defaultColumns = [
  { label: 'N° Protocolo', field: 'numProtocol' },
  {
    label: 'Data da Ocorrência',
    field: 'occurrenceDate',
    display: 'fmtdOccurrenceDate',
  },
  { label: 'Associado(s)', field: 'associateName' },
  { label: 'Placa(s)', field: 'vehiclePlate' },
  { label: 'Causa(s)', field: 'causes' },
  { label: 'Origem(ns)', field: 'origin' },
  { label: 'Destino(s)', field: 'destiny' },
  { label: 'Tipos de Orçamento', field: 'typesBudget' },
  { label: 'Prestador de Serviço', field: 'serviceProviderName' },
  { label: 'Valor Orçado', field: 'valueBudget', display: 'valueBudgetBRL' },
  { label: 'Valor Fechado', field: 'valueClosed', display: 'valueClosedBRL' },
  { label: 'Pago Excedente', field: 'excessPaid', display: 'excessPaidBRL' },
  {
    label: 'Pago Associação',
    field: 'associationPaid',
    display: 'associationPaidBRL',
  },
  {
    label: 'Status do Orçamento',
    field: 'budgetStatusValue',
    display: 'budgetStatusDisplay',
  },
  {
    label: 'Data do Pagamento',
    field: 'paymentDate',
    display: 'fmtdPaymentDate',
  },
];

const configStatus = {
  labels: ['Cancelado', 'Em aberto', 'Pago'],
  valueOptions: ['Cancelado', 'Em aberto', 'Pago'],
  colors: ['var(--red-theme)', 'var(--warning-dark)', 'var(--green-theme)'],
  icons: [UncheckedIcon, ClockIcon, CheckedIcon],
};

const Budgets = () => {
  const [budgetsList, setBudgetsList] = useState([]);

  const [numProtocolInput, setNumProtocolInput] = useState();
  const [associate, setAssociate] = useState();
  const [vehicle, setVehicle] = useState();
  const [typesBudget, setTypesBudget] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [typeOccurrence, setTypeOccurrence] = useState();

  const [searchHistory, setSearchHistory] = useState({});

  const [dates, setDates] = useState({
    startDate: getBackendDateFormat(subMonths(new Date(), 1)),
    endDate: getBackendDateFormat(new Date()),
  });
  const [columns, setColumns] = useState(defaultColumns);
  const [isLoading, setIsLoading] = useState(false);

  const vehicleSeletRef = useRef(null);
  const tableRef = useRef(null);

  const { user } = useAuth();
  const { defaultDateFormat, exportDateFormat, formatToBRL } = new Formatter();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const inputs = [
    {
      onAccept: value => setNumProtocolInput(value.toUpperCase()),
      as: IMaskInput,
      value: numProtocolInput,
      mask: 'a-0000000',
      placeholder: 'N° Protocolo',
      size: 'sm',
      height: '33px',
      maxWidth: '180px',
      $variant: 'registry',
    },
    {
      type: 'select',
      maxWidth: '300px',
      endpoint: 'clients/all?active=true',
      fieldToOptionLabel: 'display_name',
      fieldToOptionValue: 'id',
      placeholder: 'Associado',
      onOptionChange: option => {
        if (vehicle?.id) vehicleSeletRef.current?.clearValue();

        setAssociate(option);
      },
    },
    {
      ref: vehicleSeletRef,
      type: 'select',
      maxWidth: '220px',
      isDisabled: !associate?.id,
      endpoint: `vehicles/?client_id=${associate?.id}`,
      fieldToOptionLabel: 'plate',
      fieldToOptionValue: 'id',
      placeholder: 'Selecione uma Placa',
      onOptionChange: option => setVehicle(option),
    },
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: 'occurrencies/budgets/types',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Tipos de Orçamento',
      onOptionChange: option => setTypesBudget(option),
    },
    {
      type: 'select',
      maxWidth: '230px',
      endpoint: 'payments/status/',
      fieldToOptionLabel: 'name',
      fieldToOptionValue: 'id',
      placeholder: 'Status do pagamento',
      onOptionChange: option => setPaymentStatus(option),
    },
    {
      type: 'select',
      maxWidth: '250px',
      options: [
        { label: 'Acidente', value: 1 },
        { label: 'Pane', value: 2 },
        { label: 'Vidro', value: 3 },
      ],
      placeholder: 'Tipo de Ocorrência',
      onOptionChange: option => setTypeOccurrence(option),
    },
  ];

  const getBudgets = useCallback(async () => {
    setIsLoading(true);

    setBudgetsList([]);
    setSearchHistory({});

    const formatParam = value => {
      if (!value) return undefined;

      return value.trim();
    };

    try {
      const { data: response, status } = await api.get('reports/budgets', {
        onProgress,
        headers: {
          Authorization: BEARER + user.token,
        },
        params: {
          occurrency_created_on_from: dates?.startDate,
          occurrency_created_on_to: dates?.endDate,
          nr_protocol: formatParam(numProtocolInput),
          associate_id: associate?.value,
          vehicle_id: vehicle?.value,
          type_budget_id: typesBudget?.value,
          status_payment_id: paymentStatus?.value,
          category_id: typeOccurrence?.value,
        },
      });

      if (status !== 200) throw new Error();

      const budgetsListLog = response.data.map(item => ({
        id: item.id,
        numProtocol: item?.nr_protocol,
        occurrenceDate: item?.occurrency_created_on,
        associateName: item?.vehicles
          ?.filter(
            (a, index, self) =>
              self.findIndex(t => t.client.id === a.client.id) === index,
          )
          .map(a => a?.client?.display_name || '-')
          .join(', '),
        vehiclePlate: item?.vehicles?.map(v => v?.plate || '-').join(', '),
        causes: item?.types?.map(a => a.name).join(', '),
        origin: getUniqueValues(
          item?.vehicles?.flatMap(v => v.locales) || [],
          'from_city',
          item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
        ),
        destiny: getUniqueValues(
          item?.vehicles?.flatMap(v => v.locales) || [],
          'to_city',
          item => `${item?.name || '-'} / ${item?.state?.short_code || '-'}`,
        ),
        typesBudget: item?.type_budget,
        serviceProviderName: item?.name_provider,
        valueBudget: item?.cost_budget || 0,
        valueClosed: item?.cost_closed || 0,
        excessPaid: item?.cost_excess || 0,
        associationPaid: item?.cost_covered || 0,
        budgetStatus: item?.status_budget,
        paymentDate: item?.date_payment,
      }));

      if (budgetsListLog.length > 0) {
        setSearchHistory({
          startDate: dates?.startDate,
          endDate: dates?.endDate,
          associateName: associate?.label,
          vehiclePlate: vehicle?.label,
          typesBudget: typesBudget?.label,
          paymentStatus: paymentStatus?.label,
          typeOccurrence: typeOccurrence?.label,
        });
      } else setSearchHistory({});

      setBudgetsList(budgetsListLog);

      setIsLoading(false);
      resetProgress();
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 404:
          errorMessage = NO_DATA_FOUND;
          break;
        default:
          errorMessage = STANDARD_ERROR;
      }
      toast.error(errorMessage);
      setIsLoading(false);
      resetProgress();
    }
  }, [
    dates,
    numProtocolInput,
    associate,
    vehicle,
    typesBudget,
    paymentStatus,
    typeOccurrence,
    searchHistory,
  ]);

  const dataTable = useMemo(() => {
    return budgetsList.map(item => ({
      ...item,
      numProtocol: item?.numProtocol || '-',
      occurrenceDate: item.occurrenceDate,
      fmtdOccurrenceDate: item.occurrenceDate
        ? defaultDateFormat(item.occurrenceDate)
        : '-',
      associateName: item?.associateName || '-',
      vehiclePlate: item?.vehiclePlate || '-',
      causes: item?.causes || '-',
      origin: item?.origin || '-',
      destiny: item?.destiny || '-',
      typesBudget: item?.typesBudget || '-',
      serviceProviderName: item?.serviceProviderName || 'Não contém',
      valueBudget: item.valueBudget,
      valueBudgetBRL: formatToBRL(item.valueBudget),
      valueClosed: item.valueClosed,
      valueClosedBRL: formatToBRL(item.valueClosed),
      excessPaid: item.excessPaid,
      excessPaidBRL: formatToBRL(item.excessPaid),
      associationPaid: item.associationPaid,
      associationPaidBRL: formatToBRL(item.associationPaid),
      budgetStatusValue: item?.budgetStatus || '-',
      budgetStatusDisplay: (
        <StatusDisplay config={configStatus} value={item.budgetStatus} />
      ),
      paymentDate: item.paymentDate,
      fmtdPaymentDate: item.paymentDate
        ? defaultDateFormat(item.paymentDate, false)
        : '-',
    }));
  }, [budgetsList]);

  const handleExportData = () => {
    const formatting = {
      occurrenceDate: defaultDateFormat,
      valueBudget: formatToBRL,
      valueClosed: formatToBRL,
      excessPaid: formatToBRL,
      associationPaid: formatToBRL,
      paymentDate: value => (value ? defaultDateFormat(value, false) : '-'),
    };

    try {
      const { items, visibleCols } = getExportData(
        tableRef.current?.getData(),
        columns,
        {
          formatting,
          validateColumns: true,
        },
      );

      const data = items.map(item => item.filter(Boolean));
      const headers = visibleCols.map(column => column.label);

      return { data, headers };
    } catch {
      return;
    }
  };

  const onExportPDF = async () => {
    const id = toast.loading(GETTING_PDF);

    const { data, headers } = handleExportData();

    const description =
      (searchHistory.associateName
        ? 'Associado: ' + searchHistory.associateName + '\n'
        : '') +
      (searchHistory.vehiclePlate
        ? 'Placa: ' + searchHistory.vehiclePlate + '\n'
        : '') +
      (searchHistory.typesBudget
        ? 'Tipo de Orçamento: ' + searchHistory.typesBudget + '\n'
        : '') +
      (searchHistory?.paymentStatus
        ? 'Status do Pagamento: ' + searchHistory.paymentStatus + '\n'
        : '') +
      (searchHistory?.typeOccurrence
        ? 'Tipo de Ocorrência: ' + searchHistory.typeOccurrence + '\n'
        : '') +
      'Período: ' +
      defaultDateFormat(searchHistory.startDate) +
      ' a ' +
      defaultDateFormat(searchHistory.endDate) +
      '\nProcessado em: ' +
      defaultDateFormat(new Date()) +
      '\nTotal de registros: ' +
      data.length;

    const exportData = {
      headers,
      title: 'Relatório de Orçamentos',
      description,
      data,
    };

    exportPDF(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: PDF_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: PDF_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  const onExportXLS = async () => {
    const id = toast.loading(GETTING_XLS);

    const { data, headers } = handleExportData();

    const exportData = {
      headers,
      title: 'Relatório de Orçamentos',
      data,
    };

    exportXLS(
      exportData,
      `${exportData.title} - ${exportDateFormat(
        searchHistory.startDate,
      )} - ${exportDateFormat(searchHistory.endDate)}`,
    )
      .then(() =>
        toast.update(id, {
          render: XLS_READY,
          type: 'success',
          isLoading: false,
          autoClose: 1500,
        }),
      )
      .catch(() => {
        toast.update(id, {
          render: XLS_ERROR,
          type: 'error',
          isLoading: false,
          autoClose: 1500,
        });
      });
  };

  useEffect(() => {
    getBudgets();

    return () => {
      toast.dismiss();
    };
  }, []);

  return (
    <PageContainer hidden={isLoading}>
      <PageHeader
        titles={['Relatórios', 'Orçamentos']}
        onExportPDF={budgetsList.length > 0 && onExportPDF}
        onExportXLS={budgetsList.length > 0 && onExportXLS}
      />

      <LoadingScreen isLoading={isLoading} progress={progress} />

      <FilterTool
        getDates={setDates}
        useColumnFilter={true}
        disableFilter={budgetsList.length === 0}
        inputs={inputs}
        defaultColumns={columns}
        disableSearch={false}
        onSubmit={getBudgets}
        onFilterColumns={setColumns}
        initialDates={getInitialDates({
          months: 1,
        })}
      />

      {budgetsList.length > 0 && (
        <Table ref={tableRef} columns={columns} data={dataTable} />
      )}
    </PageContainer>
  );
};

export default Budgets;
