import { useState, useEffect, memo, useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import ReactPlayer from 'react-player';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import { getFileName } from 'utils/functions';
import { fadeIn } from 'helpers/animations';

import useAuth from 'hooks/useAuth';

import DefaultModal from 'components/ui-kit/DefaultModal';
import Loader from 'components/ui-kit/Loader';
import PrimaryButton from './PrimaryButton';

import { ReactComponent as ArrowLeftIcon } from 'assets/images/svg/arrow_left_circle.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/svg/arrow_right_circle.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/svg/download.svg';

const Container = styled.div`
  ${tw`flex flex-col h-[90vh] md:h-[75vh] justify-center`}
`;

const FileWrapper = styled.div`
  ${tw`flex h-full w-full items-center justify-center bg-black rounded-xl overflow-hidden shadow-lg shadow-basic-black`}
`;

const FigureWrapper = styled.figure`
  ${tw`bg-no-repeat h-full w-full block`}

  img {
    ${tw`object-contain h-full w-full transition-opacity ease-in-out`}

    ${({ $hiddenImg }) => $hiddenImg && tw`hover:opacity-0`}
  }
`;

const NotSupported = styled.div`
  ${tw`flex w-full h-full gap-2 items-center justify-center italic text-center text-[var(--gray-dark)] font-semibold leading-tight`}
`;

const InfoWrapper = styled.div`
  ${tw`flex flex-col w-full gap-2 items-center justify-center pt-4 relative`}
`;

const ArrowButton = styled.button`
  ${tw`h-fit w-fit z-[1] bg-white rounded-circle shadow-md p-[2px] absolute top-8 disabled:cursor-not-allowed disabled:opacity-75`}
  animation: ${fadeIn} 400ms ease-in;
  ${({ $right }) => $right && tw`right-2`}
  ${({ $left }) => $left && tw`left-2`}
`;

const CountWrapper = styled.div`
  ${tw`flex flex-row h-fit w-fit bg-[var(--dark-blue-theme)] shadow-md py-1 px-2 rounded-lg border-white border-2
  text-white text-base font-semibold truncate`}
`;

const isImageFile = extension => {
  if (!extension) return false;
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  return imageExtensions.includes(extension.toLowerCase());
};

const isVideoFile = extension => {
  if (!extension) return false;
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'mkv'];
  return videoExtensions.includes(extension.toLowerCase());
};

const isPdfFile = extension => {
  if (!extension) return false;
  return extension.toLowerCase() === 'pdf';
};

const FileModal = ({
  isOpen = false,
  file = {},
  files = [],
  onClose = () => {},
}) => {
  const [mediaSrc, setMediaSrc] = useState(null);
  const [currentFile, setCurrentFile] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [zoomStyle, setZoomStyle] = useState({});

  const { user } = useAuth();

  const getFile = useCallback(
    async file => {
      setIsLoading(true);
      setZoomStyle({});

      if (mediaSrc) {
        setMediaSrc(null);
        window.URL.revokeObjectURL(mediaSrc);
      }

      const checkIfSupported =
        isImageFile(file?.extension) ||
        isVideoFile(file?.extension) ||
        isPdfFile(file?.extension);

      if (checkIfSupported) {
        try {
          const { data: response, status } = await api.get(
            `files/streaming/${file?.id}`,
            {
              headers: {
                Authorization: BEARER + user.token,
              },
              responseType: 'blob',
            },
          );

          if (status !== 200) throw new Error();

          const urlBlob = window.URL.createObjectURL(response);
          setMediaSrc(urlBlob);
        } catch {
          toast.error('Falha ao visualizar arquivo');
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    },
    [user.token],
  );

  const handleDownload = async id => {
    const toastId = toast.loading('Carregando arquivo');

    try {
      const {
        data: response,
        status,
        headers,
      } = await api.get(`files/streaming/${id}`, {
        headers: {
          Authorization: BEARER + user.token,
          'Access-Control-Expose-Headers': 'Content-Disposition',
        },
        responseType: 'arraybuffer',
      });

      if (status !== 200) throw new Error();

      const type = headers['content-type'];
      const urlBlob = window.URL.createObjectURL(
        new Blob([response], { type, encoding: 'UTF-8' }),
      );
      const filename = getFileName(headers['content-disposition']);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.dismiss(toastId);
    } catch {
      toast.update(toastId, {
        render: 'Falha ao baixar arquivo',
        type: 'error',
        isLoading: false,
      });
    }
  };

  const handlePrev = useCallback(() => {
    setCurrentIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : 0));
  }, [files]);

  const handleNext = useCallback(() => {
    setCurrentIndex(prevIndex =>
      prevIndex < files.length - 1 ? prevIndex + 1 : files.length - 1,
    );
  }, [files]);

  const handleClose = () => {
    setCurrentIndex(0);
    setMediaSrc(null);
    window.URL.revokeObjectURL(mediaSrc);
    setZoomStyle({});
    onClose();
  };

  const handleMouseMove = useCallback(
    e => {
      if (!mediaSrc) return;

      const { left, top, width, height } = e.target.getBoundingClientRect();
      const x = ((e.pageX - left) / width) * 100;
      const y = ((e.pageY - top) / height) * 100;

      const img = new Image();
      img.src = mediaSrc;

      img.onload = () => {
        const imgHeight = img.height;

        const scaleFactor = imgHeight / height;

        setZoomStyle({
          backgroundImage: `url(${mediaSrc})`,
          backgroundPosition: `${x}% ${y}%`,
          backgroundSize: scaleFactor < 1 ? '110%' : 'auto',
        });
      };
    },
    [mediaSrc],
  );

  const handleMouseOut = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setZoomStyle({});
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'ArrowLeft') {
        handlePrev();
      } else if (event.key === 'ArrowRight') {
        handleNext();
      }
    },
    [handlePrev, handleNext],
  );

  useEffect(() => {
    if (isOpen) {
      const findIndexFile = files.findIndex(f => f?.id === file?.id);
      const fetchFile = async () => await getFile(files[findIndexFile]);
      if (findIndexFile !== 0) setCurrentIndex(findIndexFile);
      setCurrentFile(files[findIndexFile]);
      fetchFile();
    }
  }, [isOpen, file, files, getFile]);

  useEffect(() => {
    const fetchFile = async () => await getFile(files[currentIndex]);
    setCurrentIndex(currentIndex);
    setCurrentFile(files[currentIndex]);
    fetchFile();
  }, [currentIndex, getFile]);

  useEffect(() => {
    if (isOpen && !isLoading) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, isLoading, handleKeyDown]);

  return (
    <DefaultModal
      isOpen={isOpen}
      title={currentFile?.name}
      onClose={handleClose}
      maxWidth="90vw"
      onBackgroundClick={handleClose}
      onEscapeKeyDown={handleClose}>
      <Container>
        {isLoading ? (
          <Loader center full />
        ) : mediaSrc ? (
          <FileWrapper onMouseOut={handleMouseOut}>
            {isImageFile(currentFile?.extension) && (
              <FigureWrapper
                style={zoomStyle}
                $hiddenImg={!!Object.keys(zoomStyle).length}
                onMouseMove={handleMouseMove}>
                <img
                  src={mediaSrc}
                  alt={currentFile.name}
                  onError={() => {
                    setMediaSrc(null);
                  }}
                />
              </FigureWrapper>
            )}

            {isVideoFile(currentFile?.extension) && (
              <ReactPlayer
                url={mediaSrc}
                playing
                controls
                width="100%"
                height="100%"
              />
            )}

            {isPdfFile(currentFile?.extension) && (
              <object
                width="100%"
                height="100%"
                data={mediaSrc}
                type="application/pdf"
              />
            )}
          </FileWrapper>
        ) : (
          <NotSupported>
            Visualização não disponível para este arquivo, tente baixá-lo e
            abri-lo externamente.
          </NotSupported>
        )}

        <InfoWrapper>
          <CountWrapper>
            {currentIndex + 1} / {files.length}
          </CountWrapper>

          <PrimaryButton
            bg="var(--green-gradient)"
            onClick={() => handleDownload(currentFile?.id)}>
            <DownloadIcon
              fill="#FFF"
              width="16px"
              height="16px"
              style={{
                flexShrink: 0,
              }}
            />
            Baixar
          </PrimaryButton>

          {currentIndex > 0 && (
            <ArrowButton
              $left
              disabled={isLoading}
              onClick={handlePrev}
              data-tooltip-id="nav"
              data-tooltip-content="Anterior">
              <ArrowLeftIcon
                height={40}
                width={40}
                fill="var(--dark-blue-theme)"
              />
            </ArrowButton>
          )}

          {currentIndex < files.length - 1 && (
            <ArrowButton
              $right
              disabled={isLoading}
              onClick={handleNext}
              data-tooltip-id="nav"
              data-tooltip-content="Próxima">
              <ArrowRightIcon
                height={40}
                width={40}
                fill="var(--dark-blue-theme)"
              />
            </ArrowButton>
          )}
          <Tooltip id="nav" />
        </InfoWrapper>
      </Container>
    </DefaultModal>
  );
};

export default memo(FileModal);
