import { cloneElement, useMemo } from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';

import { Tooltip } from 'react-tooltip';

import { ReactComponent as ViewIcon } from 'assets/images/svg/view.svg';
import { ReactComponent as TrashIcon } from 'assets/images/svg/trash.svg';
import { ReactComponent as EditIcon } from 'assets/images/svg/edit.svg';
import { ReactComponent as CheckIcon } from 'assets/images/svg/check.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';

const Button = styled.button`
  ${tw`flex p-[5px] justify-center items-center rounded-circle`}
  min-width: 24px;
  width: 24px;
  min-height: 24px;
  height: 24px;
`;

/**
 * @typedef {Object} IconButtonProps
 * @property {string} id - ID do botão de ícone.
 * @property {'edit' | 'deactivate' | 'activate' | 'view'} type - Tipo do ícone.
 * @property {Object} [iconProps={}] - Propriedades adicionais para o ícone.
 * @property {string} tooltipText - Texto do tooltip.
 * @property {React.CSSProperties} style - Estilos do botão.
 * @property {ReactNode} children
 * @property {...*} [rest] - Outras propriedades adicionais.
 */

/**
 * @type {React.FC<IconButtonProps>}
 */

const IconButton = ({
  children,
  id = 'icon-btn',
  type = 'edit',
  iconProps = {},
  style = {},
  tooltipText = '',
  ...rest
}) => {
  const color = useMemo(() => {
    switch (type) {
      case 'edit':
        return 'var(--dark-blue-theme)';
      case 'deactivate':
        return 'var(--red-theme)';
      case 'activate':
        return 'var(--green-theme)';
      case 'delete':
        return 'var(--red-dark)';
      case 'view':
        return 'var(--basic-primary)';
    }
  }, [type]);

  const text = useMemo(() => {
    switch (type) {
      case 'edit':
        return 'Editar';
      case 'deactivate':
        return 'Desativar';
      case 'activate':
        return 'Ativar';
      case 'delete':
        return 'Excluir';
      case 'view':
        return 'Visualizar';
    }
  }, [type]);

  const iconSettings = {
    fill: '#FFFFFF',
    width: '100%',
    height: '100%',
    flexshrink: 0,
  };

  return (
    <Button
      {...rest}
      type="button"
      id={id}
      data-tooltip-id={id}
      data-tooltip-content={tooltipText || text}
      style={{ backgroundColor: color, ...style }}>
      {children ? (
        cloneElement(children, { ...iconSettings, ...iconProps })
      ) : (
        <>
          {type === 'edit' && (
            <EditIcon
              {...iconSettings}
              width="90%"
              height="90%"
              {...iconProps}
            />
          )}

          {type === 'deactivate' && (
            <CloseIcon {...iconSettings} {...iconProps} />
          )}

          {type === 'activate' && (
            <CheckIcon {...iconSettings} {...iconProps} />
          )}

          {type === 'delete' && <TrashIcon {...iconSettings} {...iconProps} />}

          {type === 'view' && <ViewIcon {...iconSettings} {...iconProps} />}
        </>
      )}

      <Tooltip id={id} />
    </Button>
  );
};

export default IconButton;
