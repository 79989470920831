export const INVALID_EMAIL = 'E-mail inválido';
export const ROUTE_DISTANCE_BETWEEN_POINTS =
  'O trajeto apresentado é apenas uma ilustração da distância entre a localização origem e destino.';
export const FIELD_IS_REQUIRED = (field = '') =>
  `${field} deve ser preenchido.`;
export const INVALID_MIN_LENGTH = (field = '', length = 0) =>
  `${field} deve ter no mínimo ${length} caracteres`;
export const INVALID_CREDENTIALS = 'Nome de usuário ou senha inválidos.';
export const USER_NOT_FOUND = 'Usuário não encontrado.';
export const STANDARD_ERROR = 'Falha ao concluir solicitação, tente novamente.';
export const NO_DATA_FOUND = 'Nenhum dado foi encontrado.';
export const USER_STATUS_CHANGED = 'Status alterado com sucesso';
export const SUCCESS_USER = (field = '') => `Usuário ${field} com sucesso.`;
export const ERROR_USER = (field = '') => `Falha ao ${field} Usuário.`;
export const FAIL_CHANGE_PASSWORD = 'Falha ao tentar alterar senha.';
export const ERROR_UPDATING_USER_PHOTO = 'Falha ao atualizar foto do usuário.';
export const FAIL_GETTING_CITIES = 'Falha ao buscar cidades.';
export const FAIL_GETTING_STATES = 'Falha ao buscar estados.';
export const FAIL_GETTING_LOCATION =
  'Falha buscando coordenadas da localização.';
export const FAIL_GETTING_ROUTE = 'Falha buscando a rota.';
export const INVALID_IMG_SIZE = 'A imagem precisa ser maior.';
export const INVALID_IMG_FORMAT =
  'Por favor, escolha um arquivo de imagem válido.';
export const OCCURRENCE_TYPE_REQUIRED =
  'Precisa ter ao menos um tipo de ocorrência selecionado.';
export const NO_OCCURRENCIES_FOUND = 'Não há ocorrências registradas.';
export const OCCURRENCE_FINISHED_SUCCESS = 'Ocorrência finalizada com sucesso.';
export const XLS_READY = 'Arquivo XLS pronto.';
export const XLS_ERROR = 'Falha ao gerar XLS.';
export const GETTING_PDF = 'Gerando arquivo PDF.';
export const PDF_READY = 'Arquivo PDF pronto.';
export const PDF_ERROR = 'Falha ao gerar PDF';
export const GETTING_XLS = 'Gerando arquivo XLS.';
export const FAIL_SAVING_FILE = 'Falha ao salvar arquivo.';
export const FAIL_DELETING_FILE = 'Falha ao excluír arquivo.';
export const FILE_DELETED = 'Arquivo excluído com sucesso.';
export const UNIQUE_PHONE = 'Número de telefone já adicionado.';
export const SUCCESS_SERVICE_PROVIDER = (field = '') =>
  `Prestador de Serviço ${field} com sucesso.`;
export const ERROR_SERVICE_PROVIDER = (field = '') =>
  `Falha ao ${field} Prestador de Serviço.`;
export const BASIC_INFOS = 'Informações Básicas';
export const SENDING_INFO = 'Enviando informações';
export const ALREADY_SAVED_INFORMATION = 'Informações já salvas';
export const ERROR_ALREADY_EXISTS =
  'Erro ao inserir informações. Já existe um registro com os mesmos dados.';
export const SUCCESS_CAUSE = (field = '') => `Causa ${field} com sucesso.`;
export const ERROR_CAUSE = (field = '') => `Falha ao ${field} Causa.`;
export const LOADING_ROUTE = 'Carregando rota...';
export const ROUTE_LOADED = 'Rota calculada com sucesso.';
export const ROUTE_NOT_LOADED = 'Falha ao carregar rota.';
export const SUCCESS_TYPE_SERVICE = (field = '') =>
  `Tipo Serviço ${field} com sucesso.`;
export const ERROR_TYPE_SERVICE = (field = '') =>
  `Falha ao ${field} Tipo Serviço.`;
export const SUCCESS_PAYMENT_METHODS = (field = '') =>
  `Método de Pagamento ${field} com sucesso.`;
export const ERROR_PAYMENT_METHODS = (field = '') =>
  `Falha ao ${field} Método de Pagamento.`;
export const SUCCESS_MODULE = (field = '') => `Módulo ${field} com sucesso.`;
export const ERROR_MODULE = (field = '') => `Falha ao ${field} Módulo.`;
export const SUCCESS_SUBMODULE = (field = '') =>
  `Submódulo ${field} com sucesso.`;
export const ERROR_SUBMODULE = (field = '') => `Falha ao ${field} Submódulo.`;
export const SUCCESS_PERMISSION = (field = '') =>
  `Permissão ${field} com sucesso.`;
export const ACTION_NOT_ALLOWED = 'Ação não permitida.';
export const ERROR_PERMISSION = (field = '') => `Falha ao ${field} Permissão.`;
export const UNIQUE_DATA = (data = '') => `${data} já está em uso.`;
export const FAIL_SAVING_DATA = (data = '') => `Falha ao cadastrar ${data}.`;

// utility messages
export const ITEM_DELETED = (item = '') => `${item} excluído com sucesso.`;
export const FAIL_DELETING_ITEM = (item = '') => `Falha ao excluír ${item}.`;

export const ITEM_SAVED = (item = '') => `${item} cadastrado com sucesso.`;
export const FAIL_SAVING_ITEM = (item = '') => `Falha ao cadastrar ${item}.`;

export const ITEM_UPDATED = (item = '') => `${item} atualizado com sucesso.`;
export const FAIL_UPDATING_ITEM = (item = '') => `Falha ao atualizar ${item}.`;
