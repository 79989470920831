import { buildRoute } from 'utils/functions';

export const PERMISSIONS = ['visualize', 'create', 'update', 'delete'];

export const [VISUALIZE, CREATE, UPDATE, DELETE] = PERMISSIONS;

export const MODULES = {
  occurrences: { main: 'occurrences' },
  financial: { main: 'financial', sub: { payments: 'payments' } },
  register: {
    main: 'register',
    sub: {
      paymentMethods: 'payment-methods',
      modules: 'modules',
      permissions: 'permissions',
      servicesProviders: 'services-providers',
      causes: 'causes',
      typesServicesProvided: 'types-services-provided',
      users: 'users',
    },
  },
  reports: {
    main: 'reports',
    sub: {
      attendancesPerUser: 'attendances-per-user',
      financial: 'financial',
      occurrences: 'occurrences',
      budgets: 'budgets',
      useOfFranchise: 'use-of-franchise',
    },
  },
  bi: { main: 'bi', sub: { occurrences: 'occurrences', budgets: 'budgets' } },
};

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/esqueceu-senha',
};

// bearer pattern para requisições
export const BEARER = 'Bearer ';

export const DEV_ID = '2e741ef9-9c86-4d05-9c05-c874915cbd9e';
export const SUPERVISOR_ID = 'a60c6544-5d6d-453a-8047-fc0224be1128';
export const OPERATOR_ID = '52c1692d-03df-4c5a-b8dc-23b26419e4c5';

export const SUBROUTES = buildRoute('', {
  occurrences: {
    path: '/occurrences',
    subroutes: {
      new: {
        path: '/new',
      },
      id: {
        path: '/:id',
      },
    },
  },
  financial: {
    path: '/financial',
    subroutes: {
      payments: {
        path: '/payments',
        subroutes: {
          id: {
            path: '/:id',
          },
        },
      },
    },
  },
  register: {
    path: '/register',
    subroutes: {
      paymentMethods: {
        path: '/payment-methods',
        subroutes: {
          new: {
            path: '/new',
          },
          id: {
            path: '/:id',
          },
        },
      },
      modules: {
        path: '/modules',
        subroutes: {
          new: {
            path: '/new',
          },
          id: {
            path: '/:id',
          },
        },
      },
      permissions: {
        path: '/permissions',
        subroutes: {
          new: {
            path: '/new',
          },
          id: {
            path: '/:id',
          },
        },
      },
      servicesProviders: {
        path: '/services-providers',
        subroutes: {
          new: {
            path: '/new',
          },
          id: {
            path: '/:id',
          },
        },
      },
      causes: {
        path: '/causes',
        subroutes: {
          new: {
            path: '/new',
          },
          id: {
            path: '/:id',
          },
        },
      },
      typesServicesProvided: {
        path: '/types-services-provided',
        subroutes: {
          new: {
            path: '/new',
          },
          id: {
            path: '/:id',
          },
        },
      },
      users: {
        path: '/users',
        subroutes: {
          new: {
            path: '/new',
          },
          id: {
            path: '/:id',
          },
        },
      },
    },
  },
  reports: {
    path: '/reports',
    subroutes: {
      attendancesPerUser: {
        path: '/attendances-per-user',
      },
      financial: {
        path: '/financial',
      },
      occurrences: {
        path: '/occurrences',
      },
      budgets: {
        path: '/budgets',
      },
      useOfFranchise: {
        path: '/use-of-franchise',
      },
    },
  },
  bi: {
    path: '/bi',
    subroutes: {
      occurrences: {
        path: '/occurrences',
      },
      budgets: {
        path: '/budgets',
      },
    },
  },
  profile: {
    path: '/profile',
  },
});

export const COLORS = {
  theme: {
    red: 'var(--red-theme)',
    green: 'var(--green-theme)',
    blue: 'var(--blue-theme)',
    gray: 'var(--gray-theme)',
    dark_gray: 'var(--gray-dark)',
  },
};

export const LANGUAGES = [
  {
    name: 'Português',
    locale: 'pt-BR',
  },
  {
    name: 'Español',
    locale: 'es-ES',
  },
  {
    name: 'English',
    locale: 'en-US',
  },
];
