import { createSlice } from '@reduxjs/toolkit';

export const occurrencesSlice = createSlice({
  name: 'occurrences',
  initialState: { list: [] },
  reducers: {
    addOccurrences(state, action) {
      const index = state.list.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.list[index] = action.payload;
      } else {
        state.list.push(action.payload);
      }
    },
    updateOccurrences(state, action) {
      const { id, ...newData } = action.payload;
      const occurrence = state.list.find(item => item.id === id);
      if (occurrence) {
        Object.assign(occurrence, newData);
      }
    },
    deleteOccurrences(state, action) {
      const id = action.payload;
      const index = state.list.findIndex(item => item.id === id);
      if (index !== -1) {
        state.list.splice(index, 1);
      }
    },
    resetOccurrences(state) {
      state.list = [];
    },
  },
});

export const {
  addOccurrences,
  updateOccurrences,
  deleteOccurrences,
  resetOccurrences,
} = occurrencesSlice.actions;

export default occurrencesSlice.reducer;
