import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import useWindowSizes from 'hooks/useWindowSizes';
import styled from 'styled-components';
import tw from 'twin.macro';
import * as yup from 'yup';
import {
  differenceInDays,
  format,
  isAfter,
  subMinutes,
  subHours,
  subDays,
} from 'date-fns';

import SliderBase from 'react-slick';
import Calendar from 'react-calendar';
import SliderArrow from './SlickArrow';
import DefaultModal from './DefaultModal';

import { ReactComponent as CalendarIcon } from 'assets/images/svg/calendar.svg';

import { breakpoints } from 'helpers/breakpoints';
import {
  defaultDateFormat,
  getBackendDateFormat,
  variant,
} from 'utils/functions';

import 'react-calendar/dist/Calendar.css';

export const CalendarBox = styled.div`
  ${tw`w-fit flex overflow-hidden`}

  height: 275px;

  @media (max-width: ${breakpoints.lg}px) {
    ${tw`justify-center items-center w-full mb-4 last:mb-0`}
  }

  & .date-picker {
    ${tw`h-full [max-height: 100%] border rounded-lg border-themes-dark-gray overflow-hidden`}
  }

  & .react-calendar__navigation__label__labelText {
    ${tw`font-bold text-white`}
  }

  & .react-calendar__tile--active {
    background-color: #002da0;

    ${tw`text-white`}
  }

  & .react-calendar__tile--active:enabled:focus {
    background-color: #002da0;

    ${tw`text-white`}
  }

  & .react-calendar__navigation {
    background-color: #002da0;

    ${tw`bg-opacity-70`}
  }
`;

export const InputBox = styled.div`
  ${tw`flex [width: 48%] h-full justify-center first:mr-auto last:ml-auto`}

  & > * {
    ${tw`mr-1 last:mr-0`}
  }
`;

export const Input = styled.input`
  ${tw`bg-transparent focus:outline-0 [max-width: 120px]
        box-border px-12 text-themes-dark-gray font-bold`}

  ${props =>
    props.$error
      ? tw`border-b border-themes-red placeholder:text-themes-red`
      : ''}
`;

export const Text = styled('p')(
  {
    color: 'var(--gray-dark)',
    fontWeight: 400,
  },

  ({ $variant }) =>
    variant({
      title: {
        fontWeight: 500,
      },
      end: {
        fontSize: '10px',
        maxWidth: '100%',

        '@media (min-width: 625px)': {
          maxWidth: '200px',
        },
      },
      gmt: {
        fontSize: '10px',
        // eslint-disable-next-line no-undef
        color: '#002DA0',
        maxWidth: '100%',

        '@media (min-width: 625px)': {
          maxWidth: '200px',
        },
      },
      info: {
        fontSize: '12px',
        marginLeft: '10px',
      },
    })({ $variant }),
);

const Wrapper = styled.div`
  ${tw`grid [grid-template-columns: 75% 25%]`}

  @media (max-width: ${breakpoints.md}px) {
    ${tw`flex flex-col`}
  }

  @media (max-width: ${breakpoints.lg}px) {
    ${tw`[grid-template-columns: 70% 30%]`}
  }
`;

const OpenButton = styled.button`
  border: solid 2px var(--gray-theme);
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  ${tw`w-fit h-fit rounded-lg grid [grid-template-columns: 16px calc(100% - 16px)] box-border p-1 items-center`}
`;

const Container = styled.div`
  ${tw`flex flex-col w-full h-full overflow-hidden`}
`;

const Header = styled.div`
  ${tw`w-full h-fit mb-4 flex justify-between`}

  @media (max-width: ${breakpoints.lg}px) {
    ${tw`flex flex-col items-center justify-center`}
  }
`;

const DateBox = styled.div`
  ${tw`border border-themes-gray w-full rounded-xl
        flex items-center justify-center box-border py-1`}
`;

const Slider = styled(SliderBase)`
  ${tw`lg:[display: none] w-full [height: 280px] overflow-hidden relative`}

  & .slick-slider {
    ${tw`relative`}
  }

  & .slick-track {
    ${tw`flex relative`}
  }

  & .slick-list {
    ${tw`flex`}
  }

  & .slick-slide {
    ${tw`flex justify-center`}
  }

  & .slick-next,
  & .slick-prev {
    ${tw`absolute [z-index: 50]`}
  }
`;

const Form = styled.form`
  ${tw`w-full h-fit flex flex-col justify-between mt-2`}
`;

const CalendarContainer = styled.div`
  ${tw`[display: none] w-full h-full flex-row overflow-hidden lg:flex `}

  & > * {
    ${tw`mr-8 last:mr-0`}
  }
`;

const ButtonsContainer = styled.div`
  ${tw`w-full h-fit flex justify-end mt-6 box-border px-4 flex-row`}

  @media (max-width: 625px) {
    ${tw`mt-3`}
  }

  & > * {
    ${tw`mr-2 last:mr-0`}
  }
`;

const Button = styled.button`
  ${tw`w-8/12 [max-width: 150px] h-8 text-base border-none 
        flex items-center justify-center rounded-lg transition duration-200  hover:opacity-[0.85] disabled:bg-themes-dark-gray disabled:cursor-not-allowed`}

  @media (min-width: ${breakpoints.md}px) and (max-width: 900px) {
    ${tw`[max-width: 120px]`}
  }

  ${props =>
    props.$submit
      ? `    background-color: #002DA0};
    color: #FFFFFF;
    `
      : tw`bg-themes-gray text-black`};
`;

const PreSelectionContainer = styled.div`
  ${tw`[display: none] w-11/12 ml-auto border-l border-l-themes-gray flex flex-col justify-between
      box-border px-4 items-center md:flex`}
`;

const PreSelectionButton = styled.button`
  ${tw`border-none w-fit h-fit box-border px-3 py-1 rounded-xl`}

  ${props =>
    props.$isActive
      ? ` background-color: #002DA0;
    color: #FFFFFF;
    `
      : tw`bg-transparent text-themes-dark-gray`}
`;

const validationSchema = yup.object({
  startHour: yup.string().required(),
  startMinutes: yup.string().required(),
  endHour: yup.string().required(),
  endMinutes: yup.string().required(),
});

const getStartDate = range => {
  const date = new Date();

  date.setDate(date.getDate() - range);

  return date;
};

const DatePicker = ({
  minDate = undefined, // data minima que o date picker pode ir, pode exemplo new Date(11/09/2022)
  initialValues = {
    startDate: undefined, // valor inicial da data de inicio
    endDate: new Date(), // valor inicial da data final
  },
  maxRange = 'none', // max range é o intervalo maximo que o dapicker pode ir
  disabled = false, // se esta desabilitado
  disableInputs = false, // remover os inputs de horario
  customPreSelection = [], // customizar o array de pre seleções
  onChange = () => {}, // quando alterar o valor
  onChangeAutoFormatter = true, // pegar o valor de onChange já formatado
  getDates = () => {}, // quando clicar em ok pegar o formato de datas do backend
  getLocaleDates = () => {}, // quando clicar em ok pegar o formato de datas do javascript
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(
    initialValues?.startDate ||
      getStartDate(maxRange === 'none' ? 31 : maxRange),
  );
  const [endDate, setEndDate] = useState(initialValues?.endDate);
  const [preSelection, setPreSelection] = useState('');
  const [currentCalendar, setCurrentCalendar] = useState(0);
  const [error, setError] = useState(false);

  const { width } = useWindowSizes();

  const defaultPreSelection = [
    {
      label: '30 minutos',
      startDate: subMinutes(new Date(), 30),
      endDate: new Date(),
    },
    {
      label: '1 hora',
      startDate: subHours(new Date(), 1),
      endDate: new Date(),
    },
    {
      label: '2 horas',
      startDate: subHours(new Date(), 2),
      endDate: new Date(),
    },
    {
      label: '4 horas',
      startDate: subHours(new Date(), 4),
      endDate: new Date(),
    },
    {
      label: '12 horas',
      startDate: subHours(new Date(), 12),
      endDate: new Date(),
    },
    {
      label: '7 dias',
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
    },
    {
      label: '15 dias',
      startDate: subDays(new Date(), 15),
      endDate: new Date(),
    },
    {
      label: '31 dias',
      startDate: subDays(new Date(), 31),
      endDate: new Date(),
    },
    {
      label: 'Personalizado',
    },
  ];

  const sliderConfig = {
    className: 'slider',
    slidesToShow: 1,
    infinite: true,
    autoplay: false,
    draggable: false,
    focusOnSelect: false,
    nextArrow: <SliderArrow />,
    prevArrow: <SliderArrow isPrev className="calendar-prev-arrow" />,
    afterChange: setCurrentCalendar,
  };

  const calcLimit = (currentStartDate, currentEndDate) => {
    if (maxRange === 'none') {
      setStartDate(currentStartDate);
      setEndDate(currentEndDate);

      return { startDate: currentStartDate, endDate: currentEndDate };
    }

    const end = new Date(currentEndDate);
    const start = new Date(currentStartDate);

    const validMaxRange = typeof maxRange === 'number';

    const difference =
      currentStartDate !== startDate
        ? differenceInDays(currentEndDate, currentStartDate)
        : differenceInDays(currentStartDate, currentEndDate);

    if (validMaxRange && difference < maxRange * -1) {
      const inverse = difference * -1;

      start.setDate(start.getDate() + (inverse - maxRange));

      setStartDate(start);
      setEndDate(end);

      return { startDate: start, endDate: end };
    } else if (validMaxRange && difference > maxRange) {
      end.setDate(end.getDate() - (difference - maxRange));
      end.setSeconds(59);

      setStartDate(start);
      setEndDate(end);

      return { startDate: start, endDate: end };
    } else {
      setStartDate(start);
      setEndDate(end);

      end.setSeconds(59);

      return { startDate: start, endDate: end };
    }
  };

  const onChangeStartDate = date => {
    if (!date) return;

    const response = calcLimit(date, endDate);

    if (onChangeAutoFormatter) {
      onChange({
        startDate: format(response.startDate, 'yyyy-MM-dd HH:mm:ss'),
        endDate: format(response.endDate, 'yyyy-MM-dd HH:mm:ss'),
      });
    } else {
      onChange(response);
    }

    setPreSelection('Personalizado');
  };

  const onChangeEndDate = date => {
    if (!date) return;

    const response = calcLimit(startDate, date);

    if (onChangeAutoFormatter) {
      onChange({
        startDate: format(response.startDate, 'yyyy-MM-dd HH:mm:ss'),
        endDate: format(response.endDate, 'yyyy-MM-dd HH:mm:ss'),
      });
    } else {
      onChange(response);
    }

    setPreSelection('Personalizado');
  };

  const calendars = [
    {
      id: 'start',
      value: startDate,
      minDate,
      maxDate: endDate,
      onChange: date => {
        onChangeStartDate(date);

        setFieldValue('startHour', date.getHours());
        setFieldValue('startMinutes', date.getMinutes());
      },
    },
    {
      id: 'end',
      value: endDate,
      minDate: startDate,
      maxDate: new Date(),
      onChange: calendarDate => {
        let date = new Date(calendarDate);

        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);

        if (isAfter(date, new Date())) {
          date = new Date();
        }

        onChangeEndDate(date);

        setFieldValue('endHour', date.getHours());
        setFieldValue('endMinutes', date.getMinutes());
      },
    },
  ];

  const renderCalendars = () => {
    return calendars.map(calendar => {
      return (
        <CalendarBox key={calendar.id}>
          <Calendar
            className="date-picker"
            tileClassName="date-picker-tile"
            // locale="pt-BR"
            maxDate={calendar.maxDate}
            minDate={calendar.minDate}
            onChange={calendar.onChange}
            value={calendar.value}
          />
        </CalendarBox>
      );
    });
  };

  const onSubmit = values => {
    if (error) return;

    const start = new Date(startDate);
    const end = new Date(endDate);

    start.setHours(values.startHour);
    start.setMinutes(values.startMinutes);
    start.setSeconds(0);
    end.setHours(values.endHour);
    end.setMinutes(values.endMinutes);
    end.setSeconds(59);

    getLocaleDates({ startDate: start, endDate: end });
    getDates({
      startDate: getBackendDateFormat(start),
      endDate: getBackendDateFormat(end),
    });

    setIsOpen(false);
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      startHour: startDate?.getHours(),
      startMinutes: startDate?.getMinutes(),
      endHour: endDate?.getHours(),
      endMinutes: endDate?.getMinutes(),
    },
    validationSchema,
    onSubmit,
  });

  const formatEndInfo = () => {
    if (!isOpen) return;

    const start = format(startDate, 'dd/MM/yyyy');
    const end = format(endDate, 'dd/MM/yyyy');

    const formatDate = date => {
      return date.split('/').join(' de ');
    };

    return `${formatDate(start)} a ${formatDate(end)}`;
  };

  const onUsePreSelection = date => {
    const start = new Date(date.startDate);
    const end = new Date(date.endDate);

    setPreSelection(date.label);
    setStartDate(start);
    setEndDate(end);

    const startHour = start.getHours();
    const startMinutes = start.getMinutes();
    const endHour = end.getHours();
    const endMinutes = end.getMinutes();

    setValues({
      startHour,
      startMinutes,
      endHour,
      endMinutes,
    });
  };

  const renderPreSelection = useMemo(() => {
    let list = [];

    if (customPreSelection.length > 0) list = customPreSelection;
    else list = defaultPreSelection;

    return list.map(item => {
      return (
        <PreSelectionButton
          $isActive={preSelection === item.label}
          key={item.label}
          onClick={() =>
            item.label !== 'Personalizado' ? onUsePreSelection(item) : null
          }>
          {item.label}
        </PreSelectionButton>
      );
    });
  }, [customPreSelection, defaultPreSelection, preSelection]);

  useEffect(() => {
    const start = new Date(
      initialValues?.startDate ||
        getStartDate(maxRange === 'none' ? 31 : maxRange),
    );
    const end = new Date(initialValues?.endDate);

    start.setSeconds(0);
    end.setSeconds(59);

    getLocaleDates({ startDate: start, endDate: end });
    getDates({
      startDate: getBackendDateFormat(start),
      endDate: getBackendDateFormat(end),
    });
  }, []);

  useEffect(() => {
    if (onChangeAutoFormatter) {
      onChange({
        startDate: format(initialValues.startDate, 'yyyy-MM-dd HH:mm:ss'),
        endDate: format(initialValues.endDate, 'yyyy-MM-dd HH:mm:ss'),
      });
    }

    onChange(initialValues);
  }, [initialValues]);

  return (
    <>
      <OpenButton
        id="datepicker-opener"
        type="button"
        disabled={disabled}
        onClick={() => setIsOpen(true)}>
        <CalendarIcon fill="var(--gray-dark)" width={16} height={16} />

        <Text $variant="info">
          {defaultDateFormat(startDate)} a {defaultDateFormat(endDate)}
        </Text>
      </OpenButton>

      <DefaultModal
        isOpen={isOpen}
        maxWidth="1000px"
        onClose={() => setIsOpen(false)}
        onBackgroundClick={() => setIsOpen(false)}
        onEscapeKeyDown={() => setIsOpen(false)}>
        <Wrapper>
          <Container>
            <Header>
              {(width < breakpoints.lg && currentCalendar === 0) ||
              width > breakpoints.lg ? (
                <div
                  style={{
                    display: 'flex',
                    width: '90%',
                    maxWidth: '350px',
                    height: 'fit-content',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <Text $variant="title">Data Inicial</Text>

                  <DateBox>
                    <Text>{defaultDateFormat(startDate)}</Text>
                  </DateBox>
                </div>
              ) : null}

              {(width < breakpoints.lg && currentCalendar === 1) ||
              width > breakpoints.lg ? (
                <div
                  style={{
                    display: 'flex',
                    width: '90%',
                    maxWidth: '350px',
                    height: 'fit-content',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <Text $variant="title">Data Final</Text>

                  <DateBox>
                    <Text>{defaultDateFormat(endDate)}</Text>
                  </DateBox>
                </div>
              ) : null}
            </Header>

            <Slider {...sliderConfig}>{renderCalendars()}</Slider>

            <CalendarContainer>{renderCalendars()}</CalendarContainer>

            <Form onSubmit={handleSubmit}>
              {!disableInputs && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: 'fit-content',
                  }}>
                  {(width < breakpoints.lg && currentCalendar === 0) ||
                  width > breakpoints.lg ? (
                    <InputBox>
                      <Input
                        id="startHour"
                        maxLength={2}
                        value={values.startHour}
                        autoComplete="off"
                        onChange={event => {
                          const { value } = event.target;

                          if (!value.match(/^\d*$/) || value > 23) return;

                          const newDate = startDate;
                          newDate.setHours(value ? value : 0);

                          if (newDate > endDate) return;

                          if (error) setError(false);

                          handleChange(event);
                          setStartDate(newDate);
                        }}
                        onBlur={handleBlur}
                        $error={errors.startHour && touched.startHour}
                        placeholder={
                          errors.startHour && touched.startHour ? '*' : ''
                        }
                      />

                      <Text $variant="title">:</Text>

                      <Input
                        id="startMinutes"
                        maxLength={2}
                        autoComplete="off"
                        value={values.startMinutes}
                        onChange={event => {
                          const { value } = event.target;

                          if (!value.match(/^\d*$/) || value > 59) return;

                          const newDate = startDate;
                          newDate.setMinutes(value ? value : 0);

                          if (newDate > endDate) return;

                          if (error) setError(false);

                          handleChange(event);
                          setStartDate(newDate);
                        }}
                        onBlur={handleBlur}
                        $error={errors.startMinutes && touched.startMinutes}
                        placeholder={
                          errors.startMinutes && touched.startMinutes ? '*' : ''
                        }
                      />
                    </InputBox>
                  ) : null}

                  {(width < breakpoints.lg && currentCalendar === 1) ||
                  width > breakpoints.lg ? (
                    <InputBox>
                      <Input
                        id="endHour"
                        maxLength={2}
                        autoComplete="off"
                        value={values.endHour}
                        onChange={event => {
                          const { value } = event.target;

                          if (!value.match(/^\d*$/) || value > 23) return;

                          const date = endDate;
                          date.setHours(value ? value : 0);

                          if (date < startDate) return;

                          if (error) setError(false);

                          handleChange(event);
                          setEndDate(date);
                        }}
                        onBlur={handleBlur}
                        $error={errors.endHour && touched.endHour}
                        placeholder={
                          errors.endHour && touched.endHour ? '*' : ''
                        }
                      />

                      <Text $variant="title">:</Text>

                      <Input
                        id="endMinutes"
                        maxLength={2}
                        autoComplete="off"
                        value={values.endMinutes}
                        onChange={event => {
                          const { value } = event.target;

                          if (!value.match(/^\d*$/) || value > 59) return;

                          const date = endDate;
                          date.setMinutes(value ? value : 0);

                          if (date.getTime() < startDate.getTime()) {
                            setError(true);
                            handleChange(event);
                          } else {
                            handleChange(event);
                            setEndDate(date);

                            if (error) setError(false);
                          }
                        }}
                        onBlur={handleBlur}
                        $error={errors.endMinutes && touched.endMinutes}
                        placeholder={
                          errors.endMinutes && touched.endMinutes ? '*' : ''
                        }
                      />
                    </InputBox>
                  ) : null}
                </div>
              )}

              {width <= 625 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'fit-content',
                    height: '100%',
                    margin: '1rem auto 0 auto',
                  }}>
                  <Text $variant="end">{formatEndInfo()}</Text>

                  <Text $variant="gmt">
                    Horário de Brasília (GMT: UTC-03:00)
                  </Text>
                </div>
              )}

              <ButtonsContainer>
                {width > 625 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 'fit-content',
                      height: '100%',
                      marginRight: 'auto',
                    }}>
                    <Text $variant="end">{formatEndInfo()}</Text>

                    <Text $variant="gmt">
                      Horário de Brasília (GMT: UTC-03:00)
                    </Text>
                  </div>
                )}

                <Button
                  type="button"
                  onClick={() => {
                    resetForm();
                    setIsOpen(false);
                    setEndDate(initialValues.endDate);
                    setStartDate(
                      initialValues.startDate || getStartDate(maxRange),
                    );
                    setPreSelection('');
                  }}>
                  Cancelar
                </Button>

                <Button $submit type="submit" disabled={error}>
                  Continuar
                </Button>
              </ButtonsContainer>
            </Form>
          </Container>

          <PreSelectionContainer>{renderPreSelection}</PreSelectionContainer>
        </Wrapper>
      </DefaultModal>
    </>
  );
};

export default DatePicker;
