import axios from 'axios';

const baseURL =
  process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION; // eslint-disable-line no-undef

const api = axios.create({
  baseURL,
  maxRedirects: 0,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      const event = new Event('session-expired');

      window.dispatchEvent(event);
    }

    return Promise.reject(error);
  },
);

export default api;
