import { useState, memo, useEffect, useRef } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { parseISO } from 'date-fns';
import CurrencyInput from 'react-currency-input-field';
import * as yup from 'yup';

import LoadingScreen from 'components/ui-kit/LoadingScreen';
import Input from 'components/ui-kit/Input';
import Textarea from 'components/ui-kit/Textarea';
import Select from 'components/ui-kit/Select';
import DateInput from 'components/ui-kit/DateInput';
import FileInput from 'components/ui-kit/FileInput';
import PrimaryButton from 'components/ui-kit/PrimaryButton';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import { FIELD_IS_REQUIRED, ALREADY_SAVED_INFORMATION } from 'utils/messages';
import useAuth from 'hooks/useAuth';
import useLoadingProgress from 'hooks/useLoadingProgress';

import { getBackendDateFormat, buildPayload } from 'utils/functions';

const Card = styled.form`
  ${tw`flex flex-col h-fit w-full max-w-sm items-center mt-5 pt-6 p-1 bg-white border-4 border-[var(--royal-blue-theme)] rounded-2xl gap-3 relative shadow-lg`}

  .title {
    ${tw`absolute px-3 py-1 text-base font-bold text-white bg-opacity-25 rounded-md [background: var(--blue-gradient)] truncate max-w-full
    top-0 -translate-y-1/2`}
  }
`;

const ContainerInputs = styled.div`
  ${tw`grid w-full gap-3 grid-cols-1`}

  ${props => props.$cols2 && tw`grid-cols-1 md:grid-cols-2`}
`;

const Text = styled.span`
  ${tw`text-base text-center font-semibold text-[var(--royal-blue-theme)] truncate max-w-full`}
`;

const toFloat = value => {
  if (typeof value === 'string') {
    return parseFloat(value.replace(',', '.'));
  }
  return value;
};

const validationSchema = yup.object().shape({
  closedValue: yup
    .string()
    .nullable()
    .when('paymentStatusId', (paymentStatusId, schema) => {
      return paymentStatusId == 3
        ? schema.required(FIELD_IS_REQUIRED('Valor Fechado'))
        : schema;
    }),
  associationValue: yup
    .string()
    .nullable()
    .when('paymentStatusId', (paymentStatusId, schema) => {
      return paymentStatusId == 3
        ? schema.required(FIELD_IS_REQUIRED('Valor Associação'))
        : schema;
    }),
  paymentMethodId: yup
    .number()
    .when('paymentStatusId', (paymentStatusId, schema) => {
      return paymentStatusId == 3
        ? schema.required(FIELD_IS_REQUIRED('Método de Pagamento'))
        : schema;
    }),
  paymentDate: yup
    .string()
    .nullable()
    .when('paymentStatusId', (paymentStatusId, schema) => {
      return paymentStatusId == 3
        ? schema.required(FIELD_IS_REQUIRED('Data do Pagamento'))
        : schema;
    }),
  paymentStatusId: yup
    .number()
    .required(FIELD_IS_REQUIRED('Status do Pagamento')),
});

const BudgetForm = ({
  data = {},
  occurrenceId = undefined,
  isDisabled = false,
  afterUpdate = () => {},
}) => {
  const [filesPayment, setFilesPayment] = useState(data.filesPayment);
  const [excessValue, setExcessValue] = useState(data?.excessValue || 0);
  const [isLoading, setIsLoading] = useState(false);

  const filesRef = useRef(null);
  const paymentMethodSelect = useRef(null);
  const paymentStatusSelect = useRef(null);

  const { user } = useAuth();
  const { onProgress, resetProgress, progress } = useLoadingProgress();

  const onSubmit = async values => {
    setIsLoading(true);

    const keyMapping = {
      closedValue: 'cost_final',
      associationValue: 'cost_covered',
      paymentMethodId: 'method_payment_id',
      paymentDate: 'date_payment',
      paymentStatusId: 'status_payment_id',
      commentsPayment: 'description_payment',
    };

    let newValues = { ...values };

    newValues['closedValue'] = toFloat(values?.closedValue);
    newValues['associationValue'] = toFloat(values?.associationValue);

    const payload = buildPayload(keyMapping, newValues, data);

    const newFiles = filesPayment.filter(file => !file.id);
    const currentFileIds = filesPayment.reduce((ids, file) => {
      if (file.id) {
        ids.push(file.id);
      }
      return ids;
    }, []);

    let allFileIds = [...currentFileIds];
    if (newFiles.length > 0) {
      const newFileIds = await filesRef.current?.sendFiles(newFiles);
      allFileIds = [...allFileIds, ...newFileIds];
    }

    const initialFileIds =
      data?.filesPayment?.map(file => ({
        id: file.id,
        name: file.name,
      })) || [];

    const removedFiles = initialFileIds.filter(
      file => !currentFileIds.includes(file.id),
    );

    if (removedFiles.length) {
      await filesRef.current?.deleteFiles(removedFiles);
    }

    const fileIdsChanged =
      JSON.stringify(data?.filesPayment?.map(file => file.id).sort()) !==
      JSON.stringify(allFileIds.sort());

    if (fileIdsChanged) {
      payload['files_payments_ids'] = allFileIds;
    }

    if (!Object.keys(payload)?.length) {
      toast.success(ALREADY_SAVED_INFORMATION);
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.put(
        `/occurrencies/${occurrenceId}/budgets/${data.budgetId}/payment`,
        { data: payload },
        {
          onProgress,
          headers: {
            Authorization: BEARER + user.token,
          },
        },
      );

      if (response.status !== 200) throw new Error();

      toast.success('Pagamento atualizado');
      afterUpdate(occurrenceId);
    } catch (error) {
      let errorMessage;
      switch (error.response?.status) {
        case 400:
          errorMessage =
            'Ocorrência Encerrada: edição de pagamento indisponível';
          afterUpdate(occurrenceId);
          break;
        default:
          errorMessage = 'Falha ao salvar Pagamento';
      }
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      resetProgress();
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    setValues,
    handleChange,
    values,
    submitCount,
    errors,
  } = useFormik({
    initialValues: {
      closedValue: undefined,
      associationValue: undefined,
      paymentMethodId: undefined,
      paymentDate: undefined,
      paymentStatusId: 1,
      commentsPayment: '',
    },
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    if (!values.closedValue || !values.associationValue) {
      setExcessValue(0);
      return;
    }
    setExcessValue(
      (toFloat(values.closedValue) - toFloat(values.associationValue)).toFixed(
        2,
      ),
    );
  }, [values.closedValue, values.associationValue]);

  useEffect(() => {
    if (data.paymentMethodId && data.paymentMethodName) {
      paymentMethodSelect.current?.setValue({
        value: data.paymentMethodId,
        label: data.paymentMethodName,
        autoSelection: true,
      });
    }
    if (data.paymentStatusId && data.paymentStatusName) {
      paymentStatusSelect.current?.setValue({
        value: data.paymentStatusId,
        label: data.paymentStatusName,
        autoSelection: true,
      });
    }
    setValues({
      closedValue: data.closedValue,
      associationValue: data.associationValue,
      paymentMethodId: data.paymentMethodId,
      paymentDate: data.paymentDate,
      paymentStatusId: data.paymentStatusId,
      commentsPayment: data.commentsPayment,
    });
  }, [data]);

  return (
    <>
      <LoadingScreen isLoading={isLoading} progress={progress} />

      <Card onSubmit={handleSubmit}>
        <div className="title">{data?.typeName}</div>

        {data?.serviceProvider && (
          <Text
            data-tooltip-id={data?.budgetId}
            data-tooltip-content={data?.serviceProvider}>
            Prestador: {data?.serviceProvider}
          </Text>
        )}
        <Text
          data-tooltip-id={data?.budgetId}
          data-tooltip-content={data?.commentsBudget}>
          Observações: {data?.commentsBudget}
        </Text>

        <Tooltip id={data?.budgetId} />
        <ContainerInputs $cols2>
          <CurrencyInput
            customInput={Input}
            maxWidth="100%"
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            label={{ text: 'Valor Orçado' }}
            placeholder=""
            value={data.budgetValue}
            disabled={true}
          />
          <CurrencyInput
            id="closedValue"
            customInput={Input}
            maxWidth="100%"
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            label={{ text: 'Valor Fechado' }}
            placeholder=""
            value={values.closedValue}
            onValueChange={value => {
              setFieldValue('closedValue', value);
            }}
            disabled={isDisabled}
            error={{
              isActive: submitCount > 0 && errors.closedValue,
              message: errors.closedValue,
            }}
          />
          <CurrencyInput
            customInput={Input}
            maxWidth="100%"
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            label={{ text: 'Pago Excedente' }}
            placeholder=""
            value={excessValue}
            disabled={true}
          />
          <CurrencyInput
            id="associationValue"
            customInput={Input}
            maxWidth="100%"
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            label={{ text: 'Pago Associação' }}
            placeholder=""
            value={values.associationValue}
            onValueChange={value => {
              setFieldValue('associationValue', value);
            }}
            disabled={isDisabled}
            error={{
              isActive: submitCount > 0 && errors.associationValue,
              message: errors.associationValue,
            }}
          />
        </ContainerInputs>

        <ContainerInputs>
          <Select
            ref={paymentMethodSelect}
            size="sm"
            maxWidth="100%"
            menuMaxHeight={130}
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint="payments/methods/?active=true"
            onOptionChange={option => {
              setFieldValue('paymentMethodId', option.value);
            }}
            placeholder=""
            label={{
              text: 'Método de Pagamento',
            }}
            error={{
              isActive: submitCount > 0 && errors.paymentMethodId,
              message: errors.paymentMethodId,
            }}
            isDisabled={isDisabled}
          />

          <DateInput
            getDates={date =>
              date
                ? setFieldValue('paymentDate', getBackendDateFormat(date))
                : setFieldValue('paymentDate', undefined)
            }
            label={{
              text: 'Data do Pagamento',
            }}
            initialValue={parseISO(data.paymentDate)}
            isClearable={true}
            disableTimes={true}
            blockFuture={true}
            closeAfterPick={true}
            error={{
              isActive: submitCount > 0 && errors.paymentDate,
              message: errors.paymentDate,
            }}
            isDisabled={isDisabled}
          />

          <Select
            ref={paymentStatusSelect}
            size="sm"
            maxWidth="100%"
            isClearable={false}
            menuMaxHeight={130}
            fieldToOptionValue="id"
            fieldToOptionLabel="name"
            endpoint="payments/status/"
            onOptionChange={option => {
              setFieldValue('paymentStatusId', option.value);
            }}
            placeholder=""
            label={{
              text: 'Status do Pagamento',
            }}
            error={{
              isActive: submitCount > 0 && errors.paymentStatusId,
              message: errors.paymentStatusId,
            }}
            isDisabled={isDisabled}
          />

          <Textarea
            id="commentsPayment"
            placeholder="Observações do Pagamento"
            value={values.commentsPayment}
            onChange={handleChange}
            height="80px"
            disabled={isDisabled}
          />
          <FileInput
            text={{
              title: 'Arquivos do orçamento',
              notFound: 'Nenhum arquivo do orçamento encontrado',
            }}
            height="100px"
            files={data.filesBudget}
            isDisabled={true}
          />
          <FileInput
            ref={filesRef}
            text={{
              title: 'Arquivos do pagamento',
              notFound: 'Clique aqui para anexar os comprovantes do pagamento',
            }}
            height="100px"
            fileType=".xlsx, .xls, image/*, .doc, .docx, .txt, .pdf"
            files={filesPayment}
            setFiles={setFilesPayment}
            onProgress={onProgress}
            isDisabled={isDisabled}
          />
        </ContainerInputs>
        {!isDisabled && (
          <PrimaryButton
            maxWidth="100%"
            type="submit"
            bg="green"
            disabled={isLoading}>
            Salvar Pagamento
          </PrimaryButton>
        )}
      </Card>
    </>
  );
};

export default memo(BudgetForm);
