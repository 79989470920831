import {
  memo,
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { startOfYear, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ThreeDots } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip';

import api from 'services/api';
import { BEARER } from 'utils/constants';
import Formatter from 'utils/formatter';
import useAuth from 'hooks/useAuth';

import Table from 'components/ui-kit/Table';

import { getBackendDateFormat } from 'utils/functions';

import { ReactComponent as InfoIcon } from 'assets/images/svg/info-fill.svg';
import { ReactComponent as CloseIcon } from 'assets/images/svg/close.svg';

const Container = styled.div`
  ${tw`flex flex-wrap w-full items-center gap-2 h-fit absolute translate-y-[30px] justify-center transition-all ease-in-out z-10`}
`;
const ActivationsWrapper = styled.div`
  ${tw`text-[var(--gray-dark)] text-sm font-semibold text-center leading-3`}

  .value {
    ${tw`font-bold px-1`}
  }
`;

const DetailsContainer = styled(motion.div)`
  ${tw`h-fit w-[330px] sm:w-[500px] md:w-[600px] max-h-[450px] z-[1] top-full right-auto absolute`}
`;

const DetailsBox = styled(motion.div)`
  ${tw`flex flex-col w-full h-full bg-white gap-2 p-1 items-center
  rounded-lg border-solid border-2 border-[var(--gray-dark)]`}
`;

const Title = styled.span`
  ${tw`text-lg text-center text-[var(--royal-blue-theme)] font-bold leading-5 px-5`}
`;

const CloseButton = styled.button`
  ${tw`w-fit h-fit border-none bg-transparent flex items-center absolute top-0 right-0 m-2 z-10`}
`;

const boxVariants = {
  open: {
    clipPath: 'inset(0% 0% 0% 0%)',
    transition: {
      type: 'spring',
      duration: 0.4,
    },
  },
  closed: {
    clipPath: 'inset(10% 50% 90% 50%)',
    transition: {
      type: 'spring',
      duration: 0.1,
    },
  },
};

const defaultColumns = [
  {
    label: 'Data',
    field: 'date',
    display: 'fmtdDate',
  },
  { label: 'Tipo', field: 'typeName' },
  { label: 'N° Protocolo', field: 'numProtocol' },
  { label: 'Associado', field: 'associateName' },
];

const getType = id => {
  switch (id) {
    case 1:
      return 'Acidente';
    case 2:
      return 'Pane';
    case 3:
      return 'Vidro';
  }
};

const ActivationsInfo = ({ typeId = undefined, vehiclePlate = undefined }) => {
  const [activationsList, setActivationsList] = useState([]);

  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tableRef = useRef(null);

  const { user } = useAuth();

  const { defaultDateFormat } = new Formatter();

  const getActivations = useCallback(async () => {
    setIsOpenInfo(false);
    setIsLoading(true);
    setActivationsList([]);

    try {
      const { data: response, status } = await api.get(
        `occurrencies/plate/${vehiclePlate}/history`,
        {
          headers: {
            Authorization: BEARER + user.token,
          },
          params: {
            created_begin: getBackendDateFormat(startOfYear(new Date())),
            created_end: getBackendDateFormat(new Date()),
            category_id: typeId,
          },
        },
      );

      if (status !== 200) throw new Error();

      const activationsLog = response.data.map(item => ({
        date: item?.created_on,
        typeName: item?.category_name,
        numProtocol: item?.nr_protocol,
        associateName: item?.client_display_name,
      }));

      setActivationsList(activationsLog);

      if (activationsLog.length >= 3) {
        toast.warning(
          'Este veículo possui uma quantidade de acionamentos igual ou superior a 3 no ano',
          { autoClose: 3000 },
        );
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        toast.error('Erro ao buscar acionamentos');
      }
    } finally {
      setIsLoading(false);
    }
  }, [vehiclePlate, typeId]);

  const dataTable = useMemo(() => {
    return activationsList.map(item => ({
      ...item,
      fmtdDate: item.date ? defaultDateFormat(item.date) : '-',
      typeName: item.typeName || '-',
      numProtocol: item?.numProtocol || '-',
      associateName: item?.associateName || '-',
    }));
  }, [activationsList]);

  useLayoutEffect(() => {
    if (!vehiclePlate || !typeId) return;

    const fetchActivations = async () => await getActivations();
    fetchActivations();

    return () => {
      toast.dismiss();
    };
  }, [vehiclePlate, typeId]);

  return (
    <Container>
      {isLoading ? (
        <ThreeDots visible width={30} height={15} color="var(--gray-dark)" />
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}>
          <ActivationsWrapper>
            Acionamentos no ano:
            <span
              className="value"
              style={{
                color:
                  dataTable.length >= 3
                    ? 'var(--warning-dark)'
                    : 'var(--green-theme)',
              }}>
              {dataTable.length}
            </span>
          </ActivationsWrapper>
          {!!dataTable.length && (
            <>
              <InfoIcon
                width={18}
                height={18}
                fill="var(--gray-dark)"
                style={{ cursor: 'pointer', outline: 'none' }}
                data-tooltip-id="info-button"
                data-tooltip-content="Ver detalhes"
                onClick={() => setIsOpenInfo(!isOpenInfo)}
              />
              <Tooltip id="info-button" place="bottom" />
            </>
          )}
        </div>
      )}
      <DetailsContainer
        initial={false}
        animate={isOpenInfo ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, display: 'flex' },
          closed: {
            opacity: 0,
            transitionEnd: {
              display: 'none',
            },
          },
        }}>
        <DetailsBox variants={boxVariants}>
          <Title>
            Acionamentos do Tipo {getType(typeId)} para a placa {vehiclePlate}{' '}
            em{' '}
            {format(utcToZonedTime(new Date(), 'America/Sao_Paulo'), 'yyyy', {
              timeZone: 'America/Sao_Paulo',
            })}
            :
          </Title>

          {!!dataTable.length && (
            <Table ref={tableRef} columns={defaultColumns} data={dataTable} />
          )}
          <CloseButton
            type="button"
            data-tooltip-id="close-button"
            data-tooltip-content="Fechar"
            onClick={() => setIsOpenInfo(false)}>
            <CloseIcon fill="var(--gray-dark)" width={15} height={15} />

            <Tooltip id="close-button" place="left" />
          </CloseButton>
        </DetailsBox>
      </DetailsContainer>
    </Container>
  );
};

export default memo(ActivationsInfo);
